import {
  CreateSubCategoryInput,
  UpdateSubCategoryInput,
} from "./../models/GQL_API";
import { ListingByConceptVariables, Option } from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import { SubCategory } from "../models";
import { createSubCategory, updateSubCategory } from "../graphql/mutations";
import { getSubCategory, listSubCategories, subCategoryByConceptID } from "../graphql/queries";
import { HeadCell } from "../models/dataTable";
import {
  setAllListing,
  setLastIndex,
  setListing,
  setNextToken,
  setPagination,
} from "../store/ducks/subCategory";
import { getDateFormatted } from "../helpers/utils";
import { onCreateSubCategory } from "../graphql/subscriptions";
import { Languages } from "../constants/enums";


const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();
  const language = useSelector((state: any) => state.accounts.language);

  const session = useSelector((state: any) => state.app.session);
  const subCategoriesListing = useSelector(
    (state: any) => state.subCategories.listing
  );
  const subCategoriesAllListing = useSelector(
    (state: any) => state.subCategories.allListing
  );

  const nextToken = useSelector((state: any) => state.subCategories.nextToken);
  const lastIndex = useSelector((state: any) => state.subCategories.lastIndex);
  const paginationListing = useSelector(
    (state: any) => state.subCategories.pagination
  );
  const selectedConcept = useSelector((state: any) => state.concepts.selected);

  async function fetch(params: ListingByConceptVariables) {
    try {
      const { conceptID, searchText, limit, startIndex } = params;
      const filter: any = {
        conceptID: { eq: conceptID ? conceptID : selectedConcept },
        deleted: { eq: "0" },
      };
      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      const subCategoriesList: any = await API.graphql<
        GraphQLQuery<SubCategory>
      >({
        query: listSubCategories,
        variables: { filter, limit, nextToken },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      const currentNextToken =
        subCategoriesList.data.listSubCategories.nextToken;
      const responseListing = subCategoriesList.data.listSubCategories.items;

      let listing = [...subCategoriesListing, ...responseListing];
      // console.log({ listing });
      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function fetchAll(params: ListingByConceptVariables) {
    try {
      const { conceptID, searchText, limit, startIndex } = params;
      const filter: any = {
        conceptID: { eq: conceptID ? conceptID : selectedConcept },
        deleted: { eq: "0" },
      };
      if (searchText && searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      if (
        subCategoriesAllListing.length === 0 ||
        conceptID !== subCategoriesAllListing[0].conceptID
      ) {
        const listing: any = await API.graphql<GraphQLQuery<SubCategory>>({
          query: listSubCategories,
          variables: { filter, limit: limit ?? 1000 },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        return listing.data.listSubCategories.items;
      } else {
        return subCategoriesAllListing;
      }
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }

  async function fetchSubCategoryByConceptID(
    params: ListingByConceptVariables
  ) {
    try {
      const { conceptID, searchText, limit, forceRefresh } = params;
      const filter: any = {
        deleted: { eq: "0" },
      };
      if (searchText && searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      if (
        subCategoriesAllListing.length === 0 ||
        conceptID !== subCategoriesAllListing[0].conceptID ||
        forceRefresh
      ) {
        const listing: any = await API.graphql<GraphQLQuery<SubCategory>>({
          query: subCategoryByConceptID,
          variables: { filter, conceptID: conceptID, limit: limit ?? 10000 },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        let nextToken = listing.data.subCategoryByConceptID.nextToken;
        let allItems = listing.data.subCategoryByConceptID.items;

        while (nextToken && nextToken.length > 0) {
          const newListing: any = await API.graphql<GraphQLQuery<SubCategory>>({
            query: subCategoryByConceptID,
            variables: {
              filter,
              conceptID: conceptID,
              limit: limit ?? 10000,
              nextToken,
            },
            authMode: session
              ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
              : GRAPHQL_AUTH_MODE.AWS_IAM,
          });

          allItems = allItems.concat(
            newListing.data.subCategoryByConceptID.items
          );
          nextToken = newListing.data.subCategoryByConceptID.nextToken;
        }
        return allItems.sort((a: any, b: any) => {
          return b.precedence - a.precedence;
        });
      } else {
        return subCategoriesAllListing;
      }
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }

  async function get(params: any) {
    const { id } = params;

    try {
      const subCategory: any = await API.graphql({
        query: getSubCategory,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return subCategory.data.getSubCategory;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: any, session = true) {
    let { userID, userName, data } = params;

    let multiLanguages: any = [[`${Languages.ENGLISH}-name`, data.name]];
    multiLanguages = JSON.stringify(multiLanguages);

    try {
      const createInput: CreateSubCategoryInput = {
        name: data.name.toLowerCase(),
        precedence: data.precedence ? data.precedence : "0",
        image: data.image ? data.image : null,
        menuPDF: data.menuPDF ? data.menuPDF : null,
        conceptID: data.conceptID,
        mainCategoryID: data.mainCategoryID ? data.mainCategoryID : null,
        openTime: data.openTime ? data.openTime : null,
        closeTime: data.closeTime ? data.closeTime : null,
        guestView: data.guestView ? data.guestView : false,
        guestOrder: data.guestOrder ? data.guestOrder : false,
        staffOrder: data.staffOrder ? data.staffOrder : false,
        multiLanguages: multiLanguages,
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };
      const subCategory: any = await API.graphql<GraphQLQuery<SubCategory>>({
        query: createSubCategory,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));

      let newListing = [
        ...subCategoriesAllListing,
        subCategory.data.createSubCategory,
      ];
      dispatch(setAllListing(newListing));

      showConfirm(`New ${singleName} has been created successfully`);
      return subCategory;
    } catch (err) {
      console.log(err);
    }
  }

  async function update(params: any, session = false) {
    try {
      const { data } = params;
      let original = await get(params);

      let multiLanguages: any = [];
      if (original.multiLanguages) {
        multiLanguages = new Map(JSON.parse(original.multiLanguages));
        if (data.name) {
          multiLanguages.set(`${language}-name`, data.name);
        }
        if (data.subName) {
          multiLanguages.set(`${language}-subName`, data.subName);
        }
        multiLanguages = JSON.stringify(Array.from(multiLanguages.entries()));
      } else {
        if (data.name) {
          multiLanguages.push([`${language}-name`, data.name]);
        }
        if (data.subName) {
          multiLanguages.push([`${language}-subName`, data.subName]);
        }
        multiLanguages = JSON.stringify(multiLanguages);
      }

      const updateInput: UpdateSubCategoryInput = {
        id: original.id,
        name:
          data.name && language === Languages.ENGLISH
            ? data.name.toLowerCase()
            : original!.name,
        mainCategoryID: data.mainCategoryID
          ? data.mainCategoryID
          : original.mainCategoryID,
        openTime: data.openTime ? data.openTime : original.openTime,
        closeTime: data.closeTime ? data.closeTime : original.closeTime,
        guestView: data.guestView,
        guestOrder: data.guestOrder,
        staffOrder: data.staffOrder,
        precedence: data.precedence ? data.precedence : original.precedence,
        menuPDF: data.menuPDF ? data.menuPDF : original.menuPDF,
        multiLanguages: multiLanguages,
        image: data.image ? data.image : original.image,
        _version: original._version,
      };

      const SubCategory: any = await API.graphql<GraphQLQuery<SubCategory>>({
        query: updateSubCategory,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been updated successfully`);
      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));

      let newListing: any = [];
      subCategoriesAllListing.map((item: any) => {
        if (item.id === SubCategory.data.updateSubCategory.id) {
          newListing.push(SubCategory.data.updateSubCategory);
        } else {
          newListing.push(item);
        }
      });
      dispatch(setAllListing(newListing));

      return SubCategory.data.updateSubCategory;
    } catch (err) {
      showError(err);
    }
  }

  async function exportAll(params: ListingByConceptVariables) {
    const data = await fetch(params);
    return data;
  }

  function options(listing: SubCategory[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  async function trash(params: any) {
    let original = await get(params);
    const updateInput: UpdateSubCategoryInput = {
      id: original.id,
      deleted: "1",
      _version: original._version,
    };

    await API.graphql<GraphQLQuery<SubCategory>>({
      query: updateSubCategory,
      variables: { input: updateInput },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    });

    showConfirm(`${singleName} has been moved to trash successfully`);
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "precedence",
      numeric: false,
      disablePadding: false,
      label: "Precedence",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];
  const dataCells: readonly string[] = ["name", "precedence"];

  const api: any = {};

  api[`${listingName}Model`] = SubCategory as any;
  api[`${listingName}CreateSubscription`] = onCreateSubCategory;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}Options`] = options;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}FetchByConceptID`] = fetchSubCategoryByConceptID;
  api[`${listingName}ChangeListing`] = (listing: SubCategory[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeAllListing`] = (listing: SubCategory[]) =>
    dispatch(setAllListing(listing));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = subCategoriesListing;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  return api;
};

export default useResource;
