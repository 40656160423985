import {
  CreateVariables,
  GroupSettingBulkTrashVariables,
  GroupSettingGetVariables,
  GroupSettingListingVariables,
  GroupSettingUpdateVariables,
} from "./../models/app";
import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch } from "react-redux";
import { setListing, setSelected } from "../store/ducks/groupSetting";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { GroupSetting } from "../models";
import { CreateGroupSettingInput } from "../models/GQL_API";
import { onCreateGroupSetting } from "../graphql/subscriptions";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: GroupSettingListingVariables) {
    const { searchText, startIndex, limit, groupID, isDefault } = params;
    // stop using this table
    return [];
    try {
      const listing = await DataStore.query(
        GroupSetting as any,
        (model: any) => {
          model.deleted("eq", "0");

          if (groupID) model.groupID("eq", groupID);
          if (isDefault !== undefined) model.isDefault("eq", isDefault);

          if (searchText.length > 0)
            model.message("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      // dispatch(setListing(listing));

      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function get(params: GroupSettingGetVariables) {
    const { id, listing } = params;
    // stop using this table
    return {};
    try {
      const single: GroupSetting | undefined =
        listing.length === 0
          ? await DataStore.query(GroupSetting as any, id)
          : listing.find((model: GroupSetting) => model.id === id);

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;
    // stop using this table
    return {};

    try {
      const createInput: CreateGroupSettingInput = {
        groupID: data.groupID,
        statusID: data.statusID,
        needsVerification: data.needsVerification,
        needsDeposit: data.needsDeposit,
        isDefault: data.isDefault,
        amount: data.amount ? parseInt(data.amount) : 0,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      if (data.days) createInput.days = data.days;
      if (data.timeSlots) createInput.timeSlots = data.timeSlots;

      await DataStore.save(new GroupSetting(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: GroupSettingUpdateVariables) {
    const { id, listing, data } = params;

    // stop using this table
    return {};

    // try {
    //   const original = await get({ id, listing });

    //   await DataStore.save(
    //     GroupSetting.copyOf(original!, (updated) => {
    //       updated.statusID =
    //         data.statusID !== undefined ? data.statusID : original!.statusID;
    //       updated.needsVerification =
    //         data.needsVerification !== undefined
    //           ? data.needsVerification
    //           : original!.needsVerification;
    //       updated.needsDeposit =
    //         data.needsDeposit !== undefined
    //           ? data.needsDeposit
    //           : original!.needsDeposit;
    //       updated.isDefault = data.isDefault
    //         ? data.isDefault
    //         : original!.isDefault;
    //       updated.amount = data.amount
    //         ? parseInt(data.amount)
    //         : original!.amount;
    //       updated.days = data.days ? data.days : original!.days;
    //       updated.timeSlots = data.timeSlots
    //         ? data.timeSlots
    //         : original!.timeSlots;
    //     })
    //   );

    //   showConfirm(`${singleName} has been updated successfully`);
    // } catch (err) {
    //   showError(err);
    // }
  }

  async function trash(params: GroupSettingGetVariables) {
    try {
      // stop using this table
      return {};
      // const original = await get(params);

      // await DataStore.save(
      //   GroupSetting.copyOf(original!, (updated) => {
      //     updated.deleted = "1";
      //   })
      // );

      // showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: GroupSettingBulkTrashVariables) {
    const { ids, listing } = params;
    // stop using this table
    return {};
    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: GroupSettingGetVariables) {
    const { id, listing } = params;
    // stop using this table
    return {};
    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "groupID",
      numeric: false,
      disablePadding: false,
      label: "Group",
    },
    {
      id: "isDefault",
      numeric: false,
      disablePadding: false,
      label: "Setting Type",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["groupID", "isDefault"];

  const api: any = {};

  api[`${listingName}Model`] = GroupSetting as any;
  api[`${listingName}CreateSubscription`] = onCreateGroupSetting;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: GroupSetting[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useResource;
