import { LambdaFunctions } from "../constants/enums"
import invokeLambda from "./invokeLambda"

export const fetchTransactionsStatistics = async (variables: any) => {
  try {
    const operationId = 34
    const requestBody = {
      operationId: operationId,
      transactionDashboardFilters: variables,
    }
    return await invokeLambda(LambdaFunctions.callCenterOperations, requestBody);
  } catch (error) {
    console.error('Error fetching transactions statistics:', error)
    throw error;
  }
}

export const fetchTransactionsChart = async (filter: any, chartRange: any) => {
  try {
    const operationId = 35
    const requestBody = {
      operationId: operationId,
      transactionDashboardFilters: filter,
      transactionDashboardChartRange: chartRange
    }
    return await invokeLambda(LambdaFunctions.callCenterOperations, requestBody);
  } catch (error) {
    console.error('Error fetching transactions chart data:', error)
    throw error;
  }
}
