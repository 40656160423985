import {
  CreateMainCategoryInput,
  DeleteMainCategoryInput,
  UpdateMainCategoryInput,
} from "./../models/GQL_API";
import { ListingByConceptVariables, Option } from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import { MainCategory } from "../models";
import {
  createMainCategory,
  deleteMainCategory,
  updateMainCategory,
} from "../graphql/mutations";
import { getMainCategory, listMainCategories, mainCategoryByConceptID } from "../graphql/queries";
import { HeadCell } from "../models/dataTable";
import {
  setAllListing,
  setLastIndex,
  setListing,
  setNextToken,
  setPagination,
} from "../store/ducks/mainCategory";
import { getDateFormatted } from "../helpers/utils";
import { onCreateMainCategory } from "../graphql/subscriptions";
import { Languages } from "../constants/enums";


const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();
  
  const session = useSelector((state: any) => state.app.session);
  const mainCategoriesListing = useSelector(
    (state: any) => state.mainCategories.listing
  );
  const mainCategoriesAllListing = useSelector(
    (state: any) => state.mainCategories.allListing
  );
  const language = useSelector((state: any) => state.accounts.language);

  const nextToken = useSelector((state: any) => state.mainCategories.nextToken);
  const lastIndex = useSelector((state: any) => state.mainCategories.lastIndex);
  const paginationListing = useSelector(
    (state: any) => state.mainCategories.pagination
  );
  const selectedConcept = useSelector((state: any) => state.concepts.selected);

  async function fetch(params: ListingByConceptVariables) {
    try {
      const { conceptID, searchText, limit, startIndex } = params;
      const filter: any = {
        conceptID: { eq: conceptID ? conceptID : selectedConcept },
        deleted: { eq: "0" },
      };
      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
        const listing: any = await API.graphql<GraphQLQuery<MainCategory>>({
          query: listMainCategories,
          variables: { filter, limit: 1000 },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        dispatch(setLastIndex(0));
        dispatch(setNextToken(null));
        dispatch(setPagination([]));

        return listing.data.listMainCategories.items;
      }
      if (
        paginationListing.length > 0 &&
        selectedConcept === paginationListing[0]?.conceptID &&
        ((lastIndex >= startIndex &&
          lastIndex !== null &&
          limit < paginationListing.length) ||
          !nextToken)
      ) {
        return paginationListing.slice(startIndex, startIndex + limit);
      }
      const listing: any = await API.graphql<GraphQLQuery<MainCategory>>({
        query: listMainCategories,
        variables: {
          filter,
          limit: limit ?? 1000,
          nextToken:
            paginationListing.length > 0 &&
            selectedConcept === paginationListing[0]?.conceptID
              ? nextToken
              : undefined,
        },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(setLastIndex(startIndex));
      dispatch(setNextToken(listing.data.listMainCategories.nextToken));
      let list =
        selectedConcept === paginationListing[0]?.conceptID
          ? paginationListing.concat(listing.data.listMainCategories.items)
          : listing.data.listMainCategories.items;
      dispatch(setPagination(list));

      return list.slice(startIndex, startIndex + limit);
    } catch (err) {
      showError(err);
    }
  }

  async function fetchAll(params: ListingByConceptVariables) {
    try {
      const { conceptID, searchText, limit, startIndex } = params;
      const filter: any = {
        conceptID: { eq: conceptID ? conceptID : selectedConcept },
        deleted: { eq: "0" },
      };
      if (searchText && searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      if (
        mainCategoriesAllListing.length === 0 ||
        conceptID !== mainCategoriesAllListing[0].conceptID
      ) {
        const listing: any = await API.graphql<GraphQLQuery<MainCategory>>({
          query: listMainCategories,
          variables: { filter, limit: limit ?? 1000 },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        return listing.data.listMainCategories.items;
      } else {
        return mainCategoriesAllListing;
      }
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }

  async function fetchMainCategoryByConceptID(params: ListingByConceptVariables) {
    try {
      const { conceptID, searchText, limit, forceRefresh } = params;
      const filter: any = {
        deleted: { eq: "0" },
      };
      if (searchText && searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      if (
        mainCategoriesAllListing.length === 0 ||
        conceptID !== mainCategoriesAllListing[0].conceptID ||
        forceRefresh
      ) {
  
        const listing: any = await API.graphql<GraphQLQuery<MainCategory>>({
          query: mainCategoryByConceptID,
          variables: { filter, conceptID: conceptID ,limit: limit ?? 10000 },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        let nextToken = listing.data.mainCategoryByConceptID.nextToken;
        let allItems = listing.data.mainCategoryByConceptID.items;

      while (nextToken && nextToken.length > 0) {
        const newListing: any = await API.graphql<GraphQLQuery<MainCategory>>({
          query: mainCategoryByConceptID,
          variables: { filter, conceptID: conceptID,limit: limit ?? 10000, nextToken },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        allItems = allItems.concat(newListing.data.mainCategoryByConceptID.items);
        nextToken = newListing.data.mainCategoryByConceptID.nextToken;
      }
      return allItems.sort((a:any, b:any) => {return b.precedence - a.precedence;});

      } else {
        return mainCategoriesAllListing;
      }
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }

  async function get(params: any) {
    const { id } = params;

    try {
      const mainCategory: any = await API.graphql({
        query: getMainCategory,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return mainCategory.data.getMainCategory;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: any, session = true) {
    let { userID, userName, data } = params;

    let multiLanguages:any=[[`${Languages.ENGLISH}-name`,data.name]]
    multiLanguages=JSON.stringify(multiLanguages);

    try {
      const createInput: CreateMainCategoryInput = {
        name: data.name.toLowerCase(),
        precedence: data.precedence ? data.precedence : "0",
        openTime: data.openTime ? data.openTime : null,
        closeTime: data.closeTime ? data.closeTime : null,
        guestView: data.guestView ? data.guestView : false,
        guestOrder: data.guestOrder ? data.guestOrder : false,
        staffOrder: data.staffOrder ? data.staffOrder : false,
        image: data.image ? data.image : null,
        menuPDF: data.menuPDF ? data.menuPDF : null,
        multiLanguages:multiLanguages,
        conceptID: data.conceptID,
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      const mainCategory:any = await API.graphql<GraphQLQuery<MainCategory>>({
        query: createMainCategory,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`New ${singleName} has been created successfully`);
      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));

      let newListing=[...mainCategoriesAllListing,mainCategory.data.createMainCategory]
      dispatch(setAllListing(newListing));

      return mainCategory;
    } catch (err) {
      console.log(err);
    }
  }

  async function update(params: any, session = false) {
    try {
      const { data } = params;
      let original = await get(params);

      let multiLanguages:any=[]
      if(original.multiLanguages)
      {
        multiLanguages=new Map(JSON.parse(original.multiLanguages))
        if(data.name){multiLanguages.set(`${language}-name`,data.name)}
        if(data.subName){multiLanguages.set(`${language}-subName`,data.subName)}
        multiLanguages=JSON.stringify(Array.from(multiLanguages.entries()));
      }
      else
      {
        if(data.name){multiLanguages.push([`${language}-name`,data.name])}
        if(data.subName){multiLanguages.push([`${language}-subName`,data.subName])}
        multiLanguages=JSON.stringify(multiLanguages);
      }

      const updateInput: UpdateMainCategoryInput = {
        id: original.id,
        name: (data.name && language===Languages.ENGLISH) ?  data.name.toLowerCase() : original!.name,
        precedence: data.precedence ? data.precedence : original.precedence,
        openTime: data.openTime ? data.openTime : original.openTime,
        closeTime: data.closeTime ? data.closeTime : original.closeTime,
        guestView: data.guestView,
        guestOrder: data.guestOrder,
        staffOrder: data.staffOrder,
        multiLanguages:multiLanguages,
        image: data.image ? data.image : original.image,
        menuPDF: data.menuPDF ? data.menuPDF : original.menuPDF,
        _version: original._version,
      };

      const mainCategory: any = await API.graphql<GraphQLQuery<MainCategory>>({
        query: updateMainCategory,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been updated successfully`);
      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));

      let newListing:any=[]
      mainCategoriesAllListing.map((item:any)=>{
        if(item.id===mainCategory.data.updateMainCategory.id)
        {newListing.push(mainCategory.data.updateMainCategory)}
        else{newListing.push(item)}
      })
      dispatch(setAllListing(newListing));
      return mainCategory.data.updateMainCategory;
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: any) {
    let original = await get(params);
    const updateInput: UpdateMainCategoryInput = {
      id: original.id,
      deleted: "1",
      _version: original._version,
    };

    const mainCategory: any = await API.graphql<GraphQLQuery<MainCategory>>({
      query: updateMainCategory,
      variables: { input: updateInput },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    });

    showConfirm(`${singleName} has been moved to trash successfully`);

    for (let i = 0; i < mainCategoriesListing.length; i++) {
      if (mainCategoriesListing[i].id == original.id) {
        mainCategoriesListing.splice(i, 1);
        dispatch(setListing(mainCategoriesListing));
        break;
      }
    }
    for (let i = 0; i < paginationListing.length; i++) {
      if (paginationListing[i].id == original.id) {
        paginationListing.splice(i, 1);
        dispatch(setPagination(paginationListing));
        break;
      }
    }
  }
  async function bulkTrash(params: any) {
    const { ids, listing } = params;
    // console.log(listing);

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function exportAll(params: ListingByConceptVariables) {
    const data = await fetch(params);
    return data;
  }

  function options(listing: MainCategory[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "precedence",
      numeric: false,
      disablePadding: false,
      label: "Precedence",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];
  const dataCells: readonly string[] = ["name", "precedence"];

  const api: any = {};

  api[`${listingName}Model`] = MainCategory as any;
  api[`${listingName}CreateSubscription`] = onCreateMainCategory;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}Options`] = options;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
api[`${listingName}FetchByConceptID`] = fetchMainCategoryByConceptID;
  api[`${listingName}ChangeListing`] = (listing: MainCategory[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeAllListing`] = (listing: MainCategory[]) =>
    dispatch(setAllListing(listing));
  return api;
};

export default useResource;
