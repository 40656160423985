// actions
const SET = "mainCategories/SET";
const SET_SEARCH_TEXT = "mainCategories/SET_SEARCH_TEXT";
const SET_SELECTED = "mainCategories/SET_SELECTED";
const SET_LAST_INDEX = "mainCategories/SET_LAST_INDEX";
const SET_NEXT_TOKEN = "mainCategories/SET_NEXT_TOKEN";
const SET_PAGINATION = "mainCategories/SET_PAGINATION";
const SET_ALL_LISTING = "mainCategories/SET_ALL_LISTING";


const DEFAULT_STATE = {
  listing: [],
  searchText: "",
  selected: "",
  pagination:[],
  allListing:[],
  nextToken:null,
  lastIndex:null
};

// reducer 
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_PAGINATION:
      return Object.assign({}, state, { pagination: action.pagination });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.id });
    case SET_LAST_INDEX:
      return Object.assign({}, state, { lastIndex: action.id });
    case SET_ALL_LISTING:
      return Object.assign({}, state, { allListing: action.allListing });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});
export const setPagination = (pagination: any[]) => ({ pagination, type: SET_PAGINATION });
export const setNextToken = (id: any) => ({ id, type: SET_NEXT_TOKEN });
export const setLastIndex = (id: number) => ({ id, type: SET_LAST_INDEX });
export const setAllListing = (allListing: any[]) => ({ allListing, type: SET_ALL_LISTING });

