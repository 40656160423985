import Box from "@mui/material/Box";
import { SxProps } from "@mui/system";
import React from "react";

interface Props {
  children: React.ReactNode;
  scroll?: boolean;
  maxHeight?: string;
  sx?: SxProps;
}

const LightBox: React.FC<Props> = ({ children, scroll, maxHeight, sx }) => {
  return (
    <Box
      style={scroll ? { maxHeight: maxHeight, overflowY: "scroll" } : {}}
      sx={{
        border: "1px solid",
        borderColor: (theme) => `${theme.palette.divider}`,
        p: 2,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default LightBox;
