import { LambdaFunctions } from "../constants/enums";
import invokeLambda from "./invokeLambda";

async function confirmOrder(order:any,concept:any) {
  try {
    const operationId = 28
    const requestBody = {
      operationId: operationId,
      onlineOrder: order,
      concept,
    }
    return await invokeLambda(LambdaFunctions.callCenterOperations, requestBody);
  } catch (error:any) {
    console.error('Error confirmingOrder:', error.message)
    throw error;
  }
}

export default confirmOrder;
