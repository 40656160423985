import { useDropzone } from "react-dropzone";
import { Storage } from "aws-amplify";
import React from "react";
import { Box, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function DropZone({ field, onAcceptedFiles }: any) {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop: (acceptedFiles) => {
      // console.log("Selected files:", acceptedFiles[0].name);
      const file = acceptedFiles;
      file.map((file: any) => {
        uploadImage(file);
      });
      onAcceptedFiles(acceptedFiles);
      // console.log("file uploaded successfully");
    },
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.name}>{file.name}</li>
  ));

  // Function to upload the image
  const uploadImage = async (file: any) => {
    try {
      const result = await Storage.put(file.name, file, { level: "public" });
      // console.log("Image uploaded successfully:", result);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  return (
    <Box key={field.name} style={{ width: "60%", marginBottom: 20 }}>
      <Typography>{field.label}</Typography>
      <Box
        {...getRootProps({
          // className: 'dropzone',
        })}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
          border: "2px dashed #bdbdbd",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        <input {...getInputProps()} />
        <CloudUploadIcon sx={{ fontSize: 35, marginBottom: 3 }} />

        <Typography variant="body1" component="p">
          Drag and drop some files here, or click to select files
        </Typography>

        <ul>{files}</ul>
      </Box>
    </Box>
  );
}

export default DropZone;
