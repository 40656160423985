// actions
const SET = "servingAreas/SET";
const SET_SELECTED = "servingAreas/SET_SELECTED";
const SET_NEXT_TOKEN = "servingAreas/SET_NEXT_TOKEN";

const DEFAULT_STATE = {
  listing: [],
  selected: "",

  nextToken: null,
  nextNextToken: undefined,
  previousTokens: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.nextToken });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
export const setNextToken = (nextToken: any) => ({
  nextToken,
  type: SET_NEXT_TOKEN,
});
