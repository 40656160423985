import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import {
  setDays,
  setListing,
  setNextToken,
  setSelected,
  setTimeSlots,
} from "../store/ducks/area";
import { Area } from "../models";
import {
  AreaBulkTrashVariables,
  AreaGetVariables,
  AreaListingVariables,
  AreaUpdateVariables,
  Option,
  HeadCell,
  CreateVariables,
} from "../models/app";
import { CreateAreaInput, UpdateAreaInput } from "../models/GQL_API";
import useApp from "./useApp";
import { createArea, deleteArea, updateArea } from "../graphql/mutations";
import { getArea, listAreas } from "../graphql/queries";
import { onCreateArea } from "../graphql/subscriptions";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );

  async function fetch(params: AreaListingVariables) {
    const { searchText, startIndex, limit, conceptID } = params;
    // stop using this table
    return [];
    try {
      // Filter Section
      const filter: any = {
        deleted: { eq: "0" },
        conceptID: { eq: conceptID },
      };

      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }

      const ListData: any = await API.graphql<Area>({
        query: listAreas,
        variables: { filter, limit, nextToken: nextToken },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const currentNextToken = ListData.data.listAreas.nextToken;
      const listing = ListData.data.listAreas.items;

      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchException(params: AreaListingVariables) {
    const {
      startIndex,
      limit,
      searchText,
      conceptID,
      isDefault,
      weekDay,
      timeSlot,
      expiryDate,
    } = params;

    try {
      // Filter Section
      const filter: any = {
        deleted: { eq: "0" },
        conceptID: { eq: conceptID },
      };

      if (searchText.length > 0)
        filter.name = { contains: searchText.toLowerCase() };

      if (isDefault) filter.isDefault = { eq: isDefault };
      if (weekDay) filter.days = { contains: weekDay };
      if (timeSlot) filter.timeSlots = { contains: timeSlot };
      if (expiryDate) filter.expiryDate = { ge: expiryDate };

      const ListData: any = await API.graphql<Area>({
        query: listAreas,
        variables: { filter, limit, nextToken: nextToken },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const listing = ListData.data.listAreas.items;

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function get(params: AreaGetVariables) {
    const { id, listing } = params;

    try {
      let single: Area | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await API.graphql<Area>({
          query: getArea,
          variables: { id },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        single = listing.data.getArea;
      }

      return single;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.conceptID) {
      const error = new Error("Cannot create area without conceptID ");
      return showError(error);
    }

    try {
      const createInput: CreateAreaInput = {
        conceptID: data.conceptID,
        name: data.name.toLowerCase(),
        isDefault: data.isDefault,
        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      if (data.expiryDate) createInput.expiryDate = data.expiryDate;
      if (data.days) createInput.days = data.days;
      if (data.timeSlots) createInput.timeSlots = data.timeSlots;

      const model = await API.graphql<Area>({
        query: createArea,
        variables: { input: createInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      if (data.listing) {
        dispatch(setListing([...data.listing, model]));
      }

      return `New ${singleName} has been created successfully`;
    } catch (err) {
      throw err;
    }
  }

  async function update(params: AreaUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original: any = await get({ listing, id });

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateAreaInput = {
        id: original.id,
        name: data.name ? data.name.toLowerCase() : original!.name,
        isDefault: data.isDefault ? data.isDefault : original!.isDefault,
        expiryDate: data.expiryDate ? data.expiryDate : original!.expiryDate,
        days: data.days ? data.days : original!.days,
        timeSlots: data.timeSlots ? data.timeSlots : original!.timeSlots,

        _version: original._version,
      };

      await API.graphql<Area>({
        query: updateArea,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      return `${singleName} has been updated successfully`;
    } catch (err) {
      throw err;
    }
  }

  async function trash(params: AreaGetVariables) {
    try {
      const original: any = await get(params);

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateAreaInput = {
        id: original.id,
        deleted: "1",
        _version: original._version,
      };

      await API.graphql<Area>({
        query: updateArea,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      return `${singleName} has been moved to trash successfully`;
    } catch (err) {
      throw err;
    }
  }

  async function bulkTrash(params: AreaBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: string) => {
      try {
        await trash({ id, listing });
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    return `${ids.size} ${listingName} items has been moved to trash`;
  }

  async function remove(params: AreaGetVariables) {
    try {
      const { id, listing } = params;

      await API.graphql<Area>({
        query: deleteArea,
        variables: { id: id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  async function exportAll(params: AreaListingVariables) {
    try {
      const data = await fetch(params);
      let exportedData: any[] = [];

      for (let area of data!) {
        let row: any = { ...area };

        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      showError(err);
    }
  }

  function options(listing: Area[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const api: any = {};

  api[`${listingName}Model`] = Area as any;
  api[`${listingName}CreateSubscription`] = onCreateArea;

  api[`${listingName}Options`] = options;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchException`] = fetchException;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}Export`] = exportAll;

  api[`${listingName}ChangeListing`] = (listing: Area[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));
  api[`${listingName}ChangeDays`] = (days: any) => dispatch(setDays(days));
  api[`${listingName}ChangeTimeSlots`] = (timeSlots: any) =>
    dispatch(setTimeSlots(timeSlots));

  return api;
};

export default useResource;
