// actions
const SET = "campaigns/SET";
const SET_SEARCH_TEXT = "campaigns/SET_SEARCH_TEXT";
const SET_FILTER_STATUS = "campaigns/SET_FILTER_STATUS";
const SET_FILTER_CREATED_AT_FROM = "campaigns/SET_FILTER_CREATED_AT_FROM";
const SET_FILTER_CREATED_AT_TO = "campaigns/SET_FILTER_CREATED_AT_TO";
const SET_FILTER_START_AT_FROM = "campaigns/SET_FILTER_START_AT_FROM";
const SET_FILTER_START_AT_TO = "campaigns/SET_FILTER_START_AT_TO";
const SET_SELECTED = "campaigns/SET_SELECTED";
const SET_LAST_INDEX = "onlineOrders/SET_LAST_INDEX";
const SET_NEXT_TOKEN = "onlineOrders/SET_NEXT_TOKEN";
const SET_PREVIOUS_TOKENS = "onlineOrders/SET_PREVIOUS_TOKENS";

const SET_FILTERS = "campaigns/SET_FILTERS";
const SET_SELECTED_FILTERS = "campaigns/SET_SELECTED_FILTERS";
const SET_FILTER = "onlineOrders/SET_FILTER";


const DEFAULT_STATE = {
  listing: [],
  filters: [],
  selectedFilters: [],
  searchText: "",
  filterStatus: "all",
  filterCreatedAtFrom: "",
  filterCreatedAtTo: "",
  filterStartDateFrom: "",
  filterStartDateTo: "",
  selected: "",
  nextToken: null,
  prevTokens: [],
  lastIndex: null,
  filter: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_FILTERS:
      return Object.assign({}, state, { filters: action.filters });
    case SET_SELECTED_FILTERS:
      return Object.assign({}, state, { selectedFilters: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_FILTER_STATUS:
      return Object.assign({}, state, { filterStatus: action.filterStatus });
    case SET_FILTER_CREATED_AT_FROM:
      return Object.assign({}, state, { filterCreatedAtFrom: action.filterCreatedAtFrom });
    case SET_FILTER_CREATED_AT_TO:
      return Object.assign({}, state, { filterCreatedAtTo: action.filterCreatedAtTo });
    case SET_FILTER_START_AT_FROM:
      return Object.assign({}, state, { filterStartDateFrom: action.filterStartDateFrom });
    case SET_FILTER_START_AT_TO:
      return Object.assign({}, state, { filterStartDateTo: action.filterStartDateTo });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_PREVIOUS_TOKENS:
      return Object.assign({}, state, { previousTokens: action.previousTokens });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.id });
    case SET_LAST_INDEX:
      return Object.assign({}, state, { lastIndex: action.id });
    case SET_FILTER:
      return Object.assign({}, state, { filter: action.id });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});
export const setFilterStatus = (filterStatus: string) => ({
  filterStatus,
  type: SET_FILTER_STATUS,
});
export const setFilterCreatedAtFrom = (filterCreatedAtFrom: string) => ({
  filterCreatedAtFrom,
  type: SET_FILTER_CREATED_AT_FROM,
});
export const setFilterCreatedAtTo = (filterCreatedAtTo: string) => ({
  filterCreatedAtTo,
  type: SET_FILTER_CREATED_AT_TO,
});
export const setFilterStartDateFrom = (filterStartDateFrom: string) => ({
  filterStartDateFrom,
  type: SET_FILTER_START_AT_FROM,
});
export const setFilterStartDateTo = (filterStartDateTo: string) => ({
  filterStartDateTo,
  type: SET_FILTER_START_AT_TO,
});

export const setFilters = (filters: string[]) => ({
  filters,
  type: SET_FILTERS,
});
export const setSelectedFilters = (listing: any[]) => ({
  listing,
  type: SET_SELECTED_FILTERS,
});
export const setNextToken = (id: any) => ({ id, type: SET_NEXT_TOKEN });
export const setLastIndex = (id: number) => ({ id, type: SET_LAST_INDEX });
export const setPreviousToken = (previousTokens: any) => ({ previousTokens, type: SET_PREVIOUS_TOKENS });
export const setFilter = (id: any) => ({ id, type: SET_FILTER });
