import { ZoneConcept } from "../../models";

// actions
const SET = "zones/SET";
const SET_ALL = "zones/SET_ALL";
const SET_REPORT = "zones/SET_REPORT";
const SET_SELECTED = "zones/SET_SELECTED";
const SET_SELECTED_ZONE_CONCEPTS = "zones/SET_SELECTED_ZONE_CONCEPTS";

const CHANGE_LIMIT = "zones/CHANGE_LIMIT";
const NEXT_ACTION = "zones/NEXT_ACTION";
const SET_NEXT_TOKEN = "zones/SET_NEXT_TOKEN";
const SET_PREVIOUS_TOKENS = "zones/SET_PREVIOUS_TOKENS";
const SET_UPDATED_ZONE = "zones/SET_UPDATED_ZONE";

const DEFAULT_STATE = {
  listing: [],
  listingAll: [],
  report: [],
  selected: "",
  updatedZone: null,
  selectedZoneConcepts: [],
  nextToken: null,
  nextNextToken: undefined,
  previousTokens: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_ALL:
      return Object.assign({}, state, { listingAll: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, {
        selected: action.concept,
      });
    case SET_SELECTED_ZONE_CONCEPTS:
      return Object.assign({}, state, {
        selectedZoneConcepts: action.zoneConcepts,
      });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.nextToken });
    case SET_PREVIOUS_TOKENS:
      return Object.assign({}, state, {
        previousTokens: action.previousTokens,
      });
    case SET_UPDATED_ZONE:
      return Object.assign({}, state, {
        updatedZone: action.updatedZone,
      });
    case CHANGE_LIMIT:
      return Object.assign({}, state, {
        limit: action.limit,
        nextToken: action.nextToken,
        nextNextToken: action.nextNextToken,
        previousTokens: action.previousTokens,
      });
    case NEXT_ACTION:
      return Object.assign({}, state, {
        nextToken: action.nextToken,
        previousTokens: action.previousTokens,
      });
    case SET_REPORT:
      return Object.assign({}, state, {
        report: action.report,
      });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setListingAll = (listing: any[]) => ({ listing, type: SET_ALL });
export const setSelected = (single: any) => ({ single, type: SET_SELECTED });
export const setSelectedZoneConcepts = (zoneConcepts: ZoneConcept[]) => ({
  zoneConcepts,
  type: SET_SELECTED_ZONE_CONCEPTS,
});

export const changeLimit = (limit: any) => ({
  limit: limit,
  nextToken: null,
  nextNextToken: null,
  previousTokens: [],
  type: CHANGE_LIMIT,
});
export const nextAction = (nextToken: any, previousTokens: any[]) => ({
  nextToken: nextToken,
  previousTokens: previousTokens ? [...previousTokens, nextToken] : [nextToken],
  type: NEXT_ACTION,
});
export const setNextToken = (nextToken: any) => ({
  nextToken,
  type: SET_NEXT_TOKEN,
});
export const setPreviousToken = (previousTokens: any) => ({
  previousTokens,
  type: SET_PREVIOUS_TOKENS,
});
export const setUpdatedZone = (updatedZone: any) => ({
  updatedZone,
  type: SET_UPDATED_ZONE,
});

export const setReport = (report: any) => ({
  report,
  type: SET_REPORT,
});
