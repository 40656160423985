import { listParentConcepts } from "./../graphql/queries";
import { API, DataStore } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setBranches,
  setListing,
  setFilters,
  setSelectedFilters,
} from "../store/ducks/concept";
import { ParentConcept } from "../models";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { CreateParentConceptInput } from "../models/GQL_API";
import {
  ConceptUpdateVariables,
  CreateVariables,
  ConceptGetVariables,
  ConceptListingVariables,
  Option,
} from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { onCreateParentConcept } from "../graphql/subscriptions";
const useResource = (listingName: string, singleName: string) => {
  const session = useSelector((state: any) => state.app.session);
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: ConceptListingVariables) {
    try {
      const listing = await DataStore.query(ParentConcept as any);
      return listing;
    } catch (err: Error | any) {
      // showError(err.message);
    }
  }

  async function fetchOnline() {
    try {
      const listing: any = await API.graphql({
        query: listParentConcepts,
        variables: { limit: 100 },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return listing.data.listParentConcepts.items;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.accountID) {
      const error = new Error(`Cannot create ${singleName} without accountID`);
      return showError(error);
    }

    try {
      const createInput: CreateParentConceptInput = {
        name: data.name ? data.name : "",
        type: data.type ? data.type : "",
        image: data.image ? data.image : "",
        logo: data.logo ? data.logo : "",
        venues: data.venues ? data.venues : "",
        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };
      // console.log("starting");
      await DataStore.save(new ParentConcept(createInput as any));
      // console.log("finished");
      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: ConceptGetVariables) {
    const { id, listing } = params;
    try {
      const single: ParentConcept | undefined =
        listing.length === 0
          ? await DataStore.query(ParentConcept as any, id)
          : listing.find((model: any) => model.id === id);

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: ConceptUpdateVariables) {
    const { id, listing, data } = params;
    try {
      const original: any = await get({ id, listing });
      // console.log({ original: original });
      // const updated: GraphQLResult<any> = await API.graphql({
      //   query: updateParentConcept,
      //   variables: {
      //     input: {
      //       id: data.id,
      //       type:data.type,
      //       image:data.image,
      //       logo:data.logo,
      //       venues:data.venues,
      //       _version: original._version
      //     },
      //   },
      //   authMode: true
      //     ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      //     : GRAPHQL_AUTH_MODE.AWS_IAM,
      // });
      const updates = await DataStore.save(
        ParentConcept.copyOf(original!, (updated) => {
          updated.name = data.name ? data.name : original!.name;
          updated.type = data.type ? data.type : original!.type;
          updated.image = data.image ? data.image : original!.image;
          updated.logo = data.logo ? data.logo : original!.logo;
          updated.venues = data.venues ? data.venues : original!.venues;
        })
      );
      showConfirm(`${singleName} has been updated successfully`);
      return updates;
    } catch (err) {
      showError(err);
    }
  }

  async function remove(params: ConceptGetVariables) {
    const { id, listing } = params;
    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: ConceptGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: ParentConcept) => model.id === id);

      return model ? model.name : "";
    }

    return "";
  };

  function options(listing: ParentConcept[]) {
    const options: Option[] = [];

    for (let option of listing) {
      if (option.type) options.push({ label: option.type, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Type",
    },
  ];

  const dataCells: readonly string[] = ["name", "type"];

  const api: any = {};

  api[`${listingName}Model`] = ParentConcept as any;
  api[`${listingName}CreateSubscription`] = onCreateParentConcept;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchOnline`] = fetchOnline;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}Get`] = get;

  // api[`${listingName}ChangeListing`] = (listing: ParentConcept[]) => {
  //   dispatch(setListing(listing));
  //   dispatch(setFilters(listing.map((model: any) => model.name)));
  // };

  // api[`${listingName}ChangeSelected`] = (conceptID: string) => {
  //   dispatch(setSelected(conceptID));

  //   localStorage.setItem(LOCAL_STORAGE.SELECTED_CONCEPT, conceptID);
  // };
  api[`${listingName}SetBranches`] = (branches: any) =>
    dispatch(setBranches(branches));
  api[`${listingName}ChangeFilters`] = (filters: string[]) =>
    dispatch(setFilters(filters));
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) =>
    dispatch(setSelectedFilters(filters));

  return api;
};

export default useResource;
