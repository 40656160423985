import { Box, Button, Paper, TextField, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useApp from '../../../hooks/useApp'
import { useEffect, useState } from 'react'
import ButtonLoader from '../ButtonLoader'
import useUpdating from '../../../hooks/useUpdating'
import usePrice from '../../../hooks/usePrice'
import useMenuItem from '../../../hooks/useMenuItem'
import useLoading from '../../../hooks/useLoading'
import ContentLoader from '../ContentLoader'
import { Price } from '../../../models'
import { useSelector } from 'react-redux'

interface Props {
  menuItemId: string
  priceId: string
}
interface CreatePriceInput {
  name: string
  nameArabic: string
  price: number
  symphonyID?: string
  conceptID: string
}
interface UpdatePriceInput {
  name?: string
  nameArabic?: string
  price?: number
  symphonyID?: string
}

const PriceModal: React.FC<Props> = ({ menuItemId, priceId }) => {
  const session = useSelector((state: any) => state.app.session)
  const conceptsSelected = useSelector((state: any) => state.concepts.selected)

  const priceListing = useSelector((state: any) => state.prices.listing)

  const { loading, changeLoading } = useLoading()
  const [name, setName] = useState('')
  const [nameArabic, setNameArabic] = useState('')
  const [price, setPrice] = useState(0)
  const [symphonyID, setSymphonyID] = useState('0')
  const { hideDeletePopup, showError } = useApp()
  const { updating, changeUpdating } = useUpdating()
  const {
    pricesCreate,
    pricesGet,
    pricesUpdate,
    pricesChangeListing,
  } = usePrice('prices', 'price')
  const { menuItemsUpdate } = useMenuItem('menuItems', 'menuItem')

  const getNameArabic = (price: Price): string => {
    return price.multiLanguages
      ? `${new Map(JSON.parse(price.multiLanguages)).get('Arabic-name')}`
      : ''
  }

  useEffect(() => {
    const fetch = async () => {
      const price: Price = await pricesGet({ id: priceId })
      setName(price.name)
      setNameArabic(getNameArabic(price))
      setPrice(price.price)
      setSymphonyID(price.symphonyID ? price.symphonyID : '0')
      changeLoading(false)
    }
    priceId.length > 0 ? fetch() : changeLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setName(event.target.value)
  }
  const handleArabicNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setNameArabic(event.target.value)
  }
  const handlePriceChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (event.target.value === '') {
      setPrice(0)
    } else {
      setPrice(+event.target.value)
    }
  }
  const handleSymphonyIDChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSymphonyID(event.target.value)
  }

  const create = async () => {
    changeUpdating(true)

    if (name === '') {
      changeUpdating(false)
      return showError('Name is required')
    }

    if (price === undefined || price === null) {
      changeUpdating(false)
      return showError('Price is required')
    }
    if (price < 0) {
      changeUpdating(false)
      return showError('Price must be greater than 0')
    }

    const input: CreatePriceInput = {
      name,
      nameArabic,
      price,
      symphonyID,
      conceptID: conceptsSelected,
    }

    const priceEntry = await pricesCreate({
      userID: session.sub,
      userName: session.name,
      data: input,
    })

    if (priceEntry) {
      pricesChangeListing([...priceListing, priceEntry])
      if (menuItemId) {
        await menuItemsUpdate({
          id: menuItemId,
          data: {
            prices: [priceEntry.id],
          },
        })
      }

      hideDeletePopup()
    }

    changeUpdating(false)
  }

  const update = async () => {
    changeUpdating(true)
    const updateInput: UpdatePriceInput = {
      name,
      nameArabic,
      price,
      symphonyID,
    }
    if (name === '') {
      changeUpdating(false)
      return showError('Name is required')
    }
    if (price === undefined || price === null) {
      changeUpdating(false)
      return showError('Price is required')
    }
    if (price < 0) {
      changeUpdating(false)
      return showError('Price must be greater than 0')
    }
    let updatedPrice = await pricesUpdate({ id: priceId, data: updateInput })
    let index = 0
    for (let i = 0; i < priceListing.length; i++) {
      if (priceListing[i].id === priceId) {
        index = i
      }
    }
    priceListing[index] = updatedPrice
    pricesChangeListing(priceListing)

    if (menuItemId) {
      await menuItemsUpdate({
        id: menuItemId,
        data: {
          prices: [priceId],
        },
      })
    }

    changeUpdating(false)

    hideDeletePopup()
  }

  const handleSave = () => {
    priceId ? update() : create()
  }

  if (loading) return <ContentLoader />

  return (
    <Box
      sx={{
        width: '90%',
        maxWidth: 892,
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'grey.300',
          padding: '8px 16px',
          color: 'black',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6">
          {priceId.length === 0 ? 'Add New Price' : 'Edit Price'}
        </Typography>
        <CloseIcon
          aria-label="close modal"
          onClick={hideDeletePopup}
          sx={{ cursor: 'pointer' }}
        />
      </Box>
      <Paper
        square
        elevation={0}
        sx={{ border: '1px solid', borderColor: 'divider' }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ mb: 0.5 }}>
            <Typography component={'span'}>Name</Typography>
          </Box>
          <TextField
            placeholder={'name english'}
            fullWidth
            size="small"
            value={name}
            onChange={(event) => handleNameChange(event)}
          />
        </Box>
        <Box sx={{ p: 2 }}>
          <Box sx={{ mb: 0.5 }}>
            <Typography component={'span'}>Name Arabic</Typography>
          </Box>
          <TextField
            placeholder={'name arabic'}
            fullWidth
            size="small"
            value={nameArabic}
            onChange={(event) => handleArabicNameChange(event)}
          />
        </Box>
        <Box sx={{ px: 2, pt: 0, pb: 2 }}>
          <Box sx={{ mb: 0.5 }}>
            <Typography component={'span'}>Price</Typography>
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Typography
              variant="body2"
              sx={{
                position: 'absolute',
                top: '50%',
                left: 14,
                transform: 'translateY(-50%)',
                color: 'grey.600',
              }}
            >
              EGP
            </Typography>
            <TextField
              placeholder={'00'}
              fullWidth
              type={'number'}
              size="small"
              sx={{ '& .MuiOutlinedInput-input': { pl: 7 } }}
              inputProps={{ min: 0, inputMode: 'numeric' }}
              value={price}
              onChange={(event) => handlePriceChange(event)}
              onWheel={(e) => {
                //@ts-ignore
                e.target.blur()
              }}
            />
          </Box>
        </Box>
        <Box sx={{ px: 2, pt: 0, pb: 2 }}>
          <Box sx={{ mb: 0.5 }}>
            <Typography component={'span'}>Simphony ID</Typography>
          </Box>
          <TextField
            placeholder={'$30'}
            fullWidth
            size="small"
            value={symphonyID}
            onChange={(event) => handleSymphonyIDChange(event)}
          />
        </Box>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Button
            disableElevation
            variant="text"
            size="small"
            onClick={hideDeletePopup}
            sx={{ textTransform: 'none' }}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            variant="contained"
            size="small"
            onClick={handleSave}
            sx={{ ml: 2, textTransform: 'none' }}
            disabled={updating}
            endIcon={updating && <ButtonLoader />}
          >
            {priceId.length === 0 ? 'Add New Price' : 'Update Price'}
          </Button>
        </Box>
      </Paper>
    </Box>
  )
}

export default PriceModal
