// actions
const SET = "loyaltyDashboard/SET";
const SET_DASHBOARD_DATA = "loyaltyDashboard/SET_DASHBOARD_DATA";

const DEFAULT_STATE = {
  data: null,
  dashboardData: {
    totalDiscount: 0,
    totalDiscountPercentage: 0,
    totalDiscountChangeDirection: "No Change",
    totalRedemptions: 0,
    totalRedemptionsPercentage: 0,
    totalRedemptionsChangeDirection: 'No Change',
    top3RedeemedLoyaltyItemsIds: ''
}
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { data: action.data[0] });
    case SET_DASHBOARD_DATA:
      return Object.assign({}, state, { dashboardData: action.data });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setLoyaltyDashboardData = (data: any) => ({data,type:SET});
export const setDashboardData = (data: any) => ({data,type:SET_DASHBOARD_DATA});
