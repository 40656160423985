import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { faker } from "@faker-js/faker";
import { API, DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { createUser, updateUser } from "../graphql/mutations";
import { ByPhoneNumber, getUser, listUsers } from "../graphql/queries";
import { extractSelectedCheckboxes, validatePhone } from "../helpers/utils";
import { User } from "../models";
import { UserGetVariables, UserListingVariables } from "../models/app";
import { HeadCell } from "../models/dataTable";
import { CreateUserInput } from "../models/GQL_API";
import { setIsSearching, setListing, setSelected } from "../store/ducks/user";
import {
  CreateVariables,
  ListingVariables,
  Option,
  UserBulkTrashVariables,
  UserUpdateVariables,
} from "./../models/app";
import useApp from "./useApp";
import useFlag from "./useFlag";
import useGroup from "./useGroup";
import useInterest from "./useInterest";
import useTimeline from "./useTimeline";

const useResource = (listingName: string, singleName: string) => {
  const session = useSelector((state: any) => state.app.session);
  const dispatch = useDispatch();
  const { showConfirm, showError, setSelectedUser } = useApp();
  const { flagsGetName } = useFlag("flags", "flag");
  const { interestsGetName } = useInterest("interests", "interest");
  const { groupsGetName } = useGroup("groups", "group");
  const { timelinesCreate } = useTimeline("timelines", "timelines");
  const account = useSelector((state: any) => state.accounts.selected);
  const guestListing = useSelector((state: any) => state.guests.listing);

  async function fetchAll(params: ListingVariables) {
    const { startIndex, limit } = params;

    try {
      const listing: any = await DataStore.query(
        User as any,
        (model: any) => {
          model.deleted("eq", "0");
          model.accountID("eq", account.id);
          return model;
        },
        {
          page: startIndex / limit < 0 ? 0 : startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  // async function fetchAllOffline(params: ListingVariables) {
  //   const { startIndex, limit } = params;

  //   try {
  //     const listing: any = await DataStore.query(
  //       User as any,
  //       (model: any) => {
  //         model.deleted("eq", "0");
  //         model.accountID("eq", account.id);
  //         return model;
  //       },
  //       {
  //         // page: startIndex / limit,
  //         // limit: limit,
  //         sort: (s) => s.createdAt(SortDirection.DESCENDING),
  //       }
  //     );

  //     return listing;
  //   } catch (err: Error | any) {
  //     showError(err.message);
  //   }
  // }

  async function fetchAllOffline(params: UserListingVariables) {
    const {
      searchText,
      startIndex,
      email,
      phoneNumber,
      groupsSelectedFilters,
      interestsSelectedFilters,
    } = params;

    try {
      const listing = await DataStore.query(
        User as any,
        (model: any) => {
          model.deleted("eq", "0");
          model.accountID("eq", account.id);
          if (email) model.email("eq", email);
          if (phoneNumber) model.phone_number("eq", phoneNumber);

          if (groupsSelectedFilters && groupsSelectedFilters.length > 0) {
            model.or((model: any) => {
              for (let filter of groupsSelectedFilters) {
                model.group("contains", filter.id);
              }
            });
          }

          if (interestsSelectedFilters && interestsSelectedFilters.length > 0) {
            model.or((model: any) => {
              for (let filter of interestsSelectedFilters) {
                model.interests("contains", filter.id);
              }
            });
          }

          if (searchText && searchText.length > 0) {
            model.or((model: any) => {
              model.given_name("contains", searchText.toLowerCase());
              model.family_name("contains", searchText.toLowerCase());
              model.nickname("contains", searchText.toLowerCase());
              model.username("contains", searchText.toLowerCase());
              model.name("contains", searchText.toLowerCase());
              model.phone_number("contains", searchText);
              model.email("contains", searchText.toLowerCase());
            });
          }

          return model;
        },
        {
          sort: (s) => s.name(SortDirection.ASCENDING),
        }
      );

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function fetchOffline(params: UserListingVariables) {
    const {
      searchText,
      startIndex,
      limit,
      email,
      phoneNumber,
      groupsSelectedFilters,
      interestsSelectedFilters,
      page,
    } = params;

    try {
      const listing = await DataStore.query(
        User as any,
        (model: any) => {
          model.deleted("eq", "0");
          model.accountID("eq", account.id);
          if (email) model.email("eq", email);
          if (phoneNumber) model.phone_number("eq", phoneNumber);

          if (groupsSelectedFilters && groupsSelectedFilters.length > 0) {
            model.or((model: any) => {
              for (let filter of groupsSelectedFilters) {
                model.group("contains", filter.id);
              }
            });
          }

          if (interestsSelectedFilters && interestsSelectedFilters.length > 0) {
            model.or((model: any) => {
              for (let filter of interestsSelectedFilters) {
                model.interests("contains", filter.id);
              }
            });
          }

          if (searchText && searchText.length > 0) {
            model.or((model: any) => {
              model.given_name("contains", searchText.toLowerCase());
              model.family_name("contains", searchText.toLowerCase());
              model.nickname("contains", searchText.toLowerCase());
              model.username("contains", searchText.toLowerCase());
              model.name("contains", searchText.toLowerCase());
              model.phone_number("contains", searchText);
              model.email("contains", searchText.toLowerCase());
            });
          }

          return model;
        },
        {
          page: page ?? startIndex / limit,
          limit: limit,
          sort: (s) => s.name(SortDirection.ASCENDING),
        }
      );

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function fetch(params: UserListingVariables) {
    let {
      searchText,
      limit,
      email,
      phoneNumber,
      groupsSelectedFilters,
      interestsSelectedFilters,
    } = params;

    const filter: any = { deleted: { eq: "0" }, accountID: { eq: account.id } };

    if (email) filter.email = { eq: email };
    if (phoneNumber) filter.phone_number = { eq: phoneNumber };

    if (searchText && searchText.length > 0) {
      searchText = searchText.trim();
      filter.or = [
        { given_name: { contains: searchText.toLowerCase() } },
        { family_name: { contains: searchText.toLowerCase() } },
        { nickname: { contains: searchText.toLowerCase() } },
        { name: { contains: searchText.toLowerCase() } },
        { username: { contains: searchText.toLowerCase() } },
        { email: { contains: searchText.toLowerCase() } },
        { phone_number: { beginsWith: searchText.toLowerCase() } },
      ];
    }

    if (groupsSelectedFilters && groupsSelectedFilters.length > 0) {
      const groupsFilters: any[] = [];

      for (let filter of groupsSelectedFilters) {
        groupsFilters.push({ group: { eq: filter.id } });
      }

      filter.or = groupsFilters;
    }

    if (interestsSelectedFilters && interestsSelectedFilters.length > 0) {
      const filters: any[] = [];

      for (let filter of interestsSelectedFilters) {
        filters.push({ interests: { contains: filter.id } });
      }

      filter.or = filters;
    }

    try {
      const listing: any = await API.graphql({
        query: listUsers,
        variables: {
          limit: 1,
          filter,
        },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return listing.data.listUsers.items;
    } catch (err) {
      throw err;
    }
  }

  async function fetchByPhoneNumberOnline(params: any) {
    const { phone_number } = params;
    const filter: any = {
      deleted: { eq: "0" },
      accountID: { eq: account.id },
    };
    try {
      const listing: any = await API.graphql({
        query: ByPhoneNumber,
        variables: { filter, phone_number },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return listing.data.ByPhoneNumber.items[0];
    } catch (err) {
      throw err;
    }
  }

  // async function fetchByEmailOnline(params: any) {
  //   const { email } = params;
  //   const filter: any = { deleted: { eq: "0" }, accountID: { eq: account.id } };
  //   try {
  //     const listing: any = await API.graphql({
  //       query: usersByEmail,
  //       variables: { filter, email },
  //       authMode: session
  //         ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
  //         : GRAPHQL_AUTH_MODE.AWS_IAM,
  //     });
  //     return listing.data.usersByEmail.items[0];
  //   } catch (err) {
  //     throw err;
  //   }
  // }

  async function exportAll(params: UserListingVariables) {
    try {
      const data = await fetchAllOffline(params);

      let exportedData: any[] = [];

      for (let user of data!) {
        let row: any = { ...user };

        if (user.group) {
          row.groupName = groupsGetName({
            id: user.group,
            listing: params.groupsListing ? params.groupsListing : [],
          });
        }

        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      showError(err);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: UserGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: User) => model.id === id);

      return model ? model.name : "";
    }

    return "";
  };

  // async function get(params: UserGetVariables) {
  //   const { id } = params;
  //   try {
  //     const single: User | undefined = await DataStore.query(User as any, id);

  //     if (single) return single;

  //     throw new Error("Cannot find guest with given id " + id);
  //   } catch (err) {
  //     showError(err);
  //   }
  // }

  async function get(params: any) {
    const { id } = params;

    if (!id) return undefined;

    try {
      const user: any = await API.graphql({
        query: getUser,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return user.data.getUser;
    } catch (err) {
      throw err;
    }
  }

  /**
   * Construct name from given_name, middle_name, and family_name
   *
   * @param given_name given_name: string
   * @param middle_name middle_name: string
   * @param family_name family_name: string
   *
   * @returns string
   */
  const constructName = (
    given_name: string,
    middle_name: string,
    family_name: string
  ) => {
    if (
      given_name.length > 0 &&
      middle_name.length > 0 &&
      family_name.length > 0
    ) {
      return (
        given_name.trim() + " " + middle_name.trim() + " " + family_name.trim()
      );
    }

    if (given_name.length > 0 && family_name.length > 0) {
      return given_name.trim() + " " + family_name.trim();
    }

    if (given_name.length > 0 && middle_name.length > 0) {
      return given_name.trim() + " " + middle_name.trim();
    }

    return given_name;
  };

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.accountID) {
      throw new Error(`Cannot create ${singleName} without accountID`);
    }
    if (!data.flagsListing) {
      throw new Error(`Cannot create ${singleName} without flagsListing`);
    }
    if (!data.interestsListing) {
      throw new Error(`Cannot create ${singleName} without interestsListing`);
    }
    // if (!data.group) {
    //   throw new Error(`Cannot create ${singleName} without group`);
    // }

    try {
      const flags: string[] = extractSelectedCheckboxes("flags_", data);
      const flagsName: string[] = [];
      const interests: string[] = extractSelectedCheckboxes("interests_", data);
      const interestsName: string[] = [];

      for (let flagID of flags) {
        flagsName.push(
          flagsGetName({ id: flagID, listing: data.flagsListing })
        );
      }

      for (let interestID of interests) {
        interestsName.push(
          interestsGetName({ id: interestID, listing: data.interestsListing })
        );
      }

      if (data.phone_number) {
        if (data.phone_number && !validatePhone(data.phone_number)) {
          throw new Error("Phone number is not valid");
        }
        const params: UserListingVariables = {
          searchText: "",
          startIndex: 0,
          limit: 99999999,
          phoneNumber: data.phone_number,
          accountID: account.id,
        };
        let userExists = await fetch(params);
        if (userExists && userExists.length > 0) {
          throw new Error("Phone number already exists");
        }
      }

      if (data.email) {
        const params: UserListingVariables = {
          searchText: "",
          startIndex: 0,
          limit: 99999999,
          accountID: account.id,
          email: data.email,
        };

        let userExists = await fetch(params);

        if (userExists !== undefined && userExists.length > 0) {
          throw new Error("Email already exists");
        }
      }

      if (data.phone_number) {
        const params: UserListingVariables = {
          searchText: "",
          startIndex: 0,
          limit: 1000,
          accountID: account.id,
          phoneNumber: data.phone_number,
        };

        let userExists = await fetch(params);

        if (userExists !== undefined && userExists.length > 0) {
          throw new Error("Phone Number already exists");
        }
      }

      if (data.birthdate) {
        if (
          data.birthdate <= "1940-01-01" ||
          data.birthdate >= `${new Date().getFullYear() - 10}-01-01`
        ) {
          throw new Error("Invalid Date of Birth");
        }
      }
      const createInput: CreateUserInput = {
        accountID: data.accountID,
        phone_number: data.phone_number,
        given_name: data.given_name ? data.given_name.toLowerCase() : "",
        middle_name: data.middle_name ? data.middle_name.toLowerCase() : "",
        family_name: data.family_name ? data.family_name.toLowerCase() : "",
        nickname: data.nickname ? data.nickname.toLowerCase() : "",
        name: data.name ? data.name.toLowerCase() : "username",
        // name: data.name
        //   ? data.name.toLowerCase()
        //   : constructName(
        //       data.given_name ? data.given_name.toLowerCase() : "",
        //       data.middle_name ? data.middle_name.toLowerCase() : "",
        //       data.family_name ? data.family_name.toLowerCase() : ""
        //     ),
        address: data.address ? data.address : "",
        birthdate: data.birthdate ? data.birthdate : "",
        picture: data.picture ? data.picture : "",
        website: data.website ? data.website : "",
        facebook: data.facebook ? data.facebook : "",
        instagram: data.instagram ? data.instagram : "",
        username: data.username ? data.username : "",
        group: data.group,
        email: data.email ? data.email : "",
        flags,
        flagsName,
        interests,
        interestsName,
        stats: [],
        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      const user = await DataStore.save(new User(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);

      return user;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function createOnline(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.accountID) {
      throw new Error(`Cannot create ${singleName} without accountID`);
    }
    //  if (!data.flagsListing) {
    //   throw new Error(`Cannot create ${singleName} without flagsListing`);
    // }
    // if (!data.interestsListing) {
    //   throw new Error(`Cannot create ${singleName} without interestsListing`);
    // }

    try {
      // const flags: string[] = extractSelectedCheckboxes("flags_", data);
      // const flagsName: string[] = [];
      // const interests: string[] = extractSelectedCheckboxes("interests_", data);
      // const interestsName: string[] = [];

      // for (let flagID of flags) {
      //   flagsName.push(
      //     flagsGetName({ id: flagID, listing: data.flagsListing })
      //   );
      // }

      // for (let interestID of interests) {
      //   interestsName.push(
      //     interestsGetName({ id: interestID, listing: data.interestsListing })
      //   );
      // }

      if (data.phone_number) {
        if (data.phone_number && !validatePhone(data.phone_number)) {
          throw new Error("Phone number is not valid");
        }
        const params: any = {
          searchText: "",
          startIndex: 0,
          limit: 99999999,
          phone_number: data.phone_number,
          accountID: account.id,
        };
        let userExists = await fetchByPhoneNumberOnline(params);
        if (userExists) {
          throw new Error("Phone number already exists");
        }
      }

      // if (data.email) {
      //   const params: any = {
      //     searchText: "",
      //     startIndex: 0,
      //     limit: 99999999,
      //     accountID: account.id,
      //     email: data.email,
      //   };
      //   let userExists = await fetchByEmailOnline(params);
      //   if (userExists !== undefined && userExists.length > 0) {
      //     throw new Error("Email already exists");
      //   }
      // }

      // if (data.phone_number) {
      //   const params: UserListingVariables = {
      //     searchText: "",
      //     startIndex: 0,
      //     limit: 1000,
      //     accountID: account.id,
      //     phoneNumber: data.phone_number,
      //   };

      //   let userExists = await fetch(params);

      //   if (userExists !== undefined && userExists.length > 0) {
      //     throw new Error("Phone Number already exists");
      //   }
      // }

      if (data.birthdate) {
        if (
          data.birthdate <= "1940-01-01" ||
          data.birthdate >= `${new Date().getFullYear() - 10}-01-01`
        ) {
          throw new Error("Invalid Date of Birth");
        }
      }
      //  let input = {
      //     username: "+2" + data.phone_number,
      //     password: generateStrongPassword(),
      //       attributes: {
      //         email: data.email,
      //         phone_number:"+2" + data.phone_number,
      //         name: data.name
      //       }
      //   }
      // const signedUpUser = await Auth.signUp(input);
      const createInput: CreateUserInput = {
        accountID: data.accountID,
        phone_number: data.phone_number,
        given_name: data.given_name ? data.given_name.toLowerCase() : "",
        middle_name: data.middle_name ? data.middle_name.toLowerCase() : "",
        family_name: data.family_name ? data.family_name.toLowerCase() : "",
        nickname: data.nickname ? data.nickname.toLowerCase() : "",
        name: data.name ? data.name.trim().toLowerCase() : "",
        email_verified: false,
        phone_number_verified: false,
        birthdate: data.birthdate ? data.birthdate : "",
        picture: data.picture ? data.picture : "",
        website: data.website ? data.website : "",
        facebook: data.facebook ? data.facebook : "",
        instagram: data.instagram ? data.instagram : "",
        username: data.username ? data.username : "",
        group: data.group ? data.group : "",
        email: data.email ? data.email : "",
        flags: data.flags ?? [],
        flagsName: data.flagsName ?? [],
        interests: data.interests ?? [],
        interestsName: data.interestsName ?? [],
        stats: data.stats ?? [],
        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };
      const user: any = await API.graphql({
        query: createUser,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(setListing([...guestListing, user.data.createUser]));

      showConfirm(`New ${singleName} has been created successfully`);

      return user.data.createUser;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function createFakeUsers(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.accountID) {
      throw new Error(`Cannot create ${singleName} without accountID`);
    }
    if (!data.group) {
      throw new Error(`Cannot create ${singleName} without group`);
    }
    if (!data.flagsListing) {
      throw new Error(`Cannot create ${singleName} without flagsListing`);
    }
    if (!data.interestsListing) {
      throw new Error(`Cannot create ${singleName} without interestsListing`);
    }
    if (!data.statusesListing) {
      throw new Error(`Cannot create ${singleName} without statusesListing`);
    }
    if (!data.timeSlotsListing) {
      throw new Error(`Cannot create ${singleName} without timeSlotsListing`);
    }

    const flags: string[] = extractSelectedCheckboxes("flags_", {});
    const flagsName: string[] = [];
    const interests: string[] = extractSelectedCheckboxes("interests_", {});
    const interestsName: string[] = [];

    for (let flagID of flags) {
      flagsName.push(flagsGetName({ id: flagID, listing: data.flagsListing }));
    }

    for (let interestID of interests) {
      interestsName.push(
        interestsGetName({ id: interestID, listing: data.interestsListing })
      );
    }

    for (let user = 0; user < data.numOfUsers; user++) {
      const constructNameObj = {
        given_name: faker.name.firstName().toLowerCase(),
        family_name: faker.name.lastName().toLowerCase(),
      };

      const createInput: CreateUserInput = {
        accountID: data.accountID,
        phone_number: faker.phone.number("#############"),
        given_name: constructNameObj.given_name,
        family_name: constructNameObj.family_name,
        nickname: "",
        name: constructNameObj.given_name + " " + constructNameObj.family_name,
        username: "",
        address:
          faker.address.country().toLowerCase() +
          ", " +
          faker.address.city().toLowerCase(),
        birthdate: faker.date
          .birthdate({ min: 18, max: 65, mode: "year" })
          .toLocaleString(),
        picture: "",
        website: "",
        facebook: "",
        instagram: "",
        group: data.group,
        email: faker.internet.email(),
        flags,
        flagsName,
        interests,
        interestsName,
        stats: [],
        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };
      await DataStore.save(new User(createInput as any));
    }

    return showConfirm(
      `New ${data.numOfUsers} Of ${singleName} has been created successfully`
    );
  }

  // async function updateEmail(params: any) {
  //   const { original, updated } = params;

  //   if (updated.email) {
  //     const params: UserListingVariables = {
  //       searchText: "",
  //       startIndex: 0,
  //       limit: 1000,
  //       email: updated.email
  //     };
  //     let userExists = await fetch(params);

  //     if (userExists !== undefined && userExists.length > 0) {
  //       throw new Error("Email already exists");
  //
  //     }
  //   }

  //   try {
  //     const updatedData = await DataStore.save(
  //       User.copyOf(original!, (updated) => {
  //         updated.email = updated.email ? updated.email : "";
  //       })
  //     );
  //     let email = updated.email;
  //     const createInput = {
  //       ...updated,
  //       customerId: original.id,
  //       email,
  //       resource: original!,
  //       statusesListing: updated.statusesListing,
  //       timeSlotsListing: updated.timeSlotsListing,
  //       timelinesListing: updated.timelinesListing
  //     };

  //     const timelineParams: CreateVariables = {
  //       userID: session.sub,
  //       userName: session.name,
  //       data: createInput
  //     };

  //     await timelinesCreate(timelineParams);
  //     setSelectedUser(updatedData);
  //     showConfirm(`${singleName} has been updated successfully`);
  //     return updatedData;
  //   } catch (err: Error | any) {
  //     throw err
  //   }
  // }

  async function updateEmail(params: any) {
    const { original, updated } = params;

    if (updated.email) {
      const params: UserListingVariables = {
        searchText: "",
        startIndex: 0,
        limit: 1000,
        email: updated.email,
        accountID: account.id,
      };
      let userExists = await fetch(params);

      if (userExists !== undefined && userExists.length > 0) {
        throw new Error("Email already exists");
      }
    }

    try {
      let email = updated.email;
      const updatedData = await DataStore.save(
        User.copyOf(original!, (updated) => {
          updated.email = email ? email.toLowerCase() : original!.email;
        })
      );

      const createInput = {
        ...updated,
        customerId: original.id,
        email,
        resource: original!,
        statusesListing: updated.statusesListing,
        timeSlotsListing: updated.timeSlotsListing,
        timelinesListing: updated.timelinesListing,
      };

      const timelineParams: CreateVariables = {
        userID: session.sub,
        userName: session.name,
        data: createInput,
      };

      await timelinesCreate(timelineParams);
      // setSelectedUser(updatedData);
      showConfirm(`${singleName} Email has been updated successfully`);
      return updatedData;
    } catch (err: Error | any) {
      throw err;
    }
  }
  async function updateName(params: any) {
    const { original, updated } = params;

    if (updated && updated.name.length <= 1) {
      throw new Error("Guest Should have First and Last Name");
    }
    let fullName = updated.name.trim().split(" ");
    fullName = fullName.filter((str: string) => str !== "");
    let firstName = fullName[0];
    let lastName = `${fullName[2] ? fullName[2] : fullName[1]}`;
    let middleName = `${fullName[2] ? fullName[1] : ""}`;

    try {
      let name = original!.name !== undefined ? original!.name : "";

      if (updated.given_name && updated.given_name && updated.family_name) {
        name = constructName(
          updated.given_name.toLowerCase(),
          "",
          updated.family_name.toLowerCase()
        );

        if (
          original &&
          updated.phone_number &&
          updated.phone_number !== original.phone_number
        ) {
          const params: UserListingVariables = {
            searchText: "",
            startIndex: 0,
            limit: 1000,
            phoneNumber: original.phone_number,
            accountID: account.id,
          };

          let userExists = await fetch(params);

          if (userExists !== undefined && userExists.length > 0) {
            throw new Error("Phone Number already exists");
          }
        }
      }

      const updatedData = await DataStore.save(
        User.copyOf(original!, (updated) => {
          updated.given_name = firstName
            ? firstName.toLowerCase()
            : original!.given_name;

          updated.middle_name = middleName
            ? middleName.toLowerCase()
            : original!.middle_name;

          updated.family_name = lastName
            ? lastName.toLowerCase()
            : original!.family_name;
          updated.name = name.trim().toLowerCase();
        })
      );

      const createInput = {
        ...updated,
        customerId: original.id,
        name: name.trim().toLowerCase(),
        resource: original!,
        statusesListing: updated.statusesListing,
        timeSlotsListing: updated.timeSlotsListing,
        timelinesListing: updated.timelinesListing,
      };

      const timelineParams: CreateVariables = {
        userID: session.sub,
        userName: session.name,
        data: createInput,
      };

      await timelinesCreate(timelineParams);
      setSelectedUser(updatedData);
      showConfirm(`${singleName} Name has been updated successfully`);
      return updatedData;
    } catch (err: Error | any) {
      throw err;
    }
  }

  // async function update(params: UserUpdateVariables) {
  //   const { id, listing, data } = params;
  //   const flagsName: string[] = [];
  //   const interestsName: string[] = [];

  //   if (!data.accountID) {
  //     throw new Error(`Cannot update ${singleName} without accountID`);
  //   }
  //   if (!data.flagsListing) {
  //     throw new Error(`Cannot update ${singleName} without flagsListing list`);
  //   }
  //   if (!data.interestsListing) {
  //     throw new Error(`Cannot update ${singleName} without interestsListing`);
  //   }
  //   if (!data.statusesListing) {
  //     throw new Error(`Cannot update ${singleName} without statusesListing`);
  //   }
  //   if (!data.timeSlotsListing) {
  //     throw new Error(`Cannot update ${singleName} without timeSlotsListing`);
  //   }
  //   if (!data.timelinesListing) {
  //     throw new Error(`Cannot update ${singleName} without timelinesListing`);
  //   }

  //   if (data.flags) {
  //     for (let flagID of data.flags) {
  //       flagsName.push(
  //         await flagsGetName({ id: flagID, listing: data.flagsListing })
  //       );
  //     }
  //   }

  //   if (data.interests) {
  //     for (let interestID of data.interests) {
  //       interestsName.push(
  //         await interestsGetName({
  //           id: interestID,
  //           listing: data.interestsListing,
  //         })
  //       );
  //     }
  //   }

  //   if (data.phone_number) {
  //     if (data.phone_number && !validatePhone(data.phone_number)) {
  //       throw new Error("Phone number is not valid");
  //     }
  //     const params: UserListingVariables = {
  //       searchText: "",
  //       startIndex: 0,
  //       limit: 99999999,
  //       phoneNumber: data.phone_number,
  //       accountID: account.id,
  //     };
  //     let userExists = await fetch(params);
  //     if (userExists !== undefined && userExists.length > 0) {
  //       throw new Error("Phone number already exists");
  //     }
  //   }

  //   if (data.email) {
  //     const params: UserListingVariables = {
  //       searchText: "",
  //       startIndex: 0,
  //       limit: 99999999,
  //       email: data.email,
  //       accountID: account.id,
  //     };

  //     let userExists = await fetch(params);

  //     if (userExists !== undefined && userExists.length > 0) {
  //       throw new Error("Email already exists");
  //     }
  //   }

  //   try {
  //     const original = await get({ id, listing });

  //     let name = original!.name !== undefined ? original!.name : "";

  //     if (data.given_name && data.given_name && data.family_name) {
  //       name = constructName(
  //         data.given_name.toLowerCase(),
  //         "",
  //         data.family_name.toLowerCase()
  //       );

  //       if (
  //         original &&
  //         data.phone_number &&
  //         data.phone_number !== original.phone_number
  //       ) {
  //         const params: UserListingVariables = {
  //           searchText: "",
  //           startIndex: 0,
  //           limit: 1000,
  //           phoneNumber: data.phone_number,
  //           accountID: account.id,
  //         };

  //         let userExists = await fetch(params);

  //         if (userExists !== undefined && userExists.length > 0) {
  //           throw new Error("Phone Number already exists");
  //         }
  //       }
  //     }

  //     const updated = await DataStore.save(
  //       User.copyOf(original!, (updated) => {
  //         updated.phone_number = data.phone_number
  //           ? data.phone_number
  //           : original!.phone_number;
  //         updated.given_name = data.given_name
  //           ? data.given_name.toLowerCase()
  //           : original!.given_name;
  //         updated.middle_name = data.middle_name
  //           ? data.middle_name.toLowerCase()
  //           : original!.middle_name;
  //         updated.nickname = data.nickname
  //           ? data.nickname.toLowerCase()
  //           : original!.nickname;
  //         updated.family_name = data.family_name
  //           ? data.family_name.toLowerCase()
  //           : original!.family_name;
  //         updated.name = name;
  //         updated.address = data.address ? data.address : original!.address;
  //         updated.birthdate = data.birthdate
  //           ? data.birthdate
  //           : original!.birthdate;
  //         updated.picture = data.picture ? data.picture : original!.picture;
  //         updated.website = data.website ? data.website : original!.website;
  //         updated.facebook = data.facebook ? data.facebook : original!.facebook;
  //         updated.instagram = data.instagram
  //           ? data.instagram
  //           : original!.instagram;
  //         updated.username = data.username ? data.username : original!.username;
  //         updated.group = data.group ? data.group : original!.group;
  //         updated.email =
  //           data.email || data.email === "" ? data.email : original!.email;
  //         updated.flags = data.flags ? data.flags : original!.flags;
  //         updated.flagsName = data.flags ? flagsName : original!.flagsName;
  //         updated.interests = data.interests
  //           ? data.interests
  //           : original!.interests;
  //         updated.interestsName = data.interests
  //           ? interestsName
  //           : original!.interestsName;
  //         updated.stats = data.stats ? data.stats : original!.stats;
  //         updated.deleted = data.deleted ? data.deleted : original!.deleted;
  //       })
  //     );

  //     const createInput = {
  //       ...data,
  //       customerId: id,
  //       name,
  //       flagsName,
  //       interestsName,
  //       resource: original!,
  //       statusesListing: data.statusesListing,
  //       timeSlotsListing: data.timeSlotsListing,
  //       timelinesListing: data.timelinesListing,
  //     };

  //     const timelineParams: CreateVariables = {
  //       userID: session.sub,
  //       userName: session.name,
  //       data: createInput,
  //     };

  //     await timelinesCreate(timelineParams);

  //     setSelectedUser(updated);

  //     showConfirm(`${singleName} has been updated successfully`);

  //     return updated;
  //   } catch (err: Error | any) {
  //     throw err;
  //   }
  // }

  async function update(params: UserUpdateVariables) {
    const { id, data } = params;
    const flagsName: string[] = [];
    const interestsName: string[] = [];

    if (!data.accountID) {
      throw new Error(`Cannot update ${singleName} without accountID`);
    }
    if (!data.flagsListing) {
      throw new Error(`Cannot update ${singleName} without flagsListing list`);
    }
    if (!data.interestsListing) {
      throw new Error(`Cannot update ${singleName} without interestsListing`);
    }
    if (!data.statusesListing) {
      throw new Error(`Cannot update ${singleName} without statusesListing`);
    }
    if (!data.timeSlotsListing) {
      throw new Error(`Cannot update ${singleName} without timeSlotsListing`);
    }
    if (!data.timelinesListing) {
      throw new Error(`Cannot update ${singleName} without timelinesListing`);
    }

    if (data.flags) {
      for (let flagID of data.flags) {
        flagsName.push(
          await flagsGetName({ id: flagID, listing: data.flagsListing })
        );
      }
    }

    if (data.interests) {
      for (let interestID of data.interests) {
        interestsName.push(
          await interestsGetName({
            id: interestID,
            listing: data.interestsListing,
          })
        );
      }
    }

    if (data.phone_number && !validatePhone(data.phone_number)) {
      throw new Error("Phone number is not valid");
    }

    if (data.email) {
      const params: UserListingVariables = {
        searchText: "",
        startIndex: 0,
        limit: 1000,
        email: data.email,
        accountID: account.id,
      };

      let userExists = await fetch(params);

      if (userExists !== undefined && userExists.length > 0) {
        throw new Error("Email already exists");
      }
    }

    if (data.phone_number) {
      if (data.phone_number && !validatePhone(data.phone_number)) {
        throw new Error("Phone number is not valid");
      }
      const params: UserListingVariables = {
        searchText: "",
        startIndex: 0,
        limit: 99999999,
        phoneNumber: data.phone_number,
        accountID: account.id,
      };
      let userExists = await fetch(params);
      if (userExists !== undefined && userExists.length > 0) {
        throw new Error("Phone number already exists");
      }
    }

    try {
      const original = await get({ id });
      let name = data.name ?? original.name;

      if (data.given_name && data.given_name && data.family_name) {
        name = constructName(
          data.given_name.toLowerCase(),
          "",
          data.family_name.toLowerCase()
        );

        if (
          original &&
          data.phone_number &&
          data.phone_number !== original.phone_number
        ) {
          const params: UserListingVariables = {
            searchText: "",
            startIndex: 0,
            limit: 1000,
            phoneNumber: data.phone_number,
            accountID: account.id,
          };

          let userExists = await fetch(params);

          if (userExists !== undefined && userExists.length > 0) {
            throw new Error("Phone Number already exists");
          }
        }
      }
      const updated: any = await API.graphql({
        query: updateUser,
        variables: {
          input: {
            id: original!.id,
            phone_number: data.phone_number
              ? data.phone_number
              : original!.phone_number,
            phone_number_verified:
              data.phone_number && data.phone_number !== original.phone_number
                ? false
                : original.phone_number_verified,
            updated:
              data.phone_number !== original.phone_number ? "true" : "false",
            given_name: data.given_name
              ? data.given_name.toLowerCase()
              : original!.given_name,
            middle_name: data.middle_name
              ? data.middle_name.toLowerCase()
              : original!.middle_name,
            nickname: data.nickname
              ? data.nickname.toLowerCase()
              : original!.nickname,
            family_name: data.family_name
              ? data.family_name.toLowerCase()
              : original!.family_name,
            name: name.trim().toLowerCase(),
            address: data.address ? data.address : original!.address,
            birthdate: data.birthdate ? data.birthdate : original!.birthdate,
            picture: data.picture ? data.picture : original!.picture,
            website: data.website ? data.website : original!.website,
            facebook: data.facebook ? data.facebook : original!.facebook,
            instagram: data.instagram ? data.instagram : original!.instagram,
            username: data.username ? data.username : original!.username,
            group: data.group ? data.group : original!.group,
            email: data.email ? data.email : original!.email,
            flags: data.flags ? data.flags : original!.flags,
            flagsName: data.flags ? flagsName : original!.flagsName,
            interests: data.interests ? data.interests : original!.interests,
            interestsName: data.interests
              ? interestsName
              : original!.interestsName,
            stats: data.stats ? data.stats : original!.stats,
            deleted: data.deleted ?? original.deleted,
            createdAt: original.createdAt,
            _version: original._version,
          },
        },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const createInput = {
        ...data,
        customerId: id,
        name,
        address: data.address,
        flagsName,
        interestsName,
        resource: original!,
        statusesListing: data.statusesListing,
        timeSlotsListing: data.timeSlotsListing,
        timelinesListing: data.timelinesListing,
      };

      const timelineParams: CreateVariables = {
        userID: session.sub,
        userName: session.name,
        data: createInput,
      };

      await timelinesCreate(timelineParams);

      setSelectedUser(updated.data.updateUser);

      showConfirm(`${singleName} has been updated successfully`);

      return updated.data.updateUser;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function updateEmailOnline(resource: any, data: any) {
    try {
      // const original = await getOnline(resourceId);
      if (data.email) {
        const updated: any = await API.graphql({
          query: updateUser,
          variables: {
            input: {
              id: resource.id,
              email: data.email,
              _version: resource._version,
            },
          },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        if (updated) {
          // call send Notification FUnction
        }
        setSelectedUser(updated.data.updateUser);
        return updated.data.updateUser;
      }
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function trash(params: UserGetVariables) {
    try {
      const { id, listing } = params;
      const original = await get(params);

      if (original) {
        await API.graphql<User>({
          query: updateUser,
          variables: {
            input: {
              id: original.id,
              name: original.name,
              createdAt: original.createdAt,
              deleted: "1",
              _version: original._version,
            },
          },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        dispatch(
          setListing(listing.filter((resource: any) => resource.id !== id))
        );
        showConfirm(`${singleName} has been moved to trash successfully`);
      }
    } catch (err) {
      showError(err);
    }
  }

  async function updateGroup(params: UserGetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        User.copyOf(original!, (updated) => {
          updated.group = "97298e1a-4d87-4e10-9154-e21e54e5abae";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: UserBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: User) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: UserGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: User) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: User[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name!, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "phone_number",
      numeric: false,
      disablePadding: false,
      label: "Mobile",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
    },
    // {
    //   id: "group",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Group",
    // },
    // {
    //   id: "interests",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Interests",
    // },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: string[] = [
    "name",
    "phone_number",
    "email",
    // "group",
    // "interests",
    "createdByName",
    "createdAt",
  ];

  const api: any = {};

  api[`${listingName}Model`] = User as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetchOffline;
  api[`${listingName}FetchOffline`] = fetchOffline;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}UpdateGroup`] = updateGroup;
  // api[`${listingName}FetchOnline`] = fetchOnline;
  api[`${listingName}Get`] = get;
  // api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Create`] = createOnline;
  api[`${listingName}CreateOnline`] = createOnline;
  api[`${listingName}Update`] = update;
  // api[`${listingName}UpdateOnline`] = updateOnline;
  api[`${listingName}UpdateEmail`] = updateEmail;
  api[`${listingName}UpdateName`] = updateName;

  //fetch by phonenumberonline
  api[`${listingName}FetchByPhoneNumberOnline`] = fetchByPhoneNumberOnline;

  // updateNameEmailOnline
  api[`${listingName}UpdateEmailOnline`] = updateEmailOnline;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}ChangeListing`] = (listing: any[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeIsSearching`] = (payload: boolean) =>
    dispatch(setIsSearching(payload));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));
  api.createFakeUsers = createFakeUsers;

  return api;
};

export default useResource;
