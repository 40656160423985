// actions
const SET = "adminStatus/SET";
const SET_SEARCH_TEXT = "adminStatus/SET_SEARCH_TEXT";
const SET_SELECTED = "adminStatus/SET_SELECTED";
const CHANGE_LIMIT = "adminStatus/CHANGE_LIMIT";
const NEXT_ACTION = "adminStatus/NEXT_ACTION";
const SET_NEXT_TOKEN = "adminStatus/SET_NEXT_TOKEN";
const SET_PREVIOUS_TOKENS = "adminStatus/SET_PREVIOUS_TOKENS";
const SET_LAST_INDEX = "adminStatus/SET_LAST_INDEX";
const SET_PAGINATION = "adminStatus/SET_PAGINATION";


const DEFAULT_STATE = {
  listing: [],
  searchText: "",
  selected: "",
  pagination:[],
  lastIndex:null,
  limit: 10,
  nextToken: null,
  previousTokens: [],
};


// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_LAST_INDEX:
      return Object.assign({}, state, { lastIndex: action.id });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.nextToken });
    case SET_PREVIOUS_TOKENS:
      return Object.assign({}, state, {
        previousTokens: action.previousTokens,
      });
    case SET_PAGINATION:
      return Object.assign({}, state, { pagination: action.pagination });
    case CHANGE_LIMIT:
      return Object.assign({}, state, {
        limit: action.limit,
        nextToken: action.nextToken,
        nextNextToken: action.nextNextToken,
        previousTokens: action.previousTokens,
      });

    case NEXT_ACTION:
      return Object.assign({}, state, {
        nextToken: action.nextToken,
        previousTokens: action.previousTokens,
      });

    default:
      return state;
  }
};

export default reducer;


// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setPagination = (pagination: any[]) => ({ pagination, type: SET_PAGINATION });
export const setSearchText = (searchText: string) => ({ searchText, type: SET_SEARCH_TEXT,});
export const setLastIndex = (id: number) => ({ id, type: SET_LAST_INDEX });
export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
export const changeLimit = (limit: any) => ({
  limit: limit,
  nextToken: null,
  nextNextToken: null,
  previousTokens: [],
  type: CHANGE_LIMIT,
});
export const nextAction = (nextToken: any, previousTokens: any[]) => ({
  nextToken: nextToken,
  previousTokens: previousTokens ? [...previousTokens, nextToken] : [nextToken],
  type: NEXT_ACTION,
});
export const setNextToken = (nextToken: any) => ({nextToken, type: SET_NEXT_TOKEN,});
export const setPreviousToken = (previousTokens: any) => ({previousTokens, type: SET_PREVIOUS_TOKENS,});


