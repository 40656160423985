import { ADMIN_ROLES } from "../constants/enums";
import { AdminPermissions, AdminRoleGetVariables } from "../models/app";
import useAdminRole from "./useAdminRole";

const usePermissions = () => {
  const { adminRolesFetchAll } = useAdminRole("adminRoles", "adminRole");

  const adminPermissions: AdminPermissions = {
    can_access_zones_report: false,
    admins_access: true,
    dashboard: false,
    settings_access: false,
    viewReservations: false,
    createReservation: false,
    cancelReservation: false,
    createGuest: false,
    editGuest: false,
    editVip: false,
    lockTable: false,
    editLayout: false,
    transactionReport: false,
    paymentDashboard: false,
    paymobSettings: false,
    call_center1: false,
    waiter: false,
    kitchen: false,
    can_see_payments: false,
    can_see_reports: false,
    can_see_guests: false,
    can_access_order_dashboard: false,
    can_access_warehouse: false,
    can_change_admins_passwords: false,
    can_sync_item_from_simphony: false,
    can_order_later: false,
    can_assign_concept: false,
    can_assign_admin_group: false,
    can_edit_sim_emp_id: false,
    can_edit_admin: false,
    can_delete_admin: false,
    can_view_kpi: false,
    can_view_loyalty: false,
    can_view_orders_list: false,
    can_accept: false,
    can_cancel_orders: false,
    can_edit_orders: false,
    can_resolve: false,
    can_retry: false,
    can_search_call_center: false,
    can_view_admin_roles: false,
    can_view_categories: false,
    can_view_channels: false,
    can_view_choices: false,
    can_view_concepts: false,
    can_view_dispatchers: false,
    can_view_groups: false,
    can_view_loyalty_items: false,
    can_view_media: false,
    can_view_menu_items: false,
    can_view_plan_items: false,
    can_view_service: false,
    can_void_orders: false,
    can_view_zones: false,
    can_view_subCategories: false,
    can_view_campaigns: false,
    can_see_notifications: false,
    can_create_orders: false,
    can_reorder: false,
    can_mark_as_ready: false,
    can_settle: false,
    can_delete: false,
    can_regenerate_token: false,
  };

  const getAdminPermissions = async (props: {
    userGroup: any;
  }): Promise<AdminPermissions> => {
    const { userGroup } = props;
    if (userGroup && userGroup.length > 0) {
      let userRolesIds: any[] = [];
      for (let group of userGroup) {
        userRolesIds = userRolesIds.concat(group.roles);
      }

      const roles = await adminRolesFetchAll({ searchText: "" });
      const userRoles = roles.filter((r: any) =>
        userRolesIds.some((id: string) => r.id === id)
      );

      // @ts-ignore
      userRoles.forEach((r) => (adminPermissions[r.name] = true));
    }

    return adminPermissions;
  };

  return { getAdminPermissions };
};

export default usePermissions;
