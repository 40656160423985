// actions
const SET = "dispatchers/SET";
const SET_SELECTED = "dispatchers/SET_SELECTED";
const SET_FILTERS = "dispatchers/SET_FILTERS";
const SET_SELECTED_FILTERS = "dispatchers/SET_SELECTED_FILTERS";
const SET_DISPATCHER_LISTING = "dispatchers/SET_DISPATCHER_LISTING";
const SET_NEXT_TOKEN = "dispatchers/SET_NEXT_TOKEN";

const DEFAULT_STATE = {
  listing: [],
  dispatcherlisting: [],
  selected: "",
  filters: [],
  selectedFilters: [],
  nextToken: null,
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_SELECTED_FILTERS:
      return Object.assign({}, state, { selectedFilters: action.listing });
    case SET_DISPATCHER_LISTING:
      return Object.assign({}, state, { dispatcherListing: action.listing });
    case SET_FILTERS:
      return Object.assign({}, state, { filters: action.filters });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.id });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
export const setFilters = (filters: string[]) => ({
  filters,
  type: SET_FILTERS,
});
export const setSelectedFilters = (listing: any[]) => ({
  listing,
  type: SET_SELECTED_FILTERS,
});
export const setDispatcherListing = (listing: any[]) => ({
  listing,
  type: SET_DISPATCHER_LISTING,
});
export const setNextToken = (nextToken: any) => ({
  nextToken,
  type: SET_NEXT_TOKEN,
});