import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setSelected,
  setNextToken,
} from "../store/ducks/adminGroups";
import { AdminGroup } from "../models";
import {
  CreateAdminGroupInput,
  UpdateAdminGroupInput,
} from "../models/GQL_API";
import { extractSelectedCheckboxes } from "../helpers/utils";
import {
  AdminGroupBulkTrashVariables,
  AdminGroupGetVariables,
  AdminGroupUpdateVariables,
  CreateVariables,
  Option,
  HeadCell,
  AdminGroupListingVariables,
} from "../models/app";
import useApp from "./useApp";
import {
  createAdminGroup,
  deleteAdminGroup,
  updateAdminGroup,
} from "../graphql/mutations";
import { getAdminGroup, listAdminGroups } from "../graphql/queries";
import { onCreateAdminGroup } from "../graphql/subscriptions";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );
  const adminGroupsListing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );

  async function fetch(params: AdminGroupListingVariables) {
    const { searchText, limit, adminID } = params;

    try {
      const filter: any = {
        deleted: { eq: "0" },
        name: { contains: searchText.toLowerCase() },
      };

      if (adminID) {
        filter.users = { contains: adminID };
      }

      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }

      const groupsList: any = await API.graphql<AdminGroup>({
        query: listAdminGroups,
        variables: { filter, limit, nextToken: nextToken },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const currentNextToken = groupsList.data.listAdminGroups.nextToken;
      const responseListing = groupsList.data.listAdminGroups.items;

      let listing = [...adminGroupsListing, ...responseListing];

      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }
  async function fetchAll(params: AdminGroupListingVariables) {
    const { searchText, limit, adminID } = params;

    try {
      const filter: any = {
        deleted: { eq: "0" },
        name: { contains: searchText.toLowerCase() },
      };

      if (adminID) {
        filter.users = { contains: adminID };
      }

      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      let listing: any[] = [];
      let currentNextToken: any;
      let responseListing: any;
      do {
        const groupsList: any = await API.graphql<AdminGroup>({
          query: listAdminGroups,
          variables: { filter, limit },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        currentNextToken = groupsList.data.listAdminGroups.nextToken;
        responseListing = groupsList.data.listAdminGroups.items;
        listing = [...listing, ...responseListing];
      } while (currentNextToken);

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchCurrentUser(session: string) {
    try {
      const filter: any = {
        deleted: { eq: "0" },
        users: { contains: session },
      };

      const groupsList: any = await API.graphql<AdminGroup>({
        query: listAdminGroups,
        variables: { filter, nextToken: nextToken },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const currentNextToken = groupsList.data.listAdminGroups.nextToken;
      const listing = groupsList.data.listAdminGroups.items;

      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchUsersInGroup(params: any) {
    try {
      const { groupName } = params;

      const filter: any = {
        deleted: { eq: "0" },
      };

      if (groupName) {
        filter.name = { contains: groupName };
      }

      const groupsList: any = await API.graphql<AdminGroup>({
        query: listAdminGroups,
        variables: { filter, nextToken: nextToken },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const currentNextToken = groupsList.data.listAdminGroups.nextToken;
      const listing = groupsList.data.listAdminGroups.items;

      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: AdminGroupGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: AdminGroup) => model.id === id);

      return model ? model.name : "";
    }

    return "";
  };

  async function get(params: AdminGroupGetVariables) {
    try {
      const { id, listing } = params;

      let single: AdminGroup | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await API.graphql<AdminGroup>({
          query: getAdminGroup,
          variables: { id },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        single = listing.data.getAdminGroup;
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;
    const roles: string[] = extractSelectedCheckboxes("roles_", data);
    const users: string[] = extractSelectedCheckboxes("users_", data);

    try {
      const createInput: CreateAdminGroupInput = {
        name: data.name.toLowerCase(),
        roles: roles,
        users: users,
        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      if (data.description) createInput.description = data.description;

      await API.graphql<AdminGroup>({
        query: createAdminGroup,
        variables: { input: createInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      throw err;
    }
  }

  async function update(params: AdminGroupUpdateVariables) {
    const { id, listing, data } = params;

    const roles: string[] = extractSelectedCheckboxes("roles_", data);
    const users: string[] = extractSelectedCheckboxes("users_", data);

    try {
      const original: any = await get({ id, listing });

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateAdminGroupInput = {
        id: original.id,
        name: data.name ? data.name.toLowerCase() : original!.name,
        description: data.description
          ? data.description
          : original!.description,
        roles: roles,
        users: users,
        _version: original._version,
      };

      await API.graphql<AdminGroup>({
        query: updateAdminGroup,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: AdminGroupGetVariables) {
    try {
      const original: any = await get(params);

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateAdminGroupInput = {
        id: original.id,
        deleted: "1",
        _version: original._version,
      };

      await API.graphql<AdminGroup>({
        query: updateAdminGroup,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: AdminGroupBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: string) => {
      try {
        await trash({ id, listing });
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: AdminGroupGetVariables) {
    try {
      const { id, listing } = params;

      await API.graphql<AdminGroup>({
        query: deleteAdminGroup,
        variables: { id: id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  async function exportAll(params: AdminGroupListingVariables) {
    try {
      const data: any[] = await fetch(params);
      let exportedData: any[] = [];

      for (let group of data) {
        let row: any = { ...group };

        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      showError(err);
    }
  }

  function options(listing: AdminGroup[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name!, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "description"];

  const api: any = {};

  api[`${listingName}Model`] = AdminGroup as any;
  api[`${listingName}CreateSubscription`] = onCreateAdminGroup;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}FetchCurrentUser`] = fetchCurrentUser;
  api[`${listingName}FetchUsersInGroup`] = fetchUsersInGroup;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}Export`] = exportAll;

  api[`${listingName}GetName`] = getName;
  api[`${listingName}ChangeListing`] = (listing: AdminGroup[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = adminGroupsListing;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  return api;
};

export default useResource;
