// actions
const SET = "onlineOrders/SET";
const SET_LAST_INDEX = "onlineOrders/SET_LAST_INDEX";
const SET_NEXT_TOKEN = "onlineOrders/SET_NEXT_TOKEN";
const SET_PREVIOUS_TOKENS = "onlineOrders/SET_PREVIOUS_TOKENS";
const SET_PAGINATION = "onlineOrders/SET_PAGINATION";
const SET_FILTER = "onlineOrders/SET_FILTER";
const SET_SELECTED_FILTERS = "onlineOrders/SET_SELECTED_FILTERS";

const CHANGE_LIMIT = "onlineOrders/CHANGE_LIMIT";
const NEXT_ACTION = "onlineOrders/NEXT_ACTION";

const DEFAULT_STATE = {
  listing: [],
  pagination: [],
  selectedFilters: [],
  nextToken: null,
  prevTokens: [],
  lastIndex: null,
  filter: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_PAGINATION:
      return Object.assign({}, state, { pagination: action.pagination });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.id });
    case SET_LAST_INDEX:
      return Object.assign({}, state, { lastIndex: action.id });
    case SET_FILTER:
      return Object.assign({}, state, { filter: action.id });
    case SET_SELECTED_FILTERS:
      return Object.assign({}, state, { selectedFilters: action.listing });
    case SET_PREVIOUS_TOKENS:
      return Object.assign({}, state, {
        previousTokens: action.previousTokens,
      });
    case CHANGE_LIMIT:
      return Object.assign({}, state, {
        limit: action.limit,
        nextToken: action.nextToken,
        nextNextToken: action.nextNextToken,
        previousTokens: action.previousTokens,
      });
    case NEXT_ACTION:
      return Object.assign({}, state, {
        nextToken: action.nextToken,
        previousTokens: action.previousTokens,
      });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setPagination = (pagination: any[]) => ({
  pagination,
  type: SET_PAGINATION,
});
export const setNextToken = (id: any) => ({ id, type: SET_NEXT_TOKEN });
export const setLastIndex = (id: number) => ({ id, type: SET_LAST_INDEX });
export const setFilter = (id: any) => ({ id, type: SET_FILTER });

export const changeLimit = (limit: any) => ({
  limit: limit,
  nextToken: null,
  nextNextToken: null,
  previousTokens: [],
  type: CHANGE_LIMIT,
});
export const nextAction = (nextToken: any, previousTokens: any[]) => ({
  nextToken: nextToken,
  previousTokens: previousTokens ? [...previousTokens, nextToken] : [nextToken],
  type: NEXT_ACTION,
});
export const setPreviousToken = (previousTokens: any) => ({
  previousTokens,
  type: SET_PREVIOUS_TOKENS,
});
export const setSelectedFilters = (listing: any[]) => ({
  listing,
  type: SET_SELECTED_FILTERS,
});
