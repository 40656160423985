import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API, DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { LOCAL_STORAGE } from "../constants/enums";
import { getMobileNotification } from "../graphql/queries";
import { Concept, MobileNotification } from "../models";
import { CreateMobileNotificationInput } from "../models/GQL_API";
import {
  ConceptBulkTrashVariables,
  ConceptGetVariables,
  ConceptListingVariables,
  ConceptUpdateVariables,
  CreateVariables,
  Option,
} from "../models/app";
import { HeadCell } from "../models/dataTable";
import CreateNotificationFiltersService from "../services/createNotificationFiltersService";
import {
  setListing,
  setSelected,
  setSelectedFilters,
} from "../store/ducks/condition";
import { listMobileNotifications } from "./../graphql/queries";
import useApp from "./useApp";

const useResource = (listingName: string, singleName: string) => {
  const session = useSelector((state: any) => state.app.session);
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const headCells: readonly HeadCell[] = [
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "Title",
    },
    {
      id: "image",
      numeric: false,
      disablePadding: false,
      label: "Image",
    },
    {
      id: "default",
      numeric: false,
      disablePadding: false,
      label: "Default",
    },
    {
      id: "event",
      numeric: false,
      disablePadding: false,
      label: "Event",
    },
  ];
  const dataCells: readonly string[] = ["title", "image", "default", "event"];
  async function fetch(params: ConceptListingVariables) {
    const { startIndex, limit } = params;
    // stop using this table
    return [];
    try {
      const listing = await DataStore.query(
        MobileNotification as any,
        (model: any) => model.deleted("eq", "0"),
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );
      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function fetchOnline() {
    const filter: any = {
      deleted: { eq: "0" },
    };
    // stop using this table
    return [];
    try {
      const listing: any = await API.graphql({
        query: listMobileNotifications,
        variables: { filter, limit: 100 },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return listing.data.listMobileNotifications.items;
    } catch (err) {
      throw err;
    }
  }
  async function get(params: ConceptGetVariables) {
    const { id, listing } = params;

    try {
      const single: MobileNotification | undefined =
        listing.length === 0
          ? await DataStore.query(MobileNotification as any, id)
          : listing.find((model: any) => model.id === id);

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function getOnline(id: string, isAuth: boolean) {
    try {
      const user: any = await API.graphql({
        query: getMobileNotification,
        variables: { id: id },
        authMode: isAuth
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return user.data.getMobileNotification;
    } catch (err) {
      showError(err);
    }
  }
  function formatDate(date: any) {
    const formattedDate = new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    // Reformat the date string to match the desired format
    const parts = formattedDate.split("/");
    return `${parts[2]}-${parts[0].padStart(2, "0")}-${parts[1].padStart(
      2,
      "0"
    )}`;
  }
  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;
    // console.log({ data: data });
    if (!data.accountID) {
      const error = new Error(`Cannot create ${singleName} without accountID`);
      return showError(error);
    }
    try {
      const createInput: CreateMobileNotificationInput = {
        title: data.title ? data.title : "",
        description: data.description ? data.description : "",
        body: data.body ? data.body : "",
        default: data.default ? data.default : false,
        event: data.event ? data.event : false,
        image: data.image ? data.image : "",
        concepts: data.concepts ? data.concepts : [],
        condition: data.conditions ? data.conditions : [],
        startDate: data.startDate ? data.startDate : "",
        endDate: data.endDate ? data.endDate : "",
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      const notification = await DataStore.save(
        new MobileNotification(createInput as any)
      );
      const FiltersData = {
        concepts: data.concepts ? data.concepts : "",
        condition: data.conditions ? data.conditions : "",
        startDate: data.startDate ? formatDate(data.startDate) : "",
        endDate: data.endDate ? formatDate(data.endDate) : "",
        notificationID: notification.id,
        notification: {
          title: "Baky Hospitality",
          body: notification.description ? notification.description : "",
          page: "notification",
        },
      };
      await CreateNotificationFiltersService(FiltersData);

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: ConceptUpdateVariables) {
    const { id, listing, data } = params;
    try {
      const original = await get({ id, listing });

      await DataStore.save(
        MobileNotification.copyOf(original!, (updated) => {
          updated.title = data.title ? data.title : original!.title;
          updated.body = data.body ? data.body : original!.body;
          updated.image = data.image ? data.image : original!.image;
          updated.group = data.group ? data.group : original!.group;
          updated.description = data.description
            ? data.description
            : original!.description;
          updated.default = data.default ? data.default : original!.default;
          updated.event = data.event ? data.event : original!.event;
          updated.concepts = data.concepts ? data.concepts : original!.concepts;
          updated.condition = data.condition
            ? data.condition
            : original!.condition;
          updated.startDate = data.startDate
            ? data.startDate
            : original!.startDate;
          updated.endDate = data.endDate ? data.endDate : original!.endDate;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: ConceptGetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        MobileNotification.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: ConceptBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: ConceptGetVariables) {
    const { id, listing } = params;
    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: ConceptGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: Concept) => model.id === id);

      return model ? model.name : "";
    }

    return "";
  };

  function options(listing: Concept[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const api: any = {};

  api[`${listingName}Model`] = Concept as any;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchOnline`] = fetchOnline;
  api[`${listingName}Get`] = get;
  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;

  api[`${listingName}ChangeSelected`] = (conceptID: string) => {
    dispatch(setSelected(conceptID));

    localStorage.setItem(LOCAL_STORAGE.SELECTED_CONCEPT, conceptID);
  };
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) =>
    dispatch(setSelectedFilters(filters));
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;

  return api;
};

export default useResource;
