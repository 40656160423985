import { LambdaFunctions } from "../constants/enums";
import invokeLambda from "./invokeLambda";

async function listLoyaltyTransactions(filter:any) {
  try {
    const operationId = 29
    const requestBody = {
      operationId: operationId,
      filter,
      page: filter.page,
      pageSize: filter.pageSize,
    }
    return await invokeLambda(LambdaFunctions.callCenterOperations, requestBody);
  } catch (error:any) {
    console.error('Error listingLoyaltyTransactions:', error.message)
    // throw error;
  }
}

export default listLoyaltyTransactions;
