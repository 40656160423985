// https://cloudinary.com/documentation/resizing_and_cropping
// example: https://res.cloudinary.com/dipqm0n1i/image/upload/c_fill,g_auto,h_48,w_48,f_auto/EtihadAirways/1696506804232-etihad.png

// Pablo
// export const cloudinaryURL =
//   "https://callcentersolutionassests100317-testing.s3.us-east-2.amazonaws.com/public/";

// Tante Restaurant
export const cloudinaryURL =
  "https://callcentersolutionassests212433-tantres.s3.us-east-2.amazonaws.com/public/";

// Al Karim
// export const cloudinaryURL =
//   "https://callcentersolutionassests102613-alkarim.s3.us-east-2.amazonaws.com/public/";

// Valencia // Needs change 
// export const cloudinaryURL =
//   "https://callcentersolutionassests160011-valencia.s3.us-east-2.amazonaws.com/public/";

// THM
// export const cloudinaryURL =
//   "https://callcentersolutionassests124414-thm.s3.us-east-2.amazonaws.com/public/";

export const simphonyURL =
  "https://enyoqfusqvpwt6zuiaw7anhw4m0ytewh.lambda-url.us-east-2.on.aws";

export enum Directions {
  RTL = "rtl",
  LTR = "ltr",
}

export enum Languages {
  ENGLISH = "English",
  ARABIC = "Arabic",
}

export enum Pages {
  LOGIN = "",
  REGISTER = "register",
  VERIFY = "verify",
  FORGOT_PASSWORD = "forget-password",
  DASHBOARD = "overview",
  ACCOUNTS = "accounts",
  ADMINS = "admins",
  LANGUAGES = "languages",
  FEATURES = "features",
  PAGES = "pages",
  MEDIA_LIBRARY = "media-library",
  MESSAGES = "messages",
  MENUS = "menus",
  POSTS = "posts",
  SETTINGS = "settings",
  SLIDES = "slides",
  COURSES = "courses",
  MODULES = "modules",
  QUIZZES = "quizzes",
  QUIZ_QUESTIONS = "quiz-questions",
  QUESTIONS = "questions",
  QUESTIONS_TYPES = "questions-types",
  GROUPS = "groups",
  GROUP_SETTINGS = "group-settings",
  INTERESTS = "interests",
  BOOKINGS = "bookings",
  BOOKINGS_LIST = "bookings-history",
  FLAGS = "flags",
  GUESTS = "guests",
  GUESTS_PROFILE = "guests-profile",
  CONCEPTS = "concepts",
  PAYMOB = "paymob",
  BRANCHES = "branches",
  RESERVATION_STATUS = "statuses",
  TIME_SLOTS = "time-slots",
  AREAS = "areas",
  COMMENTS = "comments",
  PLAN_ITEMS = "planItems",
  TABLES = "tables",
  TIMELINES = "timelines",
  SERVING_AREAS = "serving-areas",
  OBJECTS = "objects",
  CUSTOMER_STATS = "customerStats",
  CUSTOMERS = "customers",
  FLOOR_EDITOR = "floor-editor",
  SOCIAL_LINKS = "social-links",
  CALL_CENTER = "call-center",
  CALL_CENTER_SETTINGS = "call-center-settings",
  BOOKINGS_TABLES = "bookings-tables",
  RESET_PASSWORD = "reset-password",
  NOTIFICATION_SETTINGS = "notification-settings",
  NOTIFICATIONS = "notifications",
  Mobile_NOTIFICATIONS = "mobileNotifications",
  BOOKING_CONFIRMATION = "reservations",
  ADMIN_ROLES = "admin-roles",
  ADMIN_GROUPS = "admin-groups",
  PAYMENT = "payments",
  PAYMENT_DASHBOARD = "transactionsDashboard",
  TRANSACTIONS_REPORT = "transactions",
  PAYMENT_STATS = "paymentStats",
  BOOKING_GUEST = "bookingGuests",
  Parent_Concepts = "parentconcepts",
  // Online Ordering
  CATEGORIES = "categories",
  MENU_ITEMS = "menuItems",
  PRICES = "prices",
  CHOICES = "choices",
  CHOICE_GROUPS = "choiceGroups",
  ORDER_STATUSES = "orderStatuses",
  ONLINE_ORDER = "onlineOrders",
  KPI_REPORT = "KPIReport",
  DISPATCHERS = "dispatchers",
  STAFF_MEMBERS = "staffMembers",
  WAITER_ORDER = "waiterOrder",
  MAIN_CATEGORIES = "mainCategories",
  SUB_CATEGORIES = "subCategories",
  SHIFTS = "shifts",
  PREPARATION_AREA = "preparationAreas",
  Analysis = "analysis",
  WAITERS_REPORT = "waitersReport",
  MENU_ITEMS_REPORT = "menuItemsReport",
  SERVING_AREA_REPORT = "servingAreaReport",
  WAREHOUSE = "warehouse",
  ZONES = "zones",
  LOYALTY = "loyaltyItems",
  CAMPAIGNS = "campaigns",
  CHANNELS = "channels",
  ORDER_DASHBOARD = "ordersDashboard",
  LOYALTY_DASHBOARD = "loyaltyDashboard",
  NOTIFICATION_PANEL = "notifications",
  ZONES_REPORT = "zonesReports",
}
//
export enum Reports {
  WAITER_PERFORMANCE = "Waiter Performance",
  MENU_ITEM_CONSUMPTION = "Menu Item Consumption",
}

export enum OrderReports {
  PENDING_ORDERS = "Pending Orders",
  SENT_TO_KITCHEN_ORDERS = "Sent To Kitchen Orders",
  READY_FOR_PICKUP = "Ready for Pickup",
  OUT_FOR_ORDERS = "Out for Delivery",
  ALL_ORDERS = "History",
}

export enum Orders {
  ASC = "asc",
  DSC = "desc",
}

export enum FormActions {
  ADD = "Add",
  EDIT = "Edit",
}

export enum QuestionTypes {
  TRUE_FALSE = "True False",
  CHOOSE = "Choose",
  CHOOSE_IMAGE = "Choose with Image",
  TEXT_IMAGE = "Text with Image",
  OPTIONS_IMAGE = "Options as Image",
  COMPLETE_IMAGE = "Complete with Image",
}

export enum TimelineActions {
  CHANGE_FACEBOOK = "Facebook link",
  CHANGE_INSTAGRAM = "Instagram link",
  CHANGE_NAME = "name",
  CHANGE_ADDRESS = "address modified",
  ADD_ADDRESS = "address added",
  CHANGE_GROUP = "Group",
  CHANGE_EMAIL = "Email",
  CHANGE_VERIFICATION_STATUS = "Verify Guest",
  ADD_INTEREST = "Add Customer Interests",
  REMOVE_INTEREST = "Remove Customer Interests",
  ADD_FLAG = "Add Customer Flags",
  REMOVE_FLAG = "Remove Customer Flags",
  CHANGE_PHONE_NUMBER = "Phone Number",
  DEPOSIT_PAYMENT_SUCCESS = "Deposit Payment Success",
  DEPOSIT_PAYMENT_FAILED = "Deposit Payment Failed",
  DEPOSIT_PAYMENT_REFUND = "Deposit Refund Success",
  SHIFT_CHANGE = "Shift Change",
  SHIFT_ASSIGNED = "Shift Assigned",
  ADMIN_GROUP_ADDED = "Admin Group Added",
  ADMIN_GROUP_REMOVED = "Admin Group Removed",
  PREPARATION_AREA_ADDED = "Service Area Added",
  PREPARATION_AREA_REMOVED = "Service Area Removed",
  CONCEPT_ADDED = "Concept Added",
  CONCEPT_REMOVED = "Concept Removed",
  USER_ATTRIBUTES_CHANGED = "User Attributes",
  CHANGE_PASSWORD = "Password",
  USER_ENABELED = "User Enabled",
  USER_DISABLED = "User Disabled",
  //ORDERS
  ORDER_CREATED = "Order Created",
  ORDER_ACCEPTED = "Order Accepted",
  ORDER_CANCELLED = "Order Cancelled",
  ORDER_SENT_TO_KITCHEN = "Order Sent To Kitchen",
  ORDER_OUR_FOR_DELIVERY = "Out For Delivery",
  ORDER_DELIVERED = "Order Delivered",
  ORDER_UPDATED = "Order Updated",
  ORDER_SENT = "Order Sent",
  ORDER_SETTLED = "Order Settled",
  CHANGE_BIRTHDATE = "Birth Date",
  ORDER_FAILED_TO_SEND_TO_KITCHEN = "failed to sent to restaurant",
  ORDER_VOIDED = "Order Voided",
  VOIDED = "Order Voided",
  UPDATED_ORDER = "Order Updated",
  FAILED_VOID = "failed void",
  FAILED_UPDATE = "failed update",
}

export enum Channels {
  TELEPHONE_CHANNEL = "telephone",
  ANDROID_CHANNEL = "android",
  IPHONE_CHANNEL = "iphone",
  WEB_CHANNEL = "web",
  WEBSITE = "Website",
  FACEBOOK = "Facebook",
  INSTAGRAM = "Instagram",
  WHATSAPP = "Whatsapp",
}

export enum ResourceTypes {
  CUSTOMER = "customer",
  BOOKING = "booking",
}

export enum BookingTimelineActions {
  TIME_SLOT = "Update time slot",
  STATUS = "Update Booking Status",
  TABLE = "Update Booking Table",
  DATE = "Update Booking Date",
  GUEST_COUNT = "Update Guest Count",
  RESERVATION_EMAIL = "Send Reservation Email",
}

export enum AuthErrors {
  USER_EXISTS = "UsernameExistsException",
}

export enum AdminsGroups {
  ADMIN = "admin",
}

export enum AdminsConstants {
  ADMINS_API = "AdminQueries",
}

export enum AdminsApiPaths {
  LIST_USERS = "/listUsers",
  LIST_USERS_IN_GROUP = "/listUsersInGroup",
  LIST_GROUPS_FOR_USER = "/listGroupsForUser",
  GET_USER = "/getUser",
  LIST_GROUPS = "/listGroups",
  ADD_USER_TO_GROUP = "/addUserToGroup",
  REMOVE_USER_FROM_GROUP = "/removeUserFromGroup",
  DISABLE_USER = "/disableUser",
  ENABLE_USER = "/enableUser",
  ADMIN_DELETE_USER = "/adminDeleteUser",
}

export enum StatuesIds {
  CANCELLED = "151a9e09-5d09-4f07-9928-90cccf46f41d",
  CLOSED = "1ca36387-69d5-4dba-be42-634b34493729",
}

export const NOTIFICATION_CHANNELS = [
  { label: "email", value: "email" },
  { label: "sms", value: "sms" },
];

export const OrderReviewRate = {
  veryDisSatisfied: "very dissatisfied",
  disSatisfied: "dissatisfied",
  neutral: "neutral",
  satisfied: "satisfied",
  verySatisfied: "very satisfied",
};

export const ENCRYPTION_KEY =
  "123456dasflasdldfl789asjdflkj3fadsfsdlfds0123456";

export enum ADMIN_ROLES {
  SETTINGS = "settings_access",
  DASHBOARD = "dashboard_access",
  ADMINS = "admins_access",
  VIEW_RESERVATIONS = "view_reservation_access",
  CREATE_RESERVATION = "create_reservation_access",
  CANCEL_RESERVATION = "cancel_reservation_access",
  CREATE_GUEST = "create_guest_access",
  EDIT_GUEST = "edit_guest_access",
  EDIT_VIP = "edit_vip_access",
  LOCK_TABLE = "lock_table_access",
  EDIT_LAYOUT = "edit_layout_access",
  TRANSACTION_REPORT = "transaction_report",
  PAYMENT_DASHBOARD = "payment_dashboard",
  PAYMOB_SETTINGS = "paymob_settings",
  CALL_CENTER = "call_center1",
  WAITER = "waiter",
  KITCHEN = "kitchen",
  can_see_payments = "can_see_payments",
  can_see_reports = "can_see_reports",
  can_see_guests = "can_see_guests",
  can_access_warehouse = "can_access_warehouse",
  can_access_order_dashboard = "can_access_order_dashboard",
  can_change_admins_passwords = "can_change_admins_passwords",
  can_sync_item_from_simphony = "can_sync_item_from_simphony",
  can_order_later = "can_order_later",
  can_assign_concept = "can_assign_concept",
  can_assign_admin_group = "can_assign_admin_group",
  can_edit_sim_emp_id = "can_edit_sim_emp_id",
  can_edit_admin = "can_edit_admin",
  can_delete_admin = "can_delete_admin",
  can_view_kpi = "can_view_kpi",
  can_view_loyalty = "can_view_loyalty",
  can_view_orders_list = "can_view_orders_list",
  can_view_categories = "can_view_categories",
  can_view_groups = "can_view_groups",
  can_view_zones = "can_view_zones",
  can_view_service = "can_view_service",
  can_view_choices = "can_view_choices",
  can_view_plan_items = "can_view_plan_items",
  can_view_menu_items = "can_view_menu_items",
  can_view_concepts = "can_view_concepts",
  can_view_dispatchers = "can_view_dispatchers",
  can_view_loyalty_items = "can_view_loyalty_items",
  can_view_channels = "can_view_channels",
  can_view_admin_roles = "can_view_admin_roles",
  can_view_media = "can_view_media",
  can_resolve = "can_resolve",
  can_retry = "can_retry",
  can_accept = "can_accept",
  can_cancel_orders = "can_cancel_orders",
  can_void_orders = "can_void_orders",
  can_edit_orders = "can_edit_orders",
  can_search_call_center = "can_search_call_center",
  can_view_subCategories = "can_view_subCategories",
  can_view_campaigns = "can_view_campaigns",
  can_see_notifications = "can_see_notifications",
  can_create_orders = "can_create_orders",
  can_reorder = "can_reorder",
  can_mark_as_ready = "can_mark_as_ready",
  can_settle = "can_settle",
  can_regenerate_token = "can_regenerate_token",
  can_delete = "can_delete",
}

export enum LOCAL_STORAGE {
  SYNC_BOOKINGS = "sync-bookings",
  SYNC_GUESTS = "sync-guests",
  SYNC_GUEST_STATS = "sync-guest-stats",
  SELECTED_CONCEPT = "selected-concept",
  BOOKING_DATE_RANGE = "booking-date-range",
  BOOKING_GUEST = "booking-guest",
  BOOKING_REFRESH = "booking-refresh",
  BOOKING_SYNC_DURATION = "booking-sync-duration",
  BOOKING_DATE = "booking-date",
  GUESTS_SEARCH = "guests-search",
}

export enum DATE_PICKER_TYPES {
  SINGLE = "single",
  RANGE = "range",
}

export enum MODAL_TYPES {
  DATE_PICKER = "date-picker",
  BOOKING = "booking",
  EDIT_BOOKING = "edit-booking",
  VIEW_OLD_BOOKING = "view-old-booking",
  GUEST_STATS = "guest-stats",
}

export enum Transaction_TYPES {
  DEPOSIT = "deposit",
  REFUND = "refund",
}

export enum BOOKING_STATUS {
  CANCELLED = "Cancelled",
  CONFIRMED = "Confirmed",
  CLOSED = "Closed",
  WAITING_LIST = "Waiting List",
  PENDING_REVIEW = "Pending Review",
}

export enum OrderStatus {
  pending = "pending",
  sent = "sent to restaurant",
  failed = "failed to sent to restaurant",
  confirmed = "confirmed",
  cancelled = "cancelled",
  failedCancellation = "failed to cancel",
  updated = "updated",
  review = "review",

  // NOT USED
  acceptedByWaiter = "accepted by waiter",
  acceptedByAgent = "accepted by agent",
  acceptedByKitchen = "accepted by kitchen",
  ready = "ready",
  childrenReady = "all ready",

  //
  sentToKitchen = "sent To Kitchen",
  outForDelivery = "out for delivery",
  readyForPickup = "ready for pickup",
  delivered = "delivered",
  deliveredAndSettled = "delivered and settled",
  settled = "settled",
  //
  voided = "voided",
  pendingVoiding = "pending voiding",
  failedVoid = "failed void",
  failedUpdate = "failed update",
}

export enum OrderType {
  delivery = "delivery",
  pickUp = "pick-up",
}

export enum WaiterActions {
  acceptOrder = "acceptOrder",
  acceptCall = "acceptCall",
}

export const OrderStatusList = [
  { name: OrderStatus.pending, value: OrderStatus.pending },
  { name: OrderStatus.sent, value: OrderStatus.sent },
  { name: OrderStatus.failed, value: OrderStatus.failed },
  { name: OrderStatus.confirmed, value: OrderStatus.confirmed },
  { name: OrderStatus.cancelled, value: OrderStatus.cancelled },
  {
    name: OrderStatus.failedCancellation,
    value: OrderStatus.failedCancellation,
  },
  { name: OrderStatus.updated, value: OrderStatus.updated },
  { name: OrderStatus.review, value: OrderStatus.review },
  { name: OrderStatus.acceptedByWaiter, value: OrderStatus.acceptedByWaiter },
  { name: OrderStatus.acceptedByAgent, value: OrderStatus.acceptedByAgent },
  { name: OrderStatus.sentToKitchen, value: OrderStatus.sentToKitchen },
  { name: OrderStatus.acceptedByKitchen, value: OrderStatus.acceptedByKitchen },
  { name: OrderStatus.ready, value: OrderStatus.ready },
  { name: OrderStatus.childrenReady, value: OrderStatus.childrenReady },
  { name: OrderStatus.outForDelivery, value: OrderStatus.outForDelivery },
  { name: OrderStatus.delivered, value: OrderStatus.delivered },
  {
    name: OrderStatus.deliveredAndSettled,
    value: OrderStatus.deliveredAndSettled,
  },
  { name: OrderStatus.settled, value: OrderStatus.settled },
  { name: OrderStatus.readyForPickup, value: OrderStatus.readyForPickup },
];

export const allOrderStatus = [
  OrderStatus.pending,
  OrderStatus.sent,
  OrderStatus.failed,
  OrderStatus.confirmed,
  OrderStatus.cancelled,
  OrderStatus.failedCancellation,
  OrderStatus.updated,
  OrderStatus.review,
  OrderStatus.acceptedByWaiter,
  OrderStatus.acceptedByAgent,
  OrderStatus.sentToKitchen,
  OrderStatus.acceptedByKitchen,
  OrderStatus.ready,
  OrderStatus.childrenReady,
  OrderStatus.outForDelivery,
  OrderStatus.delivered,
  OrderStatus.deliveredAndSettled,
  OrderStatus.settled,
  OrderStatus.readyForPickup,
];

export const TAX = 1.14;
export const SERVICE_CHARGE = 1.12;

export enum Website {
  ETIHAD = "https://etihad.anyware.software",
}

export enum AdminType {
  WAITER = "waiter",
  CALLCENTER = "callCenter",
}

export enum CallWaiterStatus {
  pending = "pending",
  accepted = "accepted",
  arrived = "arrived",
}

export enum ReportType {
  WAITER = "waiter",
  MENUITEM = "menu",
  SERVING_AREA = "servingArea",
}

export enum SimphonyOrderOperation {
  CREATE = 1,
  UPDATE = 2,
  CANCEL = 3,
}

export enum LoyaltyTransactionType {
  REWARD = "reward",
  REDEEEM = "redeem",
}

export enum LambdaFunctions {
  callCenterOperations = "callCenterOperations",
  callCenterSimphonyOperations = "callCenterSimphonyOperations",
  callCenterTokenGenCronJob = "callCenterTokenGenCronJob",
}

export enum ConceptSections {
  Concept_Configuration = "Concept Configuration",
  Loyalty_Configuration = "Loyalty Configuration",
  Payment_Configuration = "Payment Configuration",
}

export enum CampaignStatus {
  DRAFT = "draft",
  ACTIVE = "active",
  IN_ACTIVE = "inactive",
  FINISHED = "finished",
  SCHEDULED = "scheduled",
}

export enum PromocodeAppliesTo {
  WHOLE_CART = "Whole cart",
  SHIPPING_FEES = "Shipping fees",
}

export enum AmplifyEnv {
  PABLO = "testing",
  TANTE = "tantres",
  AL_KARIM = "alkarim",
  VALENCIA = "valencia",
}

export enum OrderChannel {
  CALL_CENTER = "call center",
}

export enum OrderPaymentType {
  CASH = "Cash",
  VISA = "Visa",
}
