import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

import ButtonLoader from "../ButtonLoader";
import Tabs from "../Tabs";
import MediaFiles from "../MediaFiles";
import useAttachment from "../../../hooks/useAttachment";
import { Attachment } from "../../../models";
import { useSelector } from "react-redux";
import useMenuItem from "../../../hooks/useMenuItem";
import useCategory from "../../../hooks/useCategory";
import { Pages } from "../../../constants/enums";
import useMainCategory from "../../../hooks/useMainCategory";
import useSubCategory from "../../../hooks/useSubCategory";
import DropZone from "../../DropZone/DropZone";


interface Props {
  title: string;
  attachment: any;
  closeModal: any;
  changeAttachment?: any;
  changePdf?:any
  resource?:any;
  slug?:any;
}

const ImagePicker: React.FC<Props> = ({
  title,
  attachment,
  closeModal,
  changeAttachment,
  changePdf,
  resource,
  slug,
}) => {
  
  const [acceptedImageFiles, setAcceptedImageFiles] = useState<File[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const session = useSelector((state: any) => state.app.session);
  const mediaLibraryListing = useSelector(
    (state: any) => state.mediaLibrary.listing
  );
  const mediaLibrarySelected = useSelector(
    (state: any) => state.mediaLibrary.selected
  );
  const { attachmentsCreate, attachmentsDelete } = useAttachment(
    "attachments",
    "attachment"
  );
  const { menuItemsUpdate } = useMenuItem(
    "menuItems",
    "menuItem"
  );
  const { categoriesUpdate } = useCategory(
    "categories",
    "category"
  );
  const { mainCategoriesUpdate } = useMainCategory(
    "mainCategories",
    "mainCategory"
  );
  const { subCategoriesUpdate } = useSubCategory(
    "subCategories",
    "subCategory"
  );
  const update = async () => {
    setIsUpdating(true);
    if(slug!==Pages.MENU_ITEMS && slug!==Pages.CATEGORIES && slug!==Pages.MAIN_CATEGORIES && slug!==Pages.SUB_CATEGORIES&& slug!==Pages.CONCEPTS)
    {
      await attachmentsDelete({ id: attachment.id });
      
        for (let mediaId of mediaLibrarySelected) {
          const mediaFile: any = mediaLibraryListing.find(
            (file: any) => file.id === mediaId
          );
    
          const createInput: any = {
            fileUrl: mediaFile.fileUrl,
            filename: mediaFile.filename,
            alternativeText: mediaFile.alternativeText,
            filetype: mediaFile.filetype,
            fileSize: mediaFile.fileSize,
            caption: mediaFile.caption,
            description: mediaFile.description,
            mediaID: mediaId,
          };
    
          const attachment: Attachment = await attachmentsCreate({
            userID: session.sub,
            userName: session.name,
            data: createInput,
          });
    
          changeAttachment(attachment);
      }
  
      setIsUpdating(false);
      closeModal();
    }
    else
    {
      if (!mediaLibrarySelected || mediaLibrarySelected.size === 0) {
          const selectedFile = acceptedImageFiles[0];
        // console.log({ selectedFile })
          changeAttachment(selectedFile.name);
      } else {
        for (let mediaId of mediaLibrarySelected) {
          const mediaFile: any = mediaLibraryListing.find(
          (file: any) => file.id === mediaId
        );
        if (slug === Pages.CONCEPTS) {
          changeAttachment([...attachment,mediaFile.fileUrl])
        } else {
          changeAttachment(mediaFile.fileUrl)
        }
        // let updateInput:any={
        //   id:resource.id,
        //   data:{image:mediaFile.fileUrl}
        // }
        // if(slug===Pages.MENU_ITEMS)
        // {
        //   await menuItemsUpdate(updateInput)
        // }
        // if(slug===Pages.CATEGORIES)
        // {
        //  await categoriesUpdate(updateInput)
        // }
        // if(slug===Pages.MAIN_CATEGORIES)
        // {
        //   if(title==="pdf")
        //   {
        //     let updateInput:any={
          //       id:resource.id,
        //       data:{menuPDF:mediaFile.fileUrl}
        //     }
        //     changePdf(mediaFile.fileUrl.split("-")[1])
        //     await mainCategoriesUpdate(updateInput)
        //   }
        //   else
        //   {
        //     await mainCategoriesUpdate(updateInput)
        //   }
        // }
        // if(slug===Pages.SUB_CATEGORIES)
        // {
        //  await subCategoriesUpdate(updateInput)
        // }
        
      }
      }
      setIsUpdating(false);
      closeModal();
    }
   
  };

  const create = async () => {
    setIsUpdating(true);

    for (let mediaId of mediaLibrarySelected) {
      const mediaFile: any = mediaLibraryListing.find(
        (file: any) => file.id === mediaId
      );
      if(slug!==Pages.MENU_ITEMS && slug!==Pages.CATEGORIES &&slug!==Pages.MAIN_CATEGORIES &&slug!==Pages.SUB_CATEGORIES&&slug!==Pages.CONCEPTS)
      {
        const createInput: any = {
          fileUrl: mediaFile.fileUrl,
          filename: mediaFile.filename,
          alternativeText: mediaFile.alternativeText,
          filetype: mediaFile.filetype,
          fileSize: mediaFile.fileSize,
          caption: mediaFile.caption,
          description: mediaFile.description,
          mediaID: mediaId,
        };
  
        const attachment: Attachment = await attachmentsCreate({
          userID: session.sub,
          userName: session.name,
          data: createInput,
        });
  
        changeAttachment(attachment);
      }
      else if (slug === Pages.CONCEPTS)
      {
        changeAttachment([...attachment,mediaFile.fileUrl]);
      }
      else
      {
        changeAttachment(mediaFile.fileUrl);
      }

    
    }

    setIsUpdating(false);
    closeModal();
  
  };

  const handleAcceptedImageFiles = (files: any) => {
    // console.log(`files: ${JSON.stringify(files)}`)
    setAcceptedImageFiles(files);
  };

  return (
    <Box
      sx={{
        width: "96%",
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
      }}
    >
      <Box
        sx={{
          background: (theme) => theme.palette.grey[300],
          padding: "8px 16px",
          color: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <CloseIcon
          aria-label="close modal"
          onClick={closeModal}
          sx={{ cursor: "pointer" }}
        />
      </Box>
      <Paper
        square
        elevation={0}
        sx={{
          border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Box sx={{ height: "calc(94vh - 120px)" }} display={'flex'} flexDirection={'row'}>
          <Tabs
            tabs={["Media Library"]}
            panels={[<MediaFiles title={title} multiple={false} />]}
            activeTab={0}
          />
          <Box width={'30%'}>
          <Tabs
            tabs={["Upload From Your Device"]}
            panels={[<DropZone
              field={'image'}
              onAcceptedFiles={
                handleAcceptedImageFiles
              }
              />]}
              activeTab={0}
              />
              </Box>
        </Box>
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "flex-end",
            borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Button
            disableElevation
            variant="contained"
            size="small"
            onClick={attachment ? update : create}
            sx={{ textTransform: "capitalize" }}
            disabled={((mediaLibrarySelected.size === 0) && !acceptedImageFiles[0]?.name) || isUpdating}
            endIcon={isUpdating && <ButtonLoader size={20} />}
          >
            {`Set ${title}`}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default ImagePicker;
