// actions
const SET = "admins/SET";
const SET_SELECTED = "admins/SET_SELECTED";
const SET_FILTERS = "admins/SET_FILTERS";
const SET_SELECTED_FILTERS = "admins/SET_SELECTED_FILTERS";
const SET_DISPATCHER_LISTING = "admins/SET_DISPATCHER_LISTING";
const SET_WAITER_LISTING = "admins/SET_WAITER_LISTING";
const SET_PAGINATION_LISTING = "admins/SET_PAGINATION_LISTING";
const SET_ADMIN_TYPE = "admins/SET_ADMIN_TYPE";
const SET_NEXT_TOKEN = "admins/SET_NEXT_TOKEN";

const DEFAULT_STATE = {
  listing: [],
  dispatcherlisting: [],
  waiterlisting: [],
  selected: "",
  adminType: "All",
  filters: [],
  paginationList: [],
  selectedFilters: [],

  nextToken: null,
  nextNextToken: undefined,
  previousTokens: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_SELECTED_FILTERS:
      return Object.assign({}, state, { selectedFilters: action.listing });
    case SET_DISPATCHER_LISTING:
      return Object.assign({}, state, { dispatcherListing: action.listing });
    case SET_WAITER_LISTING:
      return Object.assign({}, state, { waiterlisting: action.listing });
    case SET_FILTERS:
      return Object.assign({}, state, { filters: action.filters });
    case SET_PAGINATION_LISTING:
      return Object.assign({}, state, {
        paginationList: action.paginationList,
      });
    case SET_ADMIN_TYPE:
      return Object.assign({}, state, { adminType: action.adminType });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.id });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setAdminType = (adminType: any[]) => ({
  adminType,
  type: SET_ADMIN_TYPE,
});
export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
export const setPaginationList = (paginationList: any[]) => ({
  paginationList,
  type: SET_PAGINATION_LISTING,
});

export const setFilters = (filters: string[]) => ({
  filters,
  type: SET_FILTERS,
});
export const setSelectedFilters = (listing: any[]) => ({
  listing,
  type: SET_SELECTED_FILTERS,
});
export const setDispatcherListing = (listing: any[]) => ({
  listing,
  type: SET_DISPATCHER_LISTING,
});
export const setWaiterListing = (listing: any[]) => ({
  listing,
  type: SET_WAITER_LISTING,
});
export const setNextToken = (nextToken: any) => ({
  nextToken,
  type: SET_NEXT_TOKEN,
});