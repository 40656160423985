// actions
const SET = "loyaltyTransactions/SET";
const SET_PAGE = "loyaltyTransactions/SET_PAGE";
const SET_PAGINATION = "loyaltyTransactions/SET_PAGINATION";
const SET_FILTER = "loyaltyTransactions/SET_FILTER";
const SET_SELECTED_FILTERS = "loyaltyTransactions/SET_SELECTED_FILTERS";
const SET_SELECTED_FILTERS_CHANGED =
  "loyaltyTransactions/SET_SELECTED_FILTERS_CHANGED";
const CHANGE_LIMIT = "loyaltyTransactions/CHANGE_LIMIT";
const SET_NEXT_TOKEN = "loyaltyTransactions/SET_NEXT_TOKEN";

const DEFAULT_STATE = {
  listing: [],
  pagination: [],
  selectedFilters: {},
  selectedFiltersChanged: false,
  page: 1,
  filter: [],
  nextToken: null,
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_PAGINATION:
      return Object.assign({}, state, { pagination: action.pagination });
    case SET_FILTER:
      return Object.assign({}, state, { filter: action.id });
    case SET_SELECTED_FILTERS:
      return Object.assign({}, state, { selectedFilters: action.listing });
    case SET_SELECTED_FILTERS_CHANGED:
      return Object.assign({}, state, {
        selectedFiltersChanged: action.listing,
      });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.nextToken });
    case CHANGE_LIMIT:
      return Object.assign({}, state, {
        limit: action.limit,
        page: action.page,
      });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setPagination = (pagination: any[]) => ({
  pagination,
  type: SET_PAGINATION,
});
export const setFilter = (id: any) => ({ id, type: SET_FILTER });
export const changeLimit = (limit: any) => ({
  limit: limit,
  page: 0,
  type: CHANGE_LIMIT,
});
export const setSelectedFilters = (listing: any) => ({
  listing,
  type: SET_SELECTED_FILTERS,
});
export const setSelectedFiltersChanged = (listing: any) => ({
  listing,
  type: SET_SELECTED_FILTERS_CHANGED,
});
export const setPage = (page: number) => ({ page, type: SET_PAGE });
export const setNextToken = (nextToken: any) => ({
  nextToken,
  type: SET_NEXT_TOKEN,
});
