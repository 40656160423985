import { LambdaFunctions } from "../constants/enums";
import invokeLambda from "./invokeLambda";

async function encryptData(dataToEncrypt: string[]) {
  try {
    const operationId = 33;
    const requestBody = {
      operationId: operationId,
      dataToEncrypt
    }
    return await invokeLambda(LambdaFunctions.callCenterOperations, requestBody);
  } catch (error) {
    console.error('Error encrypting data:', error)
    // throw error;
  }
}

export default encryptData;
