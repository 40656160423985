import { Auth, API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { setListing, setNextToken } from "../store/ducks/dispatchers";
import { HeadCell } from "../models/dataTable";
import { AdminsApiPaths, AdminsConstants } from "../constants/enums";
import useApp from "./useApp";
import * as AWS from "aws-sdk";
import awsmobile from "../aws-exports";
import { getDomainName } from "../helpers/utils";

const useDispatchers = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();
  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );
  const dispatchersList = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const account = useSelector((state: any) => state.accounts.selected);

  const COGNITO_GROUPS = [
    { label: "admin", value: "admin" },
    // { label: "staff", value: "staff" },
  ];
  const DEVELOP_COGNITO_USER_POOL_ID = awsmobile.aws_user_pools_id;

  const DEVELOP_COGNITO_IDENTITY_POOL_ID =
    awsmobile.aws_cognito_identity_pool_id;
  async function create(data: any) {
    try {
      const user = await Auth.signUp({
        username: data.data.email,
        password: data.data.password,
        attributes: {
          name: data.data.name,
          email: data.data.email,
        },
      });

      await addUserToGroup(user.user.getUsername(), "dispatcher");
      return user;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  async function dispatchersConfig(
    apiPath: string,
    queryString: any,
    body?: any
  ) {
    let apiName = AdminsConstants.ADMINS_API;
    let path = apiPath;
    let myInit = {
      body: body,
      queryStringParameters: queryString,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return {
      apiName,
      path,
      myInit,
    };
  }
  async function addUserToGroup(username: string, groupname: string) {
    try {
      const { apiName, path, myInit } = await dispatchersConfig(
        AdminsApiPaths.ADD_USER_TO_GROUP,
        {},
        {
          username,
          groupname,
        }
      );
      const { ...result } = await API.post(apiName, path, myInit);
      showConfirm(result.message);
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function fetch(nextToken: string, searchText: string, limit: number) {
    try {
      const { apiName, path, myInit } = await dispatchersConfig(
        AdminsApiPaths.LIST_USERS_IN_GROUP,
        {
          groupname: "dispatcher",
          // limit: limit,
          token: nextToken,
        }
      );
      const { NextToken, ...data } = await API.get(apiName, path, myInit);
      nextToken = NextToken;
      const listing = data.Users;

      let dispatchers = [];
      for (let i = 0; i < listing.length; i++) {
        // let data = {
        //   name: listing[i].Attributes[2].Value,
        //   id: listing[i].Attributes[3].Value,
        // };

        let attributeName: string = "";
        let attributeValue: string = "";
        let data: any = {};
        const attributes = listing[i].Attributes;
        for (let j = 0; j < attributes.length; j++) {
          attributeName = attributes[j]?.Name;
          attributeValue = attributes[j].Value;
          data[attributeName] = attributeValue;

          if (attributes[j]?.Name === "custom:simphEmpId") {
            data.simphEmpId = attributes[j]?.Value;
          }

          // if (attributeName === "email") {
          //   data.originalEmail = attributeValue;
          //   data.email =
          //     attributeValue.split(`${getDomainName(account)}_`)?.[1] ??
          //     attributeValue;
          // }
        }
        data.id = data.originalEmail;

        dispatchers.push(data);
      }

      if (searchText && searchText.length > 0) {
        let filteredData = dispatchers.filter((item: any) => {
          return item.id.toLowerCase().includes(searchText.toLowerCase());
        });
        dispatch(setListing(filteredData));
        return filteredData;
      } else {
        dispatch(setListing(dispatchers));
        return dispatchers;
      }
    } catch (err: Error | any) {
      // console.log({
      //   err: err.message,
      // });
      showError(err);
      if (err && err.response.data) {
        showError(
          err.response.data ? err.response.data.message : "Error occurred"
        );
      } else {
        showError(
          typeof err.message === "string" ? err.message : "Error occurred"
        );
      }
    }
  }

  async function deleteUser(username: any) {
    const credentials = await Auth.currentCredentials();
    AWS.config.credentials = credentials;
    AWS.config.region = "us-east-2";
    // console.log({ username });
    new AWS.CognitoIdentityCredentials(
      {
        IdentityPoolId: DEVELOP_COGNITO_IDENTITY_POOL_ID,
      },
      { credentials: credentials }
    );
    const cognitoidentityserviceprovider =
      new AWS.CognitoIdentityServiceProvider();
    cognitoidentityserviceprovider.adminDeleteUser(
      {
        UserPoolId: DEVELOP_COGNITO_USER_POOL_ID,
        Username: username.id,
      },
      function (err, data) {
        if (data) {
          let newList = dispatchersList.filter((admin: any) => {
            return admin.sub !== username.id;
          });
          // dispatch(setPaginationList(newList));
          dispatch(setListing(newList));
          showConfirm("User deleted successfully.");
        }
        if (err) {
          console.log("error deleting user", { err });
          showError(
            typeof err.message === "string"
              ? err.message
              : "Error occurred while deleting the user"
          );
        }
      }
    );
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "simphEmpId",
      numeric: false,
      disablePadding: false,
      label: "Simphony Employee ID",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["email", "name", "simphEmpId"];

  const api: any = {};

  api[`${listingName}Create`] = create;
  api[`${listingName}Trash`] = deleteUser;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}ChangeListing`] = (listing: any[]) =>
    dispatch(setListing(listing));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = dispatchersList;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));

  return api;
};

export default useDispatchers;
