import { Order } from "../models/order";
import {
  LOCAL_STORAGE,
  Orders,
  Pages,
  TAX,
  simphonyURL,
  OrderStatus,
  SERVICE_CHARGE,
} from "../constants/enums";
import { HeadCell } from "../models/dataTable";
import * as XLSX from "xlsx";
import CryptoJS from "crypto-js";

import logoReif from "../assets/images/reif-logo.png";
import lexiesLogo from "../assets/images/lexies-logo.png";
import shinkoLogo from "../assets/images/shinko-logo.png";
import kazokuLogo from "../assets/images/kazoku-logo.png";
import heliopolisLogo from "../assets/images/sachi-helopolis-logo.png";
import parkLogo from "../assets/images/sachi-park-logo.png";
import almazaLogo from "../assets/images/sachi-almaza-logo.png";
import megumiLogo from "../assets/images/logo-megumi.png";
const notificationSound = require("../assets/sounds/notificationSound.mp3");
import { Account, OnlineOrder } from "../models";

/* Validation Helpers */
//   !/^[+-]?\d+(\.\d+)?$/.test(address.phoneNumber)
export const isMobileNumber = (phoneNumber: string) => {
  return (
    phoneNumber !== null &&
    phoneNumber.length === 11 &&
    phoneNumber.startsWith("01") &&
    /^[0-9]+$/.test(phoneNumber)
  );
};

export const isHomeNumber = (phoneNumber: string) => {
  return (
    phoneNumber !== null &&
    phoneNumber.length === 10 &&
    phoneNumber.startsWith("02") &&
    /^[0-9]+$/.test(phoneNumber)
  );
};

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function descendingComparatorLowerCase<
  T extends Record<keyof T, string | number>
>(a: T, b: T, orderBy: keyof T) {
  const aVal = String(a[orderBy]).toLowerCase();
  const bVal = String(b[orderBy]).toLowerCase();
  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }
  return 0;
}

/**
 * Capitalize Sentence

 * @param string : string
 *
 * @returns string
 */
export function capitalizeSentence(string: string): string {
  let result: string = "";

  const words: string[] = string.split(" ");

  for (let word of words) {
    result +=
      result.length === 0
        ? capitalizeFirstLetter(word)
        : " " + capitalizeFirstLetter(word);
  }

  return result;
}

/**
 * Convert string hours to it's equivalent in numbers
 *
 * @param hours hours: string
 * @param format format: string
 *
 * @returns number
 */
export const hoursStringToNumber = (hours: string, format: string): number => {
  const time = hours.split(":")[0];

  return format === "PM"
    ? parseInt(time) + 12 === 24
      ? 12
      : parseInt(time) + 12
    : parseInt(time) === 12
    ? 0
    : parseInt(time);
};

/**
 * Compare giver hour in number to current locale hour
 *
 * @param hour hour: number
 *
 * @returns number
 */
export const compareHoursToCurrent = (hour: number): number => {
  const d = new Date();
  let currentHour = d.getHours();

  return hour - currentHour;
};

/**
 * Capitalize first letter of a given string
 *
 * @param string string: string
 *
 * @returns string
 */
export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === Orders.DSC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
export function getComparatorLowerCase<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === Orders.DSC
    ? (a, b) => descendingComparatorLowerCase(a, b, orderBy)
    : (a, b) => -descendingComparatorLowerCase(a, b, orderBy);
}

export function stableSort(
  array: any,
  comparator: any,
  isSortByNameAsNumber: boolean = false
) {
  if (array?.length < 1) return [];
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);

  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (isSortByNameAsNumber) {
      return parseInt(a[0].name) < parseInt(b[0].name) ? -1 : 1;
    } else {
      if (order !== 0) return order;
      return a[1] - b[1];
    }
  });
  return stabilizedThis.map((el: any) => el[0]);
}

export function stripHtml(html: string) {
  return html.replace(/(<([^>]+)>)/gi, "");
}

export function exportXLSX(
  slug: string,
  headCells: readonly HeadCell[],
  data: any[],
  rowKeys: readonly string[],
  selected?: Set<string>,
  filename?: string,
  reportName?: string,
  businessDate?: string,
  concepts?: string,
  shifts?: string
) {
  const heads: any = [];
  const rows: any = [];

  const title = camelCaseToTitleCase(slug);

  if (reportName) {
    // Report Header
    rows.push(["Report:", reportName]);
    rows.push(["Business Date:", businessDate ? businessDate : "All"]);
    rows.push(["Concept:", concepts ? concepts : "All"]);
    rows.push(["Shifts:", shifts ? shifts : "All"]);
  } else {
    rows.push(["Report:", title]);
  }

  /* Add an empty row for spacing */
  rows.push([]);

  let exportData = data;

  if (selected) {
    exportData = data.filter((row: any) => selected.has(row.id));
  }

  let headLen =
    headCells[headCells.length - 1].label === "actions"
      ? headCells.length - 1
      : headCells.length;
  for (let i = 0; i < headLen; i++) {
    // csv format doesn't accept empty symbols
    const label =
      headCells[i].label === "# of Guests"
        ? "Number of Guests"
        : headCells[i].label;

    heads.push(label);
  }

  rows.push(heads);

  if (slug === Pages.BOOKINGS) {
    for (let row of exportData) {
      const rowItems: any = [];

      if (row.conceptName) {
        rowItems.push(row.conceptName ? row.conceptName : "");
      }

      // rowItems.push(row.timeSlotName ? row.timeSlotName : "");
      rowItems.push(
        row.slotNames ? mergeListOfStringsByDash(row.slotNames) : ""
      );
      rowItems.push(
        row.customerName ? capitalizeFirstLetter(row.customerName) : ""
      );
      rowItems.push(row.customerGroup ? row.customerGroup : "");
      rowItems.push(row.customerPhone ? row.customerPhone : "");
      rowItems.push(row.accompaniedCount ? row.accompaniedCount : "");
      rowItems.push(row.tableName ? row.tableName : "");
      rowItems.push(row.statusName ? row.statusName : "");
      rowItems.push(
        isNaN(row.customerPhone) ? "" : row.paymentStatus ? "Paid" : "Pending"
      );
      // rowItems.push(row.lastComment ? stripHtml(row.lastComment) : "");
      rowItems.push(
        row.commentsTexts ? mergeListOfStringsByDash(row.commentsTexts) : ""
      );
      rowItems.push(row.date ? row.date : "");
      rowItems.push(row.createdBy ? row.createdBy.name : "Admin");
      rowItems.push(row.createdAt ? row.createdAt.split(",")[0] : "");

      rows.push(rowItems);
    }
  } else if (slug === Pages.TRANSACTIONS_REPORT) {
    for (let row of exportData) {
      const rowItems = [];
      rowItems.push(row.transactionID ? row.transactionID : "");
      rowItems.push(row.createdAt ? row.createdAt : "");
      rowItems.push(row.guestName ? row.guestName : "");
      rowItems.push(row.guestPhone ? row.guestPhone : "");
      rowItems.push(row.amount_cents ? row.amount_cents : "");
      rowItems.push(row.status ? row.status : "");
      rowItems.push(row.type ? row.type : "");
      rowItems.push(row.conceptID ? row.conceptID : "");
      rowItems.push(row.failureReason ? row.failureReason : "");
      rows.push(rowItems);
    }
    // console.log({ rows });
  } else if (slug === Pages.ONLINE_ORDER) {
    for (let row of exportData) {
      const rowItems = [];
      rowItems.push(row.conceptID ? row.conceptID : "N/A");
      rowItems.push(row.checkNumber ? row.checkNumber : row.id.split("-")[0]);
      rowItems.push(row.orderType ? capitalizeSentence(row.orderType) : "N/A");
      rowItems.push(row.status ? capitalizeSentence(row.status) : "N/A");
      rowItems.push(row.reason ? capitalizeSentence(row.reason) : "N/A");
      rowItems.push(row.specialRequest ? row.specialRequest : "N/A");
      rowItems.push(row.userName ? capitalizeSentence(row.userName) : "N/A");
      rowItems.push(
        row.dispatcherName ? capitalizeSentence(row.dispatcherName) : "N/A"
      );
      rowItems.push(row.subTotal ? row.subTotal.toFixed(2) : 0);
      rowItems.push(row.deliveryFee ? row.deliveryFee.toFixed(2) : 0);
      rowItems.push(row.tax ? row.tax.toFixed(2) : 0);
      rowItems.push(row.totalAmount ? row.totalAmount.toFixed(2) : 0);
      rowItems.push(
        row.createdByName ? capitalizeSentence(row.createdByName) : "N/A"
      );
      rowItems.push(
        formatAMPM(
          row.orderTime.trim().toLowerCase() === "now"
            ? row.createdAt
            : new Date(row.orderTime).toISOString()
        )
      );
      rowItems.push(new Date(row.createdAt).toLocaleString());

      rows.push(rowItems);
    }
  } else {
    for (let row of exportData) {
      const rowItems: any = [];

      for (let key of rowKeys) {
        if (slug === Pages.LOYALTY_DASHBOARD && key === "createdAt") {
          rowItems.push(new Date(row[key]).toLocaleString());
        } else {
          rowItems.push(row[key] != null ? row[key] : "N/A");
        }
      }
      if (slug === Pages.GUESTS) {
        rowItems.push(row.groupName ? row.groupName : "");
        rowItems.push(
          row.interestsName ? mergeListOfStringsByDash(row.interestsName) : ""
        );
      }

      // rowItems.push(row.createdByName ? row.createdByName : "Admin");
      // if (row.createdAt) rowItems.push(row.createdAt.split(",")[0]);

      rows.push(rowItems);
    }
  }

  const wb = XLSX.utils.book_new();
  const newWs = XLSX.utils.aoa_to_sheet(rows);
  XLSX.utils.book_append_sheet(wb, newWs);
  const rawExcel = XLSX.write(wb, { type: "base64" });

  const encodedUri = encodeURI(rawExcel);
  const link = document.createElement("a");
  link.setAttribute(
    "href",
    "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      encodedUri
  );
  link.setAttribute("download", `${filename ? filename : slug}.xlsx`);
  document.body.appendChild(link); // Required for FF

  link.click();
}

export function randomStr(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function oldDateChecker(date: any) {
  if (!date) {
    return false;
  }
  let givenDate = new Date(date);
  givenDate.setHours(0, 0, 0, 0);

  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  let checker = false;
  if (givenDate.getTime() < currentDate.getTime()) {
    checker = true;
  }
  return checker;
}

// return full date into string
export function isoFullDateFormated(isoFullDate: string) {
  // insert format "2023-01-15:15:52";
  const isoNewDate = new Date(isoFullDate);
  return isoNewDate;
}

export const formatAMPM = (isoDate: string) => {
  const date = new Date(isoDate);
  let hours: string | number = date.getHours();
  let minutes: string | number = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  hours %= 12;
  hours = hours || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  const strTime = `${hours}:${minutes} ${ampm}`;

  return strTime;
};

export function getWeekDays(locale: string) {
  const baseDate = new Date(Date.UTC(2017, 0, 2)); // just a Monday
  const weekDays = [];

  for (let i = 0; i < 7; i++) {
    weekDays.push(baseDate.toLocaleDateString(locale, { weekday: "long" }));
    baseDate.setDate(baseDate.getDate() + 1);
  }

  return weekDays;
}

export function getDayName(dateStr: string, locale: string) {
  const date = new Date(dateStr);
  return date.toLocaleDateString(locale, { weekday: "short" });
}

export function getDayNameLong(dateStr: string, locale: string) {
  const date = new Date(dateStr);
  return date.toLocaleDateString(locale, { weekday: "long" });
}

export function getDayNumber(dateStr: string, locale: string) {
  const date = new Date(dateStr);
  return date.getDate();
}

export function getMonthName(dateStr: string, locale: string) {
  const date = new Date(dateStr);
  return date.toLocaleDateString(locale, { month: "short" });
}

export function getMonthNameFromNum(monthNumber: number): string {
  const dateObj = new Date();
  dateObj.setMonth(monthNumber - 1);
  return dateObj.toLocaleString("default", { month: "short" });
}

export function getDateFormatted(date: any) {
  const today = date ? new Date(date) : new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();

  return yyyy + "-" + mm + "-" + dd;
}

// get hours and minutes
export function getFullDateFormatted(date: any) {
  const today = date ? new Date(date) : new Date();
  const hh = String(today.getHours()).padStart(2, "0");
  const min = String(today.getMinutes()).padStart(2, "0");
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();

  return yyyy + "-" + mm + "-" + dd + ", " + hh + ":" + min;
}

export function formatDateToYYYYMMDDHHMMSS(inputDate: any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");
  const second = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

export function convertDateToReadableFormate(dateString: string): string {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours %= 12;
  hours = hours || 12;
  const formattedDate = `${day}-${month}-${year} ${hours}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${ampm}`;
  return formattedDate;
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export function convertNumToTime(timeInSeconds: number) {
  let milliseconds = timeInSeconds * (1000 * 60);

  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  hours = hours % 24;

  const time = `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds
  )}`;

  return time;
}

export function capitalizeWords(string: string) {
  return string.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
}

export const mergeListOfStringsByDash = (list: string[] | null) => {
  if (list !== null && list.length)
    return list.reduce((prev, current) => `${prev} - ${current}`);

  return "";
};

export const mergeListOfStrings = (
  list: (string | null)[],
  delimiter: string
) => {
  if (list !== null && list.length)
    return list.reduce((prev, current) => `${prev} ${delimiter} ${current}`);

  return "";
};

export const makeFakePhoneNumbers = (index: number, length: number) => {
  var result = `01${index}`;
  var characters = "0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getDomainName = (account: Account) => {
  if (window.location.hostname === "kioskdev") return "kioskdev";

  // if (
  //   // window.location.hostname ===
  //     // "onlineorderingcreateorder.drwxy8igs4w9j.amplifyapp.com"
  // )
  //   return "anyware";

  // if (
  //   // window.location.hostname === "192.168.42.194:3000" ||
  //   window.location.hostname === "prod.d1uxgdbmrbcym6.amplifyapp.com"
  // )
  //   return "anyware";

  return account.domain;
};

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "kioskdev" ||
    // [::1] is the IPv6 kioskdev address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    ) ||
    window.location.hostname.match(
      /^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const isProduction = Boolean(
  window.location.hostname === "192.168.42.194:3000" ||
    window.location.hostname === "www.baky.anyware.software" ||
    window.location.hostname === "baky.anyware.software"
);

export const createArray = (size: number) =>
  Array.from({ length: size }, (_, i) => i);

export function convertToCamelCase(text: string) {
  return text.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
}

export function extractSelectedCheckboxes(keyName: string, data: any) {
  const selected: string[] = [];

  for (let key of Object.getOwnPropertyNames(data)) {
    if (key.includes(keyName)) {
      if (data[key] === true) {
        selected.push(key.replace(keyName, ""));
      }
    }
  }

  return selected;
}
//  original validation
// export function validatePhone(phone: string) {
//   const localAndInternational =
//     /(^[1-9]\d{9}$|^[1-9]\d{9}$|^(\+|00)[0-9]{12,14}$)/;
//   return localAndInternational.test(phone);
// }

// international validation

export function validatePhone(phone: string) {
  const localAndInternational =
    /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
  return localAndInternational.test(phone);
}

export async function clearBrowser(reload = true) {
  // remove indexedDB
  const indexedDBs = await window.indexedDB.databases();
  const amplifyDB = indexedDBs.find(
    (db: any) => db.name === "amplify-datastore"
  )?.name;
  if (amplifyDB) window.indexedDB.deleteDatabase(amplifyDB);

  // clear localStorage
  window.localStorage.clear();

  // clear sessionStorage
  window.sessionStorage.clear();

  // reload
  if (reload) window.location.reload();
}

export function hexEncode(str: string) {
  var hex, i;

  var result = "";
  for (i = 0; i < str.length; i++) {
    hex = str.charCodeAt(i).toString(16);
    result += ("000" + hex).slice(-4);
  }

  return result;
}

export function hexDecode(hexString: string) {
  var j;
  var hexes = hexString.match(/.{1,4}/g) || [];
  var back = "";
  for (j = 0; j < hexes.length; j++) {
    back += String.fromCharCode(parseInt(hexes[j], 16));
  }

  return back;
}

export function isNumeric(num: string) {
  return !isNaN(parseInt(num));
}

export function persistBookingDate(date: Date): Date {
  const bookingsDate: string | null = localStorage.getItem(
    LOCAL_STORAGE.BOOKING_DATE
  );

  if (bookingsDate) return new Date(bookingsDate);

  return date;
}

export function persistSelectedConcept(conceptID: string) {
  const selected: string | null = localStorage.getItem(
    LOCAL_STORAGE.SELECTED_CONCEPT
  );

  return selected ? selected : conceptID;
}

export function excerpt(str: string, maxLength: number): string {
  if (str.length > maxLength) return str.substring(0, maxLength) + "...";

  return str;
}

export function isEquivalentArrays(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    let prob = arr1[i];

    if (arr2.indexOf(prob) === -1) return false;
  }

  return true;
}

export function currentDate(date: Date | string): Date {
  const newDate: Date = new Date();

  if (newDate.getHours() < 4) {
    let yesterday: Date = new Date(date);
    yesterday.setDate(yesterday.getDate() - 1);

    return yesterday;
  }

  return new Date(date);
}

export function renderPickedDates(
  startDate: Date,
  endDate: Date,
  BookingDateStatus: boolean = false
): string {
  const startWeekday = startDate.toLocaleString("default", {
    weekday: "short",
  });
  const endWeekday = endDate.toLocaleString("default", {
    weekday: "short",
  });

  if (BookingDateStatus) {
    if (getDateFormatted(startDate) === getDateFormatted(endDate))
      return `${startWeekday} ${getDateFormatted(startDate)}`;

    return `${startWeekday} ${getDateFormatted(
      startDate
    )} to ${endWeekday} ${getDateFormatted(endDate)}`;
  } else {
    if (getDateFormatted(startDate) === getDateFormatted(endDate))
      return `${startWeekday} ${getDateFormatted(
        persistBookingDate(startDate)
      )}`;

    return `${startWeekday} ${getDateFormatted(
      persistBookingDate(startDate)
    )} to ${endWeekday} ${getDateFormatted(persistBookingDate(endDate))}`;
  }
}

export function getValuePercentage(value: number, total: number): number {
  let percentage = 0;
  percentage = (value / total) * 100;
  return percentage;
}

export const renderConceptLogo = (conceptName: string) => {
  if (conceptName.toLowerCase() === "lexies") return lexiesLogo;

  if (conceptName.toLowerCase() === "shinko") return shinkoLogo;

  if (conceptName.toLowerCase() === "kazoku") return kazokuLogo;

  if (conceptName.toLowerCase() === "sachi heliopolis") return heliopolisLogo;

  if (conceptName.toLowerCase() === "sachi park") return parkLogo;
  if (conceptName.toLowerCase() === "sachi almaza") return almazaLogo;

  if (conceptName.toLowerCase() === "megumi") return megumiLogo;

  return logoReif;
};

export async function createOrderRequest(
  tender: any,
  orderItems: any,
  conceptID: any,
  orderType: any,
  operationID: any
) {
  let simphonyItems: any = [];

  try {
    const orderData: any = {
      operationID: operationID,
      EmployeeID: 90004,
      OrderType: orderType,
      RevenueCenter: 51,
      TenderType: tender,
      conceptID: conceptID,
    };

    for (let item of orderItems) {
      let simphonyItem = {
        symphonyID: item.symphonyID,
        choiceSymphonyID: item.choiceSymphonyID,
        quantity: item.quantity,
      };
      simphonyItems.push(simphonyItem);
    }
    orderData.simphonyItems = simphonyItems;

    // Create Order request
    const response = await fetch(simphonyURL, {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(orderData),
    });

    const createOrderResponse = await response.json();
    var isSuccess =
      createOrderResponse.pTotalsResponseEx.operationalResult.success;
  } catch (e) {
    console.error({ createOrderError: e });
  }
}

export const convertToK = (value: number): number => {
  // receives value as 42000 and return 42 to display in terms of K
  while (value % 1000 === 0 && value !== 0) {
    value /= 1000;
  }
  return value;
};
export function roundToTwoDecimalPlaces(
  number: number,
  fractionDigits: number = 2
) {
  // Check if the number has fractional digits
  if (Number.isInteger(number)) {
    // If it's an integer, no need to round
    return number;
  } else {
    // If it has fractional digits, round to two decimal places
    return parseFloat(number.toFixed(fractionDigits));
  }
}

export const convertNumberToKMstring = (
  value: number,
  fractionDigits: number = 2
): string => {
  if (isNaN(Number(value))) {
    return value as unknown as string;
  }
  let roundedValue!: string;
  let million = false;
  let val = value;
  if (val / 1000 > 1) {
    val /= 1000;
    if (val / 1000 > 1) {
      million = true;
      val /= 1000;
    }
  } else {
    return val as unknown as string;
  }

  roundedValue =
    roundToTwoDecimalPlaces(val, fractionDigits).toString() +
    `${million ? "M" : "K"}`;

  return roundedValue;
};

export const generateStrongPassword = (length: number = 12): string => {
  // Define character sets
  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercase = "abcdefghijklmnopqrstuvwxyz";
  const numbers = "0123456789";
  const specialChars = "!@#$%^&*()-=_+[]{}|;:,.<>?";

  const allChars = uppercase + lowercase + numbers + specialChars;

  // Ensure at least one character from each character set
  const randomUppercase = uppercase.charAt(
    CryptoJS.lib.WordArray.random(1).words[0] % uppercase.length
  );
  const randomLowercase = lowercase.charAt(
    CryptoJS.lib.WordArray.random(1).words[0] % lowercase.length
  );
  const randomNumber = numbers.charAt(
    CryptoJS.lib.WordArray.random(1).words[0] % numbers.length
  );
  const randomSpecialChar = specialChars.charAt(
    CryptoJS.lib.WordArray.random(1).words[0] % specialChars.length
  );

  // The remaining characters are randomly selected from all character sets
  const remainingChars = allChars.slice(4); // Exclude the characters already picked
  let password =
    randomUppercase + randomLowercase + randomNumber + randomSpecialChar;
  for (let i = 0; i < length - 4; i++) {
    const randomIndex =
      CryptoJS.lib.WordArray.random(1).words[0] % remainingChars.length;
    password += remainingChars.charAt(randomIndex);
  }

  // Shuffle the password characters to add more randomness
  password = password
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");

  return password;
};

// Function to calculate the maximum allowed birthdate (18 years ago from the current date)
export const calculateMaxDate = () => {
  const today = new Date();
  const minDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  return minDate.toISOString().split("T")[0];
};

// Function to calculate the minimum allowed birthdate (70 years ago from the current date)
export const calculateMinDate = () => {
  const today = new Date();
  const maxDate = new Date(
    today.getFullYear() - 70,
    today.getMonth(),
    today.getDate()
  );
  return maxDate.toISOString().split("T")[0];
};

export const getCartSubTaxTotal = (orderedItems: any[]): any => {
  if (orderedItems.length === 0) return 0;
  let totalPrice = 0;
  for (const item of orderedItems) {
    totalPrice += item.price * item.quantity;
  }
  return parseFloat(totalPrice.toFixed(2));
};

// export const getCartSubTotal = (
//   subTaxTotal: number,
//   conceptVat: number,
//   deliveryFee: number,
//   addDeliveryToVat: Boolean
// ) => {
//   let value: any = 0;
//   if (addDeliveryToVat) {
//     value = parseFloat(
//       ((subTaxTotal + deliveryFee) * (conceptVat || TAX)).toFixed(2)
//     );
//   } else {
//     value = parseFloat((subTaxTotal * (conceptVat || TAX)).toFixed(2));
//   }
//   return value;
// };

export const getCartSubTotal = (
  subTaxTotal: number,
  conceptServiceCharge: number,
  orderType: string
) => {
  let value: any = 0;
  if (orderType === "delivery") {
    value = parseFloat((subTaxTotal || 0).toFixed(2));
  } else {
    value = parseFloat((subTaxTotal * (conceptServiceCharge || 1)).toFixed(2));
  }
  return value;
};

export const getCartTotalPrice = (
  subTotal: number,
  conceptVat: number,
  deliveryFee: number,
  addDeliveryToVat: Boolean
) => {
  let value: any = 0;
  if (addDeliveryToVat) {
    value = parseFloat(
      ((subTotal + deliveryFee) * (conceptVat || 1)).toFixed(2)
    );
  } else {
    console.log({ conceptVat });
    value = parseFloat((subTotal * (conceptVat || 1)).toFixed(2)) + deliveryFee;
  }
  return value;
};

// export const getCartTotalPrice = (
//   subTotal: number,
//   deliveryFee: number,
//   addDeliveryToVat: Boolean
// ) => {
//   let value:any=0;
//   if(addDeliveryToVat){
//     value = parseFloat((subTotal).toFixed(2));
//   } else{
//     value = parseFloat((subTotal + deliveryFee).toFixed(2));
//   }
//   return value;
// };

export const reValidatePromocode = (
  deliveryFee: number,
  orderValue: number,
  orderItemsQty: number,
  promocode: any
) => {
  try {
    if (!promocode) return;
    if (
      promocode.minOrderReq === "Minimum order value" &&
      promocode.minOrderVal > orderValue
    ) {
      throw new Error(`order value must be at least ${promocode.minOrderVal}`);
    }
    if (
      promocode.minOrderReq === "Minimum quantity of items" &&
      promocode.minOrderQty > orderItemsQty
    ) {
      throw new Error(
        `order items quantity must be at least ${promocode.minOrderQty}`
      );
    }
    let totalDiscountedPrice;
    let discountedValue = 0;
    let discountedAmount = 0;
    let totalDiscountedDeliveryFee;
    let valueToCal = orderValue;
    if (!promocode.calcAfterTax) {
      valueToCal = orderValue;
    }
    if (promocode.appliesTo === "Whole cart") {
      console.log({ valueToCal });
      const orderAmountBefDiscount = valueToCal;
      const discountedTotalPrice =
        orderAmountBefDiscount * (1 - promocode.discountVal / 100);

      discountedAmount = orderAmountBefDiscount - discountedTotalPrice;

      totalDiscountedPrice =
        discountedAmount > promocode.discountLimit
          ? orderAmountBefDiscount - promocode.discountLimit
          : discountedTotalPrice;
      if (promocode.discountLimit === 0) {
        totalDiscountedPrice = orderAmountBefDiscount - discountedAmount;
      }

      // handle the case when discountVal is 100
      console.log({
        totalDiscountedPriceBef: totalDiscountedPrice,
        discountedTotalPrice,
      });
      if (discountedTotalPrice === 0) {
        totalDiscountedPrice = promocode.discountLimit
          ? orderAmountBefDiscount - promocode.discountLimit
          : 0;
        totalDiscountedPrice =
          totalDiscountedPrice < 0 ? 0 : totalDiscountedPrice;
      }
      console.log({ discountedTotalPrice, totalDiscountedPrice });
      discountedValue = orderAmountBefDiscount - totalDiscountedPrice;
      if (!promocode.calcAfterTax) {
        // discountedValue = discountedValue * (1 + 0.14);
        // discountedValue += deliveryFee;
      }
    } else if (promocode.appliesTo === "Shipping fees") {
      const orderAmountBefDiscount = valueToCal + deliveryFee;

      const discountedDeliveryFee =
        deliveryFee * (1 - promocode.discountVal / 100);
      totalDiscountedDeliveryFee =
        discountedDeliveryFee > promocode.discountLimit
          ? deliveryFee - promocode.discountLimit
          : discountedDeliveryFee;

      if (promocode.discountLimit === 0) {
        totalDiscountedDeliveryFee = discountedDeliveryFee;
      }

      console.log({ discountedDeliveryFee });
      // handle the case when discountVal is 100
      if (discountedDeliveryFee === 0) {
        totalDiscountedDeliveryFee = promocode.discountLimit
          ? deliveryFee - promocode.discountLimit
          : 0;
        totalDiscountedDeliveryFee =
          totalDiscountedDeliveryFee < 0 ? 0 : totalDiscountedDeliveryFee;
      }

      totalDiscountedPrice =
        orderAmountBefDiscount - deliveryFee + totalDiscountedDeliveryFee;
      discountedValue = orderAmountBefDiscount - totalDiscountedPrice;
    }

    console.log({ discountedValue });
    if (!totalDiscountedPrice) {
      totalDiscountedPrice = 0;
    }
    totalDiscountedPrice = parseFloat(totalDiscountedPrice.toFixed(2));
    discountedValue = parseFloat(discountedValue.toFixed(2));

    return discountedValue;
  } catch (err) {
    console.log(`error validating promocode: ${err}`);
    throw err;
  }
};
// export const reValidatePromocode = (
//   deliveryFee: number,
//   orderValue: number,
//   orderItemsQty: number,
//   promocode: any
// ) => {
//   try {
//     if (!promocode) return;
//     if (
//       promocode.minOrderReq === "Minimum order value" &&
//       promocode.minOrderVal > orderValue
//     ) {
//       throw new Error(`order value must be at least ${promocode.minOrderVal}`);
//     }
//     if (
//       promocode.minOrderReq === "Minimum quantity of items" &&
//       promocode.minOrderQty > orderItemsQty
//     ) {
//       throw new Error(
//         `order items quantity must be at least ${promocode.minOrderQty}`
//       );
//     }
//     let totalDiscountedPrice;
//     let discountedValue;
//     if (promocode.appliesTo === "Whole cart") {
//       const orderAmountBefDiscount = orderValue + deliveryFee;
//       const discountedTotalPrice =
//         orderAmountBefDiscount * (1 - promocode.discountVal / 100);

//       totalDiscountedPrice =
//         discountedTotalPrice > promocode.discountLimit
//           ? promocode.discountLimit
//           : discountedTotalPrice;

//       // handle the case when discountVal is 100
//       if (discountedTotalPrice === 0) {
//         totalDiscountedPrice = promocode.discountLimit
//           ? promocode.discountLimit
//           : 0;
//       }
//       discountedValue = orderAmountBefDiscount - totalDiscountedPrice;
//     } else if (promocode.appliesTo === "Shipping fees") {
//       const orderAmountBefDiscount = orderValue + deliveryFee;
//       const discountedDeliveryFee =
//         deliveryFee * (1 - promocode.discountVal / 100);

//       totalDiscountedPrice =
//         discountedDeliveryFee > promocode.discountLimit
//           ? promocode.discountLimit + orderValue
//           : discountedDeliveryFee + orderValue;

//       // handle the case when discountVal is 100
//       if (discountedDeliveryFee === 0) {
//         totalDiscountedPrice = promocode.discountLimit
//           ? promocode.discountLimit
//           : 0;
//       }

//       discountedValue = orderAmountBefDiscount - totalDiscountedPrice;
//     }
//     return Math.ceil(discountedValue ?? 0);
//   } catch (err) {
//     console.log(`error validating promocode: ${err}`);
//     throw err;
//   }
// };

export function isValidEmail(email: string) {
  // Regular expression to match phone number at the beginning and @anyware.software at the end
  var regex = /^\d+@anyware\.software$/;
  // Check if the email matches the regular expression

  return regex.test(email);
}
export function isValidEmailExp(email: string) {
  // Check if the email matches the regular expression
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

export const playNotificationSound = () => {
  const audio = new Audio(notificationSound);
  if (audio) audio.play();
};

export const getChangedStringData = <T extends Record<string, string>>(
  oldData: T,
  newData: Required<T>
) => {
  const changedData = {
    newEmpty: {} as Partial<T>,
    newFilled: {} as Partial<T>,
  };
  for (const key in newData) {
    if (newData[key] && oldData[key] !== newData[key]) {
      changedData.newFilled[key] = newData[key];
    } else if (newData[key] === "" && oldData[key] !== newData[key]) {
      changedData.newEmpty[key] = newData[key];
    }
  }
  return changedData;
};

export const fiveMinutesPassed = (isoDateString: string) => {
  // Parse the ISO date string into a Date object
  const date = new Date(isoDateString).getTime();

  // Get the current date and time
  const now = new Date().getTime();

  // Calculate the difference in milliseconds
  const difference = now - date;

  // Calculate the difference in minutes
  const differenceInMinutes = difference / (1000 * 60);

  // Check if 5 minutes have passed
  return differenceInMinutes >= 5;
};

export const calculateDiscountedValue = (order: any) => {
  // console.log({ order });
  if (!order) return "";
  const oldTax = order.subTotal - order.subTaxTotal;
  const oldSubTaxTotal = order.subTaxTotal;
  const deliveryFees = order.deliveryFee;
  const newTotal = order.totalAmount;
  const { newSubTaxTotal } = computeNewSubTaxTotalAndNewTax(
    oldSubTaxTotal,
    oldTax,
    deliveryFees,
    newTotal
  );
  return truncateToTwoDecimals(oldSubTaxTotal - newSubTaxTotal);
};
export const calculateNewTax = (order: any) => {
  // console.log({ order });
  if (!order) return "";
  const oldTax = order.subTotal - order.subTaxTotal;
  const oldSubTaxTotal = order.subTaxTotal;
  const deliveryFees = order.deliveryFee;
  const newTotal = order.totalAmount;
  let { newTax } = computeNewSubTaxTotalAndNewTax(
    oldSubTaxTotal,
    oldTax,
    deliveryFees,
    newTotal
  );
  if (newTax < 0) {
    newTax = 0;
  }
  return truncateToTwoDecimals(newTax);
};
function computeNewSubTaxTotalAndNewTax(
  oldSubTaxTotal: number,
  oldTax: number,
  deliveryFees: number,
  newTotal: number
) {
  const oldRatio = oldSubTaxTotal / oldTax;

  const newAmountExcludingFees = newTotal - deliveryFees;

  const newTax = newAmountExcludingFees / (1 + oldRatio);
  const newSubTaxTotal = newAmountExcludingFees - newTax;

  return {
    newSubTaxTotal: truncateToTwoDecimals(newSubTaxTotal),
    newTax: truncateToTwoDecimals(newTax),
  };
}
export const truncateToTwoDecimals = (num: number) => {
  return Math.floor(num * 100) / 100;
};

export const removeTypenameField = (array: any[]) => {
  return array.map((item) => {
    // Create a shallow copy of the item to avoid mutating the original array
    const newItem = { ...item };

    // Check if the __typename field exists and remove it
    if (newItem.hasOwnProperty("__typename")) {
      delete newItem.__typename;
    }

    return newItem;
  });
};

export const isPlaceOpen = (
  openTime: string,
  closeTime: string,
  deliveryTime: string,
  Time: string
) => {
  if (openTime === closeTime || !openTime || !closeTime) return true;

  // Get the current date and time
  let currentDateTime = new Date();
  if (deliveryTime.toLowerCase() === "later" && Time) {
    currentDateTime = new Date(Time);
  }
  // Parse openTime and closeTime
  let openDateTime = new Date(`${currentDateTime.toDateString()} ${openTime}`);
  let closeDateTime = new Date(
    `${currentDateTime.toDateString()} ${closeTime}`
  );

  // If closeTime is earlier than openTime, it means the closing time is on the next day
  if (closeDateTime <= openDateTime) {
    closeDateTime.setDate(closeDateTime.getDate() + 1);
  }

  // Determine the time to check based on deliveryTime
  let timeToCheck = currentDateTime;
  if (deliveryTime.toLowerCase() === "later" && Time) {
    timeToCheck = new Date(Time);
  }

  // Check if the selected time is within the open-close range
  return timeToCheck >= openDateTime && timeToCheck <= closeDateTime;
};

export function toTitleCase(str: string) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function camelCaseToTitleCase(str: string) {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space before each uppercase letter
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
