/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEmailVerification = /* GraphQL */ `
  mutation CreateEmailVerification(
    $input: CreateEmailVerificationInput!
    $condition: ModelEmailVerificationConditionInput
  ) {
    createEmailVerification(input: $input, condition: $condition) {
      id
      accountID
      name
      email
      verification_code
      createdAt
      deleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEmailVerification = /* GraphQL */ `
  mutation UpdateEmailVerification(
    $input: UpdateEmailVerificationInput!
    $condition: ModelEmailVerificationConditionInput
  ) {
    updateEmailVerification(input: $input, condition: $condition) {
      id
      accountID
      name
      email
      verification_code
      createdAt
      deleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEmailVerification = /* GraphQL */ `
  mutation DeleteEmailVerification(
    $input: DeleteEmailVerificationInput!
    $condition: ModelEmailVerificationConditionInput
  ) {
    deleteEmailVerification(input: $input, condition: $condition) {
      id
      accountID
      name
      email
      verification_code
      createdAt
      deleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSocialLink = /* GraphQL */ `
  mutation CreateSocialLink(
    $input: CreateSocialLinkInput!
    $condition: ModelSocialLinkConditionInput
  ) {
    createSocialLink(input: $input, condition: $condition) {
      id
      name
      icon
      slug
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSocialLink = /* GraphQL */ `
  mutation UpdateSocialLink(
    $input: UpdateSocialLinkInput!
    $condition: ModelSocialLinkConditionInput
  ) {
    updateSocialLink(input: $input, condition: $condition) {
      id
      name
      icon
      slug
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSocialLink = /* GraphQL */ `
  mutation DeleteSocialLink(
    $input: DeleteSocialLinkInput!
    $condition: ModelSocialLinkConditionInput
  ) {
    deleteSocialLink(input: $input, condition: $condition) {
      id
      name
      icon
      slug
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLanguage = /* GraphQL */ `
  mutation CreateLanguage(
    $input: CreateLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    createLanguage(input: $input, condition: $condition) {
      id
      name
      code
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLanguage = /* GraphQL */ `
  mutation UpdateLanguage(
    $input: UpdateLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    updateLanguage(input: $input, condition: $condition) {
      id
      name
      code
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLanguage = /* GraphQL */ `
  mutation DeleteLanguage(
    $input: DeleteLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    deleteLanguage(input: $input, condition: $condition) {
      id
      name
      code
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAttachment = /* GraphQL */ `
  mutation CreateAttachment(
    $input: CreateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    createAttachment(input: $input, condition: $condition) {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAttachment = /* GraphQL */ `
  mutation UpdateAttachment(
    $input: UpdateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    updateAttachment(input: $input, condition: $condition) {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAttachment = /* GraphQL */ `
  mutation DeleteAttachment(
    $input: DeleteAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    deleteAttachment(input: $input, condition: $condition) {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      accountID
      name
      description
      color
      requireApproval
      status
      agents
      groupSettings
      numToUpgrade
      numToDowngrade
      upgradeGroup
      downgradeGroup
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      accountID
      name
      description
      color
      requireApproval
      status
      agents
      groupSettings
      numToUpgrade
      numToDowngrade
      upgradeGroup
      downgradeGroup
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      accountID
      name
      description
      color
      requireApproval
      status
      agents
      groupSettings
      numToUpgrade
      numToDowngrade
      upgradeGroup
      downgradeGroup
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createGroupSetting = /* GraphQL */ `
  mutation CreateGroupSetting(
    $input: CreateGroupSettingInput!
    $condition: ModelGroupSettingConditionInput
  ) {
    createGroupSetting(input: $input, condition: $condition) {
      id
      groupID
      statusID
      needsVerification
      needsDeposit
      isDefault
      amount
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateGroupSetting = /* GraphQL */ `
  mutation UpdateGroupSetting(
    $input: UpdateGroupSettingInput!
    $condition: ModelGroupSettingConditionInput
  ) {
    updateGroupSetting(input: $input, condition: $condition) {
      id
      groupID
      statusID
      needsVerification
      needsDeposit
      isDefault
      amount
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteGroupSetting = /* GraphQL */ `
  mutation DeleteGroupSetting(
    $input: DeleteGroupSettingInput!
    $condition: ModelGroupSettingConditionInput
  ) {
    deleteGroupSetting(input: $input, condition: $condition) {
      id
      groupID
      statusID
      needsVerification
      needsDeposit
      isDefault
      amount
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCustomerStats = /* GraphQL */ `
  mutation CreateCustomerStats(
    $input: CreateCustomerStatsInput!
    $condition: ModelCustomerStatsConditionInput
  ) {
    createCustomerStats(input: $input, condition: $condition) {
      id
      conceptID
      totalReservation
      confirmed
      notConfirmed
      canceled
      checkIn
      noShow
      userID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCustomerStats = /* GraphQL */ `
  mutation UpdateCustomerStats(
    $input: UpdateCustomerStatsInput!
    $condition: ModelCustomerStatsConditionInput
  ) {
    updateCustomerStats(input: $input, condition: $condition) {
      id
      conceptID
      totalReservation
      confirmed
      notConfirmed
      canceled
      checkIn
      noShow
      userID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCustomerStats = /* GraphQL */ `
  mutation DeleteCustomerStats(
    $input: DeleteCustomerStatsInput!
    $condition: ModelCustomerStatsConditionInput
  ) {
    deleteCustomerStats(input: $input, condition: $condition) {
      id
      conceptID
      totalReservation
      confirmed
      notConfirmed
      canceled
      checkIn
      noShow
      userID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createFlag = /* GraphQL */ `
  mutation CreateFlag(
    $input: CreateFlagInput!
    $condition: ModelFlagConditionInput
  ) {
    createFlag(input: $input, condition: $condition) {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFlag = /* GraphQL */ `
  mutation UpdateFlag(
    $input: UpdateFlagInput!
    $condition: ModelFlagConditionInput
  ) {
    updateFlag(input: $input, condition: $condition) {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFlag = /* GraphQL */ `
  mutation DeleteFlag(
    $input: DeleteFlagInput!
    $condition: ModelFlagConditionInput
  ) {
    deleteFlag(input: $input, condition: $condition) {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createInterest = /* GraphQL */ `
  mutation CreateInterest(
    $input: CreateInterestInput!
    $condition: ModelInterestConditionInput
  ) {
    createInterest(input: $input, condition: $condition) {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateInterest = /* GraphQL */ `
  mutation UpdateInterest(
    $input: UpdateInterestInput!
    $condition: ModelInterestConditionInput
  ) {
    updateInterest(input: $input, condition: $condition) {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteInterest = /* GraphQL */ `
  mutation DeleteInterest(
    $input: DeleteInterestInput!
    $condition: ModelInterestConditionInput
  ) {
    deleteInterest(input: $input, condition: $condition) {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createReservationStatus = /* GraphQL */ `
  mutation CreateReservationStatus(
    $input: CreateReservationStatusInput!
    $condition: ModelReservationStatusConditionInput
  ) {
    createReservationStatus(input: $input, condition: $condition) {
      id
      accountID
      name
      category
      image {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      reservationStatusImageId
      __typename
    }
  }
`;
export const updateReservationStatus = /* GraphQL */ `
  mutation UpdateReservationStatus(
    $input: UpdateReservationStatusInput!
    $condition: ModelReservationStatusConditionInput
  ) {
    updateReservationStatus(input: $input, condition: $condition) {
      id
      accountID
      name
      category
      image {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      reservationStatusImageId
      __typename
    }
  }
`;
export const deleteReservationStatus = /* GraphQL */ `
  mutation DeleteReservationStatus(
    $input: DeleteReservationStatusInput!
    $condition: ModelReservationStatusConditionInput
  ) {
    deleteReservationStatus(input: $input, condition: $condition) {
      id
      accountID
      name
      category
      image {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      reservationStatusImageId
      __typename
    }
  }
`;
export const createTimeSlot = /* GraphQL */ `
  mutation CreateTimeSlot(
    $input: CreateTimeSlotInput!
    $condition: ModelTimeSlotConditionInput
  ) {
    createTimeSlot(input: $input, condition: $condition) {
      id
      conceptID
      name
      friendlyName
      bookings
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTimeSlot = /* GraphQL */ `
  mutation UpdateTimeSlot(
    $input: UpdateTimeSlotInput!
    $condition: ModelTimeSlotConditionInput
  ) {
    updateTimeSlot(input: $input, condition: $condition) {
      id
      conceptID
      name
      friendlyName
      bookings
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTimeSlot = /* GraphQL */ `
  mutation DeleteTimeSlot(
    $input: DeleteTimeSlotInput!
    $condition: ModelTimeSlotConditionInput
  ) {
    deleteTimeSlot(input: $input, condition: $condition) {
      id
      conceptID
      name
      friendlyName
      bookings
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createArea = /* GraphQL */ `
  mutation CreateArea(
    $input: CreateAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    createArea(input: $input, condition: $condition) {
      id
      conceptID
      name
      isDefault
      expiryDate
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateArea = /* GraphQL */ `
  mutation UpdateArea(
    $input: UpdateAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    updateArea(input: $input, condition: $condition) {
      id
      conceptID
      name
      isDefault
      expiryDate
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteArea = /* GraphQL */ `
  mutation DeleteArea(
    $input: DeleteAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    deleteArea(input: $input, condition: $condition) {
      id
      conceptID
      name
      isDefault
      expiryDate
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTable = /* GraphQL */ `
  mutation CreateTable(
    $input: CreateTableInput!
    $condition: ModelTableConditionInput
  ) {
    createTable(input: $input, condition: $condition) {
      id
      conceptID
      label
      name
      width
      height
      capacity
      image
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTable = /* GraphQL */ `
  mutation UpdateTable(
    $input: UpdateTableInput!
    $condition: ModelTableConditionInput
  ) {
    updateTable(input: $input, condition: $condition) {
      id
      conceptID
      label
      name
      width
      height
      capacity
      image
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTable = /* GraphQL */ `
  mutation DeleteTable(
    $input: DeleteTableInput!
    $condition: ModelTableConditionInput
  ) {
    deleteTable(input: $input, condition: $condition) {
      id
      conceptID
      label
      name
      width
      height
      capacity
      image
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createFriend = /* GraphQL */ `
  mutation CreateFriend(
    $input: CreateFriendInput!
    $condition: ModelFriendConditionInput
  ) {
    createFriend(input: $input, condition: $condition) {
      id
      customerId
      friendId
      bookingID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFriend = /* GraphQL */ `
  mutation UpdateFriend(
    $input: UpdateFriendInput!
    $condition: ModelFriendConditionInput
  ) {
    updateFriend(input: $input, condition: $condition) {
      id
      customerId
      friendId
      bookingID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFriend = /* GraphQL */ `
  mutation DeleteFriend(
    $input: DeleteFriendInput!
    $condition: ModelFriendConditionInput
  ) {
    deleteFriend(input: $input, condition: $condition) {
      id
      customerId
      friendId
      bookingID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      message
      customerId
      bookingId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      message
      customerId
      bookingId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      message
      customerId
      bookingId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createServingArea = /* GraphQL */ `
  mutation CreateServingArea(
    $input: CreateServingAreaInput!
    $condition: ModelServingAreaConditionInput
  ) {
    createServingArea(input: $input, condition: $condition) {
      id
      name
      fees
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateServingArea = /* GraphQL */ `
  mutation UpdateServingArea(
    $input: UpdateServingAreaInput!
    $condition: ModelServingAreaConditionInput
  ) {
    updateServingArea(input: $input, condition: $condition) {
      id
      name
      fees
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteServingArea = /* GraphQL */ `
  mutation DeleteServingArea(
    $input: DeleteServingAreaInput!
    $condition: ModelServingAreaConditionInput
  ) {
    deleteServingArea(input: $input, condition: $condition) {
      id
      name
      fees
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      accountID
      conceptID
      name
      description
      channel
      message
      from
      enabled
      autoSend
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      accountID
      conceptID
      name
      description
      channel
      message
      from
      enabled
      autoSend
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      accountID
      conceptID
      name
      description
      channel
      message
      from
      enabled
      autoSend
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMobileNotification = /* GraphQL */ `
  mutation CreateMobileNotification(
    $input: CreateMobileNotificationInput!
    $condition: ModelMobileNotificationConditionInput
  ) {
    createMobileNotification(input: $input, condition: $condition) {
      id
      title
      description
      body
      default
      event
      image
      concepts
      condition
      startDate
      endDate
      group
      read
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMobileNotification = /* GraphQL */ `
  mutation UpdateMobileNotification(
    $input: UpdateMobileNotificationInput!
    $condition: ModelMobileNotificationConditionInput
  ) {
    updateMobileNotification(input: $input, condition: $condition) {
      id
      title
      description
      body
      default
      event
      image
      concepts
      condition
      startDate
      endDate
      group
      read
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMobileNotification = /* GraphQL */ `
  mutation DeleteMobileNotification(
    $input: DeleteMobileNotificationInput!
    $condition: ModelMobileNotificationConditionInput
  ) {
    deleteMobileNotification(input: $input, condition: $condition) {
      id
      title
      description
      body
      default
      event
      image
      concepts
      condition
      startDate
      endDate
      group
      read
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserPushToken = /* GraphQL */ `
  mutation CreateUserPushToken(
    $input: CreateUserPushTokenInput!
    $condition: ModelUserPushTokenConditionInput
  ) {
    createUserPushToken(input: $input, condition: $condition) {
      id
      userID
      token
      reciveNotification
      type
      os
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserPushToken = /* GraphQL */ `
  mutation UpdateUserPushToken(
    $input: UpdateUserPushTokenInput!
    $condition: ModelUserPushTokenConditionInput
  ) {
    updateUserPushToken(input: $input, condition: $condition) {
      id
      userID
      token
      reciveNotification
      type
      os
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserPushToken = /* GraphQL */ `
  mutation DeleteUserPushToken(
    $input: DeleteUserPushTokenInput!
    $condition: ModelUserPushTokenConditionInput
  ) {
    deleteUserPushToken(input: $input, condition: $condition) {
      id
      userID
      token
      reciveNotification
      type
      os
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createNotificationReceiver = /* GraphQL */ `
  mutation CreateNotificationReceiver(
    $input: CreateNotificationReceiverInput!
    $condition: ModelNotificationReceiverConditionInput
  ) {
    createNotificationReceiver(input: $input, condition: $condition) {
      id
      userID
      notificationID
      seen
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateNotificationReceiver = /* GraphQL */ `
  mutation UpdateNotificationReceiver(
    $input: UpdateNotificationReceiverInput!
    $condition: ModelNotificationReceiverConditionInput
  ) {
    updateNotificationReceiver(input: $input, condition: $condition) {
      id
      userID
      notificationID
      seen
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteNotificationReceiver = /* GraphQL */ `
  mutation DeleteNotificationReceiver(
    $input: DeleteNotificationReceiverInput!
    $condition: ModelNotificationReceiverConditionInput
  ) {
    deleteNotificationReceiver(input: $input, condition: $condition) {
      id
      userID
      notificationID
      seen
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPreparationArea = /* GraphQL */ `
  mutation CreatePreparationArea(
    $input: CreatePreparationAreaInput!
    $condition: ModelPreparationAreaConditionInput
  ) {
    createPreparationArea(input: $input, condition: $condition) {
      id
      accountID
      name
      isAutoAccept
      hasKDS
      hasPrinter
      users
      deleted
      precedence
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePreparationArea = /* GraphQL */ `
  mutation UpdatePreparationArea(
    $input: UpdatePreparationAreaInput!
    $condition: ModelPreparationAreaConditionInput
  ) {
    updatePreparationArea(input: $input, condition: $condition) {
      id
      accountID
      name
      isAutoAccept
      hasKDS
      hasPrinter
      users
      deleted
      precedence
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePreparationArea = /* GraphQL */ `
  mutation DeletePreparationArea(
    $input: DeletePreparationAreaInput!
    $condition: ModelPreparationAreaConditionInput
  ) {
    deletePreparationArea(input: $input, condition: $condition) {
      id
      accountID
      name
      isAutoAccept
      hasKDS
      hasPrinter
      users
      deleted
      precedence
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createQRcode = /* GraphQL */ `
  mutation CreateQRcode(
    $input: CreateQRcodeInput!
    $condition: ModelQRcodeConditionInput
  ) {
    createQRcode(input: $input, condition: $condition) {
      id
      tableID
      conceptID
      tableName
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateQRcode = /* GraphQL */ `
  mutation UpdateQRcode(
    $input: UpdateQRcodeInput!
    $condition: ModelQRcodeConditionInput
  ) {
    updateQRcode(input: $input, condition: $condition) {
      id
      tableID
      conceptID
      tableName
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteQRcode = /* GraphQL */ `
  mutation DeleteQRcode(
    $input: DeleteQRcodeInput!
    $condition: ModelQRcodeConditionInput
  ) {
    deleteQRcode(input: $input, condition: $condition) {
      id
      tableID
      conceptID
      tableName
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCallWaiter = /* GraphQL */ `
  mutation CreateCallWaiter(
    $input: CreateCallWaiterInput!
    $condition: ModelCallWaiterConditionInput
  ) {
    createCallWaiter(input: $input, condition: $condition) {
      id
      conceptID
      tableID
      tableName
      waiterID
      waiterName
      userID
      count
      status
      deleted
      createdAt
      acceptedAt
      arrivedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCallWaiter = /* GraphQL */ `
  mutation UpdateCallWaiter(
    $input: UpdateCallWaiterInput!
    $condition: ModelCallWaiterConditionInput
  ) {
    updateCallWaiter(input: $input, condition: $condition) {
      id
      conceptID
      tableID
      tableName
      waiterID
      waiterName
      userID
      count
      status
      deleted
      createdAt
      acceptedAt
      arrivedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCallWaiter = /* GraphQL */ `
  mutation DeleteCallWaiter(
    $input: DeleteCallWaiterInput!
    $condition: ModelCallWaiterConditionInput
  ) {
    deleteCallWaiter(input: $input, condition: $condition) {
      id
      conceptID
      tableID
      tableName
      waiterID
      waiterName
      userID
      count
      status
      deleted
      createdAt
      acceptedAt
      arrivedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      accountID
      bookingID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      accountID
      bookingID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      accountID
      bookingID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      logo
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      deleted
      createdAt
      createdByID
      createdByName
      callCenterEmployeesIds
      kpiReportResetFrequency
      agentBarResetFrequency
      dynamicStatus
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      logo
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      deleted
      createdAt
      createdByID
      createdByName
      callCenterEmployeesIds
      kpiReportResetFrequency
      agentBarResetFrequency
      dynamicStatus
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      logo
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      deleted
      createdAt
      createdByID
      createdByName
      callCenterEmployeesIds
      kpiReportResetFrequency
      agentBarResetFrequency
      dynamicStatus
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createFeature = /* GraphQL */ `
  mutation CreateFeature(
    $input: CreateFeatureInput!
    $condition: ModelFeatureConditionInput
  ) {
    createFeature(input: $input, condition: $condition) {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFeature = /* GraphQL */ `
  mutation UpdateFeature(
    $input: UpdateFeatureInput!
    $condition: ModelFeatureConditionInput
  ) {
    updateFeature(input: $input, condition: $condition) {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFeature = /* GraphQL */ `
  mutation DeleteFeature(
    $input: DeleteFeatureInput!
    $condition: ModelFeatureConditionInput
  ) {
    deleteFeature(input: $input, condition: $condition) {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMedia = /* GraphQL */ `
  mutation CreateMedia(
    $input: CreateMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    createMedia(input: $input, condition: $condition) {
      id
      accountID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      attachments
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMedia = /* GraphQL */ `
  mutation UpdateMedia(
    $input: UpdateMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    updateMedia(input: $input, condition: $condition) {
      id
      accountID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      attachments
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMedia = /* GraphQL */ `
  mutation DeleteMedia(
    $input: DeleteMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    deleteMedia(input: $input, condition: $condition) {
      id
      accountID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      attachments
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createConcept = /* GraphQL */ `
  mutation CreateConcept(
    $input: CreateConceptInput!
    $condition: ModelConceptConditionInput
  ) {
    createConcept(input: $input, condition: $condition) {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateConcept = /* GraphQL */ `
  mutation UpdateConcept(
    $input: UpdateConceptInput!
    $condition: ModelConceptConditionInput
  ) {
    updateConcept(input: $input, condition: $condition) {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteConcept = /* GraphQL */ `
  mutation DeleteConcept(
    $input: DeleteConceptInput!
    $condition: ModelConceptConditionInput
  ) {
    deleteConcept(input: $input, condition: $condition) {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTender = /* GraphQL */ `
  mutation CreateTender(
    $input: CreateTenderInput!
    $condition: ModelTenderConditionInput
  ) {
    createTender(input: $input, condition: $condition) {
      id
      name
      simphonyID
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conceptTendersId
      __typename
    }
  }
`;
export const updateTender = /* GraphQL */ `
  mutation UpdateTender(
    $input: UpdateTenderInput!
    $condition: ModelTenderConditionInput
  ) {
    updateTender(input: $input, condition: $condition) {
      id
      name
      simphonyID
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conceptTendersId
      __typename
    }
  }
`;
export const deleteTender = /* GraphQL */ `
  mutation DeleteTender(
    $input: DeleteTenderInput!
    $condition: ModelTenderConditionInput
  ) {
    deleteTender(input: $input, condition: $condition) {
      id
      name
      simphonyID
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conceptTendersId
      __typename
    }
  }
`;
export const createOrderType = /* GraphQL */ `
  mutation CreateOrderType(
    $input: CreateOrderTypeInput!
    $condition: ModelOrderTypeConditionInput
  ) {
    createOrderType(input: $input, condition: $condition) {
      id
      name
      simphonyID
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conceptOrderTypeId
      __typename
    }
  }
`;
export const updateOrderType = /* GraphQL */ `
  mutation UpdateOrderType(
    $input: UpdateOrderTypeInput!
    $condition: ModelOrderTypeConditionInput
  ) {
    updateOrderType(input: $input, condition: $condition) {
      id
      name
      simphonyID
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conceptOrderTypeId
      __typename
    }
  }
`;
export const deleteOrderType = /* GraphQL */ `
  mutation DeleteOrderType(
    $input: DeleteOrderTypeInput!
    $condition: ModelOrderTypeConditionInput
  ) {
    deleteOrderType(input: $input, condition: $condition) {
      id
      name
      simphonyID
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conceptOrderTypeId
      __typename
    }
  }
`;
export const createParentConcept = /* GraphQL */ `
  mutation CreateParentConcept(
    $input: CreateParentConceptInput!
    $condition: ModelParentConceptConditionInput
  ) {
    createParentConcept(input: $input, condition: $condition) {
      id
      deleted
      createdAt
      createdByID
      createdByName
      image
      name
      logo
      type
      venues
      index
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateParentConcept = /* GraphQL */ `
  mutation UpdateParentConcept(
    $input: UpdateParentConceptInput!
    $condition: ModelParentConceptConditionInput
  ) {
    updateParentConcept(input: $input, condition: $condition) {
      id
      deleted
      createdAt
      createdByID
      createdByName
      image
      name
      logo
      type
      venues
      index
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteParentConcept = /* GraphQL */ `
  mutation DeleteParentConcept(
    $input: DeleteParentConceptInput!
    $condition: ModelParentConceptConditionInput
  ) {
    deleteParentConcept(input: $input, condition: $condition) {
      id
      deleted
      createdAt
      createdByID
      createdByName
      image
      name
      logo
      type
      venues
      index
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPlanItem = /* GraphQL */ `
  mutation CreatePlanItem(
    $input: CreatePlanItemInput!
    $condition: ModelPlanItemConditionInput
  ) {
    createPlanItem(input: $input, condition: $condition) {
      id
      areaID
      conceptID
      xPosition
      yPosition
      name
      isReserved
      bookings
      table
      object
      lockDays
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePlanItem = /* GraphQL */ `
  mutation UpdatePlanItem(
    $input: UpdatePlanItemInput!
    $condition: ModelPlanItemConditionInput
  ) {
    updatePlanItem(input: $input, condition: $condition) {
      id
      areaID
      conceptID
      xPosition
      yPosition
      name
      isReserved
      bookings
      table
      object
      lockDays
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePlanItem = /* GraphQL */ `
  mutation DeletePlanItem(
    $input: DeletePlanItemInput!
    $condition: ModelPlanItemConditionInput
  ) {
    deletePlanItem(input: $input, condition: $condition) {
      id
      areaID
      conceptID
      xPosition
      yPosition
      name
      isReserved
      bookings
      table
      object
      lockDays
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createObject = /* GraphQL */ `
  mutation CreateObject(
    $input: CreateObjectInput!
    $condition: ModelObjectConditionInput
  ) {
    createObject(input: $input, condition: $condition) {
      id
      conceptID
      label
      name
      image {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      width
      height
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      objectImageId
      __typename
    }
  }
`;
export const updateObject = /* GraphQL */ `
  mutation UpdateObject(
    $input: UpdateObjectInput!
    $condition: ModelObjectConditionInput
  ) {
    updateObject(input: $input, condition: $condition) {
      id
      conceptID
      label
      name
      image {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      width
      height
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      objectImageId
      __typename
    }
  }
`;
export const deleteObject = /* GraphQL */ `
  mutation DeleteObject(
    $input: DeleteObjectInput!
    $condition: ModelObjectConditionInput
  ) {
    deleteObject(input: $input, condition: $condition) {
      id
      conceptID
      label
      name
      image {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      width
      height
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      objectImageId
      __typename
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      depositValue
      depositStatus
      eventStatus
      eventName
      paymentStatus
      paymentValue
      disablePartialPayment
      disablePayment
      eventDate
      eventGuests
      timeSlotID
      timeSlots
      conceptID
      mainGuest
      customerName
      customerPhone
      customerGroup
      accompaniedCount
      channel
      statusID
      rating
      review
      confirmationHash
      isConfirmedByUser
      date
      tables
      tableName
      comments
      lastComment
      commentCreatedBy
      commentCreatedDate
      timeLeft
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      depositValue
      depositStatus
      eventStatus
      eventName
      paymentStatus
      paymentValue
      disablePartialPayment
      disablePayment
      eventDate
      eventGuests
      timeSlotID
      timeSlots
      conceptID
      mainGuest
      customerName
      customerPhone
      customerGroup
      accompaniedCount
      channel
      statusID
      rating
      review
      confirmationHash
      isConfirmedByUser
      date
      tables
      tableName
      comments
      lastComment
      commentCreatedBy
      commentCreatedDate
      timeLeft
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      depositValue
      depositStatus
      eventStatus
      eventName
      paymentStatus
      paymentValue
      disablePartialPayment
      disablePayment
      eventDate
      eventGuests
      timeSlotID
      timeSlots
      conceptID
      mainGuest
      customerName
      customerPhone
      customerGroup
      accompaniedCount
      channel
      statusID
      rating
      review
      confirmationHash
      isConfirmedByUser
      date
      tables
      tableName
      comments
      lastComment
      commentCreatedBy
      commentCreatedDate
      timeLeft
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTimeline = /* GraphQL */ `
  mutation CreateTimeline(
    $input: CreateTimelineInput!
    $condition: ModelTimelineConditionInput
  ) {
    createTimeline(input: $input, condition: $condition) {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      transactionId
      customerId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTimeline = /* GraphQL */ `
  mutation UpdateTimeline(
    $input: UpdateTimelineInput!
    $condition: ModelTimelineConditionInput
  ) {
    updateTimeline(input: $input, condition: $condition) {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      transactionId
      customerId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTimeline = /* GraphQL */ `
  mutation DeleteTimeline(
    $input: DeleteTimelineInput!
    $condition: ModelTimelineConditionInput
  ) {
    deleteTimeline(input: $input, condition: $condition) {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      transactionId
      customerId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCall = /* GraphQL */ `
  mutation CreateCall(
    $input: CreateCallInput!
    $condition: ModelCallConditionInput
  ) {
    createCall(input: $input, condition: $condition) {
      id
      phone_number
      customer {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      createdByID
      createdByName
      endedAt
      duration
      date
      employeeId
      updatedAt
      _version
      _deleted
      _lastChangedAt
      callCustomerId
      __typename
    }
  }
`;
export const updateCall = /* GraphQL */ `
  mutation UpdateCall(
    $input: UpdateCallInput!
    $condition: ModelCallConditionInput
  ) {
    updateCall(input: $input, condition: $condition) {
      id
      phone_number
      customer {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      createdByID
      createdByName
      endedAt
      duration
      date
      employeeId
      updatedAt
      _version
      _deleted
      _lastChangedAt
      callCustomerId
      __typename
    }
  }
`;
export const deleteCall = /* GraphQL */ `
  mutation DeleteCall(
    $input: DeleteCallInput!
    $condition: ModelCallConditionInput
  ) {
    deleteCall(input: $input, condition: $condition) {
      id
      phone_number
      customer {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      createdByID
      createdByName
      endedAt
      duration
      date
      employeeId
      updatedAt
      _version
      _deleted
      _lastChangedAt
      callCustomerId
      __typename
    }
  }
`;
export const createAdminRole = /* GraphQL */ `
  mutation CreateAdminRole(
    $input: CreateAdminRoleInput!
    $condition: ModelAdminRoleConditionInput
  ) {
    createAdminRole(input: $input, condition: $condition) {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminRole = /* GraphQL */ `
  mutation UpdateAdminRole(
    $input: UpdateAdminRoleInput!
    $condition: ModelAdminRoleConditionInput
  ) {
    updateAdminRole(input: $input, condition: $condition) {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminRole = /* GraphQL */ `
  mutation DeleteAdminRole(
    $input: DeleteAdminRoleInput!
    $condition: ModelAdminRoleConditionInput
  ) {
    deleteAdminRole(input: $input, condition: $condition) {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminGroup = /* GraphQL */ `
  mutation CreateAdminGroup(
    $input: CreateAdminGroupInput!
    $condition: ModelAdminGroupConditionInput
  ) {
    createAdminGroup(input: $input, condition: $condition) {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminGroup = /* GraphQL */ `
  mutation UpdateAdminGroup(
    $input: UpdateAdminGroupInput!
    $condition: ModelAdminGroupConditionInput
  ) {
    updateAdminGroup(input: $input, condition: $condition) {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminGroup = /* GraphQL */ `
  mutation DeleteAdminGroup(
    $input: DeleteAdminGroupInput!
    $condition: ModelAdminGroupConditionInput
  ) {
    deleteAdminGroup(input: $input, condition: $condition) {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserConcepts = /* GraphQL */ `
  mutation CreateUserConcepts(
    $input: CreateUserConceptsInput!
    $condition: ModelUserConceptsConditionInput
  ) {
    createUserConcepts(input: $input, condition: $condition) {
      id
      defaultConcept
      concepts
      conceptsRoles
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserConcepts = /* GraphQL */ `
  mutation UpdateUserConcepts(
    $input: UpdateUserConceptsInput!
    $condition: ModelUserConceptsConditionInput
  ) {
    updateUserConcepts(input: $input, condition: $condition) {
      id
      defaultConcept
      concepts
      conceptsRoles
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserConcepts = /* GraphQL */ `
  mutation DeleteUserConcepts(
    $input: DeleteUserConceptsInput!
    $condition: ModelUserConceptsConditionInput
  ) {
    deleteUserConcepts(input: $input, condition: $condition) {
      id
      defaultConcept
      concepts
      conceptsRoles
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      transactionID
      date
      guestName
      guestPhone
      guestID
      guestGroup
      guestsNames
      bookingID
      bookingDate
      amount_cents
      status
      failureReason
      type
      timeSlots
      tables
      conceptID
      currency
      refund
      reference_orderID
      ownerID
      refunded_amount_cents
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      transactionID
      date
      guestName
      guestPhone
      guestID
      guestGroup
      guestsNames
      bookingID
      bookingDate
      amount_cents
      status
      failureReason
      type
      timeSlots
      tables
      conceptID
      currency
      refund
      reference_orderID
      ownerID
      refunded_amount_cents
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      transactionID
      date
      guestName
      guestPhone
      guestID
      guestGroup
      guestsNames
      bookingID
      bookingDate
      amount_cents
      status
      failureReason
      type
      timeSlots
      tables
      conceptID
      currency
      refund
      reference_orderID
      ownerID
      refunded_amount_cents
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPaymentStat = /* GraphQL */ `
  mutation CreatePaymentStat(
    $input: CreatePaymentStatInput!
    $condition: ModelPaymentStatConditionInput
  ) {
    createPaymentStat(input: $input, condition: $condition) {
      id
      accountID
      conceptID
      year
      month
      monthName
      earning
      refund
      earningTrxCount
      refundTrxCount
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePaymentStat = /* GraphQL */ `
  mutation UpdatePaymentStat(
    $input: UpdatePaymentStatInput!
    $condition: ModelPaymentStatConditionInput
  ) {
    updatePaymentStat(input: $input, condition: $condition) {
      id
      accountID
      conceptID
      year
      month
      monthName
      earning
      refund
      earningTrxCount
      refundTrxCount
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePaymentStat = /* GraphQL */ `
  mutation DeletePaymentStat(
    $input: DeletePaymentStatInput!
    $condition: ModelPaymentStatConditionInput
  ) {
    deletePaymentStat(input: $input, condition: $condition) {
      id
      accountID
      conceptID
      year
      month
      monthName
      earning
      refund
      earningTrxCount
      refundTrxCount
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBookingGuest = /* GraphQL */ `
  mutation CreateBookingGuest(
    $input: CreateBookingGuestInput!
    $condition: ModelBookingGuestConditionInput
  ) {
    createBookingGuest(input: $input, condition: $condition) {
      id
      bookingID
      guestName
      paidAmount
      paymentAmount
      paymentStatus
      transactionID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBookingGuest = /* GraphQL */ `
  mutation UpdateBookingGuest(
    $input: UpdateBookingGuestInput!
    $condition: ModelBookingGuestConditionInput
  ) {
    updateBookingGuest(input: $input, condition: $condition) {
      id
      bookingID
      guestName
      paidAmount
      paymentAmount
      paymentStatus
      transactionID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBookingGuest = /* GraphQL */ `
  mutation DeleteBookingGuest(
    $input: DeleteBookingGuestInput!
    $condition: ModelBookingGuestConditionInput
  ) {
    deleteBookingGuest(input: $input, condition: $condition) {
      id
      bookingID
      guestName
      paidAmount
      paymentAmount
      paymentStatus
      transactionID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      conceptID
      preparationAreaID
      subCategory
      name
      guestView
      guestOrder
      staffOrder
      image
      darkImage
      menuItems {
        items {
          id
          conceptID
          kioskID
          name
          description
          image
          precedence
          categoryID
          categoryName
          outOfStock
          requiredPoints
          symphonyID
          comboItemID
          ratings
          prices
          choiceGroups
          choiceGroupsRules {
            id
            minNumberOfChoices
            maxNumberOfChoices
            __typename
          }
          ExtraChoiceGroups
          enabled
          hasLimitedQuantity
          quantity
          autoRestock
          restockQuantity
          deleted
          createdAt
          createdByID
          createdByName
          quantityOrdered
          performancePerDay
          multiLanguages
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      symphonyID
      isHidden
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      conceptID
      preparationAreaID
      subCategory
      name
      guestView
      guestOrder
      staffOrder
      image
      darkImage
      menuItems {
        items {
          id
          conceptID
          kioskID
          name
          description
          image
          precedence
          categoryID
          categoryName
          outOfStock
          requiredPoints
          symphonyID
          comboItemID
          ratings
          prices
          choiceGroups
          choiceGroupsRules {
            id
            minNumberOfChoices
            maxNumberOfChoices
            __typename
          }
          ExtraChoiceGroups
          enabled
          hasLimitedQuantity
          quantity
          autoRestock
          restockQuantity
          deleted
          createdAt
          createdByID
          createdByName
          quantityOrdered
          performancePerDay
          multiLanguages
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      symphonyID
      isHidden
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      conceptID
      preparationAreaID
      subCategory
      name
      guestView
      guestOrder
      staffOrder
      image
      darkImage
      menuItems {
        items {
          id
          conceptID
          kioskID
          name
          description
          image
          precedence
          categoryID
          categoryName
          outOfStock
          requiredPoints
          symphonyID
          comboItemID
          ratings
          prices
          choiceGroups
          choiceGroupsRules {
            id
            minNumberOfChoices
            maxNumberOfChoices
            __typename
          }
          ExtraChoiceGroups
          enabled
          hasLimitedQuantity
          quantity
          autoRestock
          restockQuantity
          deleted
          createdAt
          createdByID
          createdByName
          quantityOrdered
          performancePerDay
          multiLanguages
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      symphonyID
      isHidden
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMenuItem = /* GraphQL */ `
  mutation CreateMenuItem(
    $input: CreateMenuItemInput!
    $condition: ModelMenuItemConditionInput
  ) {
    createMenuItem(input: $input, condition: $condition) {
      id
      conceptID
      kioskID
      name
      description
      image
      precedence
      categoryID
      categoryName
      outOfStock
      requiredPoints
      symphonyID
      comboItemID
      ratings
      prices
      choiceGroups
      choiceGroupsRules {
        id
        minNumberOfChoices
        maxNumberOfChoices
        __typename
      }
      ExtraChoiceGroups
      enabled
      hasLimitedQuantity
      quantity
      autoRestock
      restockQuantity
      deleted
      createdAt
      createdByID
      createdByName
      quantityOrdered
      performancePerDay
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMenuItem = /* GraphQL */ `
  mutation UpdateMenuItem(
    $input: UpdateMenuItemInput!
    $condition: ModelMenuItemConditionInput
  ) {
    updateMenuItem(input: $input, condition: $condition) {
      id
      conceptID
      kioskID
      name
      description
      image
      precedence
      categoryID
      categoryName
      outOfStock
      requiredPoints
      symphonyID
      comboItemID
      ratings
      prices
      choiceGroups
      choiceGroupsRules {
        id
        minNumberOfChoices
        maxNumberOfChoices
        __typename
      }
      ExtraChoiceGroups
      enabled
      hasLimitedQuantity
      quantity
      autoRestock
      restockQuantity
      deleted
      createdAt
      createdByID
      createdByName
      quantityOrdered
      performancePerDay
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMenuItem = /* GraphQL */ `
  mutation DeleteMenuItem(
    $input: DeleteMenuItemInput!
    $condition: ModelMenuItemConditionInput
  ) {
    deleteMenuItem(input: $input, condition: $condition) {
      id
      conceptID
      kioskID
      name
      description
      image
      precedence
      categoryID
      categoryName
      outOfStock
      requiredPoints
      symphonyID
      comboItemID
      ratings
      prices
      choiceGroups
      choiceGroupsRules {
        id
        minNumberOfChoices
        maxNumberOfChoices
        __typename
      }
      ExtraChoiceGroups
      enabled
      hasLimitedQuantity
      quantity
      autoRestock
      restockQuantity
      deleted
      createdAt
      createdByID
      createdByName
      quantityOrdered
      performancePerDay
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPrice = /* GraphQL */ `
  mutation CreatePrice(
    $input: CreatePriceInput!
    $condition: ModelPriceConditionInput
  ) {
    createPrice(input: $input, condition: $condition) {
      id
      conceptID
      name
      multiLanguages
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePrice = /* GraphQL */ `
  mutation UpdatePrice(
    $input: UpdatePriceInput!
    $condition: ModelPriceConditionInput
  ) {
    updatePrice(input: $input, condition: $condition) {
      id
      conceptID
      name
      multiLanguages
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePrice = /* GraphQL */ `
  mutation DeletePrice(
    $input: DeletePriceInput!
    $condition: ModelPriceConditionInput
  ) {
    deletePrice(input: $input, condition: $condition) {
      id
      conceptID
      name
      multiLanguages
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createChoiceGroups = /* GraphQL */ `
  mutation CreateChoiceGroups(
    $input: CreateChoiceGroupsInput!
    $condition: ModelChoiceGroupsConditionInput
  ) {
    createChoiceGroups(input: $input, condition: $condition) {
      id
      conceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      choiceItems {
        items {
          id
          conceptID
          name
          price
          precedence
          choiceGroupID
          choiceGroupName
          deleted
          createdAt
          createdByID
          createdByName
          symphonyID
          multiLanguages
          definitionNumber
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      simphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChoiceGroups = /* GraphQL */ `
  mutation UpdateChoiceGroups(
    $input: UpdateChoiceGroupsInput!
    $condition: ModelChoiceGroupsConditionInput
  ) {
    updateChoiceGroups(input: $input, condition: $condition) {
      id
      conceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      choiceItems {
        items {
          id
          conceptID
          name
          price
          precedence
          choiceGroupID
          choiceGroupName
          deleted
          createdAt
          createdByID
          createdByName
          symphonyID
          multiLanguages
          definitionNumber
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      simphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChoiceGroups = /* GraphQL */ `
  mutation DeleteChoiceGroups(
    $input: DeleteChoiceGroupsInput!
    $condition: ModelChoiceGroupsConditionInput
  ) {
    deleteChoiceGroups(input: $input, condition: $condition) {
      id
      conceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      choiceItems {
        items {
          id
          conceptID
          name
          price
          precedence
          choiceGroupID
          choiceGroupName
          deleted
          createdAt
          createdByID
          createdByName
          symphonyID
          multiLanguages
          definitionNumber
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      simphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createChoice = /* GraphQL */ `
  mutation CreateChoice(
    $input: CreateChoiceInput!
    $condition: ModelChoiceConditionInput
  ) {
    createChoice(input: $input, condition: $condition) {
      id
      conceptID
      name
      price
      precedence
      choiceGroupID
      choiceGroupName
      deleted
      createdAt
      createdByID
      createdByName
      symphonyID
      multiLanguages
      definitionNumber
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChoice = /* GraphQL */ `
  mutation UpdateChoice(
    $input: UpdateChoiceInput!
    $condition: ModelChoiceConditionInput
  ) {
    updateChoice(input: $input, condition: $condition) {
      id
      conceptID
      name
      price
      precedence
      choiceGroupID
      choiceGroupName
      deleted
      createdAt
      createdByID
      createdByName
      symphonyID
      multiLanguages
      definitionNumber
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChoice = /* GraphQL */ `
  mutation DeleteChoice(
    $input: DeleteChoiceInput!
    $condition: ModelChoiceConditionInput
  ) {
    deleteChoice(input: $input, condition: $condition) {
      id
      conceptID
      name
      price
      precedence
      choiceGroupID
      choiceGroupName
      deleted
      createdAt
      createdByID
      createdByName
      symphonyID
      multiLanguages
      definitionNumber
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCart = /* GraphQL */ `
  mutation CreateCart(
    $input: CreateCartInput!
    $condition: ModelCartConditionInput
  ) {
    createCart(input: $input, condition: $condition) {
      id
      conceptID
      userID
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        comboItemID
        __typename
      }
      specialRequest
      deliveryFee
      subTotal
      subTaxTotal
      totalPrice
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCart = /* GraphQL */ `
  mutation UpdateCart(
    $input: UpdateCartInput!
    $condition: ModelCartConditionInput
  ) {
    updateCart(input: $input, condition: $condition) {
      id
      conceptID
      userID
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        comboItemID
        __typename
      }
      specialRequest
      deliveryFee
      subTotal
      subTaxTotal
      totalPrice
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCart = /* GraphQL */ `
  mutation DeleteCart(
    $input: DeleteCartInput!
    $condition: ModelCartConditionInput
  ) {
    deleteCart(input: $input, condition: $condition) {
      id
      conceptID
      userID
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        comboItemID
        __typename
      }
      specialRequest
      deliveryFee
      subTotal
      subTaxTotal
      totalPrice
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminStatus = /* GraphQL */ `
  mutation CreateAdminStatus(
    $input: CreateAdminStatusInput!
    $condition: ModelAdminStatusConditionInput
  ) {
    createAdminStatus(input: $input, condition: $condition) {
      id
      sub
      accountID
      name
      averageCallTime
      numberOfCalls
      idelDuration
      activeDuration
      awayDuration
      lastCallTime
      agentBarAverageCallTime
      agentBarNumberOfCalls
      type
      waiterStatus
      totalAverageRatio
      performancePerDay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminStatus = /* GraphQL */ `
  mutation UpdateAdminStatus(
    $input: UpdateAdminStatusInput!
    $condition: ModelAdminStatusConditionInput
  ) {
    updateAdminStatus(input: $input, condition: $condition) {
      id
      sub
      accountID
      name
      averageCallTime
      numberOfCalls
      idelDuration
      activeDuration
      awayDuration
      lastCallTime
      agentBarAverageCallTime
      agentBarNumberOfCalls
      type
      waiterStatus
      totalAverageRatio
      performancePerDay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminStatus = /* GraphQL */ `
  mutation DeleteAdminStatus(
    $input: DeleteAdminStatusInput!
    $condition: ModelAdminStatusConditionInput
  ) {
    deleteAdminStatus(input: $input, condition: $condition) {
      id
      sub
      accountID
      name
      averageCallTime
      numberOfCalls
      idelDuration
      activeDuration
      awayDuration
      lastCallTime
      agentBarAverageCallTime
      agentBarNumberOfCalls
      type
      waiterStatus
      totalAverageRatio
      performancePerDay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createOnlineOrder = /* GraphQL */ `
  mutation CreateOnlineOrder(
    $input: CreateOnlineOrderInput!
    $condition: ModelOnlineOrderConditionInput
  ) {
    createOnlineOrder(input: $input, condition: $condition) {
      id
      conceptID
      statusID
      status
      reason
      orderType
      channel
      userID
      userName
      userPhoneNumber
      dispatcherID
      dispatcherName
      cartID
      tenderId
      employeeId
      rvcId
      checkNumber
      totalAmount
      discountedValue
      subTotal
      subTaxTotal
      tax
      deliveryFee
      orderTime
      executionArn
      specialRequest
      orderAddress
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        comboItemID
        __typename
      }
      checkRef
      discountID
      amount_cents
      transactionID
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      acceptedByWaiterID
      acceptedByWaiterName
      deliveredByWaiterID
      tableID
      tableName
      shift
      parentOrder
      preparationAreaID
      childrenStatus
      createdAt
      acceptedByWaiterAt
      sentToKitchenAt
      acceptedByKitchenAt
      readyAt
      outForDeliveryAt
      deliveredAt
      promoCodeID
      promoCodeName
      promoCodeAppliedTo
      promoCode {
        id
        name
        redemptions
        status
        startDate
        endDate
        appliesTo
        minOrderReq
        minOrderVal
        minOrderQty
        tenderReq
        discountVal
        discountLimit
        quota
        quotaUsed
        totalRevenue
        appliedDiscountedVal
        usePerCustomer
        channelReq
        customerEligibility
        customerReq
        spendingExceeds
        spendingBelow
        orderSinceDate
        orderFromDate
        orderToDate
        isDisplayed
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      reviewID
      review {
        id
        accountID
        bookingID
        guestName
        guestId
        reason
        value
        userID
        orderID
        rate
        satisfaction
        thoughts
        itemReviews {
          itemID
          rate
          satisfaction
          multiLanguages
          __typename
        }
        photos
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      simphEmpID
      createdByID
      createdByName
      tmpField
      updatedByID
      updatedByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateOnlineOrder = /* GraphQL */ `
  mutation UpdateOnlineOrder(
    $input: UpdateOnlineOrderInput!
    $condition: ModelOnlineOrderConditionInput
  ) {
    updateOnlineOrder(input: $input, condition: $condition) {
      id
      conceptID
      statusID
      status
      reason
      orderType
      channel
      userID
      userName
      userPhoneNumber
      dispatcherID
      dispatcherName
      cartID
      tenderId
      employeeId
      rvcId
      checkNumber
      totalAmount
      discountedValue
      subTotal
      subTaxTotal
      tax
      deliveryFee
      orderTime
      executionArn
      specialRequest
      orderAddress
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        comboItemID
        __typename
      }
      checkRef
      discountID
      amount_cents
      transactionID
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      acceptedByWaiterID
      acceptedByWaiterName
      deliveredByWaiterID
      tableID
      tableName
      shift
      parentOrder
      preparationAreaID
      childrenStatus
      createdAt
      acceptedByWaiterAt
      sentToKitchenAt
      acceptedByKitchenAt
      readyAt
      outForDeliveryAt
      deliveredAt
      promoCodeID
      promoCodeName
      promoCodeAppliedTo
      promoCode {
        id
        name
        redemptions
        status
        startDate
        endDate
        appliesTo
        minOrderReq
        minOrderVal
        minOrderQty
        tenderReq
        discountVal
        discountLimit
        quota
        quotaUsed
        totalRevenue
        appliedDiscountedVal
        usePerCustomer
        channelReq
        customerEligibility
        customerReq
        spendingExceeds
        spendingBelow
        orderSinceDate
        orderFromDate
        orderToDate
        isDisplayed
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      reviewID
      review {
        id
        accountID
        bookingID
        guestName
        guestId
        reason
        value
        userID
        orderID
        rate
        satisfaction
        thoughts
        itemReviews {
          itemID
          rate
          satisfaction
          multiLanguages
          __typename
        }
        photos
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      simphEmpID
      createdByID
      createdByName
      tmpField
      updatedByID
      updatedByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteOnlineOrder = /* GraphQL */ `
  mutation DeleteOnlineOrder(
    $input: DeleteOnlineOrderInput!
    $condition: ModelOnlineOrderConditionInput
  ) {
    deleteOnlineOrder(input: $input, condition: $condition) {
      id
      conceptID
      statusID
      status
      reason
      orderType
      channel
      userID
      userName
      userPhoneNumber
      dispatcherID
      dispatcherName
      cartID
      tenderId
      employeeId
      rvcId
      checkNumber
      totalAmount
      discountedValue
      subTotal
      subTaxTotal
      tax
      deliveryFee
      orderTime
      executionArn
      specialRequest
      orderAddress
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        comboItemID
        __typename
      }
      checkRef
      discountID
      amount_cents
      transactionID
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      acceptedByWaiterID
      acceptedByWaiterName
      deliveredByWaiterID
      tableID
      tableName
      shift
      parentOrder
      preparationAreaID
      childrenStatus
      createdAt
      acceptedByWaiterAt
      sentToKitchenAt
      acceptedByKitchenAt
      readyAt
      outForDeliveryAt
      deliveredAt
      promoCodeID
      promoCodeName
      promoCodeAppliedTo
      promoCode {
        id
        name
        redemptions
        status
        startDate
        endDate
        appliesTo
        minOrderReq
        minOrderVal
        minOrderQty
        tenderReq
        discountVal
        discountLimit
        quota
        quotaUsed
        totalRevenue
        appliedDiscountedVal
        usePerCustomer
        channelReq
        customerEligibility
        customerReq
        spendingExceeds
        spendingBelow
        orderSinceDate
        orderFromDate
        orderToDate
        isDisplayed
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      reviewID
      review {
        id
        accountID
        bookingID
        guestName
        guestId
        reason
        value
        userID
        orderID
        rate
        satisfaction
        thoughts
        itemReviews {
          itemID
          rate
          satisfaction
          multiLanguages
          __typename
        }
        photos
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      simphEmpID
      createdByID
      createdByName
      tmpField
      updatedByID
      updatedByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createOrderStatus = /* GraphQL */ `
  mutation CreateOrderStatus(
    $input: CreateOrderStatusInput!
    $condition: ModelOrderStatusConditionInput
  ) {
    createOrderStatus(input: $input, condition: $condition) {
      id
      conceptID
      name
      color
      precedence
      accessibleStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateOrderStatus = /* GraphQL */ `
  mutation UpdateOrderStatus(
    $input: UpdateOrderStatusInput!
    $condition: ModelOrderStatusConditionInput
  ) {
    updateOrderStatus(input: $input, condition: $condition) {
      id
      conceptID
      name
      color
      precedence
      accessibleStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteOrderStatus = /* GraphQL */ `
  mutation DeleteOrderStatus(
    $input: DeleteOrderStatusInput!
    $condition: ModelOrderStatusConditionInput
  ) {
    deleteOrderStatus(input: $input, condition: $condition) {
      id
      conceptID
      name
      color
      precedence
      accessibleStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDiscountCoupon = /* GraphQL */ `
  mutation CreateDiscountCoupon(
    $input: CreateDiscountCouponInput!
    $condition: ModelDiscountCouponConditionInput
  ) {
    createDiscountCoupon(input: $input, condition: $condition) {
      id
      discountPercent
      usedBy
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDiscountCoupon = /* GraphQL */ `
  mutation UpdateDiscountCoupon(
    $input: UpdateDiscountCouponInput!
    $condition: ModelDiscountCouponConditionInput
  ) {
    updateDiscountCoupon(input: $input, condition: $condition) {
      id
      discountPercent
      usedBy
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDiscountCoupon = /* GraphQL */ `
  mutation DeleteDiscountCoupon(
    $input: DeleteDiscountCouponInput!
    $condition: ModelDiscountCouponConditionInput
  ) {
    deleteDiscountCoupon(input: $input, condition: $condition) {
      id
      discountPercent
      usedBy
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createZone = /* GraphQL */ `
  mutation CreateZone(
    $input: CreateZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    createZone(input: $input, condition: $condition) {
      id
      conceptID
      location
      deliveryFee
      concepts {
        id
        deliveryFee
        __typename
      }
      polygon
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateZone = /* GraphQL */ `
  mutation UpdateZone(
    $input: UpdateZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    updateZone(input: $input, condition: $condition) {
      id
      conceptID
      location
      deliveryFee
      concepts {
        id
        deliveryFee
        __typename
      }
      polygon
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteZone = /* GraphQL */ `
  mutation DeleteZone(
    $input: DeleteZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    deleteZone(input: $input, condition: $condition) {
      id
      conceptID
      location
      deliveryFee
      concepts {
        id
        deliveryFee
        __typename
      }
      polygon
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserIP = /* GraphQL */ `
  mutation CreateUserIP(
    $input: CreateUserIPInput!
    $condition: ModelUserIPConditionInput
  ) {
    createUserIP(input: $input, condition: $condition) {
      id
      ip
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserIP = /* GraphQL */ `
  mutation UpdateUserIP(
    $input: UpdateUserIPInput!
    $condition: ModelUserIPConditionInput
  ) {
    updateUserIP(input: $input, condition: $condition) {
      id
      ip
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserIP = /* GraphQL */ `
  mutation DeleteUserIP(
    $input: DeleteUserIPInput!
    $condition: ModelUserIPConditionInput
  ) {
    deleteUserIP(input: $input, condition: $condition) {
      id
      ip
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMainCategory = /* GraphQL */ `
  mutation CreateMainCategory(
    $input: CreateMainCategoryInput!
    $condition: ModelMainCategoryConditionInput
  ) {
    createMainCategory(input: $input, condition: $condition) {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMainCategory = /* GraphQL */ `
  mutation UpdateMainCategory(
    $input: UpdateMainCategoryInput!
    $condition: ModelMainCategoryConditionInput
  ) {
    updateMainCategory(input: $input, condition: $condition) {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMainCategory = /* GraphQL */ `
  mutation DeleteMainCategory(
    $input: DeleteMainCategoryInput!
    $condition: ModelMainCategoryConditionInput
  ) {
    deleteMainCategory(input: $input, condition: $condition) {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSubCategory = /* GraphQL */ `
  mutation CreateSubCategory(
    $input: CreateSubCategoryInput!
    $condition: ModelSubCategoryConditionInput
  ) {
    createSubCategory(input: $input, condition: $condition) {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      mainCategoryID
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSubCategory = /* GraphQL */ `
  mutation UpdateSubCategory(
    $input: UpdateSubCategoryInput!
    $condition: ModelSubCategoryConditionInput
  ) {
    updateSubCategory(input: $input, condition: $condition) {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      mainCategoryID
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSubCategory = /* GraphQL */ `
  mutation DeleteSubCategory(
    $input: DeleteSubCategoryInput!
    $condition: ModelSubCategoryConditionInput
  ) {
    deleteSubCategory(input: $input, condition: $condition) {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      mainCategoryID
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createShift = /* GraphQL */ `
  mutation CreateShift(
    $input: CreateShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    createShift(input: $input, condition: $condition) {
      id
      accountID
      name
      precedence
      startTime
      endTime
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateShift = /* GraphQL */ `
  mutation UpdateShift(
    $input: UpdateShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    updateShift(input: $input, condition: $condition) {
      id
      accountID
      name
      precedence
      startTime
      endTime
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteShift = /* GraphQL */ `
  mutation DeleteShift(
    $input: DeleteShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    deleteShift(input: $input, condition: $condition) {
      id
      accountID
      name
      precedence
      startTime
      endTime
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createChannel = /* GraphQL */ `
  mutation CreateChannel(
    $input: CreateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    createChannel(input: $input, condition: $condition) {
      id
      name
      description
      numberOfOrders
      isActive
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChannel = /* GraphQL */ `
  mutation UpdateChannel(
    $input: UpdateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    updateChannel(input: $input, condition: $condition) {
      id
      name
      description
      numberOfOrders
      isActive
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChannel = /* GraphQL */ `
  mutation DeleteChannel(
    $input: DeleteChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    deleteChannel(input: $input, condition: $condition) {
      id
      name
      description
      numberOfOrders
      isActive
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPromocode = /* GraphQL */ `
  mutation CreatePromocode(
    $input: CreatePromocodeInput!
    $condition: ModelPromocodeConditionInput
  ) {
    createPromocode(input: $input, condition: $condition) {
      id
      name
      redemptions
      status
      startDate
      endDate
      appliesTo
      minOrderReq
      minOrderVal
      minOrderQty
      tenderReq
      discountVal
      discountLimit
      quota
      quotaUsed
      totalRevenue
      appliedDiscountedVal
      usePerCustomer
      channelReq
      customerEligibility
      customerReq
      spendingExceeds
      spendingBelow
      orderSinceDate
      orderFromDate
      orderToDate
      isDisplayed
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePromocode = /* GraphQL */ `
  mutation UpdatePromocode(
    $input: UpdatePromocodeInput!
    $condition: ModelPromocodeConditionInput
  ) {
    updatePromocode(input: $input, condition: $condition) {
      id
      name
      redemptions
      status
      startDate
      endDate
      appliesTo
      minOrderReq
      minOrderVal
      minOrderQty
      tenderReq
      discountVal
      discountLimit
      quota
      quotaUsed
      totalRevenue
      appliedDiscountedVal
      usePerCustomer
      channelReq
      customerEligibility
      customerReq
      spendingExceeds
      spendingBelow
      orderSinceDate
      orderFromDate
      orderToDate
      isDisplayed
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePromocode = /* GraphQL */ `
  mutation DeletePromocode(
    $input: DeletePromocodeInput!
    $condition: ModelPromocodeConditionInput
  ) {
    deletePromocode(input: $input, condition: $condition) {
      id
      name
      redemptions
      status
      startDate
      endDate
      appliesTo
      minOrderReq
      minOrderVal
      minOrderQty
      tenderReq
      discountVal
      discountLimit
      quota
      quotaUsed
      totalRevenue
      appliedDiscountedVal
      usePerCustomer
      channelReq
      customerEligibility
      customerReq
      spendingExceeds
      spendingBelow
      orderSinceDate
      orderFromDate
      orderToDate
      isDisplayed
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPromocodeDashboard = /* GraphQL */ `
  mutation CreatePromocodeDashboard(
    $input: CreatePromocodeDashboardInput!
    $condition: ModelPromocodeDashboardConditionInput
  ) {
    createPromocodeDashboard(input: $input, condition: $condition) {
      id
      conceptID
      totalRevenue
      totalRedemptions
      totalDiscount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePromocodeDashboard = /* GraphQL */ `
  mutation UpdatePromocodeDashboard(
    $input: UpdatePromocodeDashboardInput!
    $condition: ModelPromocodeDashboardConditionInput
  ) {
    updatePromocodeDashboard(input: $input, condition: $condition) {
      id
      conceptID
      totalRevenue
      totalRedemptions
      totalDiscount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePromocodeDashboard = /* GraphQL */ `
  mutation DeletePromocodeDashboard(
    $input: DeletePromocodeDashboardInput!
    $condition: ModelPromocodeDashboardConditionInput
  ) {
    deletePromocodeDashboard(input: $input, condition: $condition) {
      id
      conceptID
      totalRevenue
      totalRedemptions
      totalDiscount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLoyaltyItem = /* GraphQL */ `
  mutation CreateLoyaltyItem(
    $input: CreateLoyaltyItemInput!
    $condition: ModelLoyaltyItemConditionInput
  ) {
    createLoyaltyItem(input: $input, condition: $condition) {
      id
      name
      description
      startDate
      endDate
      points
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLoyaltyItem = /* GraphQL */ `
  mutation UpdateLoyaltyItem(
    $input: UpdateLoyaltyItemInput!
    $condition: ModelLoyaltyItemConditionInput
  ) {
    updateLoyaltyItem(input: $input, condition: $condition) {
      id
      name
      description
      startDate
      endDate
      points
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLoyaltyItem = /* GraphQL */ `
  mutation DeleteLoyaltyItem(
    $input: DeleteLoyaltyItemInput!
    $condition: ModelLoyaltyItemConditionInput
  ) {
    deleteLoyaltyItem(input: $input, condition: $condition) {
      id
      name
      description
      startDate
      endDate
      points
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createOTP = /* GraphQL */ `
  mutation CreateOTP(
    $input: CreateOTPInput!
    $condition: ModelOTPConditionInput
  ) {
    createOTP(input: $input, condition: $condition) {
      id
      OTP
      redeemed
      deleted
      userID
      conceptID
      loyaltyItemID
      loyaltyItem {
        id
        name
        description
        startDate
        endDate
        points
        redemptionPerUser
        image
        simphonyID
        multiLanguages
        isActive
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      concept {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptTendersId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orderType {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptOrderTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateOTP = /* GraphQL */ `
  mutation UpdateOTP(
    $input: UpdateOTPInput!
    $condition: ModelOTPConditionInput
  ) {
    updateOTP(input: $input, condition: $condition) {
      id
      OTP
      redeemed
      deleted
      userID
      conceptID
      loyaltyItemID
      loyaltyItem {
        id
        name
        description
        startDate
        endDate
        points
        redemptionPerUser
        image
        simphonyID
        multiLanguages
        isActive
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      concept {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptTendersId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orderType {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptOrderTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteOTP = /* GraphQL */ `
  mutation DeleteOTP(
    $input: DeleteOTPInput!
    $condition: ModelOTPConditionInput
  ) {
    deleteOTP(input: $input, condition: $condition) {
      id
      OTP
      redeemed
      deleted
      userID
      conceptID
      loyaltyItemID
      loyaltyItem {
        id
        name
        description
        startDate
        endDate
        points
        redemptionPerUser
        image
        simphonyID
        multiLanguages
        isActive
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      concept {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptTendersId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orderType {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptOrderTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
