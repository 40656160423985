/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://t56q9bdih1.execute-api.us-east-2.amazonaws.com/tantres",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://4hnfufs75jgmdap7qyykntmdve.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-outz7e5wpjbijbbcwcwog3v2bm",
    "aws_cognito_identity_pool_id": "us-east-2:a02784ca-338e-40ac-b787-0a396c164885",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_psK5yrduz",
    "aws_user_pools_web_client_id": "7lfi762f6sg82eo7f4cubd9h3f",
    "oauth": {
        "domain": "calllcentersolution-tantres.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://pabloabdo.anyware.software/,https://pabloabdo.anyware.software/",
        "redirectSignOut": "https://pabloabdo.anyware.software/login/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "callcentersolutionassests212433-tantres",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
