import { CreateChannelInput, UpdateChannelInput } from "./../models/GQL_API";
import { ListingByConceptVariables, Option } from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import { Channel } from "../models";
import { createChannel, updateChannel } from "../graphql/mutations";
import { getChannel, listChannels } from "../graphql/queries";
import { HeadCell } from "../models/dataTable";
import {
  setListing,
  setSelectedFilters,
  setFilters,
  setNextToken,
} from "../store/ducks/channel";
import { onCreateChannel } from "../graphql/subscriptions";

const useChannel = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();

  const session = useSelector((state: any) => state.app.session);
  const channelsListing = useSelector((state: any) => state.channels.listing);
  const nextToken = useSelector((state: any) => state.channels.nextToken);

  async function fetch(params: any) {
    try {
      const { searchText, limit } = params;
      const filter: any = {
        deleted: { eq: "0" },
      };

      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }

      const dataList: any = await API.graphql<GraphQLQuery<Channel>>({
        query: listChannels,
        variables: { filter, limit, nextToken },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const currentNextToken = dataList.data.listChannels.nextToken;
      const responseListing = dataList.data.listChannels.items;

      let listing = [...channelsListing, ...responseListing];
      // console.log({ listing });
      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err) {
      showError(err);
    }
  }
  async function fetchAll(params: any) {
    try {
      const { searchText } = params;
      const filter: any = {
        deleted: { eq: "0" },
      };

      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }

      let listing: any[] = [];
      let currentNextToken: any;
      let responseListing: any;
      do {
        const dataList: any = await API.graphql<GraphQLQuery<Channel>>({
          query: listChannels,
          variables: { filter },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        currentNextToken = dataList.data.listChannels.nextToken;
        responseListing = dataList.data.listChannels.items;
        listing = [...listing, ...responseListing];
      } while (currentNextToken);

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: any) {
    const { id } = params;

    try {
      const channel: any = await API.graphql({
        query: getChannel,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return channel.data.getChannel;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: any, session = true) {
    let { userID, userName } = params;
    let { name, description, logoUrl, isActive } = params.data;

    try {
      const createInput: CreateChannelInput = {
        name: name ? name.toLowerCase() : "",
        description: description ? description : null,
        isActive: isActive === false ? false : true,
        numberOfOrders: 0,
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      const channel = await API.graphql<GraphQLQuery<Channel>>({
        query: createChannel,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`New ${singleName} has been created successfully`);

      return channel;
    } catch (err) {
      console.log(err);
    }
  }

  async function update(params: any, session = false) {
    try {
      const { data } = params;
      let original = await get(params);

      const updateInput: UpdateChannelInput = {
        id: original.id,
        name: data.name ? data.name.toLowerCase() : original.name,
        description: data.description ? data.description : original.description,
        isActive: data.isActive === false ? false : true,
        numberOfOrders: data.numberOfOrders
          ? data.numberOfOrders
          : original.numberOfOrders,
        _version: original._version,
      };

      const channel: any = await API.graphql<GraphQLQuery<Channel>>({
        query: updateChannel,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been updated successfully`);

      return channel.data.updateChannel;
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: any) {
    try {
      let original = await get(params);

      const updateInput: UpdateChannelInput = {
        id: original.id,
        deleted: "1",

        _version: original._version,
      };

      await API.graphql<GraphQLQuery<Channel>>({
        query: updateChannel,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`${singleName} has been moved to trash successfully`);

      for (let i = 0; i < channelsListing.length; i++) {
        if (channelsListing[i].id === original.id) {
          channelsListing.splice(i, 1);
          dispatch(setListing(channelsListing));
          break;
        }
      }
    } catch (err) {
      showError(err);
    }
  }

  async function exportAll(params: ListingByConceptVariables) {
    const data = await fetch(params);
    return data;
  }

  function options(listing: Channel[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "isActive",
      numeric: false,
      disablePadding: false,
      label: "Is Active",
    },
    {
      id: "numberOfOrders",
      numeric: false,
      disablePadding: false,
      label: "Number of orders",
    },
    {
      id: "createdByName",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created At",
    },
  ];
  const dataCells: readonly string[] = [
    "name",
    "description",
    "isActive",
    "numberOfOrders",
  ];

  const api: any = {};

  api[`${listingName}Model`] = Channel as any;
  api[`${listingName}CreateSubscription`] = onCreateChannel
    ? onCreateChannel
    : "";
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}Options`] = options;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}ChangeListing`] = (listing: Channel[]) => {
    dispatch(setListing(listing));
    dispatch(setFilters(listing.map((model: any) => model.name)));
  };
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) => {
    dispatch(setSelectedFilters(filters));
  };
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = channelsListing;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  return api;
};

export default useChannel;
