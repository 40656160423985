import { capitalize } from "@mui/material";
import { UserConcepts } from "./../API";
import { setReport } from "./../store/ducks/zone";
import { LambdaFunctions } from "./../constants/enums";
import { useDispatch, useSelector } from "react-redux";
import invokeLambda from "../services/invokeLambda";
import useApp from "./useApp";
import { HeadCell } from "../models/dataTable";
import { toTitleCase } from "../helpers/utils";

const useZonesReport = (listingName: string, singleName: string) => {
  const { showConfirm, showError } = useApp();

  const dispatch = useDispatch();

  const zonesListing = useSelector((state: any) => state.zones.report);

  const userConcepts = useSelector((state: any) => state.userConcepts.listing);

  const headCells: readonly HeadCell[] = [
    {
      id: "location",
      numeric: false,
      disablePadding: false,
      label: "Zone",
    },
    {
      id: "ordersCount",
      numeric: true,
      disablePadding: false,
      label: "Orders Count",
    },
  ];

  const dataCells: readonly string[] = ["location", "ordersCount"];

  const fetch = async (requestBody: any) => {
    const conceptIDs = requestBody?.conceptIDs?.length
      ? requestBody.conceptIDs
      : userConcepts[0].concepts;

    const body = {
      operationId: 43,
      startDate: requestBody.fromDate,
      endDate: requestBody.toDate,
      conceptIDs,
    };

    console.log({ body });

    try {
      const r = await invokeLambda(LambdaFunctions.callCenterOperations, body);

      const result = r.filter((o: any) =>
        o.location
          .trim()
          .toLowerCase()
          .includes(requestBody.searchText.trim().toLowerCase())
      );

      const sortedResults = result.sort(
        (a: any, b: any) => b.ordersCount - a.ordersCount
      );

      const capitalizedResults = sortedResults.map((o: any) => ({
        ...o,
        location: toTitleCase(o.location),
      }));

      return capitalizedResults;
    } catch (e) {
      showError(e);
      console.log(e);
    }
  };

  const api: any = {};
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}NextToken`] = undefined;
  api[`${listingName}Listing`] = zonesListing;
  api[`${listingName}ChangeListing`] = (listing: any) =>
    dispatch(setReport(listing));
  api[`${listingName}ClearListing`] = () => dispatch(setReport([]));
  api[`${listingName}ClearNextToken`] = () => {};

  return api;
};

export default useZonesReport;
