import { updatePromocode } from "../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { Promocode } from "../API";
import { CampaignStatus } from "../constants/enums";
// import { Promocode } from "../models";


const checkPromocodeStatus = (promocode: any) => {
  const currentDate = new Date().toISOString();
  const promocodeStartDate = new Date(promocode.startDate).toISOString();
  const promocodeEndDate = new Date(promocode.endDate).toISOString();
  const promocodeStatus = promocode.status;
  let newPromocodeStatus = promocodeStatus;

  // if status is draft return as draft can only be changed on publish
  if (promocodeStatus === CampaignStatus.DRAFT) return promocodeStatus;

  if (currentDate >= promocodeStartDate && promocodeEndDate >= currentDate) {
    // status can be active or inactive
    if (promocodeStatus === CampaignStatus.ACTIVE || promocodeStatus === CampaignStatus.IN_ACTIVE) {
      return promocodeStatus;
    }
  } else if (promocodeEndDate < currentDate) {
    // status can be finished
    if (promocodeStatus !== CampaignStatus.FINISHED) {
      newPromocodeStatus = CampaignStatus.FINISHED;
    }
  } else if (promocodeStartDate > currentDate) {
    // status can be scheduled
    if (promocodeStatus !== CampaignStatus.SCHEDULED) {
      newPromocodeStatus = CampaignStatus.SCHEDULED;
    }
  }

  return newPromocodeStatus;
};

export const updatePromocodeStatus = async (
  promocode: any,
  session: any
) => {
  const newPromocodeStatus = checkPromocodeStatus(promocode);
  if (promocode.status !== CampaignStatus.DRAFT && newPromocodeStatus !== promocode.status) {
    // update promocode status and return the updatedPromocode
    const promocodeUpdateVariables = {
      input: {
        id: promocode.id,
        status: newPromocodeStatus,
        _version: promocode._version,
      }
    };
    const updatedPromocodeBody: any = await API.graphql<GraphQLQuery<Promocode>>({
      query: updatePromocode,
      variables: promocodeUpdateVariables,
      authMode: session
        ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        : GRAPHQL_AUTH_MODE.AWS_IAM,
    });

    return updatedPromocodeBody.data.updatePromocode;
  }
  return promocode;
};

export const calcCampaignDashboard = (campaigns: any) => {
  const campaignDashboard = {
    totalRevenue: 0,
    totalRevenuePercentage: 0,
    totalRedemptions: 0,
    totalRedemptionsPercentage: 0,
    totalDiscount: 0,
    totalDiscountPercentage: 0,
  }

  if (!campaigns || campaigns.length === 0) return campaignDashboard;

  let totalRevenueChange = 0;
  let totalRedemptionsChange = 0;
  let totalDiscountChange = 0;

  for (let i = 0; i < campaigns.length; i++) {
    const promoCode = campaigns[i];

    campaignDashboard.totalRevenue += promoCode.totalRevenue;
    campaignDashboard.totalRedemptions += promoCode.redemptions;
    campaignDashboard.totalDiscount += promoCode.appliedDiscountedVal;

    if (i > 0) {
      // Calculate percentage change for total revenue
      totalRevenueChange += ((promoCode.totalRevenue - campaigns[i - 1].totalRevenue) / campaigns[i - 1].totalRevenue) * 100;

      // Calculate percentage change for total redemptions
      totalRedemptionsChange += ((promoCode.redemptions - campaigns[i - 1].redemptions) / campaigns[i - 1].redemptions) * 100;

      // Calculate percentage change for total discount
      totalDiscountChange += ((promoCode.appliedDiscountedVal - campaigns[i - 1].appliedDiscountedVal) / campaigns[i - 1].appliedDiscountedVal) * 100;
    }
  }

  const totalCampaigns = campaigns.length - 1;

  // Calculate averages
  campaignDashboard.totalRevenuePercentage = totalCampaigns > 0 ? totalRevenueChange / totalCampaigns : 0;
  campaignDashboard.totalRedemptionsPercentage = totalCampaigns > 0 ? totalRedemptionsChange / totalCampaigns : 0;
  campaignDashboard.totalDiscountPercentage = totalCampaigns > 0 ? totalDiscountChange / totalCampaigns : 0;

  if (campaignDashboard.totalRevenue === 0) {
    campaignDashboard.totalRevenuePercentage = 0;
  }
  if (campaignDashboard.totalRedemptions === 0) {
    campaignDashboard.totalRedemptionsPercentage = 0;
  }
  if (campaignDashboard.totalDiscount === 0) {
    campaignDashboard.totalDiscountPercentage = 0;
  }

  return campaignDashboard;
}

export const calcCampaignChart = (campaigns: any) => {
  return campaigns
    .slice() // Create a copy of the array to avoid modifying the original array
    .sort((a: any, b: any) => b.totalRevenue - a.totalRevenue) // Sort in descending order based on total revenue
    .slice(0, 3); // Take the top 3 promo codes
}
export const mergeCampaignUpdates = (newList: Promocode[], oldList: Promocode[]): Promocode[] => {
  const promoMap = new Map<string, Promocode>();
  oldList.forEach((promo) => promoMap.set(promo.id, promo));
  newList.forEach((newPromo) => {
    promoMap.set(newPromo.id, newPromo);
  });
  const mergedList = Array.from(promoMap.values());

  return mergedList;

}