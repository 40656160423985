// actions
const SET = "transactions/SET";
const SET_SELECTED = "transactions/SET_SELECTED";
const SET_EARNING_AMOUNT = "transactions/SET_EARNING_AMOUNT";
const SET_CHART_RANGE = "transactions/SET_CHART_RANGE";
const SET_CHART_DATA = "transactions/SET_CHART_DATA";
const SET_REFUND_AMOUNT = "transactions/SET_REFUND_AMOUNT";
const SET_LINKS_SENT = "transactions/SET_LINKS_SENT";
const SET_LAST_INDEX = "transactions/SET_LAST_INDEX";
const SET_NEXT_TOKEN = "transactions/SET_NEXT_TOKEN";
const SET_PAGINATION = "transactions/SET_PAGINATION";
const SET_FILTER = "transactions/SET_FILTER";
const SET_SELECTED_FILTERS = "transactions/SET_SELECTED_FILTERS";

const CHANGE_LIMIT = "transactions/CHANGE_LIMIT";
const NEXT_ACTION = "transactions/NEXT_ACTION";
const SET_PREVIOUS_TOKENS = "transactions/SET_PREVIOUS_TOKENS";
const SET_STATUS = "transactions/SET_STATUS";

const DEFAULT_STATE = {
  listing: [],
  selected: "",
  earningAmount: 0,
  refundAmount: 0,
  linksSent: 0,
  pagination: [],
  selectedFilters: [],
  nextToken: null,
  prevTokens: [],
  lastIndex: null,
  filter: [],
  chartRange: "last week",
  chartData: [],
  status: 'Success'
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_PAGINATION:
      return Object.assign({}, state, { pagination: action.pagination });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.id });
    case SET_LAST_INDEX:
      return Object.assign({}, state, { lastIndex: action.id });
    case SET_FILTER:
      return Object.assign({}, state, { filter: action.id });
    case SET_SELECTED_FILTERS:
      return Object.assign({}, state, { selectedFilters: action.listing });
    case SET_PREVIOUS_TOKENS:
      return Object.assign({}, state, {
        previousTokens: action.previousTokens,
      });
    case CHANGE_LIMIT:
      return Object.assign({}, state, {
        limit: action.limit,
        nextToken: action.nextToken,
        nextNextToken: action.nextNextToken,
        previousTokens: action.previousTokens,
      });
    case NEXT_ACTION:
      return Object.assign({}, state, {
        nextToken: action.nextToken,
        previousTokens: action.previousTokens,
      });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_EARNING_AMOUNT:
      return Object.assign({}, state, { earningAmount: action.earningAmount });
    case SET_REFUND_AMOUNT:
      return Object.assign({}, state, { refundAmount: action.refundAmount });
    case SET_LINKS_SENT:
      return Object.assign({}, state, { linksSent: action.linksSent });
    case SET_CHART_RANGE:
      return Object.assign({}, state, { chartRange: action.chartRange });
    case SET_CHART_DATA:
      return Object.assign({}, state, { chartData: action.chartData });
    case SET_STATUS:
      return Object.assign({}, state, { status: action.status });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setPagination = (pagination: any[]) => ({
  pagination,
  type: SET_PAGINATION,
});
export const setNextToken = (id: any) => ({ id, type: SET_NEXT_TOKEN });
export const setLastIndex = (id: number) => ({ id, type: SET_LAST_INDEX });
export const setFilter = (id: any) => ({ id, type: SET_FILTER });

export const changeLimit = (limit: any) => ({
  limit: limit,
  nextToken: null,
  nextNextToken: null,
  previousTokens: [],
  type: CHANGE_LIMIT,
});
export const nextAction = (nextToken: any, previousTokens: any[]) => ({
  nextToken: nextToken,
  previousTokens: previousTokens ? [...previousTokens, nextToken] : [nextToken],
  type: NEXT_ACTION,
});
export const setPreviousToken = (previousTokens: any) => ({
  previousTokens,
  type: SET_PREVIOUS_TOKENS,
});
export const setSelectedFilters = (listing: any[]) => ({
  listing,
  type: SET_SELECTED_FILTERS,
});
export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
export const setEarningAmount = (earningAmount: number) => ({
  earningAmount,
  type: SET_EARNING_AMOUNT,
});
export const setRefundAmount = (refundAmount: number) => ({
  refundAmount,
  type: SET_REFUND_AMOUNT,
});
export const setLinksSent = (linksSent: number) => ({
  linksSent,
  type: SET_LINKS_SENT,
});
export const setChartRange = (chartRange: string) => ({
  chartRange,
  type: SET_CHART_RANGE,
});
export const setChartData = (chartData: any[]) => ({
  chartData,
  type: SET_CHART_DATA,
});

export const setStatus = (status: string) => ({
  status,
  type: SET_STATUS,
});





































// // actions
// const SET = "transactions/SET";
// const SET_SELECTED = "transactions/SET_SELECTED";
// const SET_EARNING_AMOUNT = "transactions/SET_EARNING_AMOUNT";
// const SET_REFUND_AMOUNT = "transactions/SET_REFUND_AMOUNT";
// const SET_LINKS_SENT = "transactions/SET_LINKS_SENT";

// const DEFAULT_STATE = {
//   listing: [],
//   selected: "",
//   earningAmount: 0,
//   refundAmount: 0,
//   linksSent: 0,
// };

// // reducer
// const reducer = (state = DEFAULT_STATE, action: any = {}) => {
//   switch (action.type) {
//     case SET:
//       return Object.assign({}, state, { listing: action.listing });
//     case SET_SELECTED:
//       return Object.assign({}, state, { selected: action.id });
//     case SET_EARNING_AMOUNT:
//       return Object.assign({}, state, { earningAmount: action.earningAmount });
//     case SET_REFUND_AMOUNT:
//       return Object.assign({}, state, { refundAmount: action.refundAmount });
//     case SET_LINKS_SENT:
//       return Object.assign({}, state, { linksSent: action.linksSent });
//     default:
//       return state;
//   }
// };

// export default reducer;

// // action creators
// export const setListing = (listing: any[]) => ({ listing, type: SET });
// export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
// export const setEarningAmount = (earningAmount: number) => ({
//   earningAmount,
//   type: SET_EARNING_AMOUNT,
// });
// export const setRefundAmount = (refundAmount: number) => ({
//   refundAmount,
//   type: SET_REFUND_AMOUNT,
// });
// export const setLinksSent = (linksSent: number) => ({
//   linksSent,
//   type: SET_LINKS_SENT,
// });
