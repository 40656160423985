import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import { setListing, setSelected, setNextToken } from "../store/ducks/media";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { Media } from "../models";

import {
  CreateVariables,
  MediaBulkTrashVariables,
  GetVariables,
  UpdateVariables,
  Option,
  ListingByAccountVariables,
} from "../models/app";
import { CreateMediaInput, UpdateMediaInput } from "../models/GQL_API";
import { createMedia, deleteMedia, updateMedia } from "../graphql/mutations";
import { getMedia, listMedia } from "../graphql/queries";
import { onCreateMedia } from "../graphql/subscriptions";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const accountsSelected = useSelector((state: any) => state.accounts.selected);

  const nextToken = useSelector((state: any) => state.mediaLibrary.nextToken);
  const mediaLisiting = useSelector((state: any) => state.mediaLibrary.listing);
  async function fetch(params: ListingByAccountVariables) {
    const { accountID, searchText, startIndex, limit } = params;

    try {
      // Filter Section
      const filter: any = {
        deleted: { eq: "0" },
        accountID: { eq: accountID },
      };

      if (searchText.length > 0) {
        filter.filename = { contains: searchText.toLowerCase() };
      }

      const ListData: any = await API.graphql<Media>({
        query: listMedia,
        variables: { filter, limit, nextToken: nextToken },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const currentNextToken = ListData.data.listMedia.nextToken;
      const responseListing = ListData.data.listMedia.items;

      let listing = [...mediaLisiting, ...responseListing];
      // console.log({ listing });
      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.error(err);
      showError(err.message || err);
      return [];
    }
  }
  async function fetchAll(params: ListingByAccountVariables) {
    const { accountID, searchText, startIndex, limit } = params;

    try {
      // Filter Section
      const filter: any = {
        deleted: { eq: "0" },
        accountID: { eq: accountID },
      };

      if (searchText.length > 0) {
        filter.filename = { contains: searchText.toLowerCase() };
      }

      let listing: any[] = [];
      let currentNextToken: any;
      let responseListing: any;
      do {
        const ListData: any = await API.graphql<Media>({
          query: listMedia,
          variables: { filter, limit, nextToken: nextToken },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        currentNextToken = ListData.data.listMedia.nextToken;
        responseListing = ListData.data.listMedia.items;
        listing = [...listing, ...responseListing];
      } while (currentNextToken);

      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      
      return listing;
    } catch (err: Error | any) {
      console.error(err);
      showError(err.message || err);
      return [];
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      let single: Media | undefined;

      const listing: any = await API.graphql<Media>({
        query: getMedia,
        variables: { id },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      single = listing.data.getMedia;

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateMediaInput = {
        accountID: accountsSelected.id,
        fileUrl: data.fileUrl,
        filename: data.filename.toLowerCase(),
        filetype: data.filetype,
        fileSize: data.fileSize,
        alternativeText: data.alternativeText,
        caption: data.caption,
        description: data.description,
        attachments: data.attachments,
        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      const model = await API.graphql<Media>({
        query: createMedia,
        variables: { input: createInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      if (data.listing) {
        dispatch(setListing([...data.listing, model]));
      }

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: UpdateVariables) {
    const { id, data } = params;

    try {
      const original: any = await get({ id });

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateMediaInput = {
        id: original.id,
        fileUrl:
          data.fileUrl !== undefined
            ? data.fileUrl.toLowerCase()
            : original!.fileUrl,
        filename:
          data.filename !== undefined
            ? data.filename.toLowerCase()
            : original!.filename,
        filetype:
          data.filetype !== undefined
            ? data.filetype.toLowerCase()
            : original!.filetype,
        fileSize:
          data.fileSize !== undefined ? data.fileSize : original!.fileSize,
        alternativeText:
          data.alternativeText !== undefined
            ? data.alternativeText.toLowerCase()
            : original!.alternativeText,
        caption:
          data.caption !== undefined
            ? data.caption.toLowerCase()
            : original!.caption,
        description:
          data.description !== undefined
            ? data.description.toLowerCase()
            : original!.description,
        attachments:
          data.attachments !== undefined
            ? data.attachments.toLowerCase()
            : original!.attachments,

        _version: original._version,
      };

      await API.graphql<Media>({
        query: updateMedia,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original: any = await get(params);

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateMediaInput = {
        id: original.id,
        deleted: "1",
        _version: original._version,
      };

      await API.graphql<Media>({
        query: updateMedia,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: MediaBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: GetVariables) {
    const { id } = params;

    try {
      await API.graphql<Media>({
        query: deleteMedia,
        variables: { id: id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: Media[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.filename, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdByName",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const api: any = {};

  api[`${listingName}Model`] = Media as any;
  api[`${listingName}CreateSubscription`] = onCreateMedia;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Media[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = mediaLisiting;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  return api;
};

export default useResource;
