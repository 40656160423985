import { updateTransaction } from "../graphql/mutations";
import { UpdateTransactionInput, Transaction } from "../models/GQL_API";
import { useDispatch, useSelector } from "react-redux";
import { setListing, setSelected, setNextToken } from "../store/ducks/payment";
import {
  HeadCell,
  ListingVariables,
  TransactionGetVariables,
} from "../models/app";
import useApp from "./useApp";
import { getTransaction, listTransactions } from "../graphql/queries";
import { onCreateTransaction } from "../graphql/subscriptions";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE, GraphQLQuery } from "@aws-amplify/api";

const usePayment = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const session = useSelector((state: any) => state.app.session);

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );

  const transactionsListing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  async function fetch(params: any) {
    try {
      const { searchText, limit, conceptID } = params;
      // console.log({ params });

      const filter: any = {};
      filter.or = [];
      filter.and = [];
      filter.conceptID = { eq: conceptID };
      if (searchText.length > 0) {
        filter.or.push({ guestName: { contains: searchText.toLowerCase() } });
        filter.or.push({
          guestPhone: { contains: searchText.toLowerCase() },
        });
      }
      if (params.fromDate && params.toDate) {
        const fromDate = new Date(params.fromDate);
        fromDate.setHours(0, 0, 0, 500);
        const toDate = new Date(params.toDate);
        toDate.setHours(23, 59, 59, 999);
        params.fromDate = fromDate.toISOString();
        params.toDate = toDate.toISOString();
        filter.and.push({
          createdAt: {
            ge: new Date(fromDate).toISOString(),
          },
        });
        filter.and.push({
          createdAt: {
            lt: new Date(params.toDate).toISOString(),
          },
        });
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }
      const dataList: any = await API.graphql<GraphQLQuery<Transaction>>({
        query: listTransactions,
        variables: { filter: filter, limit: 100000, nextToken },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      const currentNextToken = dataList.data.listTransactions.nextToken;
      const responseListing = dataList.data.listTransactions.items;

      let listing = [...transactionsListing, ...responseListing];
      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchAll(params: any) {
    try {
      const { searchText, limit, conceptID } = params;
      // console.log({ params });

      const filter: any = {};
      filter.or = [];
      filter.and = [];
      filter.conceptID = { eq: conceptID };
      if (searchText.length > 0) {
        filter.or.push({ guestName: { contains: searchText.toLowerCase() } });
        filter.or.push({
          guestPhone: { contains: searchText.toLowerCase() },
        });
      }
      if (params.fromDate && params.toDate) {
        const fromDate = new Date(params.fromDate);
        fromDate.setHours(0, 0, 0, 500);
        const toDate = new Date(params.toDate);
        toDate.setHours(23, 59, 59, 999);
        params.fromDate = fromDate.toISOString();
        params.toDate = toDate.toISOString();
        filter.and.push({
          createdAt: {
            ge: new Date(fromDate).toISOString(),
          },
        });
        filter.and.push({
          createdAt: {
            lt: new Date(params.toDate).toISOString(),
          },
        });
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }
      let listing: any[] = [];
      let currentNextToken: any;
      let responseListing: any;
      do {
        const dataList: any = await API.graphql<GraphQLQuery<Transaction>>({
          query: listTransactions,
          variables: { filter: filter, nextToken },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        currentNextToken = dataList.data.listTransactions.nextToken;
        responseListing = dataList.data.listTransactions.items;
        listing = [...listing, ...responseListing];
      } while (currentNextToken);
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function exportAll(params: any) {
    try {
      let exportedData: Transaction[] = [];
      const data: Transaction[] = await fetchAll(params);
      for (let user of data!) {
        let row: Transaction = { ...user };
        exportedData.push(row);
      }
      return exportedData;
    } catch (err: any) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "transactionID",
      numeric: false,
      disablePadding: false,
      label: "Trx ID",
    },

    {
      id: "guestName",
      numeric: false,
      disablePadding: false,
      label: "Guest Name",
    },
    {
      id: "guestPhone",
      numeric: false,
      disablePadding: false,
      label: "Guest Phone Number",
    },
    {
      id: "amount_cents",
      numeric: false,
      disablePadding: false,
      label: "Amount",
    },
    {
      id: "currency",
      numeric: false,
      disablePadding: false,
      label: "Currency",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Payment Status",
    },
    {
      id: "failureReason",
      numeric: false,
      disablePadding: false,
      label: "Details",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "type",
    },

    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Creation Date",
    },
  ];
  const dataCells: readonly string[] = [
    "transactionID",
    "guestName",
    "guestPhone",
    "amount_cents",
    "currency",
    "status",
    "failureReason",
    "type",
    "createdAt",
  ];

  const api: any = {};
  api[`${listingName}CreateSubscription`] = onCreateTransaction;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  //   api[`${listingName}Get`] = get;
  //   api[`${listingName}Update`] = update;
  api[`${listingName}Export`] = exportAll;

  api[`${listingName}ChangeListing`] = (listing: Transaction[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = transactionsListing;
  return api;
};
export default usePayment;
