import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import { HeadCell } from "../models/dataTable";
import {
  setListing,
  setPagination,
  setFilter,
  setSelectedFilters,
  setPage,
  setNextToken,
} from "../store/ducks/loyaltyTransactions";
import listLoyaltyTransactions from "../services/loyaltyTransactionsServices";
import { setLoyaltyDashboardData } from "../store/ducks/loyaltyDashboard";

const useLoyaltyDashboard = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError } = useApp();

  let page = useSelector((state: any) => state.loyaltyTransactions.page);
  let selectedFilters = useSelector(
    (state: any) => state.loyaltyTransactions.selectedFilters
  );

  let paginationListing = useSelector(
    (state: any) => state.loyaltyTransactions.pagination
  );
  let loyaltyTransactionsListing = useSelector(
    (state: any) => state.loyaltyTransactions.listing
  );
  let nextToken = useSelector(
    (state: any) => state.loyaltyTransactions.nextToken
  );

  async function fetch(params: any) {
    try {
      const {
        limit,
        searchText,
        moveForward,
        loyaltyTransactionsSelectedFilters,
      } = params;
      let pageSize = limit ? limit : 50;

      const filter: any = {
        ...loyaltyTransactionsSelectedFilters,
        deleted: "0",
        searchText: searchText,
        page: moveForward ? page + 1 : page - 1 || 1,
        pageSize: pageSize,
      };

      if (
        selectedFilters &&
        selectedFilters.toString() !==
          [searchText, filter, loyaltyTransactionsSelectedFilters].toString()
      ) {
        dispatch(setPagination([]));
        dispatch(setPage(1));
      }

      if (moveForward) {
        dispatch(setPage(page + 1));
      } else {
        dispatch(setPage(page - 1 || 1));
      }

      const { transactions, loyaltyDashboardData } =
        await listLoyaltyTransactions(filter);

      const fetchedTransactions = transactions?.length ? transactions : [];

      dispatch(setPagination(fetchedTransactions));
      dispatch(setListing(fetchedTransactions));
      dispatch(setSelectedFilters(filter));
      dispatch(setLoyaltyDashboardData(loyaltyDashboardData || {}));

      return fetchedTransactions;
    } catch (err) {
      showError(err);
    }
  }

  async function exportAll(params: any) {
    try {
      const data = await fetch(params);

      let exportedData = [];

      for (let Loyalty of data!) {
        let row: any = { ...Loyalty };
        if (Loyalty.createdByName) {
          row.createdBy = row.createdByName;
        }

        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "userName",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "conceptName",
      numeric: false,
      disablePadding: false,
      label: "Branch",
    },
    {
      id: "items",
      numeric: false,
      disablePadding: false,
      label: "Items",
    },
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Transaction #",
    },
    {
      id: "checkNumber",
      numeric: false,
      disablePadding: false,
      label: "Check #",
    },
    {
      id: "points",
      numeric: false,
      disablePadding: false,
      label: "Points",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Operation",
    },
    // {
    //   id: "employeeName",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Redeemed by",
    // },

    {
      id: "createdByName",
      numeric: false,
      disablePadding: false,
      label: "Created by",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created at",
    },
  ];

  const dataCells: readonly string[] = [
    "userName",
    "conceptName",
    "items",
    "id",
    "checkNumber",
    "points",
    "type",
    // "employeeName",
  ];

  const api: any = {};

  // api[`${listingName}Model`] = OnlineOrder as any
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}ChangeListing`] = (listing: any[]) =>
    dispatch(setListing(listing));
  // api[`${listingName}ChangeFilters`] = (filters: string[]) =>
  //   dispatch(setFilter(filters))
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) => {
    dispatch(setSelectedFilters(filters));
  };
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = loyaltyTransactionsListing;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  return api;
};

export default useLoyaltyDashboard;
