/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateEmailVerificationInput = {
  id?: string | null,
  accountID: string,
  name: string,
  email: string,
  verification_code: string,
  createdAt?: string | null,
  deleted?: string | null,
  _version?: number | null,
};

export type ModelEmailVerificationConditionInput = {
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  verification_code?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  and?: Array< ModelEmailVerificationConditionInput | null > | null,
  or?: Array< ModelEmailVerificationConditionInput | null > | null,
  not?: ModelEmailVerificationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type EmailVerification = {
  __typename: "EmailVerification",
  id: string,
  accountID: string,
  name: string,
  email: string,
  verification_code: string,
  createdAt: string,
  deleted?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateEmailVerificationInput = {
  id: string,
  accountID?: string | null,
  name?: string | null,
  email?: string | null,
  verification_code?: string | null,
  createdAt?: string | null,
  deleted?: string | null,
  _version?: number | null,
};

export type DeleteEmailVerificationInput = {
  id: string,
  _version?: number | null,
};

export type CreateSocialLinkInput = {
  id?: string | null,
  name: string,
  icon: string,
  slug: string,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelSocialLinkConditionInput = {
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelSocialLinkConditionInput | null > | null,
  or?: Array< ModelSocialLinkConditionInput | null > | null,
  not?: ModelSocialLinkConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type SocialLink = {
  __typename: "SocialLink",
  id: string,
  name: string,
  icon: string,
  slug: string,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateSocialLinkInput = {
  id: string,
  name?: string | null,
  icon?: string | null,
  slug?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteSocialLinkInput = {
  id: string,
  _version?: number | null,
};

export type CreateLanguageInput = {
  id?: string | null,
  name: string,
  code: string,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelLanguageConditionInput = {
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelLanguageConditionInput | null > | null,
  or?: Array< ModelLanguageConditionInput | null > | null,
  not?: ModelLanguageConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Language = {
  __typename: "Language",
  id: string,
  name: string,
  code: string,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateLanguageInput = {
  id: string,
  name?: string | null,
  code?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteLanguageInput = {
  id: string,
  _version?: number | null,
};

export type CreateAttachmentInput = {
  id?: string | null,
  mediaID: string,
  fileUrl: string,
  filename: string,
  filetype?: string | null,
  fileSize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelAttachmentConditionInput = {
  mediaID?: ModelIDInput | null,
  fileUrl?: ModelStringInput | null,
  filename?: ModelStringInput | null,
  filetype?: ModelStringInput | null,
  fileSize?: ModelIntInput | null,
  alternativeText?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAttachmentConditionInput | null > | null,
  or?: Array< ModelAttachmentConditionInput | null > | null,
  not?: ModelAttachmentConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Attachment = {
  __typename: "Attachment",
  id: string,
  mediaID: string,
  fileUrl: string,
  filename: string,
  filetype?: string | null,
  fileSize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAttachmentInput = {
  id: string,
  mediaID?: string | null,
  fileUrl?: string | null,
  filename?: string | null,
  filetype?: string | null,
  fileSize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteAttachmentInput = {
  id: string,
  _version?: number | null,
};

export type CreateGroupInput = {
  id?: string | null,
  accountID: string,
  name: string,
  description?: string | null,
  color?: string | null,
  requireApproval?: boolean | null,
  status?: string | null,
  agents?: Array< string | null > | null,
  groupSettings?: Array< string | null > | null,
  numToUpgrade?: number | null,
  numToDowngrade?: number | null,
  upgradeGroup?: string | null,
  downgradeGroup?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelGroupConditionInput = {
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  color?: ModelStringInput | null,
  requireApproval?: ModelBooleanInput | null,
  status?: ModelStringInput | null,
  agents?: ModelStringInput | null,
  groupSettings?: ModelStringInput | null,
  numToUpgrade?: ModelIntInput | null,
  numToDowngrade?: ModelIntInput | null,
  upgradeGroup?: ModelIDInput | null,
  downgradeGroup?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelGroupConditionInput | null > | null,
  or?: Array< ModelGroupConditionInput | null > | null,
  not?: ModelGroupConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Group = {
  __typename: "Group",
  id: string,
  accountID: string,
  name: string,
  description?: string | null,
  color?: string | null,
  requireApproval?: boolean | null,
  status?: string | null,
  agents?: Array< string | null > | null,
  groupSettings?: Array< string | null > | null,
  numToUpgrade?: number | null,
  numToDowngrade?: number | null,
  upgradeGroup?: string | null,
  downgradeGroup?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateGroupInput = {
  id: string,
  accountID?: string | null,
  name?: string | null,
  description?: string | null,
  color?: string | null,
  requireApproval?: boolean | null,
  status?: string | null,
  agents?: Array< string | null > | null,
  groupSettings?: Array< string | null > | null,
  numToUpgrade?: number | null,
  numToDowngrade?: number | null,
  upgradeGroup?: string | null,
  downgradeGroup?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteGroupInput = {
  id: string,
  _version?: number | null,
};

export type CreateGroupSettingInput = {
  id?: string | null,
  groupID: string,
  statusID: string,
  needsVerification: boolean,
  needsDeposit: boolean,
  isDefault: boolean,
  amount?: number | null,
  days?: Array< string | null > | null,
  timeSlots?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelGroupSettingConditionInput = {
  groupID?: ModelStringInput | null,
  statusID?: ModelIDInput | null,
  needsVerification?: ModelBooleanInput | null,
  needsDeposit?: ModelBooleanInput | null,
  isDefault?: ModelBooleanInput | null,
  amount?: ModelIntInput | null,
  days?: ModelStringInput | null,
  timeSlots?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelGroupSettingConditionInput | null > | null,
  or?: Array< ModelGroupSettingConditionInput | null > | null,
  not?: ModelGroupSettingConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type GroupSetting = {
  __typename: "GroupSetting",
  id: string,
  groupID: string,
  statusID: string,
  needsVerification: boolean,
  needsDeposit: boolean,
  isDefault: boolean,
  amount?: number | null,
  days?: Array< string | null > | null,
  timeSlots?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateGroupSettingInput = {
  id: string,
  groupID?: string | null,
  statusID?: string | null,
  needsVerification?: boolean | null,
  needsDeposit?: boolean | null,
  isDefault?: boolean | null,
  amount?: number | null,
  days?: Array< string | null > | null,
  timeSlots?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteGroupSettingInput = {
  id: string,
  _version?: number | null,
};

export type CreateCustomerStatsInput = {
  id?: string | null,
  conceptID: string,
  totalReservation?: number | null,
  confirmed?: number | null,
  notConfirmed?: number | null,
  canceled?: number | null,
  checkIn?: number | null,
  noShow?: number | null,
  userID?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelCustomerStatsConditionInput = {
  conceptID?: ModelIDInput | null,
  totalReservation?: ModelIntInput | null,
  confirmed?: ModelIntInput | null,
  notConfirmed?: ModelIntInput | null,
  canceled?: ModelIntInput | null,
  checkIn?: ModelIntInput | null,
  noShow?: ModelIntInput | null,
  userID?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelCustomerStatsConditionInput | null > | null,
  or?: Array< ModelCustomerStatsConditionInput | null > | null,
  not?: ModelCustomerStatsConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type CustomerStats = {
  __typename: "CustomerStats",
  id: string,
  conceptID: string,
  totalReservation?: number | null,
  confirmed?: number | null,
  notConfirmed?: number | null,
  canceled?: number | null,
  checkIn?: number | null,
  noShow?: number | null,
  userID?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCustomerStatsInput = {
  id: string,
  conceptID?: string | null,
  totalReservation?: number | null,
  confirmed?: number | null,
  notConfirmed?: number | null,
  canceled?: number | null,
  checkIn?: number | null,
  noShow?: number | null,
  userID?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteCustomerStatsInput = {
  id: string,
  _version?: number | null,
};

export type CreateFlagInput = {
  id?: string | null,
  accountID: string,
  name: string,
  icon: string,
  color?: string | null,
  customers?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelFlagConditionInput = {
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  color?: ModelStringInput | null,
  customers?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelFlagConditionInput | null > | null,
  or?: Array< ModelFlagConditionInput | null > | null,
  not?: ModelFlagConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Flag = {
  __typename: "Flag",
  id: string,
  accountID: string,
  name: string,
  icon: string,
  color?: string | null,
  customers?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateFlagInput = {
  id: string,
  accountID?: string | null,
  name?: string | null,
  icon?: string | null,
  color?: string | null,
  customers?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteFlagInput = {
  id: string,
  _version?: number | null,
};

export type CreateInterestInput = {
  id?: string | null,
  accountID: string,
  name: string,
  icon?: string | null,
  color?: string | null,
  customers?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelInterestConditionInput = {
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  color?: ModelStringInput | null,
  customers?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelInterestConditionInput | null > | null,
  or?: Array< ModelInterestConditionInput | null > | null,
  not?: ModelInterestConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Interest = {
  __typename: "Interest",
  id: string,
  accountID: string,
  name: string,
  icon?: string | null,
  color?: string | null,
  customers?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateInterestInput = {
  id: string,
  accountID?: string | null,
  name?: string | null,
  icon?: string | null,
  color?: string | null,
  customers?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteInterestInput = {
  id: string,
  _version?: number | null,
};

export type CreateReservationStatusInput = {
  id?: string | null,
  accountID: string,
  name: string,
  category: string,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
  reservationStatusImageId?: string | null,
};

export type ModelReservationStatusConditionInput = {
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  category?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelReservationStatusConditionInput | null > | null,
  or?: Array< ModelReservationStatusConditionInput | null > | null,
  not?: ModelReservationStatusConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  reservationStatusImageId?: ModelIDInput | null,
};

export type ReservationStatus = {
  __typename: "ReservationStatus",
  id: string,
  accountID: string,
  name: string,
  category: string,
  image?: Attachment | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  reservationStatusImageId?: string | null,
};

export type UpdateReservationStatusInput = {
  id: string,
  accountID?: string | null,
  name?: string | null,
  category?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
  reservationStatusImageId?: string | null,
};

export type DeleteReservationStatusInput = {
  id: string,
  _version?: number | null,
};

export type CreateTimeSlotInput = {
  id?: string | null,
  conceptID: string,
  name: string,
  friendlyName: string,
  bookings?: Array< string | null > | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelTimeSlotConditionInput = {
  conceptID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  friendlyName?: ModelStringInput | null,
  bookings?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelTimeSlotConditionInput | null > | null,
  or?: Array< ModelTimeSlotConditionInput | null > | null,
  not?: ModelTimeSlotConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type TimeSlot = {
  __typename: "TimeSlot",
  id: string,
  conceptID: string,
  name: string,
  friendlyName: string,
  bookings?: Array< string | null > | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateTimeSlotInput = {
  id: string,
  conceptID?: string | null,
  name?: string | null,
  friendlyName?: string | null,
  bookings?: Array< string | null > | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteTimeSlotInput = {
  id: string,
  _version?: number | null,
};

export type CreateAreaInput = {
  id?: string | null,
  conceptID: string,
  name: string,
  isDefault: boolean,
  expiryDate?: string | null,
  days?: Array< string | null > | null,
  timeSlots?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelAreaConditionInput = {
  conceptID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  isDefault?: ModelBooleanInput | null,
  expiryDate?: ModelStringInput | null,
  days?: ModelStringInput | null,
  timeSlots?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAreaConditionInput | null > | null,
  or?: Array< ModelAreaConditionInput | null > | null,
  not?: ModelAreaConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Area = {
  __typename: "Area",
  id: string,
  conceptID: string,
  name: string,
  isDefault: boolean,
  expiryDate?: string | null,
  days?: Array< string | null > | null,
  timeSlots?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAreaInput = {
  id: string,
  conceptID?: string | null,
  name?: string | null,
  isDefault?: boolean | null,
  expiryDate?: string | null,
  days?: Array< string | null > | null,
  timeSlots?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteAreaInput = {
  id: string,
  _version?: number | null,
};

export type CreateTableInput = {
  id?: string | null,
  conceptID: string,
  label: string,
  name: string,
  width?: number | null,
  height?: number | null,
  capacity?: number | null,
  image?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelTableConditionInput = {
  conceptID?: ModelStringInput | null,
  label?: ModelStringInput | null,
  name?: ModelStringInput | null,
  width?: ModelIntInput | null,
  height?: ModelIntInput | null,
  capacity?: ModelIntInput | null,
  image?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelTableConditionInput | null > | null,
  or?: Array< ModelTableConditionInput | null > | null,
  not?: ModelTableConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Table = {
  __typename: "Table",
  id: string,
  conceptID: string,
  label: string,
  name: string,
  width?: number | null,
  height?: number | null,
  capacity?: number | null,
  image?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateTableInput = {
  id: string,
  conceptID?: string | null,
  label?: string | null,
  name?: string | null,
  width?: number | null,
  height?: number | null,
  capacity?: number | null,
  image?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteTableInput = {
  id: string,
  _version?: number | null,
};

export type CreateFriendInput = {
  id?: string | null,
  customerId?: string | null,
  friendId?: string | null,
  bookingID?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelFriendConditionInput = {
  customerId?: ModelStringInput | null,
  friendId?: ModelStringInput | null,
  bookingID?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelFriendConditionInput | null > | null,
  or?: Array< ModelFriendConditionInput | null > | null,
  not?: ModelFriendConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Friend = {
  __typename: "Friend",
  id: string,
  customerId?: string | null,
  friendId?: string | null,
  bookingID?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateFriendInput = {
  id: string,
  customerId?: string | null,
  friendId?: string | null,
  bookingID?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteFriendInput = {
  id: string,
  _version?: number | null,
};

export type CreateCommentInput = {
  id?: string | null,
  message: string,
  customerId?: string | null,
  bookingId?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelCommentConditionInput = {
  message?: ModelStringInput | null,
  customerId?: ModelStringInput | null,
  bookingId?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelCommentConditionInput | null > | null,
  or?: Array< ModelCommentConditionInput | null > | null,
  not?: ModelCommentConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Comment = {
  __typename: "Comment",
  id: string,
  message: string,
  customerId?: string | null,
  bookingId?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCommentInput = {
  id: string,
  message?: string | null,
  customerId?: string | null,
  bookingId?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteCommentInput = {
  id: string,
  _version?: number | null,
};

export type CreateServingAreaInput = {
  id?: string | null,
  name: string,
  fees: number,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelServingAreaConditionInput = {
  name?: ModelStringInput | null,
  fees?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelServingAreaConditionInput | null > | null,
  or?: Array< ModelServingAreaConditionInput | null > | null,
  not?: ModelServingAreaConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ServingArea = {
  __typename: "ServingArea",
  id: string,
  name: string,
  fees: number,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateServingAreaInput = {
  id: string,
  name?: string | null,
  fees?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteServingAreaInput = {
  id: string,
  _version?: number | null,
};

export type CreateNotificationInput = {
  id?: string | null,
  accountID: string,
  conceptID: string,
  name?: string | null,
  description?: string | null,
  channel?: string | null,
  message?: string | null,
  from?: string | null,
  enabled?: boolean | null,
  autoSend?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelNotificationConditionInput = {
  accountID?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  channel?: ModelStringInput | null,
  message?: ModelStringInput | null,
  from?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  autoSend?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Notification = {
  __typename: "Notification",
  id: string,
  accountID: string,
  conceptID: string,
  name?: string | null,
  description?: string | null,
  channel?: string | null,
  message?: string | null,
  from?: string | null,
  enabled?: boolean | null,
  autoSend?: boolean | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateNotificationInput = {
  id: string,
  accountID?: string | null,
  conceptID?: string | null,
  name?: string | null,
  description?: string | null,
  channel?: string | null,
  message?: string | null,
  from?: string | null,
  enabled?: boolean | null,
  autoSend?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteNotificationInput = {
  id: string,
  _version?: number | null,
};

export type CreateMobileNotificationInput = {
  id?: string | null,
  title?: string | null,
  description?: string | null,
  body?: string | null,
  default?: boolean | null,
  event?: boolean | null,
  image?: string | null,
  concepts?: Array< string | null > | null,
  condition?: Array< string | null > | null,
  startDate?: string | null,
  endDate?: string | null,
  group?: string | null,
  read?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelMobileNotificationConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  body?: ModelStringInput | null,
  default?: ModelBooleanInput | null,
  event?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  concepts?: ModelStringInput | null,
  condition?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  group?: ModelStringInput | null,
  read?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelMobileNotificationConditionInput | null > | null,
  or?: Array< ModelMobileNotificationConditionInput | null > | null,
  not?: ModelMobileNotificationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type MobileNotification = {
  __typename: "MobileNotification",
  id: string,
  title?: string | null,
  description?: string | null,
  body?: string | null,
  default?: boolean | null,
  event?: boolean | null,
  image?: string | null,
  concepts?: Array< string | null > | null,
  condition?: Array< string | null > | null,
  startDate?: string | null,
  endDate?: string | null,
  group?: string | null,
  read?: boolean | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateMobileNotificationInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  body?: string | null,
  default?: boolean | null,
  event?: boolean | null,
  image?: string | null,
  concepts?: Array< string | null > | null,
  condition?: Array< string | null > | null,
  startDate?: string | null,
  endDate?: string | null,
  group?: string | null,
  read?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteMobileNotificationInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserPushTokenInput = {
  id?: string | null,
  userID?: string | null,
  token?: string | null,
  reciveNotification?: boolean | null,
  type?: string | null,
  os?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelUserPushTokenConditionInput = {
  userID?: ModelStringInput | null,
  token?: ModelStringInput | null,
  reciveNotification?: ModelBooleanInput | null,
  type?: ModelStringInput | null,
  os?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelUserPushTokenConditionInput | null > | null,
  or?: Array< ModelUserPushTokenConditionInput | null > | null,
  not?: ModelUserPushTokenConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UserPushToken = {
  __typename: "UserPushToken",
  id: string,
  userID?: string | null,
  token?: string | null,
  reciveNotification?: boolean | null,
  type?: string | null,
  os?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateUserPushTokenInput = {
  id: string,
  userID?: string | null,
  token?: string | null,
  reciveNotification?: boolean | null,
  type?: string | null,
  os?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteUserPushTokenInput = {
  id: string,
  _version?: number | null,
};

export type CreateNotificationReceiverInput = {
  id?: string | null,
  userID?: string | null,
  notificationID?: string | null,
  seen?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelNotificationReceiverConditionInput = {
  userID?: ModelStringInput | null,
  notificationID?: ModelStringInput | null,
  seen?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelNotificationReceiverConditionInput | null > | null,
  or?: Array< ModelNotificationReceiverConditionInput | null > | null,
  not?: ModelNotificationReceiverConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type NotificationReceiver = {
  __typename: "NotificationReceiver",
  id: string,
  userID?: string | null,
  notificationID?: string | null,
  seen?: boolean | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateNotificationReceiverInput = {
  id: string,
  userID?: string | null,
  notificationID?: string | null,
  seen?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteNotificationReceiverInput = {
  id: string,
  _version?: number | null,
};

export type CreatePreparationAreaInput = {
  id?: string | null,
  accountID: string,
  name: string,
  isAutoAccept?: boolean | null,
  hasKDS?: boolean | null,
  hasPrinter?: boolean | null,
  users?: Array< string | null > | null,
  deleted?: string | null,
  precedence: string,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelPreparationAreaConditionInput = {
  accountID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  isAutoAccept?: ModelBooleanInput | null,
  hasKDS?: ModelBooleanInput | null,
  hasPrinter?: ModelBooleanInput | null,
  users?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelPreparationAreaConditionInput | null > | null,
  or?: Array< ModelPreparationAreaConditionInput | null > | null,
  not?: ModelPreparationAreaConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type PreparationArea = {
  __typename: "PreparationArea",
  id: string,
  accountID: string,
  name: string,
  isAutoAccept?: boolean | null,
  hasKDS?: boolean | null,
  hasPrinter?: boolean | null,
  users?: Array< string | null > | null,
  deleted?: string | null,
  precedence: string,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdatePreparationAreaInput = {
  id: string,
  accountID?: string | null,
  name?: string | null,
  isAutoAccept?: boolean | null,
  hasKDS?: boolean | null,
  hasPrinter?: boolean | null,
  users?: Array< string | null > | null,
  deleted?: string | null,
  precedence?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeletePreparationAreaInput = {
  id: string,
  _version?: number | null,
};

export type CreateQRcodeInput = {
  id?: string | null,
  tableID: string,
  conceptID: string,
  tableName?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelQRcodeConditionInput = {
  tableID?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  tableName?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelQRcodeConditionInput | null > | null,
  or?: Array< ModelQRcodeConditionInput | null > | null,
  not?: ModelQRcodeConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type QRcode = {
  __typename: "QRcode",
  id: string,
  tableID: string,
  conceptID: string,
  tableName?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateQRcodeInput = {
  id: string,
  tableID?: string | null,
  conceptID?: string | null,
  tableName?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteQRcodeInput = {
  id: string,
  _version?: number | null,
};

export type CreateCallWaiterInput = {
  id?: string | null,
  conceptID?: string | null,
  tableID?: string | null,
  tableName?: string | null,
  waiterID?: string | null,
  waiterName?: string | null,
  userID?: string | null,
  count?: number | null,
  status?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  acceptedAt?: string | null,
  arrivedAt?: string | null,
  _version?: number | null,
};

export type ModelCallWaiterConditionInput = {
  conceptID?: ModelIDInput | null,
  tableID?: ModelIDInput | null,
  tableName?: ModelStringInput | null,
  waiterID?: ModelIDInput | null,
  waiterName?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  count?: ModelIntInput | null,
  status?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  acceptedAt?: ModelStringInput | null,
  arrivedAt?: ModelStringInput | null,
  and?: Array< ModelCallWaiterConditionInput | null > | null,
  or?: Array< ModelCallWaiterConditionInput | null > | null,
  not?: ModelCallWaiterConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type CallWaiter = {
  __typename: "CallWaiter",
  id: string,
  conceptID?: string | null,
  tableID?: string | null,
  tableName?: string | null,
  waiterID?: string | null,
  waiterName?: string | null,
  userID?: string | null,
  count?: number | null,
  status?: string | null,
  deleted?: string | null,
  createdAt: string,
  acceptedAt?: string | null,
  arrivedAt?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCallWaiterInput = {
  id: string,
  conceptID?: string | null,
  tableID?: string | null,
  tableName?: string | null,
  waiterID?: string | null,
  waiterName?: string | null,
  userID?: string | null,
  count?: number | null,
  status?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  acceptedAt?: string | null,
  arrivedAt?: string | null,
  _version?: number | null,
};

export type DeleteCallWaiterInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserInput = {
  id?: string | null,
  accountID: string,
  username?: string | null,
  preferred_username?: string | null,
  email?: string | null,
  email_verified?: boolean | null,
  phone_number?: string | null,
  phone_number_verified?: boolean | null,
  name?: string | null,
  nickname?: string | null,
  given_name?: string | null,
  middle_name?: string | null,
  family_name?: string | null,
  address?: string | null,
  birthdate?: string | null,
  gender?: string | null,
  locale?: string | null,
  picture?: string | null,
  pictureCrop?: string | null,
  website?: string | null,
  zoneinfo?: string | null,
  verification_code?: string | null,
  facebook?: string | null,
  instagram?: string | null,
  group?: string | null,
  phones?: Array< string | null > | null,
  flags?: Array< string | null > | null,
  flagsName?: Array< string | null > | null,
  interests?: Array< string | null > | null,
  interestsName?: Array< string | null > | null,
  status?: string | null,
  stats?: Array< string | null > | null,
  spending?: number | null,
  prevOrderDate?: string | null,
  lastOrderDate?: string | null,
  promocodes?: Array< PromocodeItemInput | null > | null,
  mostOrderedItems?: string | null,
  points?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  cognitoUsername?: string | null,
  cognitoSub?: string | null,
  updated?: string | null,
  sub?: string | null,
  _version?: number | null,
};

export type PromocodeItemInput = {
  name: string,
  promocodeId: string,
  canBeUsed: number,
  discountVal: number,
  discountLimit?: number | null,
  isDisplayed?: boolean | null,
};

export type ModelUserConditionInput = {
  accountID?: ModelStringInput | null,
  username?: ModelStringInput | null,
  preferred_username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  email_verified?: ModelBooleanInput | null,
  phone_number?: ModelStringInput | null,
  phone_number_verified?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  given_name?: ModelStringInput | null,
  middle_name?: ModelStringInput | null,
  family_name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  birthdate?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  locale?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  pictureCrop?: ModelStringInput | null,
  website?: ModelStringInput | null,
  zoneinfo?: ModelStringInput | null,
  verification_code?: ModelStringInput | null,
  facebook?: ModelStringInput | null,
  instagram?: ModelStringInput | null,
  group?: ModelStringInput | null,
  phones?: ModelStringInput | null,
  flags?: ModelStringInput | null,
  flagsName?: ModelStringInput | null,
  interests?: ModelStringInput | null,
  interestsName?: ModelStringInput | null,
  status?: ModelStringInput | null,
  stats?: ModelIDInput | null,
  spending?: ModelIntInput | null,
  prevOrderDate?: ModelStringInput | null,
  lastOrderDate?: ModelStringInput | null,
  mostOrderedItems?: ModelStringInput | null,
  points?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  cognitoUsername?: ModelStringInput | null,
  cognitoSub?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  sub?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type User = {
  __typename: "User",
  id: string,
  accountID: string,
  username?: string | null,
  preferred_username?: string | null,
  email?: string | null,
  email_verified?: boolean | null,
  phone_number?: string | null,
  phone_number_verified?: boolean | null,
  name?: string | null,
  nickname?: string | null,
  given_name?: string | null,
  middle_name?: string | null,
  family_name?: string | null,
  address?: string | null,
  birthdate?: string | null,
  gender?: string | null,
  locale?: string | null,
  picture?: string | null,
  pictureCrop?: string | null,
  website?: string | null,
  zoneinfo?: string | null,
  verification_code?: string | null,
  facebook?: string | null,
  instagram?: string | null,
  group?: string | null,
  phones?: Array< string | null > | null,
  flags?: Array< string | null > | null,
  flagsName?: Array< string | null > | null,
  interests?: Array< string | null > | null,
  interestsName?: Array< string | null > | null,
  status?: string | null,
  stats?: Array< string | null > | null,
  spending?: number | null,
  prevOrderDate?: string | null,
  lastOrderDate?: string | null,
  promocodes?:  Array<PromocodeItem | null > | null,
  mostOrderedItems?: string | null,
  points?: number | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  cognitoUsername?: string | null,
  cognitoSub?: string | null,
  updated?: string | null,
  sub?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type PromocodeItem = {
  __typename: "PromocodeItem",
  name: string,
  promocodeId: string,
  canBeUsed: number,
  discountVal: number,
  discountLimit?: number | null,
  isDisplayed?: boolean | null,
};

export type UpdateUserInput = {
  id: string,
  accountID?: string | null,
  username?: string | null,
  preferred_username?: string | null,
  email?: string | null,
  email_verified?: boolean | null,
  phone_number?: string | null,
  phone_number_verified?: boolean | null,
  name?: string | null,
  nickname?: string | null,
  given_name?: string | null,
  middle_name?: string | null,
  family_name?: string | null,
  address?: string | null,
  birthdate?: string | null,
  gender?: string | null,
  locale?: string | null,
  picture?: string | null,
  pictureCrop?: string | null,
  website?: string | null,
  zoneinfo?: string | null,
  verification_code?: string | null,
  facebook?: string | null,
  instagram?: string | null,
  group?: string | null,
  phones?: Array< string | null > | null,
  flags?: Array< string | null > | null,
  flagsName?: Array< string | null > | null,
  interests?: Array< string | null > | null,
  interestsName?: Array< string | null > | null,
  status?: string | null,
  stats?: Array< string | null > | null,
  spending?: number | null,
  prevOrderDate?: string | null,
  lastOrderDate?: string | null,
  promocodes?: Array< PromocodeItemInput | null > | null,
  mostOrderedItems?: string | null,
  points?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  cognitoUsername?: string | null,
  cognitoSub?: string | null,
  updated?: string | null,
  sub?: string | null,
  _version?: number | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateReviewInput = {
  id?: string | null,
  accountID?: string | null,
  bookingID?: string | null,
  guestName?: string | null,
  guestId?: string | null,
  reason?: Array< string | null > | null,
  value?: string | null,
  userID?: string | null,
  orderID?: string | null,
  rate?: number | null,
  satisfaction?: string | null,
  thoughts?: string | null,
  itemReviews?: Array< ItemReviewInput | null > | null,
  photos?: Array< string | null > | null,
  multiLanguages?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ItemReviewInput = {
  itemID: string,
  rate?: number | null,
  satisfaction?: string | null,
  multiLanguages?: string | null,
};

export type ModelReviewConditionInput = {
  accountID?: ModelStringInput | null,
  bookingID?: ModelStringInput | null,
  guestName?: ModelStringInput | null,
  guestId?: ModelStringInput | null,
  reason?: ModelStringInput | null,
  value?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  orderID?: ModelStringInput | null,
  rate?: ModelIntInput | null,
  satisfaction?: ModelStringInput | null,
  thoughts?: ModelStringInput | null,
  photos?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelReviewConditionInput | null > | null,
  or?: Array< ModelReviewConditionInput | null > | null,
  not?: ModelReviewConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Review = {
  __typename: "Review",
  id: string,
  accountID?: string | null,
  bookingID?: string | null,
  guestName?: string | null,
  guestId?: string | null,
  reason?: Array< string | null > | null,
  value?: string | null,
  userID?: string | null,
  orderID?: string | null,
  rate?: number | null,
  satisfaction?: string | null,
  thoughts?: string | null,
  itemReviews?:  Array<ItemReview | null > | null,
  photos?: Array< string | null > | null,
  multiLanguages?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ItemReview = {
  __typename: "ItemReview",
  itemID: string,
  rate?: number | null,
  satisfaction?: string | null,
  multiLanguages?: string | null,
};

export type UpdateReviewInput = {
  id: string,
  accountID?: string | null,
  bookingID?: string | null,
  guestName?: string | null,
  guestId?: string | null,
  reason?: Array< string | null > | null,
  value?: string | null,
  userID?: string | null,
  orderID?: string | null,
  rate?: number | null,
  satisfaction?: string | null,
  thoughts?: string | null,
  itemReviews?: Array< ItemReviewInput | null > | null,
  photos?: Array< string | null > | null,
  multiLanguages?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteReviewInput = {
  id: string,
  _version?: number | null,
};

export type CreateAccountInput = {
  id?: string | null,
  logo?: string | null,
  domain: string,
  siteTitle: string,
  guestsCount?: number | null,
  tagline: string,
  description: string,
  siteAddress: string,
  defaultLanguage: string,
  languages: Array< string | null >,
  features: Array< string | null >,
  status: string,
  socialLinks?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  callCenterEmployeesIds?: Array< string | null > | null,
  kpiReportResetFrequency?: string | null,
  agentBarResetFrequency?: string | null,
  dynamicStatus?: string | null,
  _version?: number | null,
};

export type ModelAccountConditionInput = {
  logo?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  siteTitle?: ModelStringInput | null,
  guestsCount?: ModelIntInput | null,
  tagline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  siteAddress?: ModelStringInput | null,
  defaultLanguage?: ModelStringInput | null,
  languages?: ModelStringInput | null,
  features?: ModelStringInput | null,
  status?: ModelStringInput | null,
  socialLinks?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  callCenterEmployeesIds?: ModelStringInput | null,
  kpiReportResetFrequency?: ModelStringInput | null,
  agentBarResetFrequency?: ModelStringInput | null,
  dynamicStatus?: ModelStringInput | null,
  and?: Array< ModelAccountConditionInput | null > | null,
  or?: Array< ModelAccountConditionInput | null > | null,
  not?: ModelAccountConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Account = {
  __typename: "Account",
  id: string,
  logo?: string | null,
  domain: string,
  siteTitle: string,
  guestsCount?: number | null,
  tagline: string,
  description: string,
  siteAddress: string,
  defaultLanguage: string,
  languages: Array< string | null >,
  features: Array< string | null >,
  status: string,
  socialLinks?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  callCenterEmployeesIds?: Array< string | null > | null,
  kpiReportResetFrequency?: string | null,
  agentBarResetFrequency?: string | null,
  dynamicStatus?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAccountInput = {
  id: string,
  logo?: string | null,
  domain?: string | null,
  siteTitle?: string | null,
  guestsCount?: number | null,
  tagline?: string | null,
  description?: string | null,
  siteAddress?: string | null,
  defaultLanguage?: string | null,
  languages?: Array< string | null > | null,
  features?: Array< string | null > | null,
  status?: string | null,
  socialLinks?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  callCenterEmployeesIds?: Array< string | null > | null,
  kpiReportResetFrequency?: string | null,
  agentBarResetFrequency?: string | null,
  dynamicStatus?: string | null,
  _version?: number | null,
};

export type DeleteAccountInput = {
  id: string,
  _version?: number | null,
};

export type CreateFeatureInput = {
  id?: string | null,
  name: string,
  icon?: string | null,
  slug: string,
  precedence: string,
  parent?: string | null,
  private?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelFeatureConditionInput = {
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  parent?: ModelStringInput | null,
  private?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelFeatureConditionInput | null > | null,
  or?: Array< ModelFeatureConditionInput | null > | null,
  not?: ModelFeatureConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Feature = {
  __typename: "Feature",
  id: string,
  name: string,
  icon?: string | null,
  slug: string,
  precedence: string,
  parent?: string | null,
  private?: boolean | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateFeatureInput = {
  id: string,
  name?: string | null,
  icon?: string | null,
  slug?: string | null,
  precedence?: string | null,
  parent?: string | null,
  private?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteFeatureInput = {
  id: string,
  _version?: number | null,
};

export type CreateMediaInput = {
  id?: string | null,
  accountID: string,
  fileUrl: string,
  filename: string,
  filetype?: string | null,
  fileSize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  attachments?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelMediaConditionInput = {
  accountID?: ModelIDInput | null,
  fileUrl?: ModelStringInput | null,
  filename?: ModelStringInput | null,
  filetype?: ModelStringInput | null,
  fileSize?: ModelIntInput | null,
  alternativeText?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  description?: ModelStringInput | null,
  attachments?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelMediaConditionInput | null > | null,
  or?: Array< ModelMediaConditionInput | null > | null,
  not?: ModelMediaConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Media = {
  __typename: "Media",
  id: string,
  accountID: string,
  fileUrl: string,
  filename: string,
  filetype?: string | null,
  fileSize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  attachments?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateMediaInput = {
  id: string,
  accountID?: string | null,
  fileUrl?: string | null,
  filename?: string | null,
  filetype?: string | null,
  fileSize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  attachments?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteMediaInput = {
  id: string,
  _version?: number | null,
};

export type CreateConceptInput = {
  id?: string | null,
  accountID: string,
  name: string,
  kiosks?: Array< string | null > | null,
  merchantID?: string | null,
  merchantUsername?: string | null,
  merchantPassword?: string | null,
  merchantIntegrationNumber?: string | null,
  merchantAPIKey?: string | null,
  simphonyServerBaseURL?: string | null,
  simphonyShortOrgName?: string | null,
  simphonyLocRef?: string | null,
  simphonyToken?: string | null,
  revenueCenterID?: number | null,
  employeeID?: number | null,
  tenderID?: number | null,
  discountID?: number | null,
  useServiceCharge?: boolean | null,
  serviceChargeId?: number | null,
  specialRequestID?: number | null,
  addressID?: number | null,
  simphonyAuthClientId?: string | null,
  simphonyAuthUsername?: string | null,
  simphonyAuthPassword?: string | null,
  simphonyAuthBaseURL?: string | null,
  orderTypeDineIn?: number | null,
  orderTypeTakeAway?: number | null,
  orderTypeDelivery?: number | null,
  simphonyTakeAwayRvc?: number | null,
  simphonyDeliveryRvc?: number | null,
  exportInterval?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  foodicsToken?: string | null,
  enableTableFoodics?: string | null,
  image?: string | null,
  logo?: string | null,
  album?: Array< string | null > | null,
  type?: string | null,
  rating?: number | null,
  location?: string | null,
  description?: string | null,
  areas?: Array< string | null > | null,
  seatTypes?: Array< string | null > | null,
  needAssistance?: boolean | null,
  spaBooking?: boolean | null,
  showerBooking?: boolean | null,
  cigarMenu?: boolean | null,
  precedence?: number | null,
  isPinRequired?: boolean | null,
  viewOnly?: boolean | null,
  openTime?: string | null,
  closeTime?: string | null,
  varifyForAmount?: number | null,
  varifyFirstOrder?: boolean | null,
  varifyNewAddress?: boolean | null,
  sliderImages?: Array< string | null > | null,
  receiveWaiters?: Array< string | null > | null,
  WaiterNotificationOption?: string | null,
  applyLoyalty?: boolean | null,
  rewardPoints?: number | null,
  moneySpent?: number | null,
  afterVat?: boolean | null,
  afterDeliveryFee?: boolean | null,
  beforePromoCode?: boolean | null,
  pointsCalcWithPromoCode?: boolean | null,
  isBusy?: boolean | null,
  allowOnlineOrdering?: boolean | null,
  minOrderVal?: number | null,
  vatPercentage?: number | null,
  serviceChargePercentage?: number | null,
  addDeliveryToVat?: boolean | null,
  _version?: number | null,
};

export type ModelConceptConditionInput = {
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  kiosks?: ModelIDInput | null,
  merchantID?: ModelStringInput | null,
  merchantUsername?: ModelStringInput | null,
  merchantPassword?: ModelStringInput | null,
  merchantIntegrationNumber?: ModelStringInput | null,
  merchantAPIKey?: ModelStringInput | null,
  simphonyServerBaseURL?: ModelStringInput | null,
  simphonyShortOrgName?: ModelStringInput | null,
  simphonyLocRef?: ModelStringInput | null,
  simphonyToken?: ModelStringInput | null,
  revenueCenterID?: ModelIntInput | null,
  employeeID?: ModelIntInput | null,
  tenderID?: ModelIntInput | null,
  discountID?: ModelIntInput | null,
  useServiceCharge?: ModelBooleanInput | null,
  serviceChargeId?: ModelIntInput | null,
  specialRequestID?: ModelIntInput | null,
  addressID?: ModelIntInput | null,
  simphonyAuthClientId?: ModelStringInput | null,
  simphonyAuthUsername?: ModelStringInput | null,
  simphonyAuthPassword?: ModelStringInput | null,
  simphonyAuthBaseURL?: ModelStringInput | null,
  orderTypeDineIn?: ModelIntInput | null,
  orderTypeTakeAway?: ModelIntInput | null,
  orderTypeDelivery?: ModelIntInput | null,
  simphonyTakeAwayRvc?: ModelIntInput | null,
  simphonyDeliveryRvc?: ModelIntInput | null,
  exportInterval?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  foodicsToken?: ModelStringInput | null,
  enableTableFoodics?: ModelStringInput | null,
  image?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  album?: ModelStringInput | null,
  type?: ModelStringInput | null,
  rating?: ModelFloatInput | null,
  location?: ModelStringInput | null,
  description?: ModelStringInput | null,
  areas?: ModelStringInput | null,
  seatTypes?: ModelStringInput | null,
  needAssistance?: ModelBooleanInput | null,
  spaBooking?: ModelBooleanInput | null,
  showerBooking?: ModelBooleanInput | null,
  cigarMenu?: ModelBooleanInput | null,
  precedence?: ModelIntInput | null,
  isPinRequired?: ModelBooleanInput | null,
  viewOnly?: ModelBooleanInput | null,
  openTime?: ModelStringInput | null,
  closeTime?: ModelStringInput | null,
  varifyForAmount?: ModelIntInput | null,
  varifyFirstOrder?: ModelBooleanInput | null,
  varifyNewAddress?: ModelBooleanInput | null,
  sliderImages?: ModelStringInput | null,
  receiveWaiters?: ModelStringInput | null,
  WaiterNotificationOption?: ModelStringInput | null,
  applyLoyalty?: ModelBooleanInput | null,
  rewardPoints?: ModelIntInput | null,
  moneySpent?: ModelIntInput | null,
  afterVat?: ModelBooleanInput | null,
  afterDeliveryFee?: ModelBooleanInput | null,
  beforePromoCode?: ModelBooleanInput | null,
  pointsCalcWithPromoCode?: ModelBooleanInput | null,
  isBusy?: ModelBooleanInput | null,
  allowOnlineOrdering?: ModelBooleanInput | null,
  minOrderVal?: ModelIntInput | null,
  vatPercentage?: ModelFloatInput | null,
  serviceChargePercentage?: ModelFloatInput | null,
  addDeliveryToVat?: ModelBooleanInput | null,
  and?: Array< ModelConceptConditionInput | null > | null,
  or?: Array< ModelConceptConditionInput | null > | null,
  not?: ModelConceptConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Concept = {
  __typename: "Concept",
  id: string,
  accountID: string,
  name: string,
  kiosks?: Array< string | null > | null,
  merchantID?: string | null,
  merchantUsername?: string | null,
  merchantPassword?: string | null,
  merchantIntegrationNumber?: string | null,
  merchantAPIKey?: string | null,
  simphonyServerBaseURL?: string | null,
  simphonyShortOrgName?: string | null,
  simphonyLocRef?: string | null,
  simphonyToken?: string | null,
  revenueCenterID?: number | null,
  employeeID?: number | null,
  tenderID?: number | null,
  tenders?: ModelTenderConnection | null,
  orderType?: ModelOrderTypeConnection | null,
  discountID?: number | null,
  useServiceCharge?: boolean | null,
  serviceChargeId?: number | null,
  specialRequestID?: number | null,
  addressID?: number | null,
  simphonyAuthClientId?: string | null,
  simphonyAuthUsername?: string | null,
  simphonyAuthPassword?: string | null,
  simphonyAuthBaseURL?: string | null,
  orderTypeDineIn?: number | null,
  orderTypeTakeAway?: number | null,
  orderTypeDelivery?: number | null,
  simphonyTakeAwayRvc?: number | null,
  simphonyDeliveryRvc?: number | null,
  exportInterval?: number | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  foodicsToken?: string | null,
  enableTableFoodics?: string | null,
  image?: string | null,
  logo?: string | null,
  album?: Array< string | null > | null,
  type?: string | null,
  rating?: number | null,
  location?: string | null,
  description?: string | null,
  areas?: Array< string | null > | null,
  seatTypes?: Array< string | null > | null,
  needAssistance?: boolean | null,
  spaBooking?: boolean | null,
  showerBooking?: boolean | null,
  cigarMenu?: boolean | null,
  precedence?: number | null,
  isPinRequired?: boolean | null,
  viewOnly?: boolean | null,
  openTime?: string | null,
  closeTime?: string | null,
  varifyForAmount?: number | null,
  varifyFirstOrder?: boolean | null,
  varifyNewAddress?: boolean | null,
  sliderImages?: Array< string | null > | null,
  receiveWaiters?: Array< string | null > | null,
  WaiterNotificationOption?: string | null,
  applyLoyalty?: boolean | null,
  rewardPoints?: number | null,
  moneySpent?: number | null,
  afterVat?: boolean | null,
  afterDeliveryFee?: boolean | null,
  beforePromoCode?: boolean | null,
  pointsCalcWithPromoCode?: boolean | null,
  isBusy?: boolean | null,
  allowOnlineOrdering?: boolean | null,
  minOrderVal?: number | null,
  vatPercentage?: number | null,
  serviceChargePercentage?: number | null,
  addDeliveryToVat?: boolean | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelTenderConnection = {
  __typename: "ModelTenderConnection",
  items:  Array<Tender | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Tender = {
  __typename: "Tender",
  id: string,
  name: string,
  simphonyID?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  conceptTendersId?: string | null,
};

export type ModelOrderTypeConnection = {
  __typename: "ModelOrderTypeConnection",
  items:  Array<OrderType | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type OrderType = {
  __typename: "OrderType",
  id: string,
  name: string,
  simphonyID?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  conceptOrderTypeId?: string | null,
};

export type UpdateConceptInput = {
  id: string,
  accountID?: string | null,
  name?: string | null,
  kiosks?: Array< string | null > | null,
  merchantID?: string | null,
  merchantUsername?: string | null,
  merchantPassword?: string | null,
  merchantIntegrationNumber?: string | null,
  merchantAPIKey?: string | null,
  simphonyServerBaseURL?: string | null,
  simphonyShortOrgName?: string | null,
  simphonyLocRef?: string | null,
  simphonyToken?: string | null,
  revenueCenterID?: number | null,
  employeeID?: number | null,
  tenderID?: number | null,
  discountID?: number | null,
  useServiceCharge?: boolean | null,
  serviceChargeId?: number | null,
  specialRequestID?: number | null,
  addressID?: number | null,
  simphonyAuthClientId?: string | null,
  simphonyAuthUsername?: string | null,
  simphonyAuthPassword?: string | null,
  simphonyAuthBaseURL?: string | null,
  orderTypeDineIn?: number | null,
  orderTypeTakeAway?: number | null,
  orderTypeDelivery?: number | null,
  simphonyTakeAwayRvc?: number | null,
  simphonyDeliveryRvc?: number | null,
  exportInterval?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  foodicsToken?: string | null,
  enableTableFoodics?: string | null,
  image?: string | null,
  logo?: string | null,
  album?: Array< string | null > | null,
  type?: string | null,
  rating?: number | null,
  location?: string | null,
  description?: string | null,
  areas?: Array< string | null > | null,
  seatTypes?: Array< string | null > | null,
  needAssistance?: boolean | null,
  spaBooking?: boolean | null,
  showerBooking?: boolean | null,
  cigarMenu?: boolean | null,
  precedence?: number | null,
  isPinRequired?: boolean | null,
  viewOnly?: boolean | null,
  openTime?: string | null,
  closeTime?: string | null,
  varifyForAmount?: number | null,
  varifyFirstOrder?: boolean | null,
  varifyNewAddress?: boolean | null,
  sliderImages?: Array< string | null > | null,
  receiveWaiters?: Array< string | null > | null,
  WaiterNotificationOption?: string | null,
  applyLoyalty?: boolean | null,
  rewardPoints?: number | null,
  moneySpent?: number | null,
  afterVat?: boolean | null,
  afterDeliveryFee?: boolean | null,
  beforePromoCode?: boolean | null,
  pointsCalcWithPromoCode?: boolean | null,
  isBusy?: boolean | null,
  allowOnlineOrdering?: boolean | null,
  minOrderVal?: number | null,
  vatPercentage?: number | null,
  serviceChargePercentage?: number | null,
  addDeliveryToVat?: boolean | null,
  _version?: number | null,
};

export type DeleteConceptInput = {
  id: string,
  _version?: number | null,
};

export type CreateTenderInput = {
  id?: string | null,
  name: string,
  simphonyID?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
  conceptTendersId?: string | null,
};

export type ModelTenderConditionInput = {
  name?: ModelStringInput | null,
  simphonyID?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelTenderConditionInput | null > | null,
  or?: Array< ModelTenderConditionInput | null > | null,
  not?: ModelTenderConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  conceptTendersId?: ModelIDInput | null,
};

export type UpdateTenderInput = {
  id: string,
  name?: string | null,
  simphonyID?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
  conceptTendersId?: string | null,
};

export type DeleteTenderInput = {
  id: string,
  _version?: number | null,
};

export type CreateOrderTypeInput = {
  id?: string | null,
  name: string,
  simphonyID?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
  conceptOrderTypeId?: string | null,
};

export type ModelOrderTypeConditionInput = {
  name?: ModelStringInput | null,
  simphonyID?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelOrderTypeConditionInput | null > | null,
  or?: Array< ModelOrderTypeConditionInput | null > | null,
  not?: ModelOrderTypeConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  conceptOrderTypeId?: ModelIDInput | null,
};

export type UpdateOrderTypeInput = {
  id: string,
  name?: string | null,
  simphonyID?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
  conceptOrderTypeId?: string | null,
};

export type DeleteOrderTypeInput = {
  id: string,
  _version?: number | null,
};

export type CreateParentConceptInput = {
  id?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  image?: string | null,
  name?: string | null,
  logo?: string | null,
  type?: string | null,
  venues?: Array< string | null > | null,
  index?: number | null,
  _version?: number | null,
};

export type ModelParentConceptConditionInput = {
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  image?: ModelStringInput | null,
  name?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  type?: ModelStringInput | null,
  venues?: ModelStringInput | null,
  index?: ModelIntInput | null,
  and?: Array< ModelParentConceptConditionInput | null > | null,
  or?: Array< ModelParentConceptConditionInput | null > | null,
  not?: ModelParentConceptConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ParentConcept = {
  __typename: "ParentConcept",
  id: string,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  image?: string | null,
  name?: string | null,
  logo?: string | null,
  type?: string | null,
  venues?: Array< string | null > | null,
  index?: number | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateParentConceptInput = {
  id: string,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  image?: string | null,
  name?: string | null,
  logo?: string | null,
  type?: string | null,
  venues?: Array< string | null > | null,
  index?: number | null,
  _version?: number | null,
};

export type DeleteParentConceptInput = {
  id: string,
  _version?: number | null,
};

export type CreatePlanItemInput = {
  id?: string | null,
  areaID: string,
  conceptID?: string | null,
  xPosition: number,
  yPosition: number,
  name?: string | null,
  isReserved?: boolean | null,
  bookings?: Array< string | null > | null,
  table?: string | null,
  object?: string | null,
  lockDays?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelPlanItemConditionInput = {
  areaID?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  xPosition?: ModelIntInput | null,
  yPosition?: ModelIntInput | null,
  name?: ModelStringInput | null,
  isReserved?: ModelBooleanInput | null,
  bookings?: ModelStringInput | null,
  table?: ModelStringInput | null,
  object?: ModelStringInput | null,
  lockDays?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelPlanItemConditionInput | null > | null,
  or?: Array< ModelPlanItemConditionInput | null > | null,
  not?: ModelPlanItemConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type PlanItem = {
  __typename: "PlanItem",
  id: string,
  areaID: string,
  conceptID?: string | null,
  xPosition: number,
  yPosition: number,
  name?: string | null,
  isReserved?: boolean | null,
  bookings?: Array< string | null > | null,
  table?: string | null,
  object?: string | null,
  lockDays?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdatePlanItemInput = {
  id: string,
  areaID?: string | null,
  conceptID?: string | null,
  xPosition?: number | null,
  yPosition?: number | null,
  name?: string | null,
  isReserved?: boolean | null,
  bookings?: Array< string | null > | null,
  table?: string | null,
  object?: string | null,
  lockDays?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeletePlanItemInput = {
  id: string,
  _version?: number | null,
};

export type CreateObjectInput = {
  id?: string | null,
  conceptID: string,
  label: string,
  name: string,
  width?: number | null,
  height?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
  objectImageId?: string | null,
};

export type ModelObjectConditionInput = {
  conceptID?: ModelStringInput | null,
  label?: ModelStringInput | null,
  name?: ModelStringInput | null,
  width?: ModelIntInput | null,
  height?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelObjectConditionInput | null > | null,
  or?: Array< ModelObjectConditionInput | null > | null,
  not?: ModelObjectConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  objectImageId?: ModelIDInput | null,
};

export type Object = {
  __typename: "Object",
  id: string,
  conceptID: string,
  label: string,
  name: string,
  image?: Attachment | null,
  width?: number | null,
  height?: number | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  objectImageId?: string | null,
};

export type UpdateObjectInput = {
  id: string,
  conceptID?: string | null,
  label?: string | null,
  name?: string | null,
  width?: number | null,
  height?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
  objectImageId?: string | null,
};

export type DeleteObjectInput = {
  id: string,
  _version?: number | null,
};

export type CreateBookingInput = {
  id?: string | null,
  depositValue?: string | null,
  depositStatus?: boolean | null,
  eventStatus?: boolean | null,
  eventName?: string | null,
  paymentStatus?: boolean | null,
  paymentValue?: string | null,
  disablePartialPayment?: boolean | null,
  disablePayment?: boolean | null,
  eventDate?: string | null,
  eventGuests?: Array< string | null > | null,
  timeSlotID?: string | null,
  timeSlots?: Array< string | null > | null,
  conceptID?: string | null,
  mainGuest: string,
  customerName?: string | null,
  customerPhone?: string | null,
  customerGroup?: string | null,
  accompaniedCount?: number | null,
  channel?: string | null,
  statusID?: string | null,
  rating?: number | null,
  review?: Array< string | null > | null,
  confirmationHash?: string | null,
  isConfirmedByUser?: boolean | null,
  date?: string | null,
  tables?: Array< string | null > | null,
  tableName?: string | null,
  comments?: Array< string | null > | null,
  lastComment?: string | null,
  commentCreatedBy?: string | null,
  commentCreatedDate?: string | null,
  timeLeft?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelBookingConditionInput = {
  depositValue?: ModelStringInput | null,
  depositStatus?: ModelBooleanInput | null,
  eventStatus?: ModelBooleanInput | null,
  eventName?: ModelStringInput | null,
  paymentStatus?: ModelBooleanInput | null,
  paymentValue?: ModelStringInput | null,
  disablePartialPayment?: ModelBooleanInput | null,
  disablePayment?: ModelBooleanInput | null,
  eventDate?: ModelStringInput | null,
  eventGuests?: ModelStringInput | null,
  timeSlotID?: ModelStringInput | null,
  timeSlots?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  mainGuest?: ModelStringInput | null,
  customerName?: ModelStringInput | null,
  customerPhone?: ModelStringInput | null,
  customerGroup?: ModelStringInput | null,
  accompaniedCount?: ModelIntInput | null,
  channel?: ModelStringInput | null,
  statusID?: ModelStringInput | null,
  rating?: ModelFloatInput | null,
  review?: ModelStringInput | null,
  confirmationHash?: ModelStringInput | null,
  isConfirmedByUser?: ModelBooleanInput | null,
  date?: ModelStringInput | null,
  tables?: ModelStringInput | null,
  tableName?: ModelStringInput | null,
  comments?: ModelStringInput | null,
  lastComment?: ModelStringInput | null,
  commentCreatedBy?: ModelStringInput | null,
  commentCreatedDate?: ModelStringInput | null,
  timeLeft?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelBookingConditionInput | null > | null,
  or?: Array< ModelBookingConditionInput | null > | null,
  not?: ModelBookingConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Booking = {
  __typename: "Booking",
  id: string,
  depositValue?: string | null,
  depositStatus?: boolean | null,
  eventStatus?: boolean | null,
  eventName?: string | null,
  paymentStatus?: boolean | null,
  paymentValue?: string | null,
  disablePartialPayment?: boolean | null,
  disablePayment?: boolean | null,
  eventDate?: string | null,
  eventGuests?: Array< string | null > | null,
  timeSlotID?: string | null,
  timeSlots?: Array< string | null > | null,
  conceptID?: string | null,
  mainGuest: string,
  customerName?: string | null,
  customerPhone?: string | null,
  customerGroup?: string | null,
  accompaniedCount?: number | null,
  channel?: string | null,
  statusID?: string | null,
  rating?: number | null,
  review?: Array< string | null > | null,
  confirmationHash?: string | null,
  isConfirmedByUser?: boolean | null,
  date?: string | null,
  tables?: Array< string | null > | null,
  tableName?: string | null,
  comments?: Array< string | null > | null,
  lastComment?: string | null,
  commentCreatedBy?: string | null,
  commentCreatedDate?: string | null,
  timeLeft?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateBookingInput = {
  id: string,
  depositValue?: string | null,
  depositStatus?: boolean | null,
  eventStatus?: boolean | null,
  eventName?: string | null,
  paymentStatus?: boolean | null,
  paymentValue?: string | null,
  disablePartialPayment?: boolean | null,
  disablePayment?: boolean | null,
  eventDate?: string | null,
  eventGuests?: Array< string | null > | null,
  timeSlotID?: string | null,
  timeSlots?: Array< string | null > | null,
  conceptID?: string | null,
  mainGuest?: string | null,
  customerName?: string | null,
  customerPhone?: string | null,
  customerGroup?: string | null,
  accompaniedCount?: number | null,
  channel?: string | null,
  statusID?: string | null,
  rating?: number | null,
  review?: Array< string | null > | null,
  confirmationHash?: string | null,
  isConfirmedByUser?: boolean | null,
  date?: string | null,
  tables?: Array< string | null > | null,
  tableName?: string | null,
  comments?: Array< string | null > | null,
  lastComment?: string | null,
  commentCreatedBy?: string | null,
  commentCreatedDate?: string | null,
  timeLeft?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteBookingInput = {
  id: string,
  _version?: number | null,
};

export type CreateTimelineInput = {
  id?: string | null,
  actionName: string,
  oldStatus: string,
  newStatus: string,
  bookingId?: string | null,
  transactionId?: string | null,
  customerId?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelTimelineConditionInput = {
  actionName?: ModelStringInput | null,
  oldStatus?: ModelStringInput | null,
  newStatus?: ModelStringInput | null,
  bookingId?: ModelStringInput | null,
  transactionId?: ModelStringInput | null,
  customerId?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelTimelineConditionInput | null > | null,
  or?: Array< ModelTimelineConditionInput | null > | null,
  not?: ModelTimelineConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Timeline = {
  __typename: "Timeline",
  id: string,
  actionName: string,
  oldStatus: string,
  newStatus: string,
  bookingId?: string | null,
  transactionId?: string | null,
  customerId?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateTimelineInput = {
  id: string,
  actionName?: string | null,
  oldStatus?: string | null,
  newStatus?: string | null,
  bookingId?: string | null,
  transactionId?: string | null,
  customerId?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteTimelineInput = {
  id: string,
  _version?: number | null,
};

export type CreateCallInput = {
  id?: string | null,
  phone_number?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  endedAt?: string | null,
  duration?: string | null,
  date: string,
  employeeId: string,
  _version?: number | null,
  callCustomerId?: string | null,
};

export type ModelCallConditionInput = {
  phone_number?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  endedAt?: ModelStringInput | null,
  duration?: ModelStringInput | null,
  date?: ModelStringInput | null,
  employeeId?: ModelStringInput | null,
  and?: Array< ModelCallConditionInput | null > | null,
  or?: Array< ModelCallConditionInput | null > | null,
  not?: ModelCallConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  callCustomerId?: ModelIDInput | null,
};

export type Call = {
  __typename: "Call",
  id: string,
  phone_number?: string | null,
  customer?: User | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  endedAt?: string | null,
  duration?: string | null,
  date: string,
  employeeId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  callCustomerId?: string | null,
};

export type UpdateCallInput = {
  id: string,
  phone_number?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  endedAt?: string | null,
  duration?: string | null,
  date?: string | null,
  employeeId?: string | null,
  _version?: number | null,
  callCustomerId?: string | null,
};

export type DeleteCallInput = {
  id: string,
  _version?: number | null,
};

export type CreateAdminRoleInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelAdminRoleConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAdminRoleConditionInput | null > | null,
  or?: Array< ModelAdminRoleConditionInput | null > | null,
  not?: ModelAdminRoleConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type AdminRole = {
  __typename: "AdminRole",
  id: string,
  name?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAdminRoleInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteAdminRoleInput = {
  id: string,
  _version?: number | null,
};

export type CreateAdminGroupInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  roles?: Array< string | null > | null,
  users?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelAdminGroupConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  roles?: ModelIDInput | null,
  users?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAdminGroupConditionInput | null > | null,
  or?: Array< ModelAdminGroupConditionInput | null > | null,
  not?: ModelAdminGroupConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type AdminGroup = {
  __typename: "AdminGroup",
  id: string,
  name?: string | null,
  description?: string | null,
  roles?: Array< string | null > | null,
  users?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAdminGroupInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  roles?: Array< string | null > | null,
  users?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteAdminGroupInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserConceptsInput = {
  id?: string | null,
  defaultConcept?: string | null,
  concepts?: Array< string | null > | null,
  conceptsRoles?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelUserConceptsConditionInput = {
  defaultConcept?: ModelIDInput | null,
  concepts?: ModelIDInput | null,
  conceptsRoles?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelUserConceptsConditionInput | null > | null,
  or?: Array< ModelUserConceptsConditionInput | null > | null,
  not?: ModelUserConceptsConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UserConcepts = {
  __typename: "UserConcepts",
  id: string,
  defaultConcept?: string | null,
  concepts?: Array< string | null > | null,
  conceptsRoles?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateUserConceptsInput = {
  id: string,
  defaultConcept?: string | null,
  concepts?: Array< string | null > | null,
  conceptsRoles?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteUserConceptsInput = {
  id: string,
  _version?: number | null,
};

export type CreateTransactionInput = {
  id?: string | null,
  transactionID: string,
  date: string,
  guestName: string,
  guestPhone: string,
  guestID: string,
  guestGroup: string,
  guestsNames?: Array< string | null > | null,
  bookingID?: string | null,
  bookingDate?: string | null,
  amount_cents: string,
  status: boolean,
  failureReason?: string | null,
  type: string,
  timeSlots?: Array< string | null > | null,
  tables?: Array< string | null > | null,
  conceptID: string,
  currency: string,
  refund: boolean,
  reference_orderID?: string | null,
  ownerID: string,
  refunded_amount_cents: string,
  deleted?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelTransactionConditionInput = {
  transactionID?: ModelStringInput | null,
  date?: ModelStringInput | null,
  guestName?: ModelStringInput | null,
  guestPhone?: ModelStringInput | null,
  guestID?: ModelStringInput | null,
  guestGroup?: ModelStringInput | null,
  guestsNames?: ModelStringInput | null,
  bookingID?: ModelStringInput | null,
  bookingDate?: ModelStringInput | null,
  amount_cents?: ModelStringInput | null,
  status?: ModelBooleanInput | null,
  failureReason?: ModelStringInput | null,
  type?: ModelStringInput | null,
  timeSlots?: ModelStringInput | null,
  tables?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  refund?: ModelBooleanInput | null,
  reference_orderID?: ModelStringInput | null,
  ownerID?: ModelStringInput | null,
  refunded_amount_cents?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTransactionConditionInput | null > | null,
  or?: Array< ModelTransactionConditionInput | null > | null,
  not?: ModelTransactionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Transaction = {
  __typename: "Transaction",
  id: string,
  transactionID: string,
  date: string,
  guestName: string,
  guestPhone: string,
  guestID: string,
  guestGroup: string,
  guestsNames?: Array< string | null > | null,
  bookingID?: string | null,
  bookingDate?: string | null,
  amount_cents: string,
  status: boolean,
  failureReason?: string | null,
  type: string,
  timeSlots?: Array< string | null > | null,
  tables?: Array< string | null > | null,
  conceptID: string,
  currency: string,
  refund: boolean,
  reference_orderID?: string | null,
  ownerID: string,
  refunded_amount_cents: string,
  deleted?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateTransactionInput = {
  id: string,
  transactionID?: string | null,
  date?: string | null,
  guestName?: string | null,
  guestPhone?: string | null,
  guestID?: string | null,
  guestGroup?: string | null,
  guestsNames?: Array< string | null > | null,
  bookingID?: string | null,
  bookingDate?: string | null,
  amount_cents?: string | null,
  status?: boolean | null,
  failureReason?: string | null,
  type?: string | null,
  timeSlots?: Array< string | null > | null,
  tables?: Array< string | null > | null,
  conceptID?: string | null,
  currency?: string | null,
  refund?: boolean | null,
  reference_orderID?: string | null,
  ownerID?: string | null,
  refunded_amount_cents?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteTransactionInput = {
  id: string,
  _version?: number | null,
};

export type CreatePaymentStatInput = {
  id?: string | null,
  accountID?: string | null,
  conceptID: string,
  year: number,
  month: number,
  monthName: string,
  earning: number,
  refund: number,
  earningTrxCount: number,
  refundTrxCount: number,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type ModelPaymentStatConditionInput = {
  accountID?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  year?: ModelIntInput | null,
  month?: ModelIntInput | null,
  monthName?: ModelStringInput | null,
  earning?: ModelFloatInput | null,
  refund?: ModelFloatInput | null,
  earningTrxCount?: ModelIntInput | null,
  refundTrxCount?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelPaymentStatConditionInput | null > | null,
  or?: Array< ModelPaymentStatConditionInput | null > | null,
  not?: ModelPaymentStatConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type PaymentStat = {
  __typename: "PaymentStat",
  id: string,
  accountID?: string | null,
  conceptID: string,
  year: number,
  month: number,
  monthName: string,
  earning: number,
  refund: number,
  earningTrxCount: number,
  refundTrxCount: number,
  deleted?: string | null,
  createdAt: string,
  createdByID?: string | null,
  createdByName?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdatePaymentStatInput = {
  id: string,
  accountID?: string | null,
  conceptID?: string | null,
  year?: number | null,
  month?: number | null,
  monthName?: string | null,
  earning?: number | null,
  refund?: number | null,
  earningTrxCount?: number | null,
  refundTrxCount?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeletePaymentStatInput = {
  id: string,
  _version?: number | null,
};

export type CreateBookingGuestInput = {
  id?: string | null,
  bookingID: string,
  guestName: string,
  paidAmount: number,
  paymentAmount: number,
  paymentStatus: boolean,
  transactionID: string,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelBookingGuestConditionInput = {
  bookingID?: ModelStringInput | null,
  guestName?: ModelStringInput | null,
  paidAmount?: ModelFloatInput | null,
  paymentAmount?: ModelFloatInput | null,
  paymentStatus?: ModelBooleanInput | null,
  transactionID?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelBookingGuestConditionInput | null > | null,
  or?: Array< ModelBookingGuestConditionInput | null > | null,
  not?: ModelBookingGuestConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type BookingGuest = {
  __typename: "BookingGuest",
  id: string,
  bookingID: string,
  guestName: string,
  paidAmount: number,
  paymentAmount: number,
  paymentStatus: boolean,
  transactionID: string,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateBookingGuestInput = {
  id: string,
  bookingID?: string | null,
  guestName?: string | null,
  paidAmount?: number | null,
  paymentAmount?: number | null,
  paymentStatus?: boolean | null,
  transactionID?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteBookingGuestInput = {
  id: string,
  _version?: number | null,
};

export type CreateCategoryInput = {
  id?: string | null,
  conceptID: string,
  preparationAreaID?: string | null,
  subCategory?: string | null,
  name: string,
  guestView?: boolean | null,
  guestOrder?: boolean | null,
  staffOrder?: boolean | null,
  image?: string | null,
  darkImage?: string | null,
  precedence: string,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  multiLanguages?: string | null,
  symphonyID?: string | null,
  isHidden?: boolean | null,
  _version?: number | null,
};

export type ModelCategoryConditionInput = {
  conceptID?: ModelIDInput | null,
  preparationAreaID?: ModelIDInput | null,
  subCategory?: ModelIDInput | null,
  name?: ModelStringInput | null,
  guestView?: ModelBooleanInput | null,
  guestOrder?: ModelBooleanInput | null,
  staffOrder?: ModelBooleanInput | null,
  image?: ModelIDInput | null,
  darkImage?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  symphonyID?: ModelStringInput | null,
  isHidden?: ModelBooleanInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Category = {
  __typename: "Category",
  id: string,
  conceptID: string,
  preparationAreaID?: string | null,
  subCategory?: string | null,
  name: string,
  guestView?: boolean | null,
  guestOrder?: boolean | null,
  staffOrder?: boolean | null,
  image?: string | null,
  darkImage?: string | null,
  menuItems?: ModelMenuItemConnection | null,
  precedence: string,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  multiLanguages?: string | null,
  symphonyID?: string | null,
  isHidden?: boolean | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelMenuItemConnection = {
  __typename: "ModelMenuItemConnection",
  items:  Array<MenuItem | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type MenuItem = {
  __typename: "MenuItem",
  id: string,
  conceptID: string,
  kioskID?: string | null,
  name: string,
  description?: string | null,
  image?: string | null,
  precedence?: string | null,
  categoryID: string,
  categoryName?: string | null,
  outOfStock?: boolean | null,
  requiredPoints?: number | null,
  symphonyID?: string | null,
  comboItemID?: string | null,
  ratings?: Array< string | null > | null,
  prices?: Array< string | null > | null,
  choiceGroups?: Array< string | null > | null,
  choiceGroupsRules?:  Array<ChoiceGroupRules | null > | null,
  ExtraChoiceGroups?: Array< string | null > | null,
  enabled?: boolean | null,
  hasLimitedQuantity?: boolean | null,
  quantity?: number | null,
  autoRestock?: boolean | null,
  restockQuantity?: number | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  quantityOrdered?: number | null,
  performancePerDay?: Array< string | null > | null,
  multiLanguages?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ChoiceGroupRules = {
  __typename: "ChoiceGroupRules",
  id: string,
  minNumberOfChoices?: number | null,
  maxNumberOfChoices?: number | null,
};

export type UpdateCategoryInput = {
  id: string,
  conceptID?: string | null,
  preparationAreaID?: string | null,
  subCategory?: string | null,
  name?: string | null,
  guestView?: boolean | null,
  guestOrder?: boolean | null,
  staffOrder?: boolean | null,
  image?: string | null,
  darkImage?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  multiLanguages?: string | null,
  symphonyID?: string | null,
  isHidden?: boolean | null,
  _version?: number | null,
};

export type DeleteCategoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateMenuItemInput = {
  id?: string | null,
  conceptID: string,
  kioskID?: string | null,
  name: string,
  description?: string | null,
  image?: string | null,
  precedence?: string | null,
  categoryID: string,
  categoryName?: string | null,
  outOfStock?: boolean | null,
  requiredPoints?: number | null,
  symphonyID?: string | null,
  comboItemID?: string | null,
  ratings?: Array< string | null > | null,
  prices?: Array< string | null > | null,
  choiceGroups?: Array< string | null > | null,
  choiceGroupsRules?: Array< ChoiceGroupRulesInput | null > | null,
  ExtraChoiceGroups?: Array< string | null > | null,
  enabled?: boolean | null,
  hasLimitedQuantity?: boolean | null,
  quantity?: number | null,
  autoRestock?: boolean | null,
  restockQuantity?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  quantityOrdered?: number | null,
  performancePerDay?: Array< string | null > | null,
  multiLanguages?: string | null,
  _version?: number | null,
};

export type ChoiceGroupRulesInput = {
  id: string,
  minNumberOfChoices?: number | null,
  maxNumberOfChoices?: number | null,
};

export type ModelMenuItemConditionInput = {
  conceptID?: ModelIDInput | null,
  kioskID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  image?: ModelIDInput | null,
  precedence?: ModelStringInput | null,
  categoryID?: ModelIDInput | null,
  categoryName?: ModelStringInput | null,
  outOfStock?: ModelBooleanInput | null,
  requiredPoints?: ModelIntInput | null,
  symphonyID?: ModelStringInput | null,
  comboItemID?: ModelStringInput | null,
  ratings?: ModelIDInput | null,
  prices?: ModelIDInput | null,
  choiceGroups?: ModelIDInput | null,
  ExtraChoiceGroups?: ModelIDInput | null,
  enabled?: ModelBooleanInput | null,
  hasLimitedQuantity?: ModelBooleanInput | null,
  quantity?: ModelIntInput | null,
  autoRestock?: ModelBooleanInput | null,
  restockQuantity?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  quantityOrdered?: ModelIntInput | null,
  performancePerDay?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  and?: Array< ModelMenuItemConditionInput | null > | null,
  or?: Array< ModelMenuItemConditionInput | null > | null,
  not?: ModelMenuItemConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateMenuItemInput = {
  id: string,
  conceptID?: string | null,
  kioskID?: string | null,
  name?: string | null,
  description?: string | null,
  image?: string | null,
  precedence?: string | null,
  categoryID?: string | null,
  categoryName?: string | null,
  outOfStock?: boolean | null,
  requiredPoints?: number | null,
  symphonyID?: string | null,
  comboItemID?: string | null,
  ratings?: Array< string | null > | null,
  prices?: Array< string | null > | null,
  choiceGroups?: Array< string | null > | null,
  choiceGroupsRules?: Array< ChoiceGroupRulesInput | null > | null,
  ExtraChoiceGroups?: Array< string | null > | null,
  enabled?: boolean | null,
  hasLimitedQuantity?: boolean | null,
  quantity?: number | null,
  autoRestock?: boolean | null,
  restockQuantity?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  quantityOrdered?: number | null,
  performancePerDay?: Array< string | null > | null,
  multiLanguages?: string | null,
  _version?: number | null,
};

export type DeleteMenuItemInput = {
  id: string,
  _version?: number | null,
};

export type CreatePriceInput = {
  id?: string | null,
  conceptID: string,
  name: string,
  multiLanguages?: string | null,
  price: number,
  symphonyID: string,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelPriceConditionInput = {
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  symphonyID?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelPriceConditionInput | null > | null,
  or?: Array< ModelPriceConditionInput | null > | null,
  not?: ModelPriceConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Price = {
  __typename: "Price",
  id: string,
  conceptID: string,
  name: string,
  multiLanguages?: string | null,
  price: number,
  symphonyID: string,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdatePriceInput = {
  id: string,
  conceptID?: string | null,
  name?: string | null,
  multiLanguages?: string | null,
  price?: number | null,
  symphonyID?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeletePriceInput = {
  id: string,
  _version?: number | null,
};

export type CreateChoiceGroupsInput = {
  id?: string | null,
  conceptID: string,
  name: string,
  required?: boolean | null,
  maxNumberOfChoices?: number | null,
  minNumberOfChoices?: number | null,
  precedence?: string | null,
  type?: string | null,
  choices?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  multiLanguages?: string | null,
  simphonyID?: string | null,
  _version?: number | null,
};

export type ModelChoiceGroupsConditionInput = {
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  required?: ModelBooleanInput | null,
  maxNumberOfChoices?: ModelIntInput | null,
  minNumberOfChoices?: ModelIntInput | null,
  precedence?: ModelStringInput | null,
  type?: ModelStringInput | null,
  choices?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  simphonyID?: ModelStringInput | null,
  and?: Array< ModelChoiceGroupsConditionInput | null > | null,
  or?: Array< ModelChoiceGroupsConditionInput | null > | null,
  not?: ModelChoiceGroupsConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ChoiceGroups = {
  __typename: "ChoiceGroups",
  id: string,
  conceptID: string,
  name: string,
  required?: boolean | null,
  maxNumberOfChoices?: number | null,
  minNumberOfChoices?: number | null,
  precedence?: string | null,
  type?: string | null,
  choices?: Array< string | null > | null,
  choiceItems?: ModelChoiceConnection | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  multiLanguages?: string | null,
  simphonyID?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelChoiceConnection = {
  __typename: "ModelChoiceConnection",
  items:  Array<Choice | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Choice = {
  __typename: "Choice",
  id: string,
  conceptID: string,
  name: string,
  price: number,
  precedence?: string | null,
  choiceGroupID: string,
  choiceGroupName?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  symphonyID?: string | null,
  multiLanguages?: string | null,
  definitionNumber?: number | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateChoiceGroupsInput = {
  id: string,
  conceptID?: string | null,
  name?: string | null,
  required?: boolean | null,
  maxNumberOfChoices?: number | null,
  minNumberOfChoices?: number | null,
  precedence?: string | null,
  type?: string | null,
  choices?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  multiLanguages?: string | null,
  simphonyID?: string | null,
  _version?: number | null,
};

export type DeleteChoiceGroupsInput = {
  id: string,
  _version?: number | null,
};

export type CreateChoiceInput = {
  id?: string | null,
  conceptID: string,
  name: string,
  price: number,
  precedence?: string | null,
  choiceGroupID: string,
  choiceGroupName?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  symphonyID?: string | null,
  multiLanguages?: string | null,
  definitionNumber?: number | null,
  _version?: number | null,
};

export type ModelChoiceConditionInput = {
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  precedence?: ModelStringInput | null,
  choiceGroupID?: ModelIDInput | null,
  choiceGroupName?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  symphonyID?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  definitionNumber?: ModelIntInput | null,
  and?: Array< ModelChoiceConditionInput | null > | null,
  or?: Array< ModelChoiceConditionInput | null > | null,
  not?: ModelChoiceConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateChoiceInput = {
  id: string,
  conceptID?: string | null,
  name?: string | null,
  price?: number | null,
  precedence?: string | null,
  choiceGroupID?: string | null,
  choiceGroupName?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  symphonyID?: string | null,
  multiLanguages?: string | null,
  definitionNumber?: number | null,
  _version?: number | null,
};

export type DeleteChoiceInput = {
  id: string,
  _version?: number | null,
};

export type CreateCartInput = {
  id?: string | null,
  conceptID: string,
  userID: string,
  orderedItems?: Array< CartItemInput | null > | null,
  specialRequest?: string | null,
  deliveryFee?: number | null,
  subTotal?: number | null,
  subTaxTotal?: number | null,
  totalPrice?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type CartItemInput = {
  menuItemID: string,
  symphonyID?: string | null,
  preparationAreaID?: string | null,
  name?: string | null,
  quantity: number,
  choiceIDs?: Array< string | null > | null,
  choiceNames?: Array< string | null > | null,
  choiceSymphonyID?: Array< string | null > | null,
  extraChoiceIDs?: Array< string | null > | null,
  extraChoiceSymphonyID?: Array< string | null > | null,
  price: number,
  priceName?: string | null,
  priceID?: string | null,
  priceMultiLanguages?: string | null,
  multiLanguages?: string | null,
  notes?: string | null,
  image?: string | null,
  comboItemID?: string | null,
};

export type ModelCartConditionInput = {
  conceptID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  specialRequest?: ModelStringInput | null,
  deliveryFee?: ModelFloatInput | null,
  subTotal?: ModelFloatInput | null,
  subTaxTotal?: ModelFloatInput | null,
  totalPrice?: ModelFloatInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCartConditionInput | null > | null,
  or?: Array< ModelCartConditionInput | null > | null,
  not?: ModelCartConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Cart = {
  __typename: "Cart",
  id: string,
  conceptID: string,
  userID: string,
  orderedItems?:  Array<CartItem | null > | null,
  specialRequest?: string | null,
  deliveryFee?: number | null,
  subTotal?: number | null,
  subTaxTotal?: number | null,
  totalPrice?: number | null,
  deleted?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type CartItem = {
  __typename: "CartItem",
  menuItemID: string,
  symphonyID?: string | null,
  preparationAreaID?: string | null,
  name?: string | null,
  quantity: number,
  choiceIDs?: Array< string | null > | null,
  choiceNames?: Array< string | null > | null,
  choiceSymphonyID?: Array< string | null > | null,
  extraChoiceIDs?: Array< string | null > | null,
  extraChoiceSymphonyID?: Array< string | null > | null,
  price: number,
  priceName?: string | null,
  priceID?: string | null,
  priceMultiLanguages?: string | null,
  multiLanguages?: string | null,
  notes?: string | null,
  image?: string | null,
  comboItemID?: string | null,
};

export type UpdateCartInput = {
  id: string,
  conceptID?: string | null,
  userID?: string | null,
  orderedItems?: Array< CartItemInput | null > | null,
  specialRequest?: string | null,
  deliveryFee?: number | null,
  subTotal?: number | null,
  subTaxTotal?: number | null,
  totalPrice?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteCartInput = {
  id: string,
  _version?: number | null,
};

export type CreateAdminStatusInput = {
  id?: string | null,
  sub: string,
  accountID: string,
  name?: string | null,
  averageCallTime?: number | null,
  numberOfCalls?: number | null,
  idelDuration?: number | null,
  activeDuration?: number | null,
  awayDuration?: number | null,
  lastCallTime?: number | null,
  agentBarAverageCallTime?: number | null,
  agentBarNumberOfCalls?: number | null,
  type?: string | null,
  waiterStatus?: string | null,
  totalAverageRatio?: number | null,
  performancePerDay?: Array< string | null > | null,
  _version?: number | null,
};

export type ModelAdminStatusConditionInput = {
  sub?: ModelStringInput | null,
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  averageCallTime?: ModelFloatInput | null,
  numberOfCalls?: ModelFloatInput | null,
  idelDuration?: ModelFloatInput | null,
  activeDuration?: ModelFloatInput | null,
  awayDuration?: ModelFloatInput | null,
  lastCallTime?: ModelFloatInput | null,
  agentBarAverageCallTime?: ModelFloatInput | null,
  agentBarNumberOfCalls?: ModelFloatInput | null,
  type?: ModelStringInput | null,
  waiterStatus?: ModelStringInput | null,
  totalAverageRatio?: ModelFloatInput | null,
  performancePerDay?: ModelStringInput | null,
  and?: Array< ModelAdminStatusConditionInput | null > | null,
  or?: Array< ModelAdminStatusConditionInput | null > | null,
  not?: ModelAdminStatusConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type AdminStatus = {
  __typename: "AdminStatus",
  id: string,
  sub: string,
  accountID: string,
  name?: string | null,
  averageCallTime?: number | null,
  numberOfCalls?: number | null,
  idelDuration?: number | null,
  activeDuration?: number | null,
  awayDuration?: number | null,
  lastCallTime?: number | null,
  agentBarAverageCallTime?: number | null,
  agentBarNumberOfCalls?: number | null,
  type?: string | null,
  waiterStatus?: string | null,
  totalAverageRatio?: number | null,
  performancePerDay?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAdminStatusInput = {
  id: string,
  sub?: string | null,
  accountID?: string | null,
  name?: string | null,
  averageCallTime?: number | null,
  numberOfCalls?: number | null,
  idelDuration?: number | null,
  activeDuration?: number | null,
  awayDuration?: number | null,
  lastCallTime?: number | null,
  agentBarAverageCallTime?: number | null,
  agentBarNumberOfCalls?: number | null,
  type?: string | null,
  waiterStatus?: string | null,
  totalAverageRatio?: number | null,
  performancePerDay?: Array< string | null > | null,
  _version?: number | null,
};

export type DeleteAdminStatusInput = {
  id: string,
  _version?: number | null,
};

export type CreateOnlineOrderInput = {
  id?: string | null,
  conceptID: string,
  statusID?: string | null,
  status?: string | null,
  reason?: string | null,
  orderType?: string | null,
  channel?: string | null,
  userID: string,
  userName?: string | null,
  userPhoneNumber?: string | null,
  dispatcherID?: string | null,
  dispatcherName?: string | null,
  cartID?: string | null,
  tenderId?: number | null,
  employeeId?: number | null,
  rvcId?: number | null,
  checkNumber?: string | null,
  totalAmount?: number | null,
  discountedValue?: number | null,
  subTotal?: number | null,
  subTaxTotal?: number | null,
  tax?: number | null,
  deliveryFee?: number | null,
  orderTime?: string | null,
  executionArn?: string | null,
  specialRequest?: string | null,
  orderAddress?: string | null,
  orderedItems?: Array< CartItemInput | null > | null,
  checkRef?: string | null,
  discountID?: number | null,
  amount_cents?: string | null,
  transactionID?: string | null,
  paymentStatus?: boolean | null,
  paymentType?: string | null,
  referenceId?: string | null,
  cardHolderNumber?: string | null,
  cardHolderName?: string | null,
  cardSchemeName?: string | null,
  deleted?: string | null,
  acceptedByWaiterID?: string | null,
  acceptedByWaiterName?: string | null,
  deliveredByWaiterID?: string | null,
  tableID?: string | null,
  tableName?: string | null,
  shift?: string | null,
  parentOrder?: string | null,
  preparationAreaID?: string | null,
  childrenStatus?: Array< string | null > | null,
  createdAt?: string | null,
  acceptedByWaiterAt?: string | null,
  sentToKitchenAt?: string | null,
  acceptedByKitchenAt?: string | null,
  readyAt?: string | null,
  outForDeliveryAt?: string | null,
  deliveredAt?: string | null,
  promoCodeID?: string | null,
  promoCodeName?: string | null,
  promoCodeAppliedTo?: string | null,
  reviewID?: string | null,
  simphEmpID?: string | null,
  createdByID: string,
  createdByName: string,
  tmpField?: string | null,
  updatedByID?: string | null,
  updatedByName?: string | null,
  _version?: number | null,
};

export type ModelOnlineOrderConditionInput = {
  conceptID?: ModelIDInput | null,
  statusID?: ModelIDInput | null,
  status?: ModelStringInput | null,
  reason?: ModelStringInput | null,
  orderType?: ModelStringInput | null,
  channel?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  userName?: ModelStringInput | null,
  userPhoneNumber?: ModelStringInput | null,
  dispatcherID?: ModelStringInput | null,
  dispatcherName?: ModelStringInput | null,
  cartID?: ModelIDInput | null,
  tenderId?: ModelIntInput | null,
  employeeId?: ModelIntInput | null,
  rvcId?: ModelIntInput | null,
  checkNumber?: ModelStringInput | null,
  totalAmount?: ModelFloatInput | null,
  discountedValue?: ModelFloatInput | null,
  subTotal?: ModelFloatInput | null,
  subTaxTotal?: ModelFloatInput | null,
  tax?: ModelFloatInput | null,
  deliveryFee?: ModelFloatInput | null,
  orderTime?: ModelStringInput | null,
  executionArn?: ModelStringInput | null,
  specialRequest?: ModelStringInput | null,
  orderAddress?: ModelStringInput | null,
  checkRef?: ModelStringInput | null,
  discountID?: ModelIntInput | null,
  amount_cents?: ModelStringInput | null,
  transactionID?: ModelStringInput | null,
  paymentStatus?: ModelBooleanInput | null,
  paymentType?: ModelStringInput | null,
  referenceId?: ModelStringInput | null,
  cardHolderNumber?: ModelStringInput | null,
  cardHolderName?: ModelStringInput | null,
  cardSchemeName?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  acceptedByWaiterID?: ModelStringInput | null,
  acceptedByWaiterName?: ModelStringInput | null,
  deliveredByWaiterID?: ModelStringInput | null,
  tableID?: ModelStringInput | null,
  tableName?: ModelStringInput | null,
  shift?: ModelStringInput | null,
  parentOrder?: ModelIDInput | null,
  preparationAreaID?: ModelIDInput | null,
  childrenStatus?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  acceptedByWaiterAt?: ModelStringInput | null,
  sentToKitchenAt?: ModelStringInput | null,
  acceptedByKitchenAt?: ModelStringInput | null,
  readyAt?: ModelStringInput | null,
  outForDeliveryAt?: ModelStringInput | null,
  deliveredAt?: ModelStringInput | null,
  promoCodeID?: ModelIDInput | null,
  promoCodeName?: ModelStringInput | null,
  promoCodeAppliedTo?: ModelStringInput | null,
  reviewID?: ModelIDInput | null,
  simphEmpID?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  tmpField?: ModelStringInput | null,
  updatedByID?: ModelStringInput | null,
  updatedByName?: ModelStringInput | null,
  and?: Array< ModelOnlineOrderConditionInput | null > | null,
  or?: Array< ModelOnlineOrderConditionInput | null > | null,
  not?: ModelOnlineOrderConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type OnlineOrder = {
  __typename: "OnlineOrder",
  id: string,
  conceptID: string,
  statusID?: string | null,
  status?: string | null,
  reason?: string | null,
  orderType?: string | null,
  channel?: string | null,
  userID: string,
  userName?: string | null,
  userPhoneNumber?: string | null,
  dispatcherID?: string | null,
  dispatcherName?: string | null,
  cartID?: string | null,
  tenderId?: number | null,
  employeeId?: number | null,
  rvcId?: number | null,
  checkNumber?: string | null,
  totalAmount?: number | null,
  discountedValue?: number | null,
  subTotal?: number | null,
  subTaxTotal?: number | null,
  tax?: number | null,
  deliveryFee?: number | null,
  orderTime?: string | null,
  executionArn?: string | null,
  specialRequest?: string | null,
  orderAddress?: string | null,
  orderedItems?:  Array<CartItem | null > | null,
  checkRef?: string | null,
  discountID?: number | null,
  amount_cents?: string | null,
  transactionID?: string | null,
  paymentStatus?: boolean | null,
  paymentType?: string | null,
  referenceId?: string | null,
  cardHolderNumber?: string | null,
  cardHolderName?: string | null,
  cardSchemeName?: string | null,
  deleted?: string | null,
  acceptedByWaiterID?: string | null,
  acceptedByWaiterName?: string | null,
  deliveredByWaiterID?: string | null,
  tableID?: string | null,
  tableName?: string | null,
  shift?: string | null,
  parentOrder?: string | null,
  preparationAreaID?: string | null,
  childrenStatus?: Array< string | null > | null,
  createdAt: string,
  acceptedByWaiterAt?: string | null,
  sentToKitchenAt?: string | null,
  acceptedByKitchenAt?: string | null,
  readyAt?: string | null,
  outForDeliveryAt?: string | null,
  deliveredAt?: string | null,
  promoCodeID?: string | null,
  promoCodeName?: string | null,
  promoCodeAppliedTo?: string | null,
  promoCode?: Promocode | null,
  reviewID?: string | null,
  review?: Review | null,
  simphEmpID?: string | null,
  createdByID: string,
  createdByName: string,
  tmpField?: string | null,
  updatedByID?: string | null,
  updatedByName?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Promocode = {
  __typename: "Promocode",
  id: string,
  name: string,
  redemptions: number,
  status?: string | null,
  startDate: string,
  endDate: string,
  appliesTo: string,
  minOrderReq: string,
  minOrderVal?: number | null,
  minOrderQty?: number | null,
  tenderReq: Array< string | null >,
  discountVal: number,
  discountLimit?: number | null,
  quota?: number | null,
  quotaUsed?: number | null,
  totalRevenue?: number | null,
  appliedDiscountedVal?: number | null,
  usePerCustomer?: number | null,
  channelReq: Array< string | null >,
  customerEligibility: string,
  customerReq?: Array< string | null > | null,
  spendingExceeds?: number | null,
  spendingBelow?: number | null,
  orderSinceDate?: string | null,
  orderFromDate?: string | null,
  orderToDate?: string | null,
  isDisplayed?: boolean | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateOnlineOrderInput = {
  id: string,
  conceptID?: string | null,
  statusID?: string | null,
  status?: string | null,
  reason?: string | null,
  orderType?: string | null,
  channel?: string | null,
  userID?: string | null,
  userName?: string | null,
  userPhoneNumber?: string | null,
  dispatcherID?: string | null,
  dispatcherName?: string | null,
  cartID?: string | null,
  tenderId?: number | null,
  employeeId?: number | null,
  rvcId?: number | null,
  checkNumber?: string | null,
  totalAmount?: number | null,
  discountedValue?: number | null,
  subTotal?: number | null,
  subTaxTotal?: number | null,
  tax?: number | null,
  deliveryFee?: number | null,
  orderTime?: string | null,
  executionArn?: string | null,
  specialRequest?: string | null,
  orderAddress?: string | null,
  orderedItems?: Array< CartItemInput | null > | null,
  checkRef?: string | null,
  discountID?: number | null,
  amount_cents?: string | null,
  transactionID?: string | null,
  paymentStatus?: boolean | null,
  paymentType?: string | null,
  referenceId?: string | null,
  cardHolderNumber?: string | null,
  cardHolderName?: string | null,
  cardSchemeName?: string | null,
  deleted?: string | null,
  acceptedByWaiterID?: string | null,
  acceptedByWaiterName?: string | null,
  deliveredByWaiterID?: string | null,
  tableID?: string | null,
  tableName?: string | null,
  shift?: string | null,
  parentOrder?: string | null,
  preparationAreaID?: string | null,
  childrenStatus?: Array< string | null > | null,
  createdAt?: string | null,
  acceptedByWaiterAt?: string | null,
  sentToKitchenAt?: string | null,
  acceptedByKitchenAt?: string | null,
  readyAt?: string | null,
  outForDeliveryAt?: string | null,
  deliveredAt?: string | null,
  promoCodeID?: string | null,
  promoCodeName?: string | null,
  promoCodeAppliedTo?: string | null,
  reviewID?: string | null,
  simphEmpID?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  tmpField?: string | null,
  updatedByID?: string | null,
  updatedByName?: string | null,
  _version?: number | null,
};

export type DeleteOnlineOrderInput = {
  id: string,
  _version?: number | null,
};

export type CreateOrderStatusInput = {
  id?: string | null,
  conceptID: string,
  name: string,
  color?: string | null,
  precedence?: number | null,
  accessibleStatus?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelOrderStatusConditionInput = {
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  color?: ModelStringInput | null,
  precedence?: ModelIntInput | null,
  accessibleStatus?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelOrderStatusConditionInput | null > | null,
  or?: Array< ModelOrderStatusConditionInput | null > | null,
  not?: ModelOrderStatusConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type OrderStatus = {
  __typename: "OrderStatus",
  id: string,
  conceptID: string,
  name: string,
  color?: string | null,
  precedence?: number | null,
  accessibleStatus?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateOrderStatusInput = {
  id: string,
  conceptID?: string | null,
  name?: string | null,
  color?: string | null,
  precedence?: number | null,
  accessibleStatus?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteOrderStatusInput = {
  id: string,
  _version?: number | null,
};

export type CreateDiscountCouponInput = {
  id?: string | null,
  discountPercent: string,
  usedBy?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelDiscountCouponConditionInput = {
  discountPercent?: ModelStringInput | null,
  usedBy?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelDiscountCouponConditionInput | null > | null,
  or?: Array< ModelDiscountCouponConditionInput | null > | null,
  not?: ModelDiscountCouponConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type DiscountCoupon = {
  __typename: "DiscountCoupon",
  id: string,
  discountPercent: string,
  usedBy?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateDiscountCouponInput = {
  id: string,
  discountPercent?: string | null,
  usedBy?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteDiscountCouponInput = {
  id: string,
  _version?: number | null,
};

export type CreateZoneInput = {
  id?: string | null,
  conceptID: string,
  location: string,
  deliveryFee: number,
  concepts?: Array< ZoneConceptInput | null > | null,
  polygon?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ZoneConceptInput = {
  id: string,
  deliveryFee: number,
};

export type ModelZoneConditionInput = {
  conceptID?: ModelIDInput | null,
  location?: ModelStringInput | null,
  deliveryFee?: ModelIntInput | null,
  polygon?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelZoneConditionInput | null > | null,
  or?: Array< ModelZoneConditionInput | null > | null,
  not?: ModelZoneConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Zone = {
  __typename: "Zone",
  id: string,
  conceptID: string,
  location: string,
  deliveryFee: number,
  concepts?:  Array<ZoneConcept | null > | null,
  polygon?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ZoneConcept = {
  __typename: "ZoneConcept",
  id: string,
  deliveryFee: number,
};

export type UpdateZoneInput = {
  id: string,
  conceptID?: string | null,
  location?: string | null,
  deliveryFee?: number | null,
  concepts?: Array< ZoneConceptInput | null > | null,
  polygon?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteZoneInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserIPInput = {
  id?: string | null,
  ip?: string | null,
  date?: string | null,
  _version?: number | null,
};

export type ModelUserIPConditionInput = {
  ip?: ModelStringInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelUserIPConditionInput | null > | null,
  or?: Array< ModelUserIPConditionInput | null > | null,
  not?: ModelUserIPConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UserIP = {
  __typename: "UserIP",
  id: string,
  ip?: string | null,
  date?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateUserIPInput = {
  id: string,
  ip?: string | null,
  date?: string | null,
  _version?: number | null,
};

export type DeleteUserIPInput = {
  id: string,
  _version?: number | null,
};

export type CreateMainCategoryInput = {
  id?: string | null,
  conceptID: string,
  name: string,
  precedence: string,
  openTime?: string | null,
  closeTime?: string | null,
  guestView?: boolean | null,
  guestOrder?: boolean | null,
  staffOrder?: boolean | null,
  image?: string | null,
  menuPDF?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  multiLanguages?: string | null,
  _version?: number | null,
};

export type ModelMainCategoryConditionInput = {
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  openTime?: ModelStringInput | null,
  closeTime?: ModelStringInput | null,
  guestView?: ModelBooleanInput | null,
  guestOrder?: ModelBooleanInput | null,
  staffOrder?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  menuPDF?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  and?: Array< ModelMainCategoryConditionInput | null > | null,
  or?: Array< ModelMainCategoryConditionInput | null > | null,
  not?: ModelMainCategoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type MainCategory = {
  __typename: "MainCategory",
  id: string,
  conceptID: string,
  name: string,
  precedence: string,
  openTime?: string | null,
  closeTime?: string | null,
  guestView?: boolean | null,
  guestOrder?: boolean | null,
  staffOrder?: boolean | null,
  image?: string | null,
  menuPDF?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  multiLanguages?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateMainCategoryInput = {
  id: string,
  conceptID?: string | null,
  name?: string | null,
  precedence?: string | null,
  openTime?: string | null,
  closeTime?: string | null,
  guestView?: boolean | null,
  guestOrder?: boolean | null,
  staffOrder?: boolean | null,
  image?: string | null,
  menuPDF?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  multiLanguages?: string | null,
  _version?: number | null,
};

export type DeleteMainCategoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateSubCategoryInput = {
  id?: string | null,
  conceptID: string,
  name: string,
  precedence: string,
  openTime?: string | null,
  closeTime?: string | null,
  mainCategoryID?: string | null,
  guestView?: boolean | null,
  guestOrder?: boolean | null,
  staffOrder?: boolean | null,
  image?: string | null,
  menuPDF?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  multiLanguages?: string | null,
  _version?: number | null,
};

export type ModelSubCategoryConditionInput = {
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  openTime?: ModelStringInput | null,
  closeTime?: ModelStringInput | null,
  mainCategoryID?: ModelIDInput | null,
  guestView?: ModelBooleanInput | null,
  guestOrder?: ModelBooleanInput | null,
  staffOrder?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  menuPDF?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  and?: Array< ModelSubCategoryConditionInput | null > | null,
  or?: Array< ModelSubCategoryConditionInput | null > | null,
  not?: ModelSubCategoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type SubCategory = {
  __typename: "SubCategory",
  id: string,
  conceptID: string,
  name: string,
  precedence: string,
  openTime?: string | null,
  closeTime?: string | null,
  mainCategoryID?: string | null,
  guestView?: boolean | null,
  guestOrder?: boolean | null,
  staffOrder?: boolean | null,
  image?: string | null,
  menuPDF?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  multiLanguages?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateSubCategoryInput = {
  id: string,
  conceptID?: string | null,
  name?: string | null,
  precedence?: string | null,
  openTime?: string | null,
  closeTime?: string | null,
  mainCategoryID?: string | null,
  guestView?: boolean | null,
  guestOrder?: boolean | null,
  staffOrder?: boolean | null,
  image?: string | null,
  menuPDF?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  multiLanguages?: string | null,
  _version?: number | null,
};

export type DeleteSubCategoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateShiftInput = {
  id?: string | null,
  accountID: string,
  name: string,
  precedence: string,
  startTime: string,
  endTime: string,
  users?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelShiftConditionInput = {
  accountID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  users?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelShiftConditionInput | null > | null,
  or?: Array< ModelShiftConditionInput | null > | null,
  not?: ModelShiftConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Shift = {
  __typename: "Shift",
  id: string,
  accountID: string,
  name: string,
  precedence: string,
  startTime: string,
  endTime: string,
  users?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateShiftInput = {
  id: string,
  accountID?: string | null,
  name?: string | null,
  precedence?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  users?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteShiftInput = {
  id: string,
  _version?: number | null,
};

export type CreateChannelInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  numberOfOrders?: number | null,
  isActive?: boolean | null,
  multiLanguages?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelChannelConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  numberOfOrders?: ModelIntInput | null,
  isActive?: ModelBooleanInput | null,
  multiLanguages?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelChannelConditionInput | null > | null,
  or?: Array< ModelChannelConditionInput | null > | null,
  not?: ModelChannelConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Channel = {
  __typename: "Channel",
  id: string,
  name: string,
  description?: string | null,
  numberOfOrders?: number | null,
  isActive?: boolean | null,
  multiLanguages?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateChannelInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  numberOfOrders?: number | null,
  isActive?: boolean | null,
  multiLanguages?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteChannelInput = {
  id: string,
  _version?: number | null,
};

export type CreatePromocodeInput = {
  id?: string | null,
  name: string,
  redemptions: number,
  status?: string | null,
  startDate: string,
  endDate: string,
  appliesTo: string,
  minOrderReq: string,
  minOrderVal?: number | null,
  minOrderQty?: number | null,
  tenderReq: Array< string | null >,
  discountVal: number,
  discountLimit?: number | null,
  quota?: number | null,
  quotaUsed?: number | null,
  totalRevenue?: number | null,
  appliedDiscountedVal?: number | null,
  usePerCustomer?: number | null,
  channelReq: Array< string | null >,
  customerEligibility: string,
  customerReq?: Array< string | null > | null,
  spendingExceeds?: number | null,
  spendingBelow?: number | null,
  orderSinceDate?: string | null,
  orderFromDate?: string | null,
  orderToDate?: string | null,
  isDisplayed?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelPromocodeConditionInput = {
  name?: ModelStringInput | null,
  redemptions?: ModelIntInput | null,
  status?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  appliesTo?: ModelStringInput | null,
  minOrderReq?: ModelStringInput | null,
  minOrderVal?: ModelIntInput | null,
  minOrderQty?: ModelIntInput | null,
  tenderReq?: ModelStringInput | null,
  discountVal?: ModelIntInput | null,
  discountLimit?: ModelIntInput | null,
  quota?: ModelIntInput | null,
  quotaUsed?: ModelIntInput | null,
  totalRevenue?: ModelIntInput | null,
  appliedDiscountedVal?: ModelIntInput | null,
  usePerCustomer?: ModelIntInput | null,
  channelReq?: ModelStringInput | null,
  customerEligibility?: ModelStringInput | null,
  customerReq?: ModelStringInput | null,
  spendingExceeds?: ModelIntInput | null,
  spendingBelow?: ModelIntInput | null,
  orderSinceDate?: ModelStringInput | null,
  orderFromDate?: ModelStringInput | null,
  orderToDate?: ModelStringInput | null,
  isDisplayed?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelPromocodeConditionInput | null > | null,
  or?: Array< ModelPromocodeConditionInput | null > | null,
  not?: ModelPromocodeConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdatePromocodeInput = {
  id: string,
  name?: string | null,
  redemptions?: number | null,
  status?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  appliesTo?: string | null,
  minOrderReq?: string | null,
  minOrderVal?: number | null,
  minOrderQty?: number | null,
  tenderReq?: Array< string | null > | null,
  discountVal?: number | null,
  discountLimit?: number | null,
  quota?: number | null,
  quotaUsed?: number | null,
  totalRevenue?: number | null,
  appliedDiscountedVal?: number | null,
  usePerCustomer?: number | null,
  channelReq?: Array< string | null > | null,
  customerEligibility?: string | null,
  customerReq?: Array< string | null > | null,
  spendingExceeds?: number | null,
  spendingBelow?: number | null,
  orderSinceDate?: string | null,
  orderFromDate?: string | null,
  orderToDate?: string | null,
  isDisplayed?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeletePromocodeInput = {
  id: string,
  _version?: number | null,
};

export type CreatePromocodeDashboardInput = {
  id?: string | null,
  conceptID: string,
  totalRevenue?: number | null,
  totalRedemptions?: number | null,
  totalDiscount?: number | null,
  _version?: number | null,
};

export type ModelPromocodeDashboardConditionInput = {
  conceptID?: ModelIDInput | null,
  totalRevenue?: ModelIntInput | null,
  totalRedemptions?: ModelIntInput | null,
  totalDiscount?: ModelIntInput | null,
  and?: Array< ModelPromocodeDashboardConditionInput | null > | null,
  or?: Array< ModelPromocodeDashboardConditionInput | null > | null,
  not?: ModelPromocodeDashboardConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type PromocodeDashboard = {
  __typename: "PromocodeDashboard",
  id: string,
  conceptID: string,
  totalRevenue?: number | null,
  totalRedemptions?: number | null,
  totalDiscount?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdatePromocodeDashboardInput = {
  id: string,
  conceptID?: string | null,
  totalRevenue?: number | null,
  totalRedemptions?: number | null,
  totalDiscount?: number | null,
  _version?: number | null,
};

export type DeletePromocodeDashboardInput = {
  id: string,
  _version?: number | null,
};

export type CreateLoyaltyItemInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  startDate: string,
  endDate?: string | null,
  points: number,
  redemptionPerUser?: number | null,
  image?: string | null,
  simphonyID: string,
  multiLanguages?: string | null,
  isActive?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelLoyaltyItemConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  points?: ModelIntInput | null,
  redemptionPerUser?: ModelIntInput | null,
  image?: ModelStringInput | null,
  simphonyID?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  isActive?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelLoyaltyItemConditionInput | null > | null,
  or?: Array< ModelLoyaltyItemConditionInput | null > | null,
  not?: ModelLoyaltyItemConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type LoyaltyItem = {
  __typename: "LoyaltyItem",
  id: string,
  name: string,
  description?: string | null,
  startDate: string,
  endDate?: string | null,
  points: number,
  redemptionPerUser?: number | null,
  image?: string | null,
  simphonyID: string,
  multiLanguages?: string | null,
  isActive?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateLoyaltyItemInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  points?: number | null,
  redemptionPerUser?: number | null,
  image?: string | null,
  simphonyID?: string | null,
  multiLanguages?: string | null,
  isActive?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteLoyaltyItemInput = {
  id: string,
  _version?: number | null,
};

export type CreateOTPInput = {
  id?: string | null,
  OTP: string,
  redeemed?: boolean | null,
  deleted?: string | null,
  userID: string,
  conceptID?: string | null,
  loyaltyItemID?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelOTPConditionInput = {
  OTP?: ModelStringInput | null,
  redeemed?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  loyaltyItemID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelOTPConditionInput | null > | null,
  or?: Array< ModelOTPConditionInput | null > | null,
  not?: ModelOTPConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type OTP = {
  __typename: "OTP",
  id: string,
  OTP: string,
  redeemed?: boolean | null,
  deleted?: string | null,
  userID: string,
  conceptID?: string | null,
  loyaltyItemID?: string | null,
  loyaltyItem?: LoyaltyItem | null,
  user?: User | null,
  concept?: Concept | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateOTPInput = {
  id: string,
  OTP?: string | null,
  redeemed?: boolean | null,
  deleted?: string | null,
  userID?: string | null,
  conceptID?: string | null,
  loyaltyItemID?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteOTPInput = {
  id: string,
  _version?: number | null,
};

export type ModelEmailVerificationFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  verification_code?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  and?: Array< ModelEmailVerificationFilterInput | null > | null,
  or?: Array< ModelEmailVerificationFilterInput | null > | null,
  not?: ModelEmailVerificationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelEmailVerificationConnection = {
  __typename: "ModelEmailVerificationConnection",
  items:  Array<EmailVerification | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSocialLinkFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelSocialLinkFilterInput | null > | null,
  or?: Array< ModelSocialLinkFilterInput | null > | null,
  not?: ModelSocialLinkFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSocialLinkConnection = {
  __typename: "ModelSocialLinkConnection",
  items:  Array<SocialLink | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelLanguageFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelLanguageFilterInput | null > | null,
  or?: Array< ModelLanguageFilterInput | null > | null,
  not?: ModelLanguageFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLanguageConnection = {
  __typename: "ModelLanguageConnection",
  items:  Array<Language | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAttachmentFilterInput = {
  id?: ModelIDInput | null,
  mediaID?: ModelIDInput | null,
  fileUrl?: ModelStringInput | null,
  filename?: ModelStringInput | null,
  filetype?: ModelStringInput | null,
  fileSize?: ModelIntInput | null,
  alternativeText?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAttachmentFilterInput | null > | null,
  or?: Array< ModelAttachmentFilterInput | null > | null,
  not?: ModelAttachmentFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAttachmentConnection = {
  __typename: "ModelAttachmentConnection",
  items:  Array<Attachment | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelGroupFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  color?: ModelStringInput | null,
  requireApproval?: ModelBooleanInput | null,
  status?: ModelStringInput | null,
  agents?: ModelStringInput | null,
  groupSettings?: ModelStringInput | null,
  numToUpgrade?: ModelIntInput | null,
  numToDowngrade?: ModelIntInput | null,
  upgradeGroup?: ModelIDInput | null,
  downgradeGroup?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelGroupFilterInput | null > | null,
  or?: Array< ModelGroupFilterInput | null > | null,
  not?: ModelGroupFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelGroupConnection = {
  __typename: "ModelGroupConnection",
  items:  Array<Group | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelGroupSettingFilterInput = {
  id?: ModelIDInput | null,
  groupID?: ModelStringInput | null,
  statusID?: ModelIDInput | null,
  needsVerification?: ModelBooleanInput | null,
  needsDeposit?: ModelBooleanInput | null,
  isDefault?: ModelBooleanInput | null,
  amount?: ModelIntInput | null,
  days?: ModelStringInput | null,
  timeSlots?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelGroupSettingFilterInput | null > | null,
  or?: Array< ModelGroupSettingFilterInput | null > | null,
  not?: ModelGroupSettingFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelGroupSettingConnection = {
  __typename: "ModelGroupSettingConnection",
  items:  Array<GroupSetting | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCustomerStatsFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  totalReservation?: ModelIntInput | null,
  confirmed?: ModelIntInput | null,
  notConfirmed?: ModelIntInput | null,
  canceled?: ModelIntInput | null,
  checkIn?: ModelIntInput | null,
  noShow?: ModelIntInput | null,
  userID?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelCustomerStatsFilterInput | null > | null,
  or?: Array< ModelCustomerStatsFilterInput | null > | null,
  not?: ModelCustomerStatsFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCustomerStatsConnection = {
  __typename: "ModelCustomerStatsConnection",
  items:  Array<CustomerStats | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFlagFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  color?: ModelStringInput | null,
  customers?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelFlagFilterInput | null > | null,
  or?: Array< ModelFlagFilterInput | null > | null,
  not?: ModelFlagFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFlagConnection = {
  __typename: "ModelFlagConnection",
  items:  Array<Flag | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelInterestFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  color?: ModelStringInput | null,
  customers?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelInterestFilterInput | null > | null,
  or?: Array< ModelInterestFilterInput | null > | null,
  not?: ModelInterestFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelInterestConnection = {
  __typename: "ModelInterestConnection",
  items:  Array<Interest | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelReservationStatusFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  category?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelReservationStatusFilterInput | null > | null,
  or?: Array< ModelReservationStatusFilterInput | null > | null,
  not?: ModelReservationStatusFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  reservationStatusImageId?: ModelIDInput | null,
};

export type ModelReservationStatusConnection = {
  __typename: "ModelReservationStatusConnection",
  items:  Array<ReservationStatus | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTimeSlotFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  friendlyName?: ModelStringInput | null,
  bookings?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelTimeSlotFilterInput | null > | null,
  or?: Array< ModelTimeSlotFilterInput | null > | null,
  not?: ModelTimeSlotFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTimeSlotConnection = {
  __typename: "ModelTimeSlotConnection",
  items:  Array<TimeSlot | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAreaFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  isDefault?: ModelBooleanInput | null,
  expiryDate?: ModelStringInput | null,
  days?: ModelStringInput | null,
  timeSlots?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAreaFilterInput | null > | null,
  or?: Array< ModelAreaFilterInput | null > | null,
  not?: ModelAreaFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAreaConnection = {
  __typename: "ModelAreaConnection",
  items:  Array<Area | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTableFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelStringInput | null,
  label?: ModelStringInput | null,
  name?: ModelStringInput | null,
  width?: ModelIntInput | null,
  height?: ModelIntInput | null,
  capacity?: ModelIntInput | null,
  image?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelTableFilterInput | null > | null,
  or?: Array< ModelTableFilterInput | null > | null,
  not?: ModelTableFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTableConnection = {
  __typename: "ModelTableConnection",
  items:  Array<Table | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFriendFilterInput = {
  id?: ModelIDInput | null,
  customerId?: ModelStringInput | null,
  friendId?: ModelStringInput | null,
  bookingID?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelFriendFilterInput | null > | null,
  or?: Array< ModelFriendFilterInput | null > | null,
  not?: ModelFriendFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFriendConnection = {
  __typename: "ModelFriendConnection",
  items:  Array<Friend | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCommentFilterInput = {
  id?: ModelIDInput | null,
  message?: ModelStringInput | null,
  customerId?: ModelStringInput | null,
  bookingId?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelCommentFilterInput | null > | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  not?: ModelCommentFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelServingAreaFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  fees?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelServingAreaFilterInput | null > | null,
  or?: Array< ModelServingAreaFilterInput | null > | null,
  not?: ModelServingAreaFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelServingAreaConnection = {
  __typename: "ModelServingAreaConnection",
  items:  Array<ServingArea | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  channel?: ModelStringInput | null,
  message?: ModelStringInput | null,
  from?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  autoSend?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMobileNotificationFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  body?: ModelStringInput | null,
  default?: ModelBooleanInput | null,
  event?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  concepts?: ModelStringInput | null,
  condition?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  group?: ModelStringInput | null,
  read?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelMobileNotificationFilterInput | null > | null,
  or?: Array< ModelMobileNotificationFilterInput | null > | null,
  not?: ModelMobileNotificationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelMobileNotificationConnection = {
  __typename: "ModelMobileNotificationConnection",
  items:  Array<MobileNotification | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserPushTokenFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelStringInput | null,
  token?: ModelStringInput | null,
  reciveNotification?: ModelBooleanInput | null,
  type?: ModelStringInput | null,
  os?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelUserPushTokenFilterInput | null > | null,
  or?: Array< ModelUserPushTokenFilterInput | null > | null,
  not?: ModelUserPushTokenFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserPushTokenConnection = {
  __typename: "ModelUserPushTokenConnection",
  items:  Array<UserPushToken | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelNotificationReceiverFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelStringInput | null,
  notificationID?: ModelStringInput | null,
  seen?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelNotificationReceiverFilterInput | null > | null,
  or?: Array< ModelNotificationReceiverFilterInput | null > | null,
  not?: ModelNotificationReceiverFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelNotificationReceiverConnection = {
  __typename: "ModelNotificationReceiverConnection",
  items:  Array<NotificationReceiver | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPreparationAreaFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  isAutoAccept?: ModelBooleanInput | null,
  hasKDS?: ModelBooleanInput | null,
  hasPrinter?: ModelBooleanInput | null,
  users?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelPreparationAreaFilterInput | null > | null,
  or?: Array< ModelPreparationAreaFilterInput | null > | null,
  not?: ModelPreparationAreaFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPreparationAreaConnection = {
  __typename: "ModelPreparationAreaConnection",
  items:  Array<PreparationArea | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelQRcodeFilterInput = {
  id?: ModelIDInput | null,
  tableID?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  tableName?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelQRcodeFilterInput | null > | null,
  or?: Array< ModelQRcodeFilterInput | null > | null,
  not?: ModelQRcodeFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelQRcodeConnection = {
  __typename: "ModelQRcodeConnection",
  items:  Array<QRcode | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCallWaiterFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  tableID?: ModelIDInput | null,
  tableName?: ModelStringInput | null,
  waiterID?: ModelIDInput | null,
  waiterName?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  count?: ModelIntInput | null,
  status?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  acceptedAt?: ModelStringInput | null,
  arrivedAt?: ModelStringInput | null,
  and?: Array< ModelCallWaiterFilterInput | null > | null,
  or?: Array< ModelCallWaiterFilterInput | null > | null,
  not?: ModelCallWaiterFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCallWaiterConnection = {
  __typename: "ModelCallWaiterConnection",
  items:  Array<CallWaiter | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  username?: ModelStringInput | null,
  preferred_username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  email_verified?: ModelBooleanInput | null,
  phone_number?: ModelStringInput | null,
  phone_number_verified?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  given_name?: ModelStringInput | null,
  middle_name?: ModelStringInput | null,
  family_name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  birthdate?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  locale?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  pictureCrop?: ModelStringInput | null,
  website?: ModelStringInput | null,
  zoneinfo?: ModelStringInput | null,
  verification_code?: ModelStringInput | null,
  facebook?: ModelStringInput | null,
  instagram?: ModelStringInput | null,
  group?: ModelStringInput | null,
  phones?: ModelStringInput | null,
  flags?: ModelStringInput | null,
  flagsName?: ModelStringInput | null,
  interests?: ModelStringInput | null,
  interestsName?: ModelStringInput | null,
  status?: ModelStringInput | null,
  stats?: ModelIDInput | null,
  spending?: ModelIntInput | null,
  prevOrderDate?: ModelStringInput | null,
  lastOrderDate?: ModelStringInput | null,
  mostOrderedItems?: ModelStringInput | null,
  points?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  cognitoUsername?: ModelStringInput | null,
  cognitoSub?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  sub?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
  scannedCount?: number | null,
  count?: number | null,
};

export type ModelUserUsersByPhoneNumberCompositeKeyConditionInput = {
  eq?: ModelUserUsersByPhoneNumberCompositeKeyInput | null,
  le?: ModelUserUsersByPhoneNumberCompositeKeyInput | null,
  lt?: ModelUserUsersByPhoneNumberCompositeKeyInput | null,
  ge?: ModelUserUsersByPhoneNumberCompositeKeyInput | null,
  gt?: ModelUserUsersByPhoneNumberCompositeKeyInput | null,
  between?: Array< ModelUserUsersByPhoneNumberCompositeKeyInput | null > | null,
  beginsWith?: ModelUserUsersByPhoneNumberCompositeKeyInput | null,
};

export type ModelUserUsersByPhoneNumberCompositeKeyInput = {
  deleted?: string | null,
  name?: string | null,
  createdAt?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelReviewFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  bookingID?: ModelStringInput | null,
  guestName?: ModelStringInput | null,
  guestId?: ModelStringInput | null,
  reason?: ModelStringInput | null,
  value?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  orderID?: ModelStringInput | null,
  rate?: ModelIntInput | null,
  satisfaction?: ModelStringInput | null,
  thoughts?: ModelStringInput | null,
  photos?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelReviewFilterInput | null > | null,
  or?: Array< ModelReviewFilterInput | null > | null,
  not?: ModelReviewFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelReviewConnection = {
  __typename: "ModelReviewConnection",
  items:  Array<Review | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAccountFilterInput = {
  id?: ModelIDInput | null,
  logo?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  siteTitle?: ModelStringInput | null,
  guestsCount?: ModelIntInput | null,
  tagline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  siteAddress?: ModelStringInput | null,
  defaultLanguage?: ModelStringInput | null,
  languages?: ModelStringInput | null,
  features?: ModelStringInput | null,
  status?: ModelStringInput | null,
  socialLinks?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  callCenterEmployeesIds?: ModelStringInput | null,
  kpiReportResetFrequency?: ModelStringInput | null,
  agentBarResetFrequency?: ModelStringInput | null,
  dynamicStatus?: ModelStringInput | null,
  and?: Array< ModelAccountFilterInput | null > | null,
  or?: Array< ModelAccountFilterInput | null > | null,
  not?: ModelAccountFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAccountConnection = {
  __typename: "ModelAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFeatureFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  parent?: ModelStringInput | null,
  private?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelFeatureFilterInput | null > | null,
  or?: Array< ModelFeatureFilterInput | null > | null,
  not?: ModelFeatureFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFeatureConnection = {
  __typename: "ModelFeatureConnection",
  items:  Array<Feature | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMediaFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelIDInput | null,
  fileUrl?: ModelStringInput | null,
  filename?: ModelStringInput | null,
  filetype?: ModelStringInput | null,
  fileSize?: ModelIntInput | null,
  alternativeText?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  description?: ModelStringInput | null,
  attachments?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelMediaFilterInput | null > | null,
  or?: Array< ModelMediaFilterInput | null > | null,
  not?: ModelMediaFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelMediaConnection = {
  __typename: "ModelMediaConnection",
  items:  Array<Media | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelConceptFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  kiosks?: ModelIDInput | null,
  merchantID?: ModelStringInput | null,
  merchantUsername?: ModelStringInput | null,
  merchantPassword?: ModelStringInput | null,
  merchantIntegrationNumber?: ModelStringInput | null,
  merchantAPIKey?: ModelStringInput | null,
  simphonyServerBaseURL?: ModelStringInput | null,
  simphonyShortOrgName?: ModelStringInput | null,
  simphonyLocRef?: ModelStringInput | null,
  simphonyToken?: ModelStringInput | null,
  revenueCenterID?: ModelIntInput | null,
  employeeID?: ModelIntInput | null,
  tenderID?: ModelIntInput | null,
  discountID?: ModelIntInput | null,
  useServiceCharge?: ModelBooleanInput | null,
  serviceChargeId?: ModelIntInput | null,
  specialRequestID?: ModelIntInput | null,
  addressID?: ModelIntInput | null,
  simphonyAuthClientId?: ModelStringInput | null,
  simphonyAuthUsername?: ModelStringInput | null,
  simphonyAuthPassword?: ModelStringInput | null,
  simphonyAuthBaseURL?: ModelStringInput | null,
  orderTypeDineIn?: ModelIntInput | null,
  orderTypeTakeAway?: ModelIntInput | null,
  orderTypeDelivery?: ModelIntInput | null,
  simphonyTakeAwayRvc?: ModelIntInput | null,
  simphonyDeliveryRvc?: ModelIntInput | null,
  exportInterval?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  foodicsToken?: ModelStringInput | null,
  enableTableFoodics?: ModelStringInput | null,
  image?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  album?: ModelStringInput | null,
  type?: ModelStringInput | null,
  rating?: ModelFloatInput | null,
  location?: ModelStringInput | null,
  description?: ModelStringInput | null,
  areas?: ModelStringInput | null,
  seatTypes?: ModelStringInput | null,
  needAssistance?: ModelBooleanInput | null,
  spaBooking?: ModelBooleanInput | null,
  showerBooking?: ModelBooleanInput | null,
  cigarMenu?: ModelBooleanInput | null,
  precedence?: ModelIntInput | null,
  isPinRequired?: ModelBooleanInput | null,
  viewOnly?: ModelBooleanInput | null,
  openTime?: ModelStringInput | null,
  closeTime?: ModelStringInput | null,
  varifyForAmount?: ModelIntInput | null,
  varifyFirstOrder?: ModelBooleanInput | null,
  varifyNewAddress?: ModelBooleanInput | null,
  sliderImages?: ModelStringInput | null,
  receiveWaiters?: ModelStringInput | null,
  WaiterNotificationOption?: ModelStringInput | null,
  applyLoyalty?: ModelBooleanInput | null,
  rewardPoints?: ModelIntInput | null,
  moneySpent?: ModelIntInput | null,
  afterVat?: ModelBooleanInput | null,
  afterDeliveryFee?: ModelBooleanInput | null,
  beforePromoCode?: ModelBooleanInput | null,
  pointsCalcWithPromoCode?: ModelBooleanInput | null,
  isBusy?: ModelBooleanInput | null,
  allowOnlineOrdering?: ModelBooleanInput | null,
  minOrderVal?: ModelIntInput | null,
  vatPercentage?: ModelFloatInput | null,
  serviceChargePercentage?: ModelFloatInput | null,
  addDeliveryToVat?: ModelBooleanInput | null,
  and?: Array< ModelConceptFilterInput | null > | null,
  or?: Array< ModelConceptFilterInput | null > | null,
  not?: ModelConceptFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelConceptConnection = {
  __typename: "ModelConceptConnection",
  items:  Array<Concept | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTenderFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  simphonyID?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelTenderFilterInput | null > | null,
  or?: Array< ModelTenderFilterInput | null > | null,
  not?: ModelTenderFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  conceptTendersId?: ModelIDInput | null,
};

export type ModelOrderTypeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  simphonyID?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelOrderTypeFilterInput | null > | null,
  or?: Array< ModelOrderTypeFilterInput | null > | null,
  not?: ModelOrderTypeFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  conceptOrderTypeId?: ModelIDInput | null,
};

export type ModelParentConceptFilterInput = {
  id?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  image?: ModelStringInput | null,
  name?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  type?: ModelStringInput | null,
  venues?: ModelStringInput | null,
  index?: ModelIntInput | null,
  and?: Array< ModelParentConceptFilterInput | null > | null,
  or?: Array< ModelParentConceptFilterInput | null > | null,
  not?: ModelParentConceptFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelParentConceptConnection = {
  __typename: "ModelParentConceptConnection",
  items:  Array<ParentConcept | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPlanItemFilterInput = {
  id?: ModelIDInput | null,
  areaID?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  xPosition?: ModelIntInput | null,
  yPosition?: ModelIntInput | null,
  name?: ModelStringInput | null,
  isReserved?: ModelBooleanInput | null,
  bookings?: ModelStringInput | null,
  table?: ModelStringInput | null,
  object?: ModelStringInput | null,
  lockDays?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelPlanItemFilterInput | null > | null,
  or?: Array< ModelPlanItemFilterInput | null > | null,
  not?: ModelPlanItemFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPlanItemConnection = {
  __typename: "ModelPlanItemConnection",
  items:  Array<PlanItem | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPlanItemByConceptIDCompositeKeyConditionInput = {
  eq?: ModelPlanItemByConceptIDCompositeKeyInput | null,
  le?: ModelPlanItemByConceptIDCompositeKeyInput | null,
  lt?: ModelPlanItemByConceptIDCompositeKeyInput | null,
  ge?: ModelPlanItemByConceptIDCompositeKeyInput | null,
  gt?: ModelPlanItemByConceptIDCompositeKeyInput | null,
  between?: Array< ModelPlanItemByConceptIDCompositeKeyInput | null > | null,
  beginsWith?: ModelPlanItemByConceptIDCompositeKeyInput | null,
};

export type ModelPlanItemByConceptIDCompositeKeyInput = {
  areaID?: string | null,
  name?: string | null,
};

export type ModelObjectFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelStringInput | null,
  label?: ModelStringInput | null,
  name?: ModelStringInput | null,
  width?: ModelIntInput | null,
  height?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelObjectFilterInput | null > | null,
  or?: Array< ModelObjectFilterInput | null > | null,
  not?: ModelObjectFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  objectImageId?: ModelIDInput | null,
};

export type ModelObjectConnection = {
  __typename: "ModelObjectConnection",
  items:  Array<Object | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBookingFilterInput = {
  id?: ModelIDInput | null,
  depositValue?: ModelStringInput | null,
  depositStatus?: ModelBooleanInput | null,
  eventStatus?: ModelBooleanInput | null,
  eventName?: ModelStringInput | null,
  paymentStatus?: ModelBooleanInput | null,
  paymentValue?: ModelStringInput | null,
  disablePartialPayment?: ModelBooleanInput | null,
  disablePayment?: ModelBooleanInput | null,
  eventDate?: ModelStringInput | null,
  eventGuests?: ModelStringInput | null,
  timeSlotID?: ModelStringInput | null,
  timeSlots?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  mainGuest?: ModelStringInput | null,
  customerName?: ModelStringInput | null,
  customerPhone?: ModelStringInput | null,
  customerGroup?: ModelStringInput | null,
  accompaniedCount?: ModelIntInput | null,
  channel?: ModelStringInput | null,
  statusID?: ModelStringInput | null,
  rating?: ModelFloatInput | null,
  review?: ModelStringInput | null,
  confirmationHash?: ModelStringInput | null,
  isConfirmedByUser?: ModelBooleanInput | null,
  date?: ModelStringInput | null,
  tables?: ModelStringInput | null,
  tableName?: ModelStringInput | null,
  comments?: ModelStringInput | null,
  lastComment?: ModelStringInput | null,
  commentCreatedBy?: ModelStringInput | null,
  commentCreatedDate?: ModelStringInput | null,
  timeLeft?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelBookingFilterInput | null > | null,
  or?: Array< ModelBookingFilterInput | null > | null,
  not?: ModelBookingFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelBookingConnection = {
  __typename: "ModelBookingConnection",
  items:  Array<Booking | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTimelineFilterInput = {
  id?: ModelIDInput | null,
  actionName?: ModelStringInput | null,
  oldStatus?: ModelStringInput | null,
  newStatus?: ModelStringInput | null,
  bookingId?: ModelStringInput | null,
  transactionId?: ModelStringInput | null,
  customerId?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelTimelineFilterInput | null > | null,
  or?: Array< ModelTimelineFilterInput | null > | null,
  not?: ModelTimelineFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTimelineConnection = {
  __typename: "ModelTimelineConnection",
  items:  Array<Timeline | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelCallFilterInput = {
  id?: ModelIDInput | null,
  phone_number?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  endedAt?: ModelStringInput | null,
  duration?: ModelStringInput | null,
  date?: ModelStringInput | null,
  employeeId?: ModelStringInput | null,
  and?: Array< ModelCallFilterInput | null > | null,
  or?: Array< ModelCallFilterInput | null > | null,
  not?: ModelCallFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  callCustomerId?: ModelIDInput | null,
};

export type ModelCallConnection = {
  __typename: "ModelCallConnection",
  items:  Array<Call | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAdminRoleFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAdminRoleFilterInput | null > | null,
  or?: Array< ModelAdminRoleFilterInput | null > | null,
  not?: ModelAdminRoleFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAdminRoleConnection = {
  __typename: "ModelAdminRoleConnection",
  items:  Array<AdminRole | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAdminGroupFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  roles?: ModelIDInput | null,
  users?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAdminGroupFilterInput | null > | null,
  or?: Array< ModelAdminGroupFilterInput | null > | null,
  not?: ModelAdminGroupFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAdminGroupConnection = {
  __typename: "ModelAdminGroupConnection",
  items:  Array<AdminGroup | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserConceptsFilterInput = {
  id?: ModelIDInput | null,
  defaultConcept?: ModelIDInput | null,
  concepts?: ModelIDInput | null,
  conceptsRoles?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelUserConceptsFilterInput | null > | null,
  or?: Array< ModelUserConceptsFilterInput | null > | null,
  not?: ModelUserConceptsFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserConceptsConnection = {
  __typename: "ModelUserConceptsConnection",
  items:  Array<UserConcepts | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTransactionFilterInput = {
  id?: ModelIDInput | null,
  transactionID?: ModelStringInput | null,
  date?: ModelStringInput | null,
  guestName?: ModelStringInput | null,
  guestPhone?: ModelStringInput | null,
  guestID?: ModelStringInput | null,
  guestGroup?: ModelStringInput | null,
  guestsNames?: ModelStringInput | null,
  bookingID?: ModelStringInput | null,
  bookingDate?: ModelStringInput | null,
  amount_cents?: ModelStringInput | null,
  status?: ModelBooleanInput | null,
  failureReason?: ModelStringInput | null,
  type?: ModelStringInput | null,
  timeSlots?: ModelStringInput | null,
  tables?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  refund?: ModelBooleanInput | null,
  reference_orderID?: ModelStringInput | null,
  ownerID?: ModelStringInput | null,
  refunded_amount_cents?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTransactionFilterInput | null > | null,
  or?: Array< ModelTransactionFilterInput | null > | null,
  not?: ModelTransactionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTransactionConnection = {
  __typename: "ModelTransactionConnection",
  items:  Array<Transaction | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPaymentStatFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  year?: ModelIntInput | null,
  month?: ModelIntInput | null,
  monthName?: ModelStringInput | null,
  earning?: ModelFloatInput | null,
  refund?: ModelFloatInput | null,
  earningTrxCount?: ModelIntInput | null,
  refundTrxCount?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelPaymentStatFilterInput | null > | null,
  or?: Array< ModelPaymentStatFilterInput | null > | null,
  not?: ModelPaymentStatFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPaymentStatConnection = {
  __typename: "ModelPaymentStatConnection",
  items:  Array<PaymentStat | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBookingGuestFilterInput = {
  id?: ModelIDInput | null,
  bookingID?: ModelStringInput | null,
  guestName?: ModelStringInput | null,
  paidAmount?: ModelFloatInput | null,
  paymentAmount?: ModelFloatInput | null,
  paymentStatus?: ModelBooleanInput | null,
  transactionID?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelBookingGuestFilterInput | null > | null,
  or?: Array< ModelBookingGuestFilterInput | null > | null,
  not?: ModelBookingGuestFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelBookingGuestConnection = {
  __typename: "ModelBookingGuestConnection",
  items:  Array<BookingGuest | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  preparationAreaID?: ModelIDInput | null,
  subCategory?: ModelIDInput | null,
  name?: ModelStringInput | null,
  guestView?: ModelBooleanInput | null,
  guestOrder?: ModelBooleanInput | null,
  staffOrder?: ModelBooleanInput | null,
  image?: ModelIDInput | null,
  darkImage?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  symphonyID?: ModelStringInput | null,
  isHidden?: ModelBooleanInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMenuItemFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  kioskID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  image?: ModelIDInput | null,
  precedence?: ModelStringInput | null,
  categoryID?: ModelIDInput | null,
  categoryName?: ModelStringInput | null,
  outOfStock?: ModelBooleanInput | null,
  requiredPoints?: ModelIntInput | null,
  symphonyID?: ModelStringInput | null,
  comboItemID?: ModelStringInput | null,
  ratings?: ModelIDInput | null,
  prices?: ModelIDInput | null,
  choiceGroups?: ModelIDInput | null,
  ExtraChoiceGroups?: ModelIDInput | null,
  enabled?: ModelBooleanInput | null,
  hasLimitedQuantity?: ModelBooleanInput | null,
  quantity?: ModelIntInput | null,
  autoRestock?: ModelBooleanInput | null,
  restockQuantity?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  quantityOrdered?: ModelIntInput | null,
  performancePerDay?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  and?: Array< ModelMenuItemFilterInput | null > | null,
  or?: Array< ModelMenuItemFilterInput | null > | null,
  not?: ModelMenuItemFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPriceFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  symphonyID?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelPriceFilterInput | null > | null,
  or?: Array< ModelPriceFilterInput | null > | null,
  not?: ModelPriceFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPriceConnection = {
  __typename: "ModelPriceConnection",
  items:  Array<Price | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelChoiceGroupsFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  required?: ModelBooleanInput | null,
  maxNumberOfChoices?: ModelIntInput | null,
  minNumberOfChoices?: ModelIntInput | null,
  precedence?: ModelStringInput | null,
  type?: ModelStringInput | null,
  choices?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  simphonyID?: ModelStringInput | null,
  and?: Array< ModelChoiceGroupsFilterInput | null > | null,
  or?: Array< ModelChoiceGroupsFilterInput | null > | null,
  not?: ModelChoiceGroupsFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelChoiceGroupsConnection = {
  __typename: "ModelChoiceGroupsConnection",
  items:  Array<ChoiceGroups | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelChoiceFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  precedence?: ModelStringInput | null,
  choiceGroupID?: ModelIDInput | null,
  choiceGroupName?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  symphonyID?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  definitionNumber?: ModelIntInput | null,
  and?: Array< ModelChoiceFilterInput | null > | null,
  or?: Array< ModelChoiceFilterInput | null > | null,
  not?: ModelChoiceFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCartFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  specialRequest?: ModelStringInput | null,
  deliveryFee?: ModelFloatInput | null,
  subTotal?: ModelFloatInput | null,
  subTaxTotal?: ModelFloatInput | null,
  totalPrice?: ModelFloatInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCartFilterInput | null > | null,
  or?: Array< ModelCartFilterInput | null > | null,
  not?: ModelCartFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCartConnection = {
  __typename: "ModelCartConnection",
  items:  Array<Cart | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAdminStatusFilterInput = {
  id?: ModelIDInput | null,
  sub?: ModelStringInput | null,
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  averageCallTime?: ModelFloatInput | null,
  numberOfCalls?: ModelFloatInput | null,
  idelDuration?: ModelFloatInput | null,
  activeDuration?: ModelFloatInput | null,
  awayDuration?: ModelFloatInput | null,
  lastCallTime?: ModelFloatInput | null,
  agentBarAverageCallTime?: ModelFloatInput | null,
  agentBarNumberOfCalls?: ModelFloatInput | null,
  type?: ModelStringInput | null,
  waiterStatus?: ModelStringInput | null,
  totalAverageRatio?: ModelFloatInput | null,
  performancePerDay?: ModelStringInput | null,
  and?: Array< ModelAdminStatusFilterInput | null > | null,
  or?: Array< ModelAdminStatusFilterInput | null > | null,
  not?: ModelAdminStatusFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAdminStatusConnection = {
  __typename: "ModelAdminStatusConnection",
  items:  Array<AdminStatus | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFloatKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelOnlineOrderFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  statusID?: ModelIDInput | null,
  status?: ModelStringInput | null,
  reason?: ModelStringInput | null,
  orderType?: ModelStringInput | null,
  channel?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  userName?: ModelStringInput | null,
  userPhoneNumber?: ModelStringInput | null,
  dispatcherID?: ModelStringInput | null,
  dispatcherName?: ModelStringInput | null,
  cartID?: ModelIDInput | null,
  tenderId?: ModelIntInput | null,
  employeeId?: ModelIntInput | null,
  rvcId?: ModelIntInput | null,
  checkNumber?: ModelStringInput | null,
  totalAmount?: ModelFloatInput | null,
  discountedValue?: ModelFloatInput | null,
  subTotal?: ModelFloatInput | null,
  subTaxTotal?: ModelFloatInput | null,
  tax?: ModelFloatInput | null,
  deliveryFee?: ModelFloatInput | null,
  orderTime?: ModelStringInput | null,
  executionArn?: ModelStringInput | null,
  specialRequest?: ModelStringInput | null,
  orderAddress?: ModelStringInput | null,
  checkRef?: ModelStringInput | null,
  discountID?: ModelIntInput | null,
  amount_cents?: ModelStringInput | null,
  transactionID?: ModelStringInput | null,
  paymentStatus?: ModelBooleanInput | null,
  paymentType?: ModelStringInput | null,
  referenceId?: ModelStringInput | null,
  cardHolderNumber?: ModelStringInput | null,
  cardHolderName?: ModelStringInput | null,
  cardSchemeName?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  acceptedByWaiterID?: ModelStringInput | null,
  acceptedByWaiterName?: ModelStringInput | null,
  deliveredByWaiterID?: ModelStringInput | null,
  tableID?: ModelStringInput | null,
  tableName?: ModelStringInput | null,
  shift?: ModelStringInput | null,
  parentOrder?: ModelIDInput | null,
  preparationAreaID?: ModelIDInput | null,
  childrenStatus?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  acceptedByWaiterAt?: ModelStringInput | null,
  sentToKitchenAt?: ModelStringInput | null,
  acceptedByKitchenAt?: ModelStringInput | null,
  readyAt?: ModelStringInput | null,
  outForDeliveryAt?: ModelStringInput | null,
  deliveredAt?: ModelStringInput | null,
  promoCodeID?: ModelIDInput | null,
  promoCodeName?: ModelStringInput | null,
  promoCodeAppliedTo?: ModelStringInput | null,
  reviewID?: ModelIDInput | null,
  simphEmpID?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  tmpField?: ModelStringInput | null,
  updatedByID?: ModelStringInput | null,
  updatedByName?: ModelStringInput | null,
  and?: Array< ModelOnlineOrderFilterInput | null > | null,
  or?: Array< ModelOnlineOrderFilterInput | null > | null,
  not?: ModelOnlineOrderFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelOnlineOrderConnection = {
  __typename: "ModelOnlineOrderConnection",
  items:  Array<OnlineOrder | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelOrderStatusFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  color?: ModelStringInput | null,
  precedence?: ModelIntInput | null,
  accessibleStatus?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelOrderStatusFilterInput | null > | null,
  or?: Array< ModelOrderStatusFilterInput | null > | null,
  not?: ModelOrderStatusFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelOrderStatusConnection = {
  __typename: "ModelOrderStatusConnection",
  items:  Array<OrderStatus | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDiscountCouponFilterInput = {
  id?: ModelIDInput | null,
  discountPercent?: ModelStringInput | null,
  usedBy?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelDiscountCouponFilterInput | null > | null,
  or?: Array< ModelDiscountCouponFilterInput | null > | null,
  not?: ModelDiscountCouponFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelDiscountCouponConnection = {
  __typename: "ModelDiscountCouponConnection",
  items:  Array<DiscountCoupon | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelZoneFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  location?: ModelStringInput | null,
  deliveryFee?: ModelIntInput | null,
  polygon?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelZoneFilterInput | null > | null,
  or?: Array< ModelZoneFilterInput | null > | null,
  not?: ModelZoneFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelZoneConnection = {
  __typename: "ModelZoneConnection",
  items:  Array<Zone | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserIPFilterInput = {
  id?: ModelIDInput | null,
  ip?: ModelStringInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelUserIPFilterInput | null > | null,
  or?: Array< ModelUserIPFilterInput | null > | null,
  not?: ModelUserIPFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserIPConnection = {
  __typename: "ModelUserIPConnection",
  items:  Array<UserIP | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMainCategoryFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  openTime?: ModelStringInput | null,
  closeTime?: ModelStringInput | null,
  guestView?: ModelBooleanInput | null,
  guestOrder?: ModelBooleanInput | null,
  staffOrder?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  menuPDF?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  and?: Array< ModelMainCategoryFilterInput | null > | null,
  or?: Array< ModelMainCategoryFilterInput | null > | null,
  not?: ModelMainCategoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelMainCategoryConnection = {
  __typename: "ModelMainCategoryConnection",
  items:  Array<MainCategory | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSubCategoryFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  openTime?: ModelStringInput | null,
  closeTime?: ModelStringInput | null,
  mainCategoryID?: ModelIDInput | null,
  guestView?: ModelBooleanInput | null,
  guestOrder?: ModelBooleanInput | null,
  staffOrder?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  menuPDF?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  and?: Array< ModelSubCategoryFilterInput | null > | null,
  or?: Array< ModelSubCategoryFilterInput | null > | null,
  not?: ModelSubCategoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubCategoryConnection = {
  __typename: "ModelSubCategoryConnection",
  items:  Array<SubCategory | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelShiftFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  users?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelShiftFilterInput | null > | null,
  or?: Array< ModelShiftFilterInput | null > | null,
  not?: ModelShiftFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelShiftConnection = {
  __typename: "ModelShiftConnection",
  items:  Array<Shift | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelChannelFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  numberOfOrders?: ModelIntInput | null,
  isActive?: ModelBooleanInput | null,
  multiLanguages?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelChannelFilterInput | null > | null,
  or?: Array< ModelChannelFilterInput | null > | null,
  not?: ModelChannelFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelChannelConnection = {
  __typename: "ModelChannelConnection",
  items:  Array<Channel | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPromocodeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  redemptions?: ModelIntInput | null,
  status?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  appliesTo?: ModelStringInput | null,
  minOrderReq?: ModelStringInput | null,
  minOrderVal?: ModelIntInput | null,
  minOrderQty?: ModelIntInput | null,
  tenderReq?: ModelStringInput | null,
  discountVal?: ModelIntInput | null,
  discountLimit?: ModelIntInput | null,
  quota?: ModelIntInput | null,
  quotaUsed?: ModelIntInput | null,
  totalRevenue?: ModelIntInput | null,
  appliedDiscountedVal?: ModelIntInput | null,
  usePerCustomer?: ModelIntInput | null,
  channelReq?: ModelStringInput | null,
  customerEligibility?: ModelStringInput | null,
  customerReq?: ModelStringInput | null,
  spendingExceeds?: ModelIntInput | null,
  spendingBelow?: ModelIntInput | null,
  orderSinceDate?: ModelStringInput | null,
  orderFromDate?: ModelStringInput | null,
  orderToDate?: ModelStringInput | null,
  isDisplayed?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelPromocodeFilterInput | null > | null,
  or?: Array< ModelPromocodeFilterInput | null > | null,
  not?: ModelPromocodeFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPromocodeConnection = {
  __typename: "ModelPromocodeConnection",
  items:  Array<Promocode | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelPromocodeDashboardFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  totalRevenue?: ModelIntInput | null,
  totalRedemptions?: ModelIntInput | null,
  totalDiscount?: ModelIntInput | null,
  and?: Array< ModelPromocodeDashboardFilterInput | null > | null,
  or?: Array< ModelPromocodeDashboardFilterInput | null > | null,
  not?: ModelPromocodeDashboardFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPromocodeDashboardConnection = {
  __typename: "ModelPromocodeDashboardConnection",
  items:  Array<PromocodeDashboard | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelLoyaltyItemFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  points?: ModelIntInput | null,
  redemptionPerUser?: ModelIntInput | null,
  image?: ModelStringInput | null,
  simphonyID?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  isActive?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelLoyaltyItemFilterInput | null > | null,
  or?: Array< ModelLoyaltyItemFilterInput | null > | null,
  not?: ModelLoyaltyItemFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLoyaltyItemConnection = {
  __typename: "ModelLoyaltyItemConnection",
  items:  Array<LoyaltyItem | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelOTPFilterInput = {
  id?: ModelIDInput | null,
  OTP?: ModelStringInput | null,
  redeemed?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  loyaltyItemID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelOTPFilterInput | null > | null,
  or?: Array< ModelOTPFilterInput | null > | null,
  not?: ModelOTPFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelOTPConnection = {
  __typename: "ModelOTPConnection",
  items:  Array<OTP | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSubscriptionEmailVerificationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  verification_code?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEmailVerificationFilterInput | null > | null,
  or?: Array< ModelSubscriptionEmailVerificationFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionSocialLinkFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  icon?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSocialLinkFilterInput | null > | null,
  or?: Array< ModelSubscriptionSocialLinkFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionLanguageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  code?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLanguageFilterInput | null > | null,
  or?: Array< ModelSubscriptionLanguageFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionAttachmentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  mediaID?: ModelSubscriptionIDInput | null,
  fileUrl?: ModelSubscriptionStringInput | null,
  filename?: ModelSubscriptionStringInput | null,
  filetype?: ModelSubscriptionStringInput | null,
  fileSize?: ModelSubscriptionIntInput | null,
  alternativeText?: ModelSubscriptionStringInput | null,
  caption?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAttachmentFilterInput | null > | null,
  or?: Array< ModelSubscriptionAttachmentFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  color?: ModelSubscriptionStringInput | null,
  requireApproval?: ModelSubscriptionBooleanInput | null,
  status?: ModelSubscriptionStringInput | null,
  agents?: ModelSubscriptionStringInput | null,
  groupSettings?: ModelSubscriptionStringInput | null,
  numToUpgrade?: ModelSubscriptionIntInput | null,
  numToDowngrade?: ModelSubscriptionIntInput | null,
  upgradeGroup?: ModelSubscriptionIDInput | null,
  downgradeGroup?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionGroupFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionGroupSettingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  groupID?: ModelSubscriptionStringInput | null,
  statusID?: ModelSubscriptionIDInput | null,
  needsVerification?: ModelSubscriptionBooleanInput | null,
  needsDeposit?: ModelSubscriptionBooleanInput | null,
  isDefault?: ModelSubscriptionBooleanInput | null,
  amount?: ModelSubscriptionIntInput | null,
  days?: ModelSubscriptionStringInput | null,
  timeSlots?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGroupSettingFilterInput | null > | null,
  or?: Array< ModelSubscriptionGroupSettingFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCustomerStatsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  totalReservation?: ModelSubscriptionIntInput | null,
  confirmed?: ModelSubscriptionIntInput | null,
  notConfirmed?: ModelSubscriptionIntInput | null,
  canceled?: ModelSubscriptionIntInput | null,
  checkIn?: ModelSubscriptionIntInput | null,
  noShow?: ModelSubscriptionIntInput | null,
  userID?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCustomerStatsFilterInput | null > | null,
  or?: Array< ModelSubscriptionCustomerStatsFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFlagFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  icon?: ModelSubscriptionStringInput | null,
  color?: ModelSubscriptionStringInput | null,
  customers?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFlagFilterInput | null > | null,
  or?: Array< ModelSubscriptionFlagFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionInterestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  icon?: ModelSubscriptionStringInput | null,
  color?: ModelSubscriptionStringInput | null,
  customers?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInterestFilterInput | null > | null,
  or?: Array< ModelSubscriptionInterestFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionReservationStatusFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReservationStatusFilterInput | null > | null,
  or?: Array< ModelSubscriptionReservationStatusFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTimeSlotFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  friendlyName?: ModelSubscriptionStringInput | null,
  bookings?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTimeSlotFilterInput | null > | null,
  or?: Array< ModelSubscriptionTimeSlotFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionAreaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  isDefault?: ModelSubscriptionBooleanInput | null,
  expiryDate?: ModelSubscriptionStringInput | null,
  days?: ModelSubscriptionStringInput | null,
  timeSlots?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAreaFilterInput | null > | null,
  or?: Array< ModelSubscriptionAreaFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTableFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionStringInput | null,
  label?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  width?: ModelSubscriptionIntInput | null,
  height?: ModelSubscriptionIntInput | null,
  capacity?: ModelSubscriptionIntInput | null,
  image?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTableFilterInput | null > | null,
  or?: Array< ModelSubscriptionTableFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFriendFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  customerId?: ModelSubscriptionStringInput | null,
  friendId?: ModelSubscriptionStringInput | null,
  bookingID?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFriendFilterInput | null > | null,
  or?: Array< ModelSubscriptionFriendFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCommentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  message?: ModelSubscriptionStringInput | null,
  customerId?: ModelSubscriptionStringInput | null,
  bookingId?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCommentFilterInput | null > | null,
  or?: Array< ModelSubscriptionCommentFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionServingAreaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  fees?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionServingAreaFilterInput | null > | null,
  or?: Array< ModelSubscriptionServingAreaFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionNotificationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  conceptID?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  channel?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  from?: ModelSubscriptionStringInput | null,
  enabled?: ModelSubscriptionBooleanInput | null,
  autoSend?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
  or?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMobileNotificationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  body?: ModelSubscriptionStringInput | null,
  default?: ModelSubscriptionBooleanInput | null,
  event?: ModelSubscriptionBooleanInput | null,
  image?: ModelSubscriptionStringInput | null,
  concepts?: ModelSubscriptionStringInput | null,
  condition?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  group?: ModelSubscriptionStringInput | null,
  read?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMobileNotificationFilterInput | null > | null,
  or?: Array< ModelSubscriptionMobileNotificationFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserPushTokenFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionStringInput | null,
  token?: ModelSubscriptionStringInput | null,
  reciveNotification?: ModelSubscriptionBooleanInput | null,
  type?: ModelSubscriptionStringInput | null,
  os?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserPushTokenFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserPushTokenFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionNotificationReceiverFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionStringInput | null,
  notificationID?: ModelSubscriptionStringInput | null,
  seen?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNotificationReceiverFilterInput | null > | null,
  or?: Array< ModelSubscriptionNotificationReceiverFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPreparationAreaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  isAutoAccept?: ModelSubscriptionBooleanInput | null,
  hasKDS?: ModelSubscriptionBooleanInput | null,
  hasPrinter?: ModelSubscriptionBooleanInput | null,
  users?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPreparationAreaFilterInput | null > | null,
  or?: Array< ModelSubscriptionPreparationAreaFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionQRcodeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tableID?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  tableName?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionQRcodeFilterInput | null > | null,
  or?: Array< ModelSubscriptionQRcodeFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCallWaiterFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  tableID?: ModelSubscriptionIDInput | null,
  tableName?: ModelSubscriptionStringInput | null,
  waiterID?: ModelSubscriptionIDInput | null,
  waiterName?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionIDInput | null,
  count?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  acceptedAt?: ModelSubscriptionStringInput | null,
  arrivedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCallWaiterFilterInput | null > | null,
  or?: Array< ModelSubscriptionCallWaiterFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  username?: ModelSubscriptionStringInput | null,
  preferred_username?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  email_verified?: ModelSubscriptionBooleanInput | null,
  phone_number?: ModelSubscriptionStringInput | null,
  phone_number_verified?: ModelSubscriptionBooleanInput | null,
  name?: ModelSubscriptionStringInput | null,
  nickname?: ModelSubscriptionStringInput | null,
  given_name?: ModelSubscriptionStringInput | null,
  middle_name?: ModelSubscriptionStringInput | null,
  family_name?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  birthdate?: ModelSubscriptionStringInput | null,
  gender?: ModelSubscriptionStringInput | null,
  locale?: ModelSubscriptionStringInput | null,
  picture?: ModelSubscriptionStringInput | null,
  pictureCrop?: ModelSubscriptionStringInput | null,
  website?: ModelSubscriptionStringInput | null,
  zoneinfo?: ModelSubscriptionStringInput | null,
  verification_code?: ModelSubscriptionStringInput | null,
  facebook?: ModelSubscriptionStringInput | null,
  instagram?: ModelSubscriptionStringInput | null,
  group?: ModelSubscriptionStringInput | null,
  phones?: ModelSubscriptionStringInput | null,
  flags?: ModelSubscriptionStringInput | null,
  flagsName?: ModelSubscriptionStringInput | null,
  interests?: ModelSubscriptionStringInput | null,
  interestsName?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  stats?: ModelSubscriptionIDInput | null,
  spending?: ModelSubscriptionIntInput | null,
  prevOrderDate?: ModelSubscriptionStringInput | null,
  lastOrderDate?: ModelSubscriptionStringInput | null,
  mostOrderedItems?: ModelSubscriptionStringInput | null,
  points?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  cognitoUsername?: ModelSubscriptionStringInput | null,
  cognitoSub?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  sub?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionReviewFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  bookingID?: ModelSubscriptionStringInput | null,
  guestName?: ModelSubscriptionStringInput | null,
  guestId?: ModelSubscriptionStringInput | null,
  reason?: ModelSubscriptionStringInput | null,
  value?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionStringInput | null,
  orderID?: ModelSubscriptionStringInput | null,
  rate?: ModelSubscriptionIntInput | null,
  satisfaction?: ModelSubscriptionStringInput | null,
  thoughts?: ModelSubscriptionStringInput | null,
  photos?: ModelSubscriptionStringInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReviewFilterInput | null > | null,
  or?: Array< ModelSubscriptionReviewFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionAccountFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  logo?: ModelSubscriptionStringInput | null,
  domain?: ModelSubscriptionStringInput | null,
  siteTitle?: ModelSubscriptionStringInput | null,
  guestsCount?: ModelSubscriptionIntInput | null,
  tagline?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  siteAddress?: ModelSubscriptionStringInput | null,
  defaultLanguage?: ModelSubscriptionStringInput | null,
  languages?: ModelSubscriptionStringInput | null,
  features?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  socialLinks?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  callCenterEmployeesIds?: ModelSubscriptionStringInput | null,
  kpiReportResetFrequency?: ModelSubscriptionStringInput | null,
  agentBarResetFrequency?: ModelSubscriptionStringInput | null,
  dynamicStatus?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFeatureFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  icon?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  parent?: ModelSubscriptionStringInput | null,
  private?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFeatureFilterInput | null > | null,
  or?: Array< ModelSubscriptionFeatureFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMediaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionIDInput | null,
  fileUrl?: ModelSubscriptionStringInput | null,
  filename?: ModelSubscriptionStringInput | null,
  filetype?: ModelSubscriptionStringInput | null,
  fileSize?: ModelSubscriptionIntInput | null,
  alternativeText?: ModelSubscriptionStringInput | null,
  caption?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  attachments?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMediaFilterInput | null > | null,
  or?: Array< ModelSubscriptionMediaFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionConceptFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  kiosks?: ModelSubscriptionIDInput | null,
  merchantID?: ModelSubscriptionStringInput | null,
  merchantUsername?: ModelSubscriptionStringInput | null,
  merchantPassword?: ModelSubscriptionStringInput | null,
  merchantIntegrationNumber?: ModelSubscriptionStringInput | null,
  merchantAPIKey?: ModelSubscriptionStringInput | null,
  simphonyServerBaseURL?: ModelSubscriptionStringInput | null,
  simphonyShortOrgName?: ModelSubscriptionStringInput | null,
  simphonyLocRef?: ModelSubscriptionStringInput | null,
  simphonyToken?: ModelSubscriptionStringInput | null,
  revenueCenterID?: ModelSubscriptionIntInput | null,
  employeeID?: ModelSubscriptionIntInput | null,
  tenderID?: ModelSubscriptionIntInput | null,
  discountID?: ModelSubscriptionIntInput | null,
  useServiceCharge?: ModelSubscriptionBooleanInput | null,
  serviceChargeId?: ModelSubscriptionIntInput | null,
  specialRequestID?: ModelSubscriptionIntInput | null,
  addressID?: ModelSubscriptionIntInput | null,
  simphonyAuthClientId?: ModelSubscriptionStringInput | null,
  simphonyAuthUsername?: ModelSubscriptionStringInput | null,
  simphonyAuthPassword?: ModelSubscriptionStringInput | null,
  simphonyAuthBaseURL?: ModelSubscriptionStringInput | null,
  orderTypeDineIn?: ModelSubscriptionIntInput | null,
  orderTypeTakeAway?: ModelSubscriptionIntInput | null,
  orderTypeDelivery?: ModelSubscriptionIntInput | null,
  simphonyTakeAwayRvc?: ModelSubscriptionIntInput | null,
  simphonyDeliveryRvc?: ModelSubscriptionIntInput | null,
  exportInterval?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  foodicsToken?: ModelSubscriptionStringInput | null,
  enableTableFoodics?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  logo?: ModelSubscriptionStringInput | null,
  album?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  rating?: ModelSubscriptionFloatInput | null,
  location?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  areas?: ModelSubscriptionStringInput | null,
  seatTypes?: ModelSubscriptionStringInput | null,
  needAssistance?: ModelSubscriptionBooleanInput | null,
  spaBooking?: ModelSubscriptionBooleanInput | null,
  showerBooking?: ModelSubscriptionBooleanInput | null,
  cigarMenu?: ModelSubscriptionBooleanInput | null,
  precedence?: ModelSubscriptionIntInput | null,
  isPinRequired?: ModelSubscriptionBooleanInput | null,
  viewOnly?: ModelSubscriptionBooleanInput | null,
  openTime?: ModelSubscriptionStringInput | null,
  closeTime?: ModelSubscriptionStringInput | null,
  varifyForAmount?: ModelSubscriptionIntInput | null,
  varifyFirstOrder?: ModelSubscriptionBooleanInput | null,
  varifyNewAddress?: ModelSubscriptionBooleanInput | null,
  sliderImages?: ModelSubscriptionStringInput | null,
  receiveWaiters?: ModelSubscriptionStringInput | null,
  WaiterNotificationOption?: ModelSubscriptionStringInput | null,
  applyLoyalty?: ModelSubscriptionBooleanInput | null,
  rewardPoints?: ModelSubscriptionIntInput | null,
  moneySpent?: ModelSubscriptionIntInput | null,
  afterVat?: ModelSubscriptionBooleanInput | null,
  afterDeliveryFee?: ModelSubscriptionBooleanInput | null,
  beforePromoCode?: ModelSubscriptionBooleanInput | null,
  pointsCalcWithPromoCode?: ModelSubscriptionBooleanInput | null,
  isBusy?: ModelSubscriptionBooleanInput | null,
  allowOnlineOrdering?: ModelSubscriptionBooleanInput | null,
  minOrderVal?: ModelSubscriptionIntInput | null,
  vatPercentage?: ModelSubscriptionFloatInput | null,
  serviceChargePercentage?: ModelSubscriptionFloatInput | null,
  addDeliveryToVat?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionConceptFilterInput | null > | null,
  or?: Array< ModelSubscriptionConceptFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionTenderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  simphonyID?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTenderFilterInput | null > | null,
  or?: Array< ModelSubscriptionTenderFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionOrderTypeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  simphonyID?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrderTypeFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrderTypeFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionParentConceptFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  logo?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  venues?: ModelSubscriptionStringInput | null,
  index?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionParentConceptFilterInput | null > | null,
  or?: Array< ModelSubscriptionParentConceptFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPlanItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  areaID?: ModelSubscriptionStringInput | null,
  conceptID?: ModelSubscriptionStringInput | null,
  xPosition?: ModelSubscriptionIntInput | null,
  yPosition?: ModelSubscriptionIntInput | null,
  name?: ModelSubscriptionStringInput | null,
  isReserved?: ModelSubscriptionBooleanInput | null,
  bookings?: ModelSubscriptionStringInput | null,
  table?: ModelSubscriptionStringInput | null,
  object?: ModelSubscriptionStringInput | null,
  lockDays?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPlanItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionPlanItemFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionObjectFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionStringInput | null,
  label?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  width?: ModelSubscriptionIntInput | null,
  height?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionObjectFilterInput | null > | null,
  or?: Array< ModelSubscriptionObjectFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionBookingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  depositValue?: ModelSubscriptionStringInput | null,
  depositStatus?: ModelSubscriptionBooleanInput | null,
  eventStatus?: ModelSubscriptionBooleanInput | null,
  eventName?: ModelSubscriptionStringInput | null,
  paymentStatus?: ModelSubscriptionBooleanInput | null,
  paymentValue?: ModelSubscriptionStringInput | null,
  disablePartialPayment?: ModelSubscriptionBooleanInput | null,
  disablePayment?: ModelSubscriptionBooleanInput | null,
  eventDate?: ModelSubscriptionStringInput | null,
  eventGuests?: ModelSubscriptionStringInput | null,
  timeSlotID?: ModelSubscriptionStringInput | null,
  timeSlots?: ModelSubscriptionStringInput | null,
  conceptID?: ModelSubscriptionStringInput | null,
  mainGuest?: ModelSubscriptionStringInput | null,
  customerName?: ModelSubscriptionStringInput | null,
  customerPhone?: ModelSubscriptionStringInput | null,
  customerGroup?: ModelSubscriptionStringInput | null,
  accompaniedCount?: ModelSubscriptionIntInput | null,
  channel?: ModelSubscriptionStringInput | null,
  statusID?: ModelSubscriptionStringInput | null,
  rating?: ModelSubscriptionFloatInput | null,
  review?: ModelSubscriptionStringInput | null,
  confirmationHash?: ModelSubscriptionStringInput | null,
  isConfirmedByUser?: ModelSubscriptionBooleanInput | null,
  date?: ModelSubscriptionStringInput | null,
  tables?: ModelSubscriptionStringInput | null,
  tableName?: ModelSubscriptionStringInput | null,
  comments?: ModelSubscriptionStringInput | null,
  lastComment?: ModelSubscriptionStringInput | null,
  commentCreatedBy?: ModelSubscriptionStringInput | null,
  commentCreatedDate?: ModelSubscriptionStringInput | null,
  timeLeft?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBookingFilterInput | null > | null,
  or?: Array< ModelSubscriptionBookingFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTimelineFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  actionName?: ModelSubscriptionStringInput | null,
  oldStatus?: ModelSubscriptionStringInput | null,
  newStatus?: ModelSubscriptionStringInput | null,
  bookingId?: ModelSubscriptionStringInput | null,
  transactionId?: ModelSubscriptionStringInput | null,
  customerId?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTimelineFilterInput | null > | null,
  or?: Array< ModelSubscriptionTimelineFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCallFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  phone_number?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  endedAt?: ModelSubscriptionStringInput | null,
  duration?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  employeeId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCallFilterInput | null > | null,
  or?: Array< ModelSubscriptionCallFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionAdminRoleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAdminRoleFilterInput | null > | null,
  or?: Array< ModelSubscriptionAdminRoleFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionAdminGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  roles?: ModelSubscriptionIDInput | null,
  users?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAdminGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionAdminGroupFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserConceptsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  defaultConcept?: ModelSubscriptionIDInput | null,
  concepts?: ModelSubscriptionIDInput | null,
  conceptsRoles?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserConceptsFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserConceptsFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTransactionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  transactionID?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  guestName?: ModelSubscriptionStringInput | null,
  guestPhone?: ModelSubscriptionStringInput | null,
  guestID?: ModelSubscriptionStringInput | null,
  guestGroup?: ModelSubscriptionStringInput | null,
  guestsNames?: ModelSubscriptionStringInput | null,
  bookingID?: ModelSubscriptionStringInput | null,
  bookingDate?: ModelSubscriptionStringInput | null,
  amount_cents?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionBooleanInput | null,
  failureReason?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  timeSlots?: ModelSubscriptionStringInput | null,
  tables?: ModelSubscriptionStringInput | null,
  conceptID?: ModelSubscriptionStringInput | null,
  currency?: ModelSubscriptionStringInput | null,
  refund?: ModelSubscriptionBooleanInput | null,
  reference_orderID?: ModelSubscriptionStringInput | null,
  ownerID?: ModelSubscriptionStringInput | null,
  refunded_amount_cents?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTransactionFilterInput | null > | null,
  or?: Array< ModelSubscriptionTransactionFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPaymentStatFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  conceptID?: ModelSubscriptionStringInput | null,
  year?: ModelSubscriptionIntInput | null,
  month?: ModelSubscriptionIntInput | null,
  monthName?: ModelSubscriptionStringInput | null,
  earning?: ModelSubscriptionFloatInput | null,
  refund?: ModelSubscriptionFloatInput | null,
  earningTrxCount?: ModelSubscriptionIntInput | null,
  refundTrxCount?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPaymentStatFilterInput | null > | null,
  or?: Array< ModelSubscriptionPaymentStatFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionBookingGuestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  bookingID?: ModelSubscriptionStringInput | null,
  guestName?: ModelSubscriptionStringInput | null,
  paidAmount?: ModelSubscriptionFloatInput | null,
  paymentAmount?: ModelSubscriptionFloatInput | null,
  paymentStatus?: ModelSubscriptionBooleanInput | null,
  transactionID?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBookingGuestFilterInput | null > | null,
  or?: Array< ModelSubscriptionBookingGuestFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  preparationAreaID?: ModelSubscriptionIDInput | null,
  subCategory?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  guestView?: ModelSubscriptionBooleanInput | null,
  guestOrder?: ModelSubscriptionBooleanInput | null,
  staffOrder?: ModelSubscriptionBooleanInput | null,
  image?: ModelSubscriptionIDInput | null,
  darkImage?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  symphonyID?: ModelSubscriptionStringInput | null,
  isHidden?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMenuItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  kioskID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionIDInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  categoryID?: ModelSubscriptionIDInput | null,
  categoryName?: ModelSubscriptionStringInput | null,
  outOfStock?: ModelSubscriptionBooleanInput | null,
  requiredPoints?: ModelSubscriptionIntInput | null,
  symphonyID?: ModelSubscriptionStringInput | null,
  comboItemID?: ModelSubscriptionStringInput | null,
  ratings?: ModelSubscriptionIDInput | null,
  prices?: ModelSubscriptionIDInput | null,
  choiceGroups?: ModelSubscriptionIDInput | null,
  ExtraChoiceGroups?: ModelSubscriptionIDInput | null,
  enabled?: ModelSubscriptionBooleanInput | null,
  hasLimitedQuantity?: ModelSubscriptionBooleanInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  autoRestock?: ModelSubscriptionBooleanInput | null,
  restockQuantity?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  quantityOrdered?: ModelSubscriptionIntInput | null,
  performancePerDay?: ModelSubscriptionStringInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMenuItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionMenuItemFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPriceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  symphonyID?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPriceFilterInput | null > | null,
  or?: Array< ModelSubscriptionPriceFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionChoiceGroupsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  required?: ModelSubscriptionBooleanInput | null,
  maxNumberOfChoices?: ModelSubscriptionIntInput | null,
  minNumberOfChoices?: ModelSubscriptionIntInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  choices?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  simphonyID?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionChoiceGroupsFilterInput | null > | null,
  or?: Array< ModelSubscriptionChoiceGroupsFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionChoiceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  choiceGroupID?: ModelSubscriptionIDInput | null,
  choiceGroupName?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  symphonyID?: ModelSubscriptionStringInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  definitionNumber?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionChoiceFilterInput | null > | null,
  or?: Array< ModelSubscriptionChoiceFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCartFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  specialRequest?: ModelSubscriptionStringInput | null,
  deliveryFee?: ModelSubscriptionFloatInput | null,
  subTotal?: ModelSubscriptionFloatInput | null,
  subTaxTotal?: ModelSubscriptionFloatInput | null,
  totalPrice?: ModelSubscriptionFloatInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCartFilterInput | null > | null,
  or?: Array< ModelSubscriptionCartFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionAdminStatusFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  sub?: ModelSubscriptionStringInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  averageCallTime?: ModelSubscriptionFloatInput | null,
  numberOfCalls?: ModelSubscriptionFloatInput | null,
  idelDuration?: ModelSubscriptionFloatInput | null,
  activeDuration?: ModelSubscriptionFloatInput | null,
  awayDuration?: ModelSubscriptionFloatInput | null,
  lastCallTime?: ModelSubscriptionFloatInput | null,
  agentBarAverageCallTime?: ModelSubscriptionFloatInput | null,
  agentBarNumberOfCalls?: ModelSubscriptionFloatInput | null,
  type?: ModelSubscriptionStringInput | null,
  waiterStatus?: ModelSubscriptionStringInput | null,
  totalAverageRatio?: ModelSubscriptionFloatInput | null,
  performancePerDay?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAdminStatusFilterInput | null > | null,
  or?: Array< ModelSubscriptionAdminStatusFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionOnlineOrderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  statusID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  reason?: ModelSubscriptionStringInput | null,
  orderType?: ModelSubscriptionStringInput | null,
  channel?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionIDInput | null,
  userName?: ModelSubscriptionStringInput | null,
  userPhoneNumber?: ModelSubscriptionStringInput | null,
  dispatcherID?: ModelSubscriptionStringInput | null,
  dispatcherName?: ModelSubscriptionStringInput | null,
  cartID?: ModelSubscriptionIDInput | null,
  tenderId?: ModelSubscriptionIntInput | null,
  employeeId?: ModelSubscriptionIntInput | null,
  rvcId?: ModelSubscriptionIntInput | null,
  checkNumber?: ModelSubscriptionStringInput | null,
  totalAmount?: ModelSubscriptionFloatInput | null,
  discountedValue?: ModelSubscriptionFloatInput | null,
  subTotal?: ModelSubscriptionFloatInput | null,
  subTaxTotal?: ModelSubscriptionFloatInput | null,
  tax?: ModelSubscriptionFloatInput | null,
  deliveryFee?: ModelSubscriptionFloatInput | null,
  orderTime?: ModelSubscriptionStringInput | null,
  executionArn?: ModelSubscriptionStringInput | null,
  specialRequest?: ModelSubscriptionStringInput | null,
  orderAddress?: ModelSubscriptionStringInput | null,
  checkRef?: ModelSubscriptionStringInput | null,
  discountID?: ModelSubscriptionIntInput | null,
  amount_cents?: ModelSubscriptionStringInput | null,
  transactionID?: ModelSubscriptionStringInput | null,
  paymentStatus?: ModelSubscriptionBooleanInput | null,
  paymentType?: ModelSubscriptionStringInput | null,
  referenceId?: ModelSubscriptionStringInput | null,
  cardHolderNumber?: ModelSubscriptionStringInput | null,
  cardHolderName?: ModelSubscriptionStringInput | null,
  cardSchemeName?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  acceptedByWaiterID?: ModelSubscriptionStringInput | null,
  acceptedByWaiterName?: ModelSubscriptionStringInput | null,
  deliveredByWaiterID?: ModelSubscriptionStringInput | null,
  tableID?: ModelSubscriptionStringInput | null,
  tableName?: ModelSubscriptionStringInput | null,
  shift?: ModelSubscriptionStringInput | null,
  parentOrder?: ModelSubscriptionIDInput | null,
  preparationAreaID?: ModelSubscriptionIDInput | null,
  childrenStatus?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  acceptedByWaiterAt?: ModelSubscriptionStringInput | null,
  sentToKitchenAt?: ModelSubscriptionStringInput | null,
  acceptedByKitchenAt?: ModelSubscriptionStringInput | null,
  readyAt?: ModelSubscriptionStringInput | null,
  outForDeliveryAt?: ModelSubscriptionStringInput | null,
  deliveredAt?: ModelSubscriptionStringInput | null,
  promoCodeID?: ModelSubscriptionIDInput | null,
  promoCodeName?: ModelSubscriptionStringInput | null,
  promoCodeAppliedTo?: ModelSubscriptionStringInput | null,
  reviewID?: ModelSubscriptionIDInput | null,
  simphEmpID?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  tmpField?: ModelSubscriptionStringInput | null,
  updatedByID?: ModelSubscriptionStringInput | null,
  updatedByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOnlineOrderFilterInput | null > | null,
  or?: Array< ModelSubscriptionOnlineOrderFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionOrderStatusFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  color?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionIntInput | null,
  accessibleStatus?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrderStatusFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrderStatusFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionDiscountCouponFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  discountPercent?: ModelSubscriptionStringInput | null,
  usedBy?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDiscountCouponFilterInput | null > | null,
  or?: Array< ModelSubscriptionDiscountCouponFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionZoneFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  location?: ModelSubscriptionStringInput | null,
  deliveryFee?: ModelSubscriptionIntInput | null,
  polygon?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionZoneFilterInput | null > | null,
  or?: Array< ModelSubscriptionZoneFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserIPFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ip?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserIPFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserIPFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMainCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  openTime?: ModelSubscriptionStringInput | null,
  closeTime?: ModelSubscriptionStringInput | null,
  guestView?: ModelSubscriptionBooleanInput | null,
  guestOrder?: ModelSubscriptionBooleanInput | null,
  staffOrder?: ModelSubscriptionBooleanInput | null,
  image?: ModelSubscriptionStringInput | null,
  menuPDF?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMainCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionMainCategoryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionSubCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  openTime?: ModelSubscriptionStringInput | null,
  closeTime?: ModelSubscriptionStringInput | null,
  mainCategoryID?: ModelSubscriptionIDInput | null,
  guestView?: ModelSubscriptionBooleanInput | null,
  guestOrder?: ModelSubscriptionBooleanInput | null,
  staffOrder?: ModelSubscriptionBooleanInput | null,
  image?: ModelSubscriptionStringInput | null,
  menuPDF?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSubCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionSubCategoryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionShiftFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  startTime?: ModelSubscriptionStringInput | null,
  endTime?: ModelSubscriptionStringInput | null,
  users?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionShiftFilterInput | null > | null,
  or?: Array< ModelSubscriptionShiftFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionChannelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  numberOfOrders?: ModelSubscriptionIntInput | null,
  isActive?: ModelSubscriptionBooleanInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionChannelFilterInput | null > | null,
  or?: Array< ModelSubscriptionChannelFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPromocodeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  redemptions?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  appliesTo?: ModelSubscriptionStringInput | null,
  minOrderReq?: ModelSubscriptionStringInput | null,
  minOrderVal?: ModelSubscriptionIntInput | null,
  minOrderQty?: ModelSubscriptionIntInput | null,
  tenderReq?: ModelSubscriptionStringInput | null,
  discountVal?: ModelSubscriptionIntInput | null,
  discountLimit?: ModelSubscriptionIntInput | null,
  quota?: ModelSubscriptionIntInput | null,
  quotaUsed?: ModelSubscriptionIntInput | null,
  totalRevenue?: ModelSubscriptionIntInput | null,
  appliedDiscountedVal?: ModelSubscriptionIntInput | null,
  usePerCustomer?: ModelSubscriptionIntInput | null,
  channelReq?: ModelSubscriptionStringInput | null,
  customerEligibility?: ModelSubscriptionStringInput | null,
  customerReq?: ModelSubscriptionStringInput | null,
  spendingExceeds?: ModelSubscriptionIntInput | null,
  spendingBelow?: ModelSubscriptionIntInput | null,
  orderSinceDate?: ModelSubscriptionStringInput | null,
  orderFromDate?: ModelSubscriptionStringInput | null,
  orderToDate?: ModelSubscriptionStringInput | null,
  isDisplayed?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPromocodeFilterInput | null > | null,
  or?: Array< ModelSubscriptionPromocodeFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPromocodeDashboardFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  totalRevenue?: ModelSubscriptionIntInput | null,
  totalRedemptions?: ModelSubscriptionIntInput | null,
  totalDiscount?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionPromocodeDashboardFilterInput | null > | null,
  or?: Array< ModelSubscriptionPromocodeDashboardFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionLoyaltyItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  points?: ModelSubscriptionIntInput | null,
  redemptionPerUser?: ModelSubscriptionIntInput | null,
  image?: ModelSubscriptionStringInput | null,
  simphonyID?: ModelSubscriptionStringInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  isActive?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLoyaltyItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionLoyaltyItemFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionOTPFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  OTP?: ModelSubscriptionStringInput | null,
  redeemed?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  loyaltyItemID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOTPFilterInput | null > | null,
  or?: Array< ModelSubscriptionOTPFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type CreateEmailVerificationMutationVariables = {
  input: CreateEmailVerificationInput,
  condition?: ModelEmailVerificationConditionInput | null,
};

export type CreateEmailVerificationMutation = {
  createEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    accountID: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateEmailVerificationMutationVariables = {
  input: UpdateEmailVerificationInput,
  condition?: ModelEmailVerificationConditionInput | null,
};

export type UpdateEmailVerificationMutation = {
  updateEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    accountID: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteEmailVerificationMutationVariables = {
  input: DeleteEmailVerificationInput,
  condition?: ModelEmailVerificationConditionInput | null,
};

export type DeleteEmailVerificationMutation = {
  deleteEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    accountID: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSocialLinkMutationVariables = {
  input: CreateSocialLinkInput,
  condition?: ModelSocialLinkConditionInput | null,
};

export type CreateSocialLinkMutation = {
  createSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSocialLinkMutationVariables = {
  input: UpdateSocialLinkInput,
  condition?: ModelSocialLinkConditionInput | null,
};

export type UpdateSocialLinkMutation = {
  updateSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSocialLinkMutationVariables = {
  input: DeleteSocialLinkInput,
  condition?: ModelSocialLinkConditionInput | null,
};

export type DeleteSocialLinkMutation = {
  deleteSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLanguageMutationVariables = {
  input: CreateLanguageInput,
  condition?: ModelLanguageConditionInput | null,
};

export type CreateLanguageMutation = {
  createLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLanguageMutationVariables = {
  input: UpdateLanguageInput,
  condition?: ModelLanguageConditionInput | null,
};

export type UpdateLanguageMutation = {
  updateLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLanguageMutationVariables = {
  input: DeleteLanguageInput,
  condition?: ModelLanguageConditionInput | null,
};

export type DeleteLanguageMutation = {
  deleteLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAttachmentMutationVariables = {
  input: CreateAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type CreateAttachmentMutation = {
  createAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAttachmentMutationVariables = {
  input: UpdateAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type UpdateAttachmentMutation = {
  updateAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAttachmentMutationVariables = {
  input: DeleteAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type DeleteAttachmentMutation = {
  deleteAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateGroupMutationVariables = {
  input: CreateGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type CreateGroupMutation = {
  createGroup?:  {
    __typename: "Group",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    color?: string | null,
    requireApproval?: boolean | null,
    status?: string | null,
    agents?: Array< string | null > | null,
    groupSettings?: Array< string | null > | null,
    numToUpgrade?: number | null,
    numToDowngrade?: number | null,
    upgradeGroup?: string | null,
    downgradeGroup?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateGroupMutationVariables = {
  input: UpdateGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type UpdateGroupMutation = {
  updateGroup?:  {
    __typename: "Group",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    color?: string | null,
    requireApproval?: boolean | null,
    status?: string | null,
    agents?: Array< string | null > | null,
    groupSettings?: Array< string | null > | null,
    numToUpgrade?: number | null,
    numToDowngrade?: number | null,
    upgradeGroup?: string | null,
    downgradeGroup?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteGroupMutationVariables = {
  input: DeleteGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type DeleteGroupMutation = {
  deleteGroup?:  {
    __typename: "Group",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    color?: string | null,
    requireApproval?: boolean | null,
    status?: string | null,
    agents?: Array< string | null > | null,
    groupSettings?: Array< string | null > | null,
    numToUpgrade?: number | null,
    numToDowngrade?: number | null,
    upgradeGroup?: string | null,
    downgradeGroup?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateGroupSettingMutationVariables = {
  input: CreateGroupSettingInput,
  condition?: ModelGroupSettingConditionInput | null,
};

export type CreateGroupSettingMutation = {
  createGroupSetting?:  {
    __typename: "GroupSetting",
    id: string,
    groupID: string,
    statusID: string,
    needsVerification: boolean,
    needsDeposit: boolean,
    isDefault: boolean,
    amount?: number | null,
    days?: Array< string | null > | null,
    timeSlots?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateGroupSettingMutationVariables = {
  input: UpdateGroupSettingInput,
  condition?: ModelGroupSettingConditionInput | null,
};

export type UpdateGroupSettingMutation = {
  updateGroupSetting?:  {
    __typename: "GroupSetting",
    id: string,
    groupID: string,
    statusID: string,
    needsVerification: boolean,
    needsDeposit: boolean,
    isDefault: boolean,
    amount?: number | null,
    days?: Array< string | null > | null,
    timeSlots?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteGroupSettingMutationVariables = {
  input: DeleteGroupSettingInput,
  condition?: ModelGroupSettingConditionInput | null,
};

export type DeleteGroupSettingMutation = {
  deleteGroupSetting?:  {
    __typename: "GroupSetting",
    id: string,
    groupID: string,
    statusID: string,
    needsVerification: boolean,
    needsDeposit: boolean,
    isDefault: boolean,
    amount?: number | null,
    days?: Array< string | null > | null,
    timeSlots?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCustomerStatsMutationVariables = {
  input: CreateCustomerStatsInput,
  condition?: ModelCustomerStatsConditionInput | null,
};

export type CreateCustomerStatsMutation = {
  createCustomerStats?:  {
    __typename: "CustomerStats",
    id: string,
    conceptID: string,
    totalReservation?: number | null,
    confirmed?: number | null,
    notConfirmed?: number | null,
    canceled?: number | null,
    checkIn?: number | null,
    noShow?: number | null,
    userID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCustomerStatsMutationVariables = {
  input: UpdateCustomerStatsInput,
  condition?: ModelCustomerStatsConditionInput | null,
};

export type UpdateCustomerStatsMutation = {
  updateCustomerStats?:  {
    __typename: "CustomerStats",
    id: string,
    conceptID: string,
    totalReservation?: number | null,
    confirmed?: number | null,
    notConfirmed?: number | null,
    canceled?: number | null,
    checkIn?: number | null,
    noShow?: number | null,
    userID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCustomerStatsMutationVariables = {
  input: DeleteCustomerStatsInput,
  condition?: ModelCustomerStatsConditionInput | null,
};

export type DeleteCustomerStatsMutation = {
  deleteCustomerStats?:  {
    __typename: "CustomerStats",
    id: string,
    conceptID: string,
    totalReservation?: number | null,
    confirmed?: number | null,
    notConfirmed?: number | null,
    canceled?: number | null,
    checkIn?: number | null,
    noShow?: number | null,
    userID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFlagMutationVariables = {
  input: CreateFlagInput,
  condition?: ModelFlagConditionInput | null,
};

export type CreateFlagMutation = {
  createFlag?:  {
    __typename: "Flag",
    id: string,
    accountID: string,
    name: string,
    icon: string,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFlagMutationVariables = {
  input: UpdateFlagInput,
  condition?: ModelFlagConditionInput | null,
};

export type UpdateFlagMutation = {
  updateFlag?:  {
    __typename: "Flag",
    id: string,
    accountID: string,
    name: string,
    icon: string,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFlagMutationVariables = {
  input: DeleteFlagInput,
  condition?: ModelFlagConditionInput | null,
};

export type DeleteFlagMutation = {
  deleteFlag?:  {
    __typename: "Flag",
    id: string,
    accountID: string,
    name: string,
    icon: string,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateInterestMutationVariables = {
  input: CreateInterestInput,
  condition?: ModelInterestConditionInput | null,
};

export type CreateInterestMutation = {
  createInterest?:  {
    __typename: "Interest",
    id: string,
    accountID: string,
    name: string,
    icon?: string | null,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateInterestMutationVariables = {
  input: UpdateInterestInput,
  condition?: ModelInterestConditionInput | null,
};

export type UpdateInterestMutation = {
  updateInterest?:  {
    __typename: "Interest",
    id: string,
    accountID: string,
    name: string,
    icon?: string | null,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteInterestMutationVariables = {
  input: DeleteInterestInput,
  condition?: ModelInterestConditionInput | null,
};

export type DeleteInterestMutation = {
  deleteInterest?:  {
    __typename: "Interest",
    id: string,
    accountID: string,
    name: string,
    icon?: string | null,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateReservationStatusMutationVariables = {
  input: CreateReservationStatusInput,
  condition?: ModelReservationStatusConditionInput | null,
};

export type CreateReservationStatusMutation = {
  createReservationStatus?:  {
    __typename: "ReservationStatus",
    id: string,
    accountID: string,
    name: string,
    category: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    reservationStatusImageId?: string | null,
  } | null,
};

export type UpdateReservationStatusMutationVariables = {
  input: UpdateReservationStatusInput,
  condition?: ModelReservationStatusConditionInput | null,
};

export type UpdateReservationStatusMutation = {
  updateReservationStatus?:  {
    __typename: "ReservationStatus",
    id: string,
    accountID: string,
    name: string,
    category: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    reservationStatusImageId?: string | null,
  } | null,
};

export type DeleteReservationStatusMutationVariables = {
  input: DeleteReservationStatusInput,
  condition?: ModelReservationStatusConditionInput | null,
};

export type DeleteReservationStatusMutation = {
  deleteReservationStatus?:  {
    __typename: "ReservationStatus",
    id: string,
    accountID: string,
    name: string,
    category: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    reservationStatusImageId?: string | null,
  } | null,
};

export type CreateTimeSlotMutationVariables = {
  input: CreateTimeSlotInput,
  condition?: ModelTimeSlotConditionInput | null,
};

export type CreateTimeSlotMutation = {
  createTimeSlot?:  {
    __typename: "TimeSlot",
    id: string,
    conceptID: string,
    name: string,
    friendlyName: string,
    bookings?: Array< string | null > | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTimeSlotMutationVariables = {
  input: UpdateTimeSlotInput,
  condition?: ModelTimeSlotConditionInput | null,
};

export type UpdateTimeSlotMutation = {
  updateTimeSlot?:  {
    __typename: "TimeSlot",
    id: string,
    conceptID: string,
    name: string,
    friendlyName: string,
    bookings?: Array< string | null > | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTimeSlotMutationVariables = {
  input: DeleteTimeSlotInput,
  condition?: ModelTimeSlotConditionInput | null,
};

export type DeleteTimeSlotMutation = {
  deleteTimeSlot?:  {
    __typename: "TimeSlot",
    id: string,
    conceptID: string,
    name: string,
    friendlyName: string,
    bookings?: Array< string | null > | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAreaMutationVariables = {
  input: CreateAreaInput,
  condition?: ModelAreaConditionInput | null,
};

export type CreateAreaMutation = {
  createArea?:  {
    __typename: "Area",
    id: string,
    conceptID: string,
    name: string,
    isDefault: boolean,
    expiryDate?: string | null,
    days?: Array< string | null > | null,
    timeSlots?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAreaMutationVariables = {
  input: UpdateAreaInput,
  condition?: ModelAreaConditionInput | null,
};

export type UpdateAreaMutation = {
  updateArea?:  {
    __typename: "Area",
    id: string,
    conceptID: string,
    name: string,
    isDefault: boolean,
    expiryDate?: string | null,
    days?: Array< string | null > | null,
    timeSlots?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAreaMutationVariables = {
  input: DeleteAreaInput,
  condition?: ModelAreaConditionInput | null,
};

export type DeleteAreaMutation = {
  deleteArea?:  {
    __typename: "Area",
    id: string,
    conceptID: string,
    name: string,
    isDefault: boolean,
    expiryDate?: string | null,
    days?: Array< string | null > | null,
    timeSlots?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTableMutationVariables = {
  input: CreateTableInput,
  condition?: ModelTableConditionInput | null,
};

export type CreateTableMutation = {
  createTable?:  {
    __typename: "Table",
    id: string,
    conceptID: string,
    label: string,
    name: string,
    width?: number | null,
    height?: number | null,
    capacity?: number | null,
    image?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTableMutationVariables = {
  input: UpdateTableInput,
  condition?: ModelTableConditionInput | null,
};

export type UpdateTableMutation = {
  updateTable?:  {
    __typename: "Table",
    id: string,
    conceptID: string,
    label: string,
    name: string,
    width?: number | null,
    height?: number | null,
    capacity?: number | null,
    image?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTableMutationVariables = {
  input: DeleteTableInput,
  condition?: ModelTableConditionInput | null,
};

export type DeleteTableMutation = {
  deleteTable?:  {
    __typename: "Table",
    id: string,
    conceptID: string,
    label: string,
    name: string,
    width?: number | null,
    height?: number | null,
    capacity?: number | null,
    image?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFriendMutationVariables = {
  input: CreateFriendInput,
  condition?: ModelFriendConditionInput | null,
};

export type CreateFriendMutation = {
  createFriend?:  {
    __typename: "Friend",
    id: string,
    customerId?: string | null,
    friendId?: string | null,
    bookingID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFriendMutationVariables = {
  input: UpdateFriendInput,
  condition?: ModelFriendConditionInput | null,
};

export type UpdateFriendMutation = {
  updateFriend?:  {
    __typename: "Friend",
    id: string,
    customerId?: string | null,
    friendId?: string | null,
    bookingID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFriendMutationVariables = {
  input: DeleteFriendInput,
  condition?: ModelFriendConditionInput | null,
};

export type DeleteFriendMutation = {
  deleteFriend?:  {
    __typename: "Friend",
    id: string,
    customerId?: string | null,
    friendId?: string | null,
    bookingID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    id: string,
    message: string,
    customerId?: string | null,
    bookingId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCommentMutationVariables = {
  input: UpdateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type UpdateCommentMutation = {
  updateComment?:  {
    __typename: "Comment",
    id: string,
    message: string,
    customerId?: string | null,
    bookingId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCommentMutationVariables = {
  input: DeleteCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type DeleteCommentMutation = {
  deleteComment?:  {
    __typename: "Comment",
    id: string,
    message: string,
    customerId?: string | null,
    bookingId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateServingAreaMutationVariables = {
  input: CreateServingAreaInput,
  condition?: ModelServingAreaConditionInput | null,
};

export type CreateServingAreaMutation = {
  createServingArea?:  {
    __typename: "ServingArea",
    id: string,
    name: string,
    fees: number,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateServingAreaMutationVariables = {
  input: UpdateServingAreaInput,
  condition?: ModelServingAreaConditionInput | null,
};

export type UpdateServingAreaMutation = {
  updateServingArea?:  {
    __typename: "ServingArea",
    id: string,
    name: string,
    fees: number,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteServingAreaMutationVariables = {
  input: DeleteServingAreaInput,
  condition?: ModelServingAreaConditionInput | null,
};

export type DeleteServingAreaMutation = {
  deleteServingArea?:  {
    __typename: "ServingArea",
    id: string,
    name: string,
    fees: number,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id: string,
    accountID: string,
    conceptID: string,
    name?: string | null,
    description?: string | null,
    channel?: string | null,
    message?: string | null,
    from?: string | null,
    enabled?: boolean | null,
    autoSend?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    accountID: string,
    conceptID: string,
    name?: string | null,
    description?: string | null,
    channel?: string | null,
    message?: string | null,
    from?: string | null,
    enabled?: boolean | null,
    autoSend?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    accountID: string,
    conceptID: string,
    name?: string | null,
    description?: string | null,
    channel?: string | null,
    message?: string | null,
    from?: string | null,
    enabled?: boolean | null,
    autoSend?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMobileNotificationMutationVariables = {
  input: CreateMobileNotificationInput,
  condition?: ModelMobileNotificationConditionInput | null,
};

export type CreateMobileNotificationMutation = {
  createMobileNotification?:  {
    __typename: "MobileNotification",
    id: string,
    title?: string | null,
    description?: string | null,
    body?: string | null,
    default?: boolean | null,
    event?: boolean | null,
    image?: string | null,
    concepts?: Array< string | null > | null,
    condition?: Array< string | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    group?: string | null,
    read?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMobileNotificationMutationVariables = {
  input: UpdateMobileNotificationInput,
  condition?: ModelMobileNotificationConditionInput | null,
};

export type UpdateMobileNotificationMutation = {
  updateMobileNotification?:  {
    __typename: "MobileNotification",
    id: string,
    title?: string | null,
    description?: string | null,
    body?: string | null,
    default?: boolean | null,
    event?: boolean | null,
    image?: string | null,
    concepts?: Array< string | null > | null,
    condition?: Array< string | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    group?: string | null,
    read?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMobileNotificationMutationVariables = {
  input: DeleteMobileNotificationInput,
  condition?: ModelMobileNotificationConditionInput | null,
};

export type DeleteMobileNotificationMutation = {
  deleteMobileNotification?:  {
    __typename: "MobileNotification",
    id: string,
    title?: string | null,
    description?: string | null,
    body?: string | null,
    default?: boolean | null,
    event?: boolean | null,
    image?: string | null,
    concepts?: Array< string | null > | null,
    condition?: Array< string | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    group?: string | null,
    read?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserPushTokenMutationVariables = {
  input: CreateUserPushTokenInput,
  condition?: ModelUserPushTokenConditionInput | null,
};

export type CreateUserPushTokenMutation = {
  createUserPushToken?:  {
    __typename: "UserPushToken",
    id: string,
    userID?: string | null,
    token?: string | null,
    reciveNotification?: boolean | null,
    type?: string | null,
    os?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserPushTokenMutationVariables = {
  input: UpdateUserPushTokenInput,
  condition?: ModelUserPushTokenConditionInput | null,
};

export type UpdateUserPushTokenMutation = {
  updateUserPushToken?:  {
    __typename: "UserPushToken",
    id: string,
    userID?: string | null,
    token?: string | null,
    reciveNotification?: boolean | null,
    type?: string | null,
    os?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserPushTokenMutationVariables = {
  input: DeleteUserPushTokenInput,
  condition?: ModelUserPushTokenConditionInput | null,
};

export type DeleteUserPushTokenMutation = {
  deleteUserPushToken?:  {
    __typename: "UserPushToken",
    id: string,
    userID?: string | null,
    token?: string | null,
    reciveNotification?: boolean | null,
    type?: string | null,
    os?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateNotificationReceiverMutationVariables = {
  input: CreateNotificationReceiverInput,
  condition?: ModelNotificationReceiverConditionInput | null,
};

export type CreateNotificationReceiverMutation = {
  createNotificationReceiver?:  {
    __typename: "NotificationReceiver",
    id: string,
    userID?: string | null,
    notificationID?: string | null,
    seen?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateNotificationReceiverMutationVariables = {
  input: UpdateNotificationReceiverInput,
  condition?: ModelNotificationReceiverConditionInput | null,
};

export type UpdateNotificationReceiverMutation = {
  updateNotificationReceiver?:  {
    __typename: "NotificationReceiver",
    id: string,
    userID?: string | null,
    notificationID?: string | null,
    seen?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteNotificationReceiverMutationVariables = {
  input: DeleteNotificationReceiverInput,
  condition?: ModelNotificationReceiverConditionInput | null,
};

export type DeleteNotificationReceiverMutation = {
  deleteNotificationReceiver?:  {
    __typename: "NotificationReceiver",
    id: string,
    userID?: string | null,
    notificationID?: string | null,
    seen?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePreparationAreaMutationVariables = {
  input: CreatePreparationAreaInput,
  condition?: ModelPreparationAreaConditionInput | null,
};

export type CreatePreparationAreaMutation = {
  createPreparationArea?:  {
    __typename: "PreparationArea",
    id: string,
    accountID: string,
    name: string,
    isAutoAccept?: boolean | null,
    hasKDS?: boolean | null,
    hasPrinter?: boolean | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    precedence: string,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePreparationAreaMutationVariables = {
  input: UpdatePreparationAreaInput,
  condition?: ModelPreparationAreaConditionInput | null,
};

export type UpdatePreparationAreaMutation = {
  updatePreparationArea?:  {
    __typename: "PreparationArea",
    id: string,
    accountID: string,
    name: string,
    isAutoAccept?: boolean | null,
    hasKDS?: boolean | null,
    hasPrinter?: boolean | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    precedence: string,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePreparationAreaMutationVariables = {
  input: DeletePreparationAreaInput,
  condition?: ModelPreparationAreaConditionInput | null,
};

export type DeletePreparationAreaMutation = {
  deletePreparationArea?:  {
    __typename: "PreparationArea",
    id: string,
    accountID: string,
    name: string,
    isAutoAccept?: boolean | null,
    hasKDS?: boolean | null,
    hasPrinter?: boolean | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    precedence: string,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateQRcodeMutationVariables = {
  input: CreateQRcodeInput,
  condition?: ModelQRcodeConditionInput | null,
};

export type CreateQRcodeMutation = {
  createQRcode?:  {
    __typename: "QRcode",
    id: string,
    tableID: string,
    conceptID: string,
    tableName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateQRcodeMutationVariables = {
  input: UpdateQRcodeInput,
  condition?: ModelQRcodeConditionInput | null,
};

export type UpdateQRcodeMutation = {
  updateQRcode?:  {
    __typename: "QRcode",
    id: string,
    tableID: string,
    conceptID: string,
    tableName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteQRcodeMutationVariables = {
  input: DeleteQRcodeInput,
  condition?: ModelQRcodeConditionInput | null,
};

export type DeleteQRcodeMutation = {
  deleteQRcode?:  {
    __typename: "QRcode",
    id: string,
    tableID: string,
    conceptID: string,
    tableName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCallWaiterMutationVariables = {
  input: CreateCallWaiterInput,
  condition?: ModelCallWaiterConditionInput | null,
};

export type CreateCallWaiterMutation = {
  createCallWaiter?:  {
    __typename: "CallWaiter",
    id: string,
    conceptID?: string | null,
    tableID?: string | null,
    tableName?: string | null,
    waiterID?: string | null,
    waiterName?: string | null,
    userID?: string | null,
    count?: number | null,
    status?: string | null,
    deleted?: string | null,
    createdAt: string,
    acceptedAt?: string | null,
    arrivedAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCallWaiterMutationVariables = {
  input: UpdateCallWaiterInput,
  condition?: ModelCallWaiterConditionInput | null,
};

export type UpdateCallWaiterMutation = {
  updateCallWaiter?:  {
    __typename: "CallWaiter",
    id: string,
    conceptID?: string | null,
    tableID?: string | null,
    tableName?: string | null,
    waiterID?: string | null,
    waiterName?: string | null,
    userID?: string | null,
    count?: number | null,
    status?: string | null,
    deleted?: string | null,
    createdAt: string,
    acceptedAt?: string | null,
    arrivedAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCallWaiterMutationVariables = {
  input: DeleteCallWaiterInput,
  condition?: ModelCallWaiterConditionInput | null,
};

export type DeleteCallWaiterMutation = {
  deleteCallWaiter?:  {
    __typename: "CallWaiter",
    id: string,
    conceptID?: string | null,
    tableID?: string | null,
    tableName?: string | null,
    waiterID?: string | null,
    waiterName?: string | null,
    userID?: string | null,
    count?: number | null,
    status?: string | null,
    deleted?: string | null,
    createdAt: string,
    acceptedAt?: string | null,
    arrivedAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    accountID: string,
    username?: string | null,
    preferred_username?: string | null,
    email?: string | null,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    pictureCrop?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    facebook?: string | null,
    instagram?: string | null,
    group?: string | null,
    phones?: Array< string | null > | null,
    flags?: Array< string | null > | null,
    flagsName?: Array< string | null > | null,
    interests?: Array< string | null > | null,
    interestsName?: Array< string | null > | null,
    status?: string | null,
    stats?: Array< string | null > | null,
    spending?: number | null,
    prevOrderDate?: string | null,
    lastOrderDate?: string | null,
    promocodes?:  Array< {
      __typename: "PromocodeItem",
      name: string,
      promocodeId: string,
      canBeUsed: number,
      discountVal: number,
      discountLimit?: number | null,
      isDisplayed?: boolean | null,
    } | null > | null,
    mostOrderedItems?: string | null,
    points?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    cognitoUsername?: string | null,
    cognitoSub?: string | null,
    updated?: string | null,
    sub?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    accountID: string,
    username?: string | null,
    preferred_username?: string | null,
    email?: string | null,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    pictureCrop?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    facebook?: string | null,
    instagram?: string | null,
    group?: string | null,
    phones?: Array< string | null > | null,
    flags?: Array< string | null > | null,
    flagsName?: Array< string | null > | null,
    interests?: Array< string | null > | null,
    interestsName?: Array< string | null > | null,
    status?: string | null,
    stats?: Array< string | null > | null,
    spending?: number | null,
    prevOrderDate?: string | null,
    lastOrderDate?: string | null,
    promocodes?:  Array< {
      __typename: "PromocodeItem",
      name: string,
      promocodeId: string,
      canBeUsed: number,
      discountVal: number,
      discountLimit?: number | null,
      isDisplayed?: boolean | null,
    } | null > | null,
    mostOrderedItems?: string | null,
    points?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    cognitoUsername?: string | null,
    cognitoSub?: string | null,
    updated?: string | null,
    sub?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    accountID: string,
    username?: string | null,
    preferred_username?: string | null,
    email?: string | null,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    pictureCrop?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    facebook?: string | null,
    instagram?: string | null,
    group?: string | null,
    phones?: Array< string | null > | null,
    flags?: Array< string | null > | null,
    flagsName?: Array< string | null > | null,
    interests?: Array< string | null > | null,
    interestsName?: Array< string | null > | null,
    status?: string | null,
    stats?: Array< string | null > | null,
    spending?: number | null,
    prevOrderDate?: string | null,
    lastOrderDate?: string | null,
    promocodes?:  Array< {
      __typename: "PromocodeItem",
      name: string,
      promocodeId: string,
      canBeUsed: number,
      discountVal: number,
      discountLimit?: number | null,
      isDisplayed?: boolean | null,
    } | null > | null,
    mostOrderedItems?: string | null,
    points?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    cognitoUsername?: string | null,
    cognitoSub?: string | null,
    updated?: string | null,
    sub?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateReviewMutationVariables = {
  input: CreateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type CreateReviewMutation = {
  createReview?:  {
    __typename: "Review",
    id: string,
    accountID?: string | null,
    bookingID?: string | null,
    guestName?: string | null,
    guestId?: string | null,
    reason?: Array< string | null > | null,
    value?: string | null,
    userID?: string | null,
    orderID?: string | null,
    rate?: number | null,
    satisfaction?: string | null,
    thoughts?: string | null,
    itemReviews?:  Array< {
      __typename: "ItemReview",
      itemID: string,
      rate?: number | null,
      satisfaction?: string | null,
      multiLanguages?: string | null,
    } | null > | null,
    photos?: Array< string | null > | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateReviewMutationVariables = {
  input: UpdateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type UpdateReviewMutation = {
  updateReview?:  {
    __typename: "Review",
    id: string,
    accountID?: string | null,
    bookingID?: string | null,
    guestName?: string | null,
    guestId?: string | null,
    reason?: Array< string | null > | null,
    value?: string | null,
    userID?: string | null,
    orderID?: string | null,
    rate?: number | null,
    satisfaction?: string | null,
    thoughts?: string | null,
    itemReviews?:  Array< {
      __typename: "ItemReview",
      itemID: string,
      rate?: number | null,
      satisfaction?: string | null,
      multiLanguages?: string | null,
    } | null > | null,
    photos?: Array< string | null > | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteReviewMutationVariables = {
  input: DeleteReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type DeleteReviewMutation = {
  deleteReview?:  {
    __typename: "Review",
    id: string,
    accountID?: string | null,
    bookingID?: string | null,
    guestName?: string | null,
    guestId?: string | null,
    reason?: Array< string | null > | null,
    value?: string | null,
    userID?: string | null,
    orderID?: string | null,
    rate?: number | null,
    satisfaction?: string | null,
    thoughts?: string | null,
    itemReviews?:  Array< {
      __typename: "ItemReview",
      itemID: string,
      rate?: number | null,
      satisfaction?: string | null,
      multiLanguages?: string | null,
    } | null > | null,
    photos?: Array< string | null > | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAccountMutationVariables = {
  input: CreateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "Account",
    id: string,
    logo?: string | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    callCenterEmployeesIds?: Array< string | null > | null,
    kpiReportResetFrequency?: string | null,
    agentBarResetFrequency?: string | null,
    dynamicStatus?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    id: string,
    logo?: string | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    callCenterEmployeesIds?: Array< string | null > | null,
    kpiReportResetFrequency?: string | null,
    agentBarResetFrequency?: string | null,
    dynamicStatus?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAccountMutationVariables = {
  input: DeleteAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type DeleteAccountMutation = {
  deleteAccount?:  {
    __typename: "Account",
    id: string,
    logo?: string | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    callCenterEmployeesIds?: Array< string | null > | null,
    kpiReportResetFrequency?: string | null,
    agentBarResetFrequency?: string | null,
    dynamicStatus?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFeatureMutationVariables = {
  input: CreateFeatureInput,
  condition?: ModelFeatureConditionInput | null,
};

export type CreateFeatureMutation = {
  createFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFeatureMutationVariables = {
  input: UpdateFeatureInput,
  condition?: ModelFeatureConditionInput | null,
};

export type UpdateFeatureMutation = {
  updateFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFeatureMutationVariables = {
  input: DeleteFeatureInput,
  condition?: ModelFeatureConditionInput | null,
};

export type DeleteFeatureMutation = {
  deleteFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMediaMutationVariables = {
  input: CreateMediaInput,
  condition?: ModelMediaConditionInput | null,
};

export type CreateMediaMutation = {
  createMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMediaMutationVariables = {
  input: UpdateMediaInput,
  condition?: ModelMediaConditionInput | null,
};

export type UpdateMediaMutation = {
  updateMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMediaMutationVariables = {
  input: DeleteMediaInput,
  condition?: ModelMediaConditionInput | null,
};

export type DeleteMediaMutation = {
  deleteMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateConceptMutationVariables = {
  input: CreateConceptInput,
  condition?: ModelConceptConditionInput | null,
};

export type CreateConceptMutation = {
  createConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    kiosks?: Array< string | null > | null,
    merchantID?: string | null,
    merchantUsername?: string | null,
    merchantPassword?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    simphonyServerBaseURL?: string | null,
    simphonyShortOrgName?: string | null,
    simphonyLocRef?: string | null,
    simphonyToken?: string | null,
    revenueCenterID?: number | null,
    employeeID?: number | null,
    tenderID?: number | null,
    tenders?:  {
      __typename: "ModelTenderConnection",
      items:  Array< {
        __typename: "Tender",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptTendersId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderType?:  {
      __typename: "ModelOrderTypeConnection",
      items:  Array< {
        __typename: "OrderType",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptOrderTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    discountID?: number | null,
    useServiceCharge?: boolean | null,
    serviceChargeId?: number | null,
    specialRequestID?: number | null,
    addressID?: number | null,
    simphonyAuthClientId?: string | null,
    simphonyAuthUsername?: string | null,
    simphonyAuthPassword?: string | null,
    simphonyAuthBaseURL?: string | null,
    orderTypeDineIn?: number | null,
    orderTypeTakeAway?: number | null,
    orderTypeDelivery?: number | null,
    simphonyTakeAwayRvc?: number | null,
    simphonyDeliveryRvc?: number | null,
    exportInterval?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    foodicsToken?: string | null,
    enableTableFoodics?: string | null,
    image?: string | null,
    logo?: string | null,
    album?: Array< string | null > | null,
    type?: string | null,
    rating?: number | null,
    location?: string | null,
    description?: string | null,
    areas?: Array< string | null > | null,
    seatTypes?: Array< string | null > | null,
    needAssistance?: boolean | null,
    spaBooking?: boolean | null,
    showerBooking?: boolean | null,
    cigarMenu?: boolean | null,
    precedence?: number | null,
    isPinRequired?: boolean | null,
    viewOnly?: boolean | null,
    openTime?: string | null,
    closeTime?: string | null,
    varifyForAmount?: number | null,
    varifyFirstOrder?: boolean | null,
    varifyNewAddress?: boolean | null,
    sliderImages?: Array< string | null > | null,
    receiveWaiters?: Array< string | null > | null,
    WaiterNotificationOption?: string | null,
    applyLoyalty?: boolean | null,
    rewardPoints?: number | null,
    moneySpent?: number | null,
    afterVat?: boolean | null,
    afterDeliveryFee?: boolean | null,
    beforePromoCode?: boolean | null,
    pointsCalcWithPromoCode?: boolean | null,
    isBusy?: boolean | null,
    allowOnlineOrdering?: boolean | null,
    minOrderVal?: number | null,
    vatPercentage?: number | null,
    serviceChargePercentage?: number | null,
    addDeliveryToVat?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateConceptMutationVariables = {
  input: UpdateConceptInput,
  condition?: ModelConceptConditionInput | null,
};

export type UpdateConceptMutation = {
  updateConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    kiosks?: Array< string | null > | null,
    merchantID?: string | null,
    merchantUsername?: string | null,
    merchantPassword?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    simphonyServerBaseURL?: string | null,
    simphonyShortOrgName?: string | null,
    simphonyLocRef?: string | null,
    simphonyToken?: string | null,
    revenueCenterID?: number | null,
    employeeID?: number | null,
    tenderID?: number | null,
    tenders?:  {
      __typename: "ModelTenderConnection",
      items:  Array< {
        __typename: "Tender",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptTendersId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderType?:  {
      __typename: "ModelOrderTypeConnection",
      items:  Array< {
        __typename: "OrderType",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptOrderTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    discountID?: number | null,
    useServiceCharge?: boolean | null,
    serviceChargeId?: number | null,
    specialRequestID?: number | null,
    addressID?: number | null,
    simphonyAuthClientId?: string | null,
    simphonyAuthUsername?: string | null,
    simphonyAuthPassword?: string | null,
    simphonyAuthBaseURL?: string | null,
    orderTypeDineIn?: number | null,
    orderTypeTakeAway?: number | null,
    orderTypeDelivery?: number | null,
    simphonyTakeAwayRvc?: number | null,
    simphonyDeliveryRvc?: number | null,
    exportInterval?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    foodicsToken?: string | null,
    enableTableFoodics?: string | null,
    image?: string | null,
    logo?: string | null,
    album?: Array< string | null > | null,
    type?: string | null,
    rating?: number | null,
    location?: string | null,
    description?: string | null,
    areas?: Array< string | null > | null,
    seatTypes?: Array< string | null > | null,
    needAssistance?: boolean | null,
    spaBooking?: boolean | null,
    showerBooking?: boolean | null,
    cigarMenu?: boolean | null,
    precedence?: number | null,
    isPinRequired?: boolean | null,
    viewOnly?: boolean | null,
    openTime?: string | null,
    closeTime?: string | null,
    varifyForAmount?: number | null,
    varifyFirstOrder?: boolean | null,
    varifyNewAddress?: boolean | null,
    sliderImages?: Array< string | null > | null,
    receiveWaiters?: Array< string | null > | null,
    WaiterNotificationOption?: string | null,
    applyLoyalty?: boolean | null,
    rewardPoints?: number | null,
    moneySpent?: number | null,
    afterVat?: boolean | null,
    afterDeliveryFee?: boolean | null,
    beforePromoCode?: boolean | null,
    pointsCalcWithPromoCode?: boolean | null,
    isBusy?: boolean | null,
    allowOnlineOrdering?: boolean | null,
    minOrderVal?: number | null,
    vatPercentage?: number | null,
    serviceChargePercentage?: number | null,
    addDeliveryToVat?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteConceptMutationVariables = {
  input: DeleteConceptInput,
  condition?: ModelConceptConditionInput | null,
};

export type DeleteConceptMutation = {
  deleteConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    kiosks?: Array< string | null > | null,
    merchantID?: string | null,
    merchantUsername?: string | null,
    merchantPassword?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    simphonyServerBaseURL?: string | null,
    simphonyShortOrgName?: string | null,
    simphonyLocRef?: string | null,
    simphonyToken?: string | null,
    revenueCenterID?: number | null,
    employeeID?: number | null,
    tenderID?: number | null,
    tenders?:  {
      __typename: "ModelTenderConnection",
      items:  Array< {
        __typename: "Tender",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptTendersId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderType?:  {
      __typename: "ModelOrderTypeConnection",
      items:  Array< {
        __typename: "OrderType",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptOrderTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    discountID?: number | null,
    useServiceCharge?: boolean | null,
    serviceChargeId?: number | null,
    specialRequestID?: number | null,
    addressID?: number | null,
    simphonyAuthClientId?: string | null,
    simphonyAuthUsername?: string | null,
    simphonyAuthPassword?: string | null,
    simphonyAuthBaseURL?: string | null,
    orderTypeDineIn?: number | null,
    orderTypeTakeAway?: number | null,
    orderTypeDelivery?: number | null,
    simphonyTakeAwayRvc?: number | null,
    simphonyDeliveryRvc?: number | null,
    exportInterval?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    foodicsToken?: string | null,
    enableTableFoodics?: string | null,
    image?: string | null,
    logo?: string | null,
    album?: Array< string | null > | null,
    type?: string | null,
    rating?: number | null,
    location?: string | null,
    description?: string | null,
    areas?: Array< string | null > | null,
    seatTypes?: Array< string | null > | null,
    needAssistance?: boolean | null,
    spaBooking?: boolean | null,
    showerBooking?: boolean | null,
    cigarMenu?: boolean | null,
    precedence?: number | null,
    isPinRequired?: boolean | null,
    viewOnly?: boolean | null,
    openTime?: string | null,
    closeTime?: string | null,
    varifyForAmount?: number | null,
    varifyFirstOrder?: boolean | null,
    varifyNewAddress?: boolean | null,
    sliderImages?: Array< string | null > | null,
    receiveWaiters?: Array< string | null > | null,
    WaiterNotificationOption?: string | null,
    applyLoyalty?: boolean | null,
    rewardPoints?: number | null,
    moneySpent?: number | null,
    afterVat?: boolean | null,
    afterDeliveryFee?: boolean | null,
    beforePromoCode?: boolean | null,
    pointsCalcWithPromoCode?: boolean | null,
    isBusy?: boolean | null,
    allowOnlineOrdering?: boolean | null,
    minOrderVal?: number | null,
    vatPercentage?: number | null,
    serviceChargePercentage?: number | null,
    addDeliveryToVat?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTenderMutationVariables = {
  input: CreateTenderInput,
  condition?: ModelTenderConditionInput | null,
};

export type CreateTenderMutation = {
  createTender?:  {
    __typename: "Tender",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptTendersId?: string | null,
  } | null,
};

export type UpdateTenderMutationVariables = {
  input: UpdateTenderInput,
  condition?: ModelTenderConditionInput | null,
};

export type UpdateTenderMutation = {
  updateTender?:  {
    __typename: "Tender",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptTendersId?: string | null,
  } | null,
};

export type DeleteTenderMutationVariables = {
  input: DeleteTenderInput,
  condition?: ModelTenderConditionInput | null,
};

export type DeleteTenderMutation = {
  deleteTender?:  {
    __typename: "Tender",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptTendersId?: string | null,
  } | null,
};

export type CreateOrderTypeMutationVariables = {
  input: CreateOrderTypeInput,
  condition?: ModelOrderTypeConditionInput | null,
};

export type CreateOrderTypeMutation = {
  createOrderType?:  {
    __typename: "OrderType",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptOrderTypeId?: string | null,
  } | null,
};

export type UpdateOrderTypeMutationVariables = {
  input: UpdateOrderTypeInput,
  condition?: ModelOrderTypeConditionInput | null,
};

export type UpdateOrderTypeMutation = {
  updateOrderType?:  {
    __typename: "OrderType",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptOrderTypeId?: string | null,
  } | null,
};

export type DeleteOrderTypeMutationVariables = {
  input: DeleteOrderTypeInput,
  condition?: ModelOrderTypeConditionInput | null,
};

export type DeleteOrderTypeMutation = {
  deleteOrderType?:  {
    __typename: "OrderType",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptOrderTypeId?: string | null,
  } | null,
};

export type CreateParentConceptMutationVariables = {
  input: CreateParentConceptInput,
  condition?: ModelParentConceptConditionInput | null,
};

export type CreateParentConceptMutation = {
  createParentConcept?:  {
    __typename: "ParentConcept",
    id: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    image?: string | null,
    name?: string | null,
    logo?: string | null,
    type?: string | null,
    venues?: Array< string | null > | null,
    index?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateParentConceptMutationVariables = {
  input: UpdateParentConceptInput,
  condition?: ModelParentConceptConditionInput | null,
};

export type UpdateParentConceptMutation = {
  updateParentConcept?:  {
    __typename: "ParentConcept",
    id: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    image?: string | null,
    name?: string | null,
    logo?: string | null,
    type?: string | null,
    venues?: Array< string | null > | null,
    index?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteParentConceptMutationVariables = {
  input: DeleteParentConceptInput,
  condition?: ModelParentConceptConditionInput | null,
};

export type DeleteParentConceptMutation = {
  deleteParentConcept?:  {
    __typename: "ParentConcept",
    id: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    image?: string | null,
    name?: string | null,
    logo?: string | null,
    type?: string | null,
    venues?: Array< string | null > | null,
    index?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePlanItemMutationVariables = {
  input: CreatePlanItemInput,
  condition?: ModelPlanItemConditionInput | null,
};

export type CreatePlanItemMutation = {
  createPlanItem?:  {
    __typename: "PlanItem",
    id: string,
    areaID: string,
    conceptID?: string | null,
    xPosition: number,
    yPosition: number,
    name?: string | null,
    isReserved?: boolean | null,
    bookings?: Array< string | null > | null,
    table?: string | null,
    object?: string | null,
    lockDays?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePlanItemMutationVariables = {
  input: UpdatePlanItemInput,
  condition?: ModelPlanItemConditionInput | null,
};

export type UpdatePlanItemMutation = {
  updatePlanItem?:  {
    __typename: "PlanItem",
    id: string,
    areaID: string,
    conceptID?: string | null,
    xPosition: number,
    yPosition: number,
    name?: string | null,
    isReserved?: boolean | null,
    bookings?: Array< string | null > | null,
    table?: string | null,
    object?: string | null,
    lockDays?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePlanItemMutationVariables = {
  input: DeletePlanItemInput,
  condition?: ModelPlanItemConditionInput | null,
};

export type DeletePlanItemMutation = {
  deletePlanItem?:  {
    __typename: "PlanItem",
    id: string,
    areaID: string,
    conceptID?: string | null,
    xPosition: number,
    yPosition: number,
    name?: string | null,
    isReserved?: boolean | null,
    bookings?: Array< string | null > | null,
    table?: string | null,
    object?: string | null,
    lockDays?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateObjectMutationVariables = {
  input: CreateObjectInput,
  condition?: ModelObjectConditionInput | null,
};

export type CreateObjectMutation = {
  createObject?:  {
    __typename: "Object",
    id: string,
    conceptID: string,
    label: string,
    name: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    width?: number | null,
    height?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    objectImageId?: string | null,
  } | null,
};

export type UpdateObjectMutationVariables = {
  input: UpdateObjectInput,
  condition?: ModelObjectConditionInput | null,
};

export type UpdateObjectMutation = {
  updateObject?:  {
    __typename: "Object",
    id: string,
    conceptID: string,
    label: string,
    name: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    width?: number | null,
    height?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    objectImageId?: string | null,
  } | null,
};

export type DeleteObjectMutationVariables = {
  input: DeleteObjectInput,
  condition?: ModelObjectConditionInput | null,
};

export type DeleteObjectMutation = {
  deleteObject?:  {
    __typename: "Object",
    id: string,
    conceptID: string,
    label: string,
    name: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    width?: number | null,
    height?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    objectImageId?: string | null,
  } | null,
};

export type CreateBookingMutationVariables = {
  input: CreateBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type CreateBookingMutation = {
  createBooking?:  {
    __typename: "Booking",
    id: string,
    depositValue?: string | null,
    depositStatus?: boolean | null,
    eventStatus?: boolean | null,
    eventName?: string | null,
    paymentStatus?: boolean | null,
    paymentValue?: string | null,
    disablePartialPayment?: boolean | null,
    disablePayment?: boolean | null,
    eventDate?: string | null,
    eventGuests?: Array< string | null > | null,
    timeSlotID?: string | null,
    timeSlots?: Array< string | null > | null,
    conceptID?: string | null,
    mainGuest: string,
    customerName?: string | null,
    customerPhone?: string | null,
    customerGroup?: string | null,
    accompaniedCount?: number | null,
    channel?: string | null,
    statusID?: string | null,
    rating?: number | null,
    review?: Array< string | null > | null,
    confirmationHash?: string | null,
    isConfirmedByUser?: boolean | null,
    date?: string | null,
    tables?: Array< string | null > | null,
    tableName?: string | null,
    comments?: Array< string | null > | null,
    lastComment?: string | null,
    commentCreatedBy?: string | null,
    commentCreatedDate?: string | null,
    timeLeft?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBookingMutationVariables = {
  input: UpdateBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type UpdateBookingMutation = {
  updateBooking?:  {
    __typename: "Booking",
    id: string,
    depositValue?: string | null,
    depositStatus?: boolean | null,
    eventStatus?: boolean | null,
    eventName?: string | null,
    paymentStatus?: boolean | null,
    paymentValue?: string | null,
    disablePartialPayment?: boolean | null,
    disablePayment?: boolean | null,
    eventDate?: string | null,
    eventGuests?: Array< string | null > | null,
    timeSlotID?: string | null,
    timeSlots?: Array< string | null > | null,
    conceptID?: string | null,
    mainGuest: string,
    customerName?: string | null,
    customerPhone?: string | null,
    customerGroup?: string | null,
    accompaniedCount?: number | null,
    channel?: string | null,
    statusID?: string | null,
    rating?: number | null,
    review?: Array< string | null > | null,
    confirmationHash?: string | null,
    isConfirmedByUser?: boolean | null,
    date?: string | null,
    tables?: Array< string | null > | null,
    tableName?: string | null,
    comments?: Array< string | null > | null,
    lastComment?: string | null,
    commentCreatedBy?: string | null,
    commentCreatedDate?: string | null,
    timeLeft?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBookingMutationVariables = {
  input: DeleteBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type DeleteBookingMutation = {
  deleteBooking?:  {
    __typename: "Booking",
    id: string,
    depositValue?: string | null,
    depositStatus?: boolean | null,
    eventStatus?: boolean | null,
    eventName?: string | null,
    paymentStatus?: boolean | null,
    paymentValue?: string | null,
    disablePartialPayment?: boolean | null,
    disablePayment?: boolean | null,
    eventDate?: string | null,
    eventGuests?: Array< string | null > | null,
    timeSlotID?: string | null,
    timeSlots?: Array< string | null > | null,
    conceptID?: string | null,
    mainGuest: string,
    customerName?: string | null,
    customerPhone?: string | null,
    customerGroup?: string | null,
    accompaniedCount?: number | null,
    channel?: string | null,
    statusID?: string | null,
    rating?: number | null,
    review?: Array< string | null > | null,
    confirmationHash?: string | null,
    isConfirmedByUser?: boolean | null,
    date?: string | null,
    tables?: Array< string | null > | null,
    tableName?: string | null,
    comments?: Array< string | null > | null,
    lastComment?: string | null,
    commentCreatedBy?: string | null,
    commentCreatedDate?: string | null,
    timeLeft?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTimelineMutationVariables = {
  input: CreateTimelineInput,
  condition?: ModelTimelineConditionInput | null,
};

export type CreateTimelineMutation = {
  createTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    transactionId?: string | null,
    customerId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTimelineMutationVariables = {
  input: UpdateTimelineInput,
  condition?: ModelTimelineConditionInput | null,
};

export type UpdateTimelineMutation = {
  updateTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    transactionId?: string | null,
    customerId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTimelineMutationVariables = {
  input: DeleteTimelineInput,
  condition?: ModelTimelineConditionInput | null,
};

export type DeleteTimelineMutation = {
  deleteTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    transactionId?: string | null,
    customerId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCallMutationVariables = {
  input: CreateCallInput,
  condition?: ModelCallConditionInput | null,
};

export type CreateCallMutation = {
  createCall?:  {
    __typename: "Call",
    id: string,
    phone_number?: string | null,
    customer?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    endedAt?: string | null,
    duration?: string | null,
    date: string,
    employeeId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    callCustomerId?: string | null,
  } | null,
};

export type UpdateCallMutationVariables = {
  input: UpdateCallInput,
  condition?: ModelCallConditionInput | null,
};

export type UpdateCallMutation = {
  updateCall?:  {
    __typename: "Call",
    id: string,
    phone_number?: string | null,
    customer?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    endedAt?: string | null,
    duration?: string | null,
    date: string,
    employeeId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    callCustomerId?: string | null,
  } | null,
};

export type DeleteCallMutationVariables = {
  input: DeleteCallInput,
  condition?: ModelCallConditionInput | null,
};

export type DeleteCallMutation = {
  deleteCall?:  {
    __typename: "Call",
    id: string,
    phone_number?: string | null,
    customer?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    endedAt?: string | null,
    duration?: string | null,
    date: string,
    employeeId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    callCustomerId?: string | null,
  } | null,
};

export type CreateAdminRoleMutationVariables = {
  input: CreateAdminRoleInput,
  condition?: ModelAdminRoleConditionInput | null,
};

export type CreateAdminRoleMutation = {
  createAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAdminRoleMutationVariables = {
  input: UpdateAdminRoleInput,
  condition?: ModelAdminRoleConditionInput | null,
};

export type UpdateAdminRoleMutation = {
  updateAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAdminRoleMutationVariables = {
  input: DeleteAdminRoleInput,
  condition?: ModelAdminRoleConditionInput | null,
};

export type DeleteAdminRoleMutation = {
  deleteAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAdminGroupMutationVariables = {
  input: CreateAdminGroupInput,
  condition?: ModelAdminGroupConditionInput | null,
};

export type CreateAdminGroupMutation = {
  createAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAdminGroupMutationVariables = {
  input: UpdateAdminGroupInput,
  condition?: ModelAdminGroupConditionInput | null,
};

export type UpdateAdminGroupMutation = {
  updateAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAdminGroupMutationVariables = {
  input: DeleteAdminGroupInput,
  condition?: ModelAdminGroupConditionInput | null,
};

export type DeleteAdminGroupMutation = {
  deleteAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserConceptsMutationVariables = {
  input: CreateUserConceptsInput,
  condition?: ModelUserConceptsConditionInput | null,
};

export type CreateUserConceptsMutation = {
  createUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserConceptsMutationVariables = {
  input: UpdateUserConceptsInput,
  condition?: ModelUserConceptsConditionInput | null,
};

export type UpdateUserConceptsMutation = {
  updateUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserConceptsMutationVariables = {
  input: DeleteUserConceptsInput,
  condition?: ModelUserConceptsConditionInput | null,
};

export type DeleteUserConceptsMutation = {
  deleteUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTransactionMutationVariables = {
  input: CreateTransactionInput,
  condition?: ModelTransactionConditionInput | null,
};

export type CreateTransactionMutation = {
  createTransaction?:  {
    __typename: "Transaction",
    id: string,
    transactionID: string,
    date: string,
    guestName: string,
    guestPhone: string,
    guestID: string,
    guestGroup: string,
    guestsNames?: Array< string | null > | null,
    bookingID?: string | null,
    bookingDate?: string | null,
    amount_cents: string,
    status: boolean,
    failureReason?: string | null,
    type: string,
    timeSlots?: Array< string | null > | null,
    tables?: Array< string | null > | null,
    conceptID: string,
    currency: string,
    refund: boolean,
    reference_orderID?: string | null,
    ownerID: string,
    refunded_amount_cents: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTransactionMutationVariables = {
  input: UpdateTransactionInput,
  condition?: ModelTransactionConditionInput | null,
};

export type UpdateTransactionMutation = {
  updateTransaction?:  {
    __typename: "Transaction",
    id: string,
    transactionID: string,
    date: string,
    guestName: string,
    guestPhone: string,
    guestID: string,
    guestGroup: string,
    guestsNames?: Array< string | null > | null,
    bookingID?: string | null,
    bookingDate?: string | null,
    amount_cents: string,
    status: boolean,
    failureReason?: string | null,
    type: string,
    timeSlots?: Array< string | null > | null,
    tables?: Array< string | null > | null,
    conceptID: string,
    currency: string,
    refund: boolean,
    reference_orderID?: string | null,
    ownerID: string,
    refunded_amount_cents: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTransactionMutationVariables = {
  input: DeleteTransactionInput,
  condition?: ModelTransactionConditionInput | null,
};

export type DeleteTransactionMutation = {
  deleteTransaction?:  {
    __typename: "Transaction",
    id: string,
    transactionID: string,
    date: string,
    guestName: string,
    guestPhone: string,
    guestID: string,
    guestGroup: string,
    guestsNames?: Array< string | null > | null,
    bookingID?: string | null,
    bookingDate?: string | null,
    amount_cents: string,
    status: boolean,
    failureReason?: string | null,
    type: string,
    timeSlots?: Array< string | null > | null,
    tables?: Array< string | null > | null,
    conceptID: string,
    currency: string,
    refund: boolean,
    reference_orderID?: string | null,
    ownerID: string,
    refunded_amount_cents: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePaymentStatMutationVariables = {
  input: CreatePaymentStatInput,
  condition?: ModelPaymentStatConditionInput | null,
};

export type CreatePaymentStatMutation = {
  createPaymentStat?:  {
    __typename: "PaymentStat",
    id: string,
    accountID?: string | null,
    conceptID: string,
    year: number,
    month: number,
    monthName: string,
    earning: number,
    refund: number,
    earningTrxCount: number,
    refundTrxCount: number,
    deleted?: string | null,
    createdAt: string,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePaymentStatMutationVariables = {
  input: UpdatePaymentStatInput,
  condition?: ModelPaymentStatConditionInput | null,
};

export type UpdatePaymentStatMutation = {
  updatePaymentStat?:  {
    __typename: "PaymentStat",
    id: string,
    accountID?: string | null,
    conceptID: string,
    year: number,
    month: number,
    monthName: string,
    earning: number,
    refund: number,
    earningTrxCount: number,
    refundTrxCount: number,
    deleted?: string | null,
    createdAt: string,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePaymentStatMutationVariables = {
  input: DeletePaymentStatInput,
  condition?: ModelPaymentStatConditionInput | null,
};

export type DeletePaymentStatMutation = {
  deletePaymentStat?:  {
    __typename: "PaymentStat",
    id: string,
    accountID?: string | null,
    conceptID: string,
    year: number,
    month: number,
    monthName: string,
    earning: number,
    refund: number,
    earningTrxCount: number,
    refundTrxCount: number,
    deleted?: string | null,
    createdAt: string,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBookingGuestMutationVariables = {
  input: CreateBookingGuestInput,
  condition?: ModelBookingGuestConditionInput | null,
};

export type CreateBookingGuestMutation = {
  createBookingGuest?:  {
    __typename: "BookingGuest",
    id: string,
    bookingID: string,
    guestName: string,
    paidAmount: number,
    paymentAmount: number,
    paymentStatus: boolean,
    transactionID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBookingGuestMutationVariables = {
  input: UpdateBookingGuestInput,
  condition?: ModelBookingGuestConditionInput | null,
};

export type UpdateBookingGuestMutation = {
  updateBookingGuest?:  {
    __typename: "BookingGuest",
    id: string,
    bookingID: string,
    guestName: string,
    paidAmount: number,
    paymentAmount: number,
    paymentStatus: boolean,
    transactionID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBookingGuestMutationVariables = {
  input: DeleteBookingGuestInput,
  condition?: ModelBookingGuestConditionInput | null,
};

export type DeleteBookingGuestMutation = {
  deleteBookingGuest?:  {
    __typename: "BookingGuest",
    id: string,
    bookingID: string,
    guestName: string,
    paidAmount: number,
    paymentAmount: number,
    paymentStatus: boolean,
    transactionID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    conceptID: string,
    preparationAreaID?: string | null,
    subCategory?: string | null,
    name: string,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    darkImage?: string | null,
    menuItems?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        conceptID: string,
        kioskID?: string | null,
        name: string,
        description?: string | null,
        image?: string | null,
        precedence?: string | null,
        categoryID: string,
        categoryName?: string | null,
        outOfStock?: boolean | null,
        requiredPoints?: number | null,
        symphonyID?: string | null,
        comboItemID?: string | null,
        ratings?: Array< string | null > | null,
        prices?: Array< string | null > | null,
        choiceGroups?: Array< string | null > | null,
        choiceGroupsRules?:  Array< {
          __typename: "ChoiceGroupRules",
          id: string,
          minNumberOfChoices?: number | null,
          maxNumberOfChoices?: number | null,
        } | null > | null,
        ExtraChoiceGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        hasLimitedQuantity?: boolean | null,
        quantity?: number | null,
        autoRestock?: boolean | null,
        restockQuantity?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        quantityOrdered?: number | null,
        performancePerDay?: Array< string | null > | null,
        multiLanguages?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    precedence: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    symphonyID?: string | null,
    isHidden?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    conceptID: string,
    preparationAreaID?: string | null,
    subCategory?: string | null,
    name: string,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    darkImage?: string | null,
    menuItems?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        conceptID: string,
        kioskID?: string | null,
        name: string,
        description?: string | null,
        image?: string | null,
        precedence?: string | null,
        categoryID: string,
        categoryName?: string | null,
        outOfStock?: boolean | null,
        requiredPoints?: number | null,
        symphonyID?: string | null,
        comboItemID?: string | null,
        ratings?: Array< string | null > | null,
        prices?: Array< string | null > | null,
        choiceGroups?: Array< string | null > | null,
        choiceGroupsRules?:  Array< {
          __typename: "ChoiceGroupRules",
          id: string,
          minNumberOfChoices?: number | null,
          maxNumberOfChoices?: number | null,
        } | null > | null,
        ExtraChoiceGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        hasLimitedQuantity?: boolean | null,
        quantity?: number | null,
        autoRestock?: boolean | null,
        restockQuantity?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        quantityOrdered?: number | null,
        performancePerDay?: Array< string | null > | null,
        multiLanguages?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    precedence: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    symphonyID?: string | null,
    isHidden?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    conceptID: string,
    preparationAreaID?: string | null,
    subCategory?: string | null,
    name: string,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    darkImage?: string | null,
    menuItems?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        conceptID: string,
        kioskID?: string | null,
        name: string,
        description?: string | null,
        image?: string | null,
        precedence?: string | null,
        categoryID: string,
        categoryName?: string | null,
        outOfStock?: boolean | null,
        requiredPoints?: number | null,
        symphonyID?: string | null,
        comboItemID?: string | null,
        ratings?: Array< string | null > | null,
        prices?: Array< string | null > | null,
        choiceGroups?: Array< string | null > | null,
        choiceGroupsRules?:  Array< {
          __typename: "ChoiceGroupRules",
          id: string,
          minNumberOfChoices?: number | null,
          maxNumberOfChoices?: number | null,
        } | null > | null,
        ExtraChoiceGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        hasLimitedQuantity?: boolean | null,
        quantity?: number | null,
        autoRestock?: boolean | null,
        restockQuantity?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        quantityOrdered?: number | null,
        performancePerDay?: Array< string | null > | null,
        multiLanguages?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    precedence: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    symphonyID?: string | null,
    isHidden?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMenuItemMutationVariables = {
  input: CreateMenuItemInput,
  condition?: ModelMenuItemConditionInput | null,
};

export type CreateMenuItemMutation = {
  createMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    conceptID: string,
    kioskID?: string | null,
    name: string,
    description?: string | null,
    image?: string | null,
    precedence?: string | null,
    categoryID: string,
    categoryName?: string | null,
    outOfStock?: boolean | null,
    requiredPoints?: number | null,
    symphonyID?: string | null,
    comboItemID?: string | null,
    ratings?: Array< string | null > | null,
    prices?: Array< string | null > | null,
    choiceGroups?: Array< string | null > | null,
    choiceGroupsRules?:  Array< {
      __typename: "ChoiceGroupRules",
      id: string,
      minNumberOfChoices?: number | null,
      maxNumberOfChoices?: number | null,
    } | null > | null,
    ExtraChoiceGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    hasLimitedQuantity?: boolean | null,
    quantity?: number | null,
    autoRestock?: boolean | null,
    restockQuantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    quantityOrdered?: number | null,
    performancePerDay?: Array< string | null > | null,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMenuItemMutationVariables = {
  input: UpdateMenuItemInput,
  condition?: ModelMenuItemConditionInput | null,
};

export type UpdateMenuItemMutation = {
  updateMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    conceptID: string,
    kioskID?: string | null,
    name: string,
    description?: string | null,
    image?: string | null,
    precedence?: string | null,
    categoryID: string,
    categoryName?: string | null,
    outOfStock?: boolean | null,
    requiredPoints?: number | null,
    symphonyID?: string | null,
    comboItemID?: string | null,
    ratings?: Array< string | null > | null,
    prices?: Array< string | null > | null,
    choiceGroups?: Array< string | null > | null,
    choiceGroupsRules?:  Array< {
      __typename: "ChoiceGroupRules",
      id: string,
      minNumberOfChoices?: number | null,
      maxNumberOfChoices?: number | null,
    } | null > | null,
    ExtraChoiceGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    hasLimitedQuantity?: boolean | null,
    quantity?: number | null,
    autoRestock?: boolean | null,
    restockQuantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    quantityOrdered?: number | null,
    performancePerDay?: Array< string | null > | null,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMenuItemMutationVariables = {
  input: DeleteMenuItemInput,
  condition?: ModelMenuItemConditionInput | null,
};

export type DeleteMenuItemMutation = {
  deleteMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    conceptID: string,
    kioskID?: string | null,
    name: string,
    description?: string | null,
    image?: string | null,
    precedence?: string | null,
    categoryID: string,
    categoryName?: string | null,
    outOfStock?: boolean | null,
    requiredPoints?: number | null,
    symphonyID?: string | null,
    comboItemID?: string | null,
    ratings?: Array< string | null > | null,
    prices?: Array< string | null > | null,
    choiceGroups?: Array< string | null > | null,
    choiceGroupsRules?:  Array< {
      __typename: "ChoiceGroupRules",
      id: string,
      minNumberOfChoices?: number | null,
      maxNumberOfChoices?: number | null,
    } | null > | null,
    ExtraChoiceGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    hasLimitedQuantity?: boolean | null,
    quantity?: number | null,
    autoRestock?: boolean | null,
    restockQuantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    quantityOrdered?: number | null,
    performancePerDay?: Array< string | null > | null,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePriceMutationVariables = {
  input: CreatePriceInput,
  condition?: ModelPriceConditionInput | null,
};

export type CreatePriceMutation = {
  createPrice?:  {
    __typename: "Price",
    id: string,
    conceptID: string,
    name: string,
    multiLanguages?: string | null,
    price: number,
    symphonyID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePriceMutationVariables = {
  input: UpdatePriceInput,
  condition?: ModelPriceConditionInput | null,
};

export type UpdatePriceMutation = {
  updatePrice?:  {
    __typename: "Price",
    id: string,
    conceptID: string,
    name: string,
    multiLanguages?: string | null,
    price: number,
    symphonyID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePriceMutationVariables = {
  input: DeletePriceInput,
  condition?: ModelPriceConditionInput | null,
};

export type DeletePriceMutation = {
  deletePrice?:  {
    __typename: "Price",
    id: string,
    conceptID: string,
    name: string,
    multiLanguages?: string | null,
    price: number,
    symphonyID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateChoiceGroupsMutationVariables = {
  input: CreateChoiceGroupsInput,
  condition?: ModelChoiceGroupsConditionInput | null,
};

export type CreateChoiceGroupsMutation = {
  createChoiceGroups?:  {
    __typename: "ChoiceGroups",
    id: string,
    conceptID: string,
    name: string,
    required?: boolean | null,
    maxNumberOfChoices?: number | null,
    minNumberOfChoices?: number | null,
    precedence?: string | null,
    type?: string | null,
    choices?: Array< string | null > | null,
    choiceItems?:  {
      __typename: "ModelChoiceConnection",
      items:  Array< {
        __typename: "Choice",
        id: string,
        conceptID: string,
        name: string,
        price: number,
        precedence?: string | null,
        choiceGroupID: string,
        choiceGroupName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        symphonyID?: string | null,
        multiLanguages?: string | null,
        definitionNumber?: number | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    simphonyID?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateChoiceGroupsMutationVariables = {
  input: UpdateChoiceGroupsInput,
  condition?: ModelChoiceGroupsConditionInput | null,
};

export type UpdateChoiceGroupsMutation = {
  updateChoiceGroups?:  {
    __typename: "ChoiceGroups",
    id: string,
    conceptID: string,
    name: string,
    required?: boolean | null,
    maxNumberOfChoices?: number | null,
    minNumberOfChoices?: number | null,
    precedence?: string | null,
    type?: string | null,
    choices?: Array< string | null > | null,
    choiceItems?:  {
      __typename: "ModelChoiceConnection",
      items:  Array< {
        __typename: "Choice",
        id: string,
        conceptID: string,
        name: string,
        price: number,
        precedence?: string | null,
        choiceGroupID: string,
        choiceGroupName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        symphonyID?: string | null,
        multiLanguages?: string | null,
        definitionNumber?: number | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    simphonyID?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteChoiceGroupsMutationVariables = {
  input: DeleteChoiceGroupsInput,
  condition?: ModelChoiceGroupsConditionInput | null,
};

export type DeleteChoiceGroupsMutation = {
  deleteChoiceGroups?:  {
    __typename: "ChoiceGroups",
    id: string,
    conceptID: string,
    name: string,
    required?: boolean | null,
    maxNumberOfChoices?: number | null,
    minNumberOfChoices?: number | null,
    precedence?: string | null,
    type?: string | null,
    choices?: Array< string | null > | null,
    choiceItems?:  {
      __typename: "ModelChoiceConnection",
      items:  Array< {
        __typename: "Choice",
        id: string,
        conceptID: string,
        name: string,
        price: number,
        precedence?: string | null,
        choiceGroupID: string,
        choiceGroupName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        symphonyID?: string | null,
        multiLanguages?: string | null,
        definitionNumber?: number | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    simphonyID?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateChoiceMutationVariables = {
  input: CreateChoiceInput,
  condition?: ModelChoiceConditionInput | null,
};

export type CreateChoiceMutation = {
  createChoice?:  {
    __typename: "Choice",
    id: string,
    conceptID: string,
    name: string,
    price: number,
    precedence?: string | null,
    choiceGroupID: string,
    choiceGroupName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    symphonyID?: string | null,
    multiLanguages?: string | null,
    definitionNumber?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateChoiceMutationVariables = {
  input: UpdateChoiceInput,
  condition?: ModelChoiceConditionInput | null,
};

export type UpdateChoiceMutation = {
  updateChoice?:  {
    __typename: "Choice",
    id: string,
    conceptID: string,
    name: string,
    price: number,
    precedence?: string | null,
    choiceGroupID: string,
    choiceGroupName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    symphonyID?: string | null,
    multiLanguages?: string | null,
    definitionNumber?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteChoiceMutationVariables = {
  input: DeleteChoiceInput,
  condition?: ModelChoiceConditionInput | null,
};

export type DeleteChoiceMutation = {
  deleteChoice?:  {
    __typename: "Choice",
    id: string,
    conceptID: string,
    name: string,
    price: number,
    precedence?: string | null,
    choiceGroupID: string,
    choiceGroupName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    symphonyID?: string | null,
    multiLanguages?: string | null,
    definitionNumber?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCartMutationVariables = {
  input: CreateCartInput,
  condition?: ModelCartConditionInput | null,
};

export type CreateCartMutation = {
  createCart?:  {
    __typename: "Cart",
    id: string,
    conceptID: string,
    userID: string,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      symphonyID?: string | null,
      preparationAreaID?: string | null,
      name?: string | null,
      quantity: number,
      choiceIDs?: Array< string | null > | null,
      choiceNames?: Array< string | null > | null,
      choiceSymphonyID?: Array< string | null > | null,
      extraChoiceIDs?: Array< string | null > | null,
      extraChoiceSymphonyID?: Array< string | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      comboItemID?: string | null,
    } | null > | null,
    specialRequest?: string | null,
    deliveryFee?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    totalPrice?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCartMutationVariables = {
  input: UpdateCartInput,
  condition?: ModelCartConditionInput | null,
};

export type UpdateCartMutation = {
  updateCart?:  {
    __typename: "Cart",
    id: string,
    conceptID: string,
    userID: string,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      symphonyID?: string | null,
      preparationAreaID?: string | null,
      name?: string | null,
      quantity: number,
      choiceIDs?: Array< string | null > | null,
      choiceNames?: Array< string | null > | null,
      choiceSymphonyID?: Array< string | null > | null,
      extraChoiceIDs?: Array< string | null > | null,
      extraChoiceSymphonyID?: Array< string | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      comboItemID?: string | null,
    } | null > | null,
    specialRequest?: string | null,
    deliveryFee?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    totalPrice?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCartMutationVariables = {
  input: DeleteCartInput,
  condition?: ModelCartConditionInput | null,
};

export type DeleteCartMutation = {
  deleteCart?:  {
    __typename: "Cart",
    id: string,
    conceptID: string,
    userID: string,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      symphonyID?: string | null,
      preparationAreaID?: string | null,
      name?: string | null,
      quantity: number,
      choiceIDs?: Array< string | null > | null,
      choiceNames?: Array< string | null > | null,
      choiceSymphonyID?: Array< string | null > | null,
      extraChoiceIDs?: Array< string | null > | null,
      extraChoiceSymphonyID?: Array< string | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      comboItemID?: string | null,
    } | null > | null,
    specialRequest?: string | null,
    deliveryFee?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    totalPrice?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAdminStatusMutationVariables = {
  input: CreateAdminStatusInput,
  condition?: ModelAdminStatusConditionInput | null,
};

export type CreateAdminStatusMutation = {
  createAdminStatus?:  {
    __typename: "AdminStatus",
    id: string,
    sub: string,
    accountID: string,
    name?: string | null,
    averageCallTime?: number | null,
    numberOfCalls?: number | null,
    idelDuration?: number | null,
    activeDuration?: number | null,
    awayDuration?: number | null,
    lastCallTime?: number | null,
    agentBarAverageCallTime?: number | null,
    agentBarNumberOfCalls?: number | null,
    type?: string | null,
    waiterStatus?: string | null,
    totalAverageRatio?: number | null,
    performancePerDay?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAdminStatusMutationVariables = {
  input: UpdateAdminStatusInput,
  condition?: ModelAdminStatusConditionInput | null,
};

export type UpdateAdminStatusMutation = {
  updateAdminStatus?:  {
    __typename: "AdminStatus",
    id: string,
    sub: string,
    accountID: string,
    name?: string | null,
    averageCallTime?: number | null,
    numberOfCalls?: number | null,
    idelDuration?: number | null,
    activeDuration?: number | null,
    awayDuration?: number | null,
    lastCallTime?: number | null,
    agentBarAverageCallTime?: number | null,
    agentBarNumberOfCalls?: number | null,
    type?: string | null,
    waiterStatus?: string | null,
    totalAverageRatio?: number | null,
    performancePerDay?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAdminStatusMutationVariables = {
  input: DeleteAdminStatusInput,
  condition?: ModelAdminStatusConditionInput | null,
};

export type DeleteAdminStatusMutation = {
  deleteAdminStatus?:  {
    __typename: "AdminStatus",
    id: string,
    sub: string,
    accountID: string,
    name?: string | null,
    averageCallTime?: number | null,
    numberOfCalls?: number | null,
    idelDuration?: number | null,
    activeDuration?: number | null,
    awayDuration?: number | null,
    lastCallTime?: number | null,
    agentBarAverageCallTime?: number | null,
    agentBarNumberOfCalls?: number | null,
    type?: string | null,
    waiterStatus?: string | null,
    totalAverageRatio?: number | null,
    performancePerDay?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateOnlineOrderMutationVariables = {
  input: CreateOnlineOrderInput,
  condition?: ModelOnlineOrderConditionInput | null,
};

export type CreateOnlineOrderMutation = {
  createOnlineOrder?:  {
    __typename: "OnlineOrder",
    id: string,
    conceptID: string,
    statusID?: string | null,
    status?: string | null,
    reason?: string | null,
    orderType?: string | null,
    channel?: string | null,
    userID: string,
    userName?: string | null,
    userPhoneNumber?: string | null,
    dispatcherID?: string | null,
    dispatcherName?: string | null,
    cartID?: string | null,
    tenderId?: number | null,
    employeeId?: number | null,
    rvcId?: number | null,
    checkNumber?: string | null,
    totalAmount?: number | null,
    discountedValue?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    tax?: number | null,
    deliveryFee?: number | null,
    orderTime?: string | null,
    executionArn?: string | null,
    specialRequest?: string | null,
    orderAddress?: string | null,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      symphonyID?: string | null,
      preparationAreaID?: string | null,
      name?: string | null,
      quantity: number,
      choiceIDs?: Array< string | null > | null,
      choiceNames?: Array< string | null > | null,
      choiceSymphonyID?: Array< string | null > | null,
      extraChoiceIDs?: Array< string | null > | null,
      extraChoiceSymphonyID?: Array< string | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      comboItemID?: string | null,
    } | null > | null,
    checkRef?: string | null,
    discountID?: number | null,
    amount_cents?: string | null,
    transactionID?: string | null,
    paymentStatus?: boolean | null,
    paymentType?: string | null,
    referenceId?: string | null,
    cardHolderNumber?: string | null,
    cardHolderName?: string | null,
    cardSchemeName?: string | null,
    deleted?: string | null,
    acceptedByWaiterID?: string | null,
    acceptedByWaiterName?: string | null,
    deliveredByWaiterID?: string | null,
    tableID?: string | null,
    tableName?: string | null,
    shift?: string | null,
    parentOrder?: string | null,
    preparationAreaID?: string | null,
    childrenStatus?: Array< string | null > | null,
    createdAt: string,
    acceptedByWaiterAt?: string | null,
    sentToKitchenAt?: string | null,
    acceptedByKitchenAt?: string | null,
    readyAt?: string | null,
    outForDeliveryAt?: string | null,
    deliveredAt?: string | null,
    promoCodeID?: string | null,
    promoCodeName?: string | null,
    promoCodeAppliedTo?: string | null,
    promoCode?:  {
      __typename: "Promocode",
      id: string,
      name: string,
      redemptions: number,
      status?: string | null,
      startDate: string,
      endDate: string,
      appliesTo: string,
      minOrderReq: string,
      minOrderVal?: number | null,
      minOrderQty?: number | null,
      tenderReq: Array< string | null >,
      discountVal: number,
      discountLimit?: number | null,
      quota?: number | null,
      quotaUsed?: number | null,
      totalRevenue?: number | null,
      appliedDiscountedVal?: number | null,
      usePerCustomer?: number | null,
      channelReq: Array< string | null >,
      customerEligibility: string,
      customerReq?: Array< string | null > | null,
      spendingExceeds?: number | null,
      spendingBelow?: number | null,
      orderSinceDate?: string | null,
      orderFromDate?: string | null,
      orderToDate?: string | null,
      isDisplayed?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    reviewID?: string | null,
    review?:  {
      __typename: "Review",
      id: string,
      accountID?: string | null,
      bookingID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    simphEmpID?: string | null,
    createdByID: string,
    createdByName: string,
    tmpField?: string | null,
    updatedByID?: string | null,
    updatedByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateOnlineOrderMutationVariables = {
  input: UpdateOnlineOrderInput,
  condition?: ModelOnlineOrderConditionInput | null,
};

export type UpdateOnlineOrderMutation = {
  updateOnlineOrder?:  {
    __typename: "OnlineOrder",
    id: string,
    conceptID: string,
    statusID?: string | null,
    status?: string | null,
    reason?: string | null,
    orderType?: string | null,
    channel?: string | null,
    userID: string,
    userName?: string | null,
    userPhoneNumber?: string | null,
    dispatcherID?: string | null,
    dispatcherName?: string | null,
    cartID?: string | null,
    tenderId?: number | null,
    employeeId?: number | null,
    rvcId?: number | null,
    checkNumber?: string | null,
    totalAmount?: number | null,
    discountedValue?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    tax?: number | null,
    deliveryFee?: number | null,
    orderTime?: string | null,
    executionArn?: string | null,
    specialRequest?: string | null,
    orderAddress?: string | null,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      symphonyID?: string | null,
      preparationAreaID?: string | null,
      name?: string | null,
      quantity: number,
      choiceIDs?: Array< string | null > | null,
      choiceNames?: Array< string | null > | null,
      choiceSymphonyID?: Array< string | null > | null,
      extraChoiceIDs?: Array< string | null > | null,
      extraChoiceSymphonyID?: Array< string | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      comboItemID?: string | null,
    } | null > | null,
    checkRef?: string | null,
    discountID?: number | null,
    amount_cents?: string | null,
    transactionID?: string | null,
    paymentStatus?: boolean | null,
    paymentType?: string | null,
    referenceId?: string | null,
    cardHolderNumber?: string | null,
    cardHolderName?: string | null,
    cardSchemeName?: string | null,
    deleted?: string | null,
    acceptedByWaiterID?: string | null,
    acceptedByWaiterName?: string | null,
    deliveredByWaiterID?: string | null,
    tableID?: string | null,
    tableName?: string | null,
    shift?: string | null,
    parentOrder?: string | null,
    preparationAreaID?: string | null,
    childrenStatus?: Array< string | null > | null,
    createdAt: string,
    acceptedByWaiterAt?: string | null,
    sentToKitchenAt?: string | null,
    acceptedByKitchenAt?: string | null,
    readyAt?: string | null,
    outForDeliveryAt?: string | null,
    deliveredAt?: string | null,
    promoCodeID?: string | null,
    promoCodeName?: string | null,
    promoCodeAppliedTo?: string | null,
    promoCode?:  {
      __typename: "Promocode",
      id: string,
      name: string,
      redemptions: number,
      status?: string | null,
      startDate: string,
      endDate: string,
      appliesTo: string,
      minOrderReq: string,
      minOrderVal?: number | null,
      minOrderQty?: number | null,
      tenderReq: Array< string | null >,
      discountVal: number,
      discountLimit?: number | null,
      quota?: number | null,
      quotaUsed?: number | null,
      totalRevenue?: number | null,
      appliedDiscountedVal?: number | null,
      usePerCustomer?: number | null,
      channelReq: Array< string | null >,
      customerEligibility: string,
      customerReq?: Array< string | null > | null,
      spendingExceeds?: number | null,
      spendingBelow?: number | null,
      orderSinceDate?: string | null,
      orderFromDate?: string | null,
      orderToDate?: string | null,
      isDisplayed?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    reviewID?: string | null,
    review?:  {
      __typename: "Review",
      id: string,
      accountID?: string | null,
      bookingID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    simphEmpID?: string | null,
    createdByID: string,
    createdByName: string,
    tmpField?: string | null,
    updatedByID?: string | null,
    updatedByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteOnlineOrderMutationVariables = {
  input: DeleteOnlineOrderInput,
  condition?: ModelOnlineOrderConditionInput | null,
};

export type DeleteOnlineOrderMutation = {
  deleteOnlineOrder?:  {
    __typename: "OnlineOrder",
    id: string,
    conceptID: string,
    statusID?: string | null,
    status?: string | null,
    reason?: string | null,
    orderType?: string | null,
    channel?: string | null,
    userID: string,
    userName?: string | null,
    userPhoneNumber?: string | null,
    dispatcherID?: string | null,
    dispatcherName?: string | null,
    cartID?: string | null,
    tenderId?: number | null,
    employeeId?: number | null,
    rvcId?: number | null,
    checkNumber?: string | null,
    totalAmount?: number | null,
    discountedValue?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    tax?: number | null,
    deliveryFee?: number | null,
    orderTime?: string | null,
    executionArn?: string | null,
    specialRequest?: string | null,
    orderAddress?: string | null,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      symphonyID?: string | null,
      preparationAreaID?: string | null,
      name?: string | null,
      quantity: number,
      choiceIDs?: Array< string | null > | null,
      choiceNames?: Array< string | null > | null,
      choiceSymphonyID?: Array< string | null > | null,
      extraChoiceIDs?: Array< string | null > | null,
      extraChoiceSymphonyID?: Array< string | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      comboItemID?: string | null,
    } | null > | null,
    checkRef?: string | null,
    discountID?: number | null,
    amount_cents?: string | null,
    transactionID?: string | null,
    paymentStatus?: boolean | null,
    paymentType?: string | null,
    referenceId?: string | null,
    cardHolderNumber?: string | null,
    cardHolderName?: string | null,
    cardSchemeName?: string | null,
    deleted?: string | null,
    acceptedByWaiterID?: string | null,
    acceptedByWaiterName?: string | null,
    deliveredByWaiterID?: string | null,
    tableID?: string | null,
    tableName?: string | null,
    shift?: string | null,
    parentOrder?: string | null,
    preparationAreaID?: string | null,
    childrenStatus?: Array< string | null > | null,
    createdAt: string,
    acceptedByWaiterAt?: string | null,
    sentToKitchenAt?: string | null,
    acceptedByKitchenAt?: string | null,
    readyAt?: string | null,
    outForDeliveryAt?: string | null,
    deliveredAt?: string | null,
    promoCodeID?: string | null,
    promoCodeName?: string | null,
    promoCodeAppliedTo?: string | null,
    promoCode?:  {
      __typename: "Promocode",
      id: string,
      name: string,
      redemptions: number,
      status?: string | null,
      startDate: string,
      endDate: string,
      appliesTo: string,
      minOrderReq: string,
      minOrderVal?: number | null,
      minOrderQty?: number | null,
      tenderReq: Array< string | null >,
      discountVal: number,
      discountLimit?: number | null,
      quota?: number | null,
      quotaUsed?: number | null,
      totalRevenue?: number | null,
      appliedDiscountedVal?: number | null,
      usePerCustomer?: number | null,
      channelReq: Array< string | null >,
      customerEligibility: string,
      customerReq?: Array< string | null > | null,
      spendingExceeds?: number | null,
      spendingBelow?: number | null,
      orderSinceDate?: string | null,
      orderFromDate?: string | null,
      orderToDate?: string | null,
      isDisplayed?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    reviewID?: string | null,
    review?:  {
      __typename: "Review",
      id: string,
      accountID?: string | null,
      bookingID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    simphEmpID?: string | null,
    createdByID: string,
    createdByName: string,
    tmpField?: string | null,
    updatedByID?: string | null,
    updatedByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateOrderStatusMutationVariables = {
  input: CreateOrderStatusInput,
  condition?: ModelOrderStatusConditionInput | null,
};

export type CreateOrderStatusMutation = {
  createOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    conceptID: string,
    name: string,
    color?: string | null,
    precedence?: number | null,
    accessibleStatus?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateOrderStatusMutationVariables = {
  input: UpdateOrderStatusInput,
  condition?: ModelOrderStatusConditionInput | null,
};

export type UpdateOrderStatusMutation = {
  updateOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    conceptID: string,
    name: string,
    color?: string | null,
    precedence?: number | null,
    accessibleStatus?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteOrderStatusMutationVariables = {
  input: DeleteOrderStatusInput,
  condition?: ModelOrderStatusConditionInput | null,
};

export type DeleteOrderStatusMutation = {
  deleteOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    conceptID: string,
    name: string,
    color?: string | null,
    precedence?: number | null,
    accessibleStatus?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDiscountCouponMutationVariables = {
  input: CreateDiscountCouponInput,
  condition?: ModelDiscountCouponConditionInput | null,
};

export type CreateDiscountCouponMutation = {
  createDiscountCoupon?:  {
    __typename: "DiscountCoupon",
    id: string,
    discountPercent: string,
    usedBy?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDiscountCouponMutationVariables = {
  input: UpdateDiscountCouponInput,
  condition?: ModelDiscountCouponConditionInput | null,
};

export type UpdateDiscountCouponMutation = {
  updateDiscountCoupon?:  {
    __typename: "DiscountCoupon",
    id: string,
    discountPercent: string,
    usedBy?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDiscountCouponMutationVariables = {
  input: DeleteDiscountCouponInput,
  condition?: ModelDiscountCouponConditionInput | null,
};

export type DeleteDiscountCouponMutation = {
  deleteDiscountCoupon?:  {
    __typename: "DiscountCoupon",
    id: string,
    discountPercent: string,
    usedBy?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateZoneMutationVariables = {
  input: CreateZoneInput,
  condition?: ModelZoneConditionInput | null,
};

export type CreateZoneMutation = {
  createZone?:  {
    __typename: "Zone",
    id: string,
    conceptID: string,
    location: string,
    deliveryFee: number,
    concepts?:  Array< {
      __typename: "ZoneConcept",
      id: string,
      deliveryFee: number,
    } | null > | null,
    polygon?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateZoneMutationVariables = {
  input: UpdateZoneInput,
  condition?: ModelZoneConditionInput | null,
};

export type UpdateZoneMutation = {
  updateZone?:  {
    __typename: "Zone",
    id: string,
    conceptID: string,
    location: string,
    deliveryFee: number,
    concepts?:  Array< {
      __typename: "ZoneConcept",
      id: string,
      deliveryFee: number,
    } | null > | null,
    polygon?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteZoneMutationVariables = {
  input: DeleteZoneInput,
  condition?: ModelZoneConditionInput | null,
};

export type DeleteZoneMutation = {
  deleteZone?:  {
    __typename: "Zone",
    id: string,
    conceptID: string,
    location: string,
    deliveryFee: number,
    concepts?:  Array< {
      __typename: "ZoneConcept",
      id: string,
      deliveryFee: number,
    } | null > | null,
    polygon?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserIPMutationVariables = {
  input: CreateUserIPInput,
  condition?: ModelUserIPConditionInput | null,
};

export type CreateUserIPMutation = {
  createUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserIPMutationVariables = {
  input: UpdateUserIPInput,
  condition?: ModelUserIPConditionInput | null,
};

export type UpdateUserIPMutation = {
  updateUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserIPMutationVariables = {
  input: DeleteUserIPInput,
  condition?: ModelUserIPConditionInput | null,
};

export type DeleteUserIPMutation = {
  deleteUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMainCategoryMutationVariables = {
  input: CreateMainCategoryInput,
  condition?: ModelMainCategoryConditionInput | null,
};

export type CreateMainCategoryMutation = {
  createMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMainCategoryMutationVariables = {
  input: UpdateMainCategoryInput,
  condition?: ModelMainCategoryConditionInput | null,
};

export type UpdateMainCategoryMutation = {
  updateMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMainCategoryMutationVariables = {
  input: DeleteMainCategoryInput,
  condition?: ModelMainCategoryConditionInput | null,
};

export type DeleteMainCategoryMutation = {
  deleteMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSubCategoryMutationVariables = {
  input: CreateSubCategoryInput,
  condition?: ModelSubCategoryConditionInput | null,
};

export type CreateSubCategoryMutation = {
  createSubCategory?:  {
    __typename: "SubCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    mainCategoryID?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSubCategoryMutationVariables = {
  input: UpdateSubCategoryInput,
  condition?: ModelSubCategoryConditionInput | null,
};

export type UpdateSubCategoryMutation = {
  updateSubCategory?:  {
    __typename: "SubCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    mainCategoryID?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSubCategoryMutationVariables = {
  input: DeleteSubCategoryInput,
  condition?: ModelSubCategoryConditionInput | null,
};

export type DeleteSubCategoryMutation = {
  deleteSubCategory?:  {
    __typename: "SubCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    mainCategoryID?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateShiftMutationVariables = {
  input: CreateShiftInput,
  condition?: ModelShiftConditionInput | null,
};

export type CreateShiftMutation = {
  createShift?:  {
    __typename: "Shift",
    id: string,
    accountID: string,
    name: string,
    precedence: string,
    startTime: string,
    endTime: string,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateShiftMutationVariables = {
  input: UpdateShiftInput,
  condition?: ModelShiftConditionInput | null,
};

export type UpdateShiftMutation = {
  updateShift?:  {
    __typename: "Shift",
    id: string,
    accountID: string,
    name: string,
    precedence: string,
    startTime: string,
    endTime: string,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteShiftMutationVariables = {
  input: DeleteShiftInput,
  condition?: ModelShiftConditionInput | null,
};

export type DeleteShiftMutation = {
  deleteShift?:  {
    __typename: "Shift",
    id: string,
    accountID: string,
    name: string,
    precedence: string,
    startTime: string,
    endTime: string,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateChannelMutationVariables = {
  input: CreateChannelInput,
  condition?: ModelChannelConditionInput | null,
};

export type CreateChannelMutation = {
  createChannel?:  {
    __typename: "Channel",
    id: string,
    name: string,
    description?: string | null,
    numberOfOrders?: number | null,
    isActive?: boolean | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateChannelMutationVariables = {
  input: UpdateChannelInput,
  condition?: ModelChannelConditionInput | null,
};

export type UpdateChannelMutation = {
  updateChannel?:  {
    __typename: "Channel",
    id: string,
    name: string,
    description?: string | null,
    numberOfOrders?: number | null,
    isActive?: boolean | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteChannelMutationVariables = {
  input: DeleteChannelInput,
  condition?: ModelChannelConditionInput | null,
};

export type DeleteChannelMutation = {
  deleteChannel?:  {
    __typename: "Channel",
    id: string,
    name: string,
    description?: string | null,
    numberOfOrders?: number | null,
    isActive?: boolean | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePromocodeMutationVariables = {
  input: CreatePromocodeInput,
  condition?: ModelPromocodeConditionInput | null,
};

export type CreatePromocodeMutation = {
  createPromocode?:  {
    __typename: "Promocode",
    id: string,
    name: string,
    redemptions: number,
    status?: string | null,
    startDate: string,
    endDate: string,
    appliesTo: string,
    minOrderReq: string,
    minOrderVal?: number | null,
    minOrderQty?: number | null,
    tenderReq: Array< string | null >,
    discountVal: number,
    discountLimit?: number | null,
    quota?: number | null,
    quotaUsed?: number | null,
    totalRevenue?: number | null,
    appliedDiscountedVal?: number | null,
    usePerCustomer?: number | null,
    channelReq: Array< string | null >,
    customerEligibility: string,
    customerReq?: Array< string | null > | null,
    spendingExceeds?: number | null,
    spendingBelow?: number | null,
    orderSinceDate?: string | null,
    orderFromDate?: string | null,
    orderToDate?: string | null,
    isDisplayed?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePromocodeMutationVariables = {
  input: UpdatePromocodeInput,
  condition?: ModelPromocodeConditionInput | null,
};

export type UpdatePromocodeMutation = {
  updatePromocode?:  {
    __typename: "Promocode",
    id: string,
    name: string,
    redemptions: number,
    status?: string | null,
    startDate: string,
    endDate: string,
    appliesTo: string,
    minOrderReq: string,
    minOrderVal?: number | null,
    minOrderQty?: number | null,
    tenderReq: Array< string | null >,
    discountVal: number,
    discountLimit?: number | null,
    quota?: number | null,
    quotaUsed?: number | null,
    totalRevenue?: number | null,
    appliedDiscountedVal?: number | null,
    usePerCustomer?: number | null,
    channelReq: Array< string | null >,
    customerEligibility: string,
    customerReq?: Array< string | null > | null,
    spendingExceeds?: number | null,
    spendingBelow?: number | null,
    orderSinceDate?: string | null,
    orderFromDate?: string | null,
    orderToDate?: string | null,
    isDisplayed?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePromocodeMutationVariables = {
  input: DeletePromocodeInput,
  condition?: ModelPromocodeConditionInput | null,
};

export type DeletePromocodeMutation = {
  deletePromocode?:  {
    __typename: "Promocode",
    id: string,
    name: string,
    redemptions: number,
    status?: string | null,
    startDate: string,
    endDate: string,
    appliesTo: string,
    minOrderReq: string,
    minOrderVal?: number | null,
    minOrderQty?: number | null,
    tenderReq: Array< string | null >,
    discountVal: number,
    discountLimit?: number | null,
    quota?: number | null,
    quotaUsed?: number | null,
    totalRevenue?: number | null,
    appliedDiscountedVal?: number | null,
    usePerCustomer?: number | null,
    channelReq: Array< string | null >,
    customerEligibility: string,
    customerReq?: Array< string | null > | null,
    spendingExceeds?: number | null,
    spendingBelow?: number | null,
    orderSinceDate?: string | null,
    orderFromDate?: string | null,
    orderToDate?: string | null,
    isDisplayed?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePromocodeDashboardMutationVariables = {
  input: CreatePromocodeDashboardInput,
  condition?: ModelPromocodeDashboardConditionInput | null,
};

export type CreatePromocodeDashboardMutation = {
  createPromocodeDashboard?:  {
    __typename: "PromocodeDashboard",
    id: string,
    conceptID: string,
    totalRevenue?: number | null,
    totalRedemptions?: number | null,
    totalDiscount?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePromocodeDashboardMutationVariables = {
  input: UpdatePromocodeDashboardInput,
  condition?: ModelPromocodeDashboardConditionInput | null,
};

export type UpdatePromocodeDashboardMutation = {
  updatePromocodeDashboard?:  {
    __typename: "PromocodeDashboard",
    id: string,
    conceptID: string,
    totalRevenue?: number | null,
    totalRedemptions?: number | null,
    totalDiscount?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePromocodeDashboardMutationVariables = {
  input: DeletePromocodeDashboardInput,
  condition?: ModelPromocodeDashboardConditionInput | null,
};

export type DeletePromocodeDashboardMutation = {
  deletePromocodeDashboard?:  {
    __typename: "PromocodeDashboard",
    id: string,
    conceptID: string,
    totalRevenue?: number | null,
    totalRedemptions?: number | null,
    totalDiscount?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLoyaltyItemMutationVariables = {
  input: CreateLoyaltyItemInput,
  condition?: ModelLoyaltyItemConditionInput | null,
};

export type CreateLoyaltyItemMutation = {
  createLoyaltyItem?:  {
    __typename: "LoyaltyItem",
    id: string,
    name: string,
    description?: string | null,
    startDate: string,
    endDate?: string | null,
    points: number,
    redemptionPerUser?: number | null,
    image?: string | null,
    simphonyID: string,
    multiLanguages?: string | null,
    isActive?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLoyaltyItemMutationVariables = {
  input: UpdateLoyaltyItemInput,
  condition?: ModelLoyaltyItemConditionInput | null,
};

export type UpdateLoyaltyItemMutation = {
  updateLoyaltyItem?:  {
    __typename: "LoyaltyItem",
    id: string,
    name: string,
    description?: string | null,
    startDate: string,
    endDate?: string | null,
    points: number,
    redemptionPerUser?: number | null,
    image?: string | null,
    simphonyID: string,
    multiLanguages?: string | null,
    isActive?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLoyaltyItemMutationVariables = {
  input: DeleteLoyaltyItemInput,
  condition?: ModelLoyaltyItemConditionInput | null,
};

export type DeleteLoyaltyItemMutation = {
  deleteLoyaltyItem?:  {
    __typename: "LoyaltyItem",
    id: string,
    name: string,
    description?: string | null,
    startDate: string,
    endDate?: string | null,
    points: number,
    redemptionPerUser?: number | null,
    image?: string | null,
    simphonyID: string,
    multiLanguages?: string | null,
    isActive?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateOTPMutationVariables = {
  input: CreateOTPInput,
  condition?: ModelOTPConditionInput | null,
};

export type CreateOTPMutation = {
  createOTP?:  {
    __typename: "OTP",
    id: string,
    OTP: string,
    redeemed?: boolean | null,
    deleted?: string | null,
    userID: string,
    conceptID?: string | null,
    loyaltyItemID?: string | null,
    loyaltyItem?:  {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID: string,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    concept?:  {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateOTPMutationVariables = {
  input: UpdateOTPInput,
  condition?: ModelOTPConditionInput | null,
};

export type UpdateOTPMutation = {
  updateOTP?:  {
    __typename: "OTP",
    id: string,
    OTP: string,
    redeemed?: boolean | null,
    deleted?: string | null,
    userID: string,
    conceptID?: string | null,
    loyaltyItemID?: string | null,
    loyaltyItem?:  {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID: string,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    concept?:  {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteOTPMutationVariables = {
  input: DeleteOTPInput,
  condition?: ModelOTPConditionInput | null,
};

export type DeleteOTPMutation = {
  deleteOTP?:  {
    __typename: "OTP",
    id: string,
    OTP: string,
    redeemed?: boolean | null,
    deleted?: string | null,
    userID: string,
    conceptID?: string | null,
    loyaltyItemID?: string | null,
    loyaltyItem?:  {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID: string,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    concept?:  {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetEmailVerificationQueryVariables = {
  id: string,
};

export type GetEmailVerificationQuery = {
  getEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    accountID: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListEmailVerificationsQueryVariables = {
  filter?: ModelEmailVerificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEmailVerificationsQuery = {
  listEmailVerifications?:  {
    __typename: "ModelEmailVerificationConnection",
    items:  Array< {
      __typename: "EmailVerification",
      id: string,
      accountID: string,
      name: string,
      email: string,
      verification_code: string,
      createdAt: string,
      deleted?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncEmailVerificationsQueryVariables = {
  filter?: ModelEmailVerificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncEmailVerificationsQuery = {
  syncEmailVerifications?:  {
    __typename: "ModelEmailVerificationConnection",
    items:  Array< {
      __typename: "EmailVerification",
      id: string,
      accountID: string,
      name: string,
      email: string,
      verification_code: string,
      createdAt: string,
      deleted?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSocialLinkQueryVariables = {
  id: string,
};

export type GetSocialLinkQuery = {
  getSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSocialLinksQueryVariables = {
  filter?: ModelSocialLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSocialLinksQuery = {
  listSocialLinks?:  {
    __typename: "ModelSocialLinkConnection",
    items:  Array< {
      __typename: "SocialLink",
      id: string,
      name: string,
      icon: string,
      slug: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSocialLinksQueryVariables = {
  filter?: ModelSocialLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSocialLinksQuery = {
  syncSocialLinks?:  {
    __typename: "ModelSocialLinkConnection",
    items:  Array< {
      __typename: "SocialLink",
      id: string,
      name: string,
      icon: string,
      slug: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLanguageQueryVariables = {
  id: string,
};

export type GetLanguageQuery = {
  getLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLanguagesQueryVariables = {
  filter?: ModelLanguageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLanguagesQuery = {
  listLanguages?:  {
    __typename: "ModelLanguageConnection",
    items:  Array< {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLanguagesQueryVariables = {
  filter?: ModelLanguageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLanguagesQuery = {
  syncLanguages?:  {
    __typename: "ModelLanguageConnection",
    items:  Array< {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAttachmentQueryVariables = {
  id: string,
};

export type GetAttachmentQuery = {
  getAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAttachmentsQueryVariables = {
  filter?: ModelAttachmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAttachmentsQuery = {
  listAttachments?:  {
    __typename: "ModelAttachmentConnection",
    items:  Array< {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAttachmentsQueryVariables = {
  filter?: ModelAttachmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAttachmentsQuery = {
  syncAttachments?:  {
    __typename: "ModelAttachmentConnection",
    items:  Array< {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetGroupQueryVariables = {
  id: string,
};

export type GetGroupQuery = {
  getGroup?:  {
    __typename: "Group",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    color?: string | null,
    requireApproval?: boolean | null,
    status?: string | null,
    agents?: Array< string | null > | null,
    groupSettings?: Array< string | null > | null,
    numToUpgrade?: number | null,
    numToDowngrade?: number | null,
    upgradeGroup?: string | null,
    downgradeGroup?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListGroupsQueryVariables = {
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGroupsQuery = {
  listGroups?:  {
    __typename: "ModelGroupConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      accountID: string,
      name: string,
      description?: string | null,
      color?: string | null,
      requireApproval?: boolean | null,
      status?: string | null,
      agents?: Array< string | null > | null,
      groupSettings?: Array< string | null > | null,
      numToUpgrade?: number | null,
      numToDowngrade?: number | null,
      upgradeGroup?: string | null,
      downgradeGroup?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncGroupsQueryVariables = {
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncGroupsQuery = {
  syncGroups?:  {
    __typename: "ModelGroupConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      accountID: string,
      name: string,
      description?: string | null,
      color?: string | null,
      requireApproval?: boolean | null,
      status?: string | null,
      agents?: Array< string | null > | null,
      groupSettings?: Array< string | null > | null,
      numToUpgrade?: number | null,
      numToDowngrade?: number | null,
      upgradeGroup?: string | null,
      downgradeGroup?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetGroupSettingQueryVariables = {
  id: string,
};

export type GetGroupSettingQuery = {
  getGroupSetting?:  {
    __typename: "GroupSetting",
    id: string,
    groupID: string,
    statusID: string,
    needsVerification: boolean,
    needsDeposit: boolean,
    isDefault: boolean,
    amount?: number | null,
    days?: Array< string | null > | null,
    timeSlots?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListGroupSettingsQueryVariables = {
  filter?: ModelGroupSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGroupSettingsQuery = {
  listGroupSettings?:  {
    __typename: "ModelGroupSettingConnection",
    items:  Array< {
      __typename: "GroupSetting",
      id: string,
      groupID: string,
      statusID: string,
      needsVerification: boolean,
      needsDeposit: boolean,
      isDefault: boolean,
      amount?: number | null,
      days?: Array< string | null > | null,
      timeSlots?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncGroupSettingsQueryVariables = {
  filter?: ModelGroupSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncGroupSettingsQuery = {
  syncGroupSettings?:  {
    __typename: "ModelGroupSettingConnection",
    items:  Array< {
      __typename: "GroupSetting",
      id: string,
      groupID: string,
      statusID: string,
      needsVerification: boolean,
      needsDeposit: boolean,
      isDefault: boolean,
      amount?: number | null,
      days?: Array< string | null > | null,
      timeSlots?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCustomerStatsQueryVariables = {
  id: string,
};

export type GetCustomerStatsQuery = {
  getCustomerStats?:  {
    __typename: "CustomerStats",
    id: string,
    conceptID: string,
    totalReservation?: number | null,
    confirmed?: number | null,
    notConfirmed?: number | null,
    canceled?: number | null,
    checkIn?: number | null,
    noShow?: number | null,
    userID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCustomerStatsQueryVariables = {
  filter?: ModelCustomerStatsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomerStatsQuery = {
  listCustomerStats?:  {
    __typename: "ModelCustomerStatsConnection",
    items:  Array< {
      __typename: "CustomerStats",
      id: string,
      conceptID: string,
      totalReservation?: number | null,
      confirmed?: number | null,
      notConfirmed?: number | null,
      canceled?: number | null,
      checkIn?: number | null,
      noShow?: number | null,
      userID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCustomerStatsQueryVariables = {
  filter?: ModelCustomerStatsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCustomerStatsQuery = {
  syncCustomerStats?:  {
    __typename: "ModelCustomerStatsConnection",
    items:  Array< {
      __typename: "CustomerStats",
      id: string,
      conceptID: string,
      totalReservation?: number | null,
      confirmed?: number | null,
      notConfirmed?: number | null,
      canceled?: number | null,
      checkIn?: number | null,
      noShow?: number | null,
      userID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFlagQueryVariables = {
  id: string,
};

export type GetFlagQuery = {
  getFlag?:  {
    __typename: "Flag",
    id: string,
    accountID: string,
    name: string,
    icon: string,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFlagsQueryVariables = {
  filter?: ModelFlagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFlagsQuery = {
  listFlags?:  {
    __typename: "ModelFlagConnection",
    items:  Array< {
      __typename: "Flag",
      id: string,
      accountID: string,
      name: string,
      icon: string,
      color?: string | null,
      customers?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFlagsQueryVariables = {
  filter?: ModelFlagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFlagsQuery = {
  syncFlags?:  {
    __typename: "ModelFlagConnection",
    items:  Array< {
      __typename: "Flag",
      id: string,
      accountID: string,
      name: string,
      icon: string,
      color?: string | null,
      customers?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInterestQueryVariables = {
  id: string,
};

export type GetInterestQuery = {
  getInterest?:  {
    __typename: "Interest",
    id: string,
    accountID: string,
    name: string,
    icon?: string | null,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListInterestsQueryVariables = {
  filter?: ModelInterestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInterestsQuery = {
  listInterests?:  {
    __typename: "ModelInterestConnection",
    items:  Array< {
      __typename: "Interest",
      id: string,
      accountID: string,
      name: string,
      icon?: string | null,
      color?: string | null,
      customers?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInterestsQueryVariables = {
  filter?: ModelInterestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInterestsQuery = {
  syncInterests?:  {
    __typename: "ModelInterestConnection",
    items:  Array< {
      __typename: "Interest",
      id: string,
      accountID: string,
      name: string,
      icon?: string | null,
      color?: string | null,
      customers?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetReservationStatusQueryVariables = {
  id: string,
};

export type GetReservationStatusQuery = {
  getReservationStatus?:  {
    __typename: "ReservationStatus",
    id: string,
    accountID: string,
    name: string,
    category: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    reservationStatusImageId?: string | null,
  } | null,
};

export type ListReservationStatusesQueryVariables = {
  filter?: ModelReservationStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReservationStatusesQuery = {
  listReservationStatuses?:  {
    __typename: "ModelReservationStatusConnection",
    items:  Array< {
      __typename: "ReservationStatus",
      id: string,
      accountID: string,
      name: string,
      category: string,
      image?:  {
        __typename: "Attachment",
        id: string,
        mediaID: string,
        fileUrl: string,
        filename: string,
        filetype?: string | null,
        fileSize?: number | null,
        alternativeText?: string | null,
        caption?: string | null,
        description?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      reservationStatusImageId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncReservationStatusesQueryVariables = {
  filter?: ModelReservationStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncReservationStatusesQuery = {
  syncReservationStatuses?:  {
    __typename: "ModelReservationStatusConnection",
    items:  Array< {
      __typename: "ReservationStatus",
      id: string,
      accountID: string,
      name: string,
      category: string,
      image?:  {
        __typename: "Attachment",
        id: string,
        mediaID: string,
        fileUrl: string,
        filename: string,
        filetype?: string | null,
        fileSize?: number | null,
        alternativeText?: string | null,
        caption?: string | null,
        description?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      reservationStatusImageId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTimeSlotQueryVariables = {
  id: string,
};

export type GetTimeSlotQuery = {
  getTimeSlot?:  {
    __typename: "TimeSlot",
    id: string,
    conceptID: string,
    name: string,
    friendlyName: string,
    bookings?: Array< string | null > | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTimeSlotsQueryVariables = {
  filter?: ModelTimeSlotFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTimeSlotsQuery = {
  listTimeSlots?:  {
    __typename: "ModelTimeSlotConnection",
    items:  Array< {
      __typename: "TimeSlot",
      id: string,
      conceptID: string,
      name: string,
      friendlyName: string,
      bookings?: Array< string | null > | null,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTimeSlotsQueryVariables = {
  filter?: ModelTimeSlotFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTimeSlotsQuery = {
  syncTimeSlots?:  {
    __typename: "ModelTimeSlotConnection",
    items:  Array< {
      __typename: "TimeSlot",
      id: string,
      conceptID: string,
      name: string,
      friendlyName: string,
      bookings?: Array< string | null > | null,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAreaQueryVariables = {
  id: string,
};

export type GetAreaQuery = {
  getArea?:  {
    __typename: "Area",
    id: string,
    conceptID: string,
    name: string,
    isDefault: boolean,
    expiryDate?: string | null,
    days?: Array< string | null > | null,
    timeSlots?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAreasQueryVariables = {
  filter?: ModelAreaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAreasQuery = {
  listAreas?:  {
    __typename: "ModelAreaConnection",
    items:  Array< {
      __typename: "Area",
      id: string,
      conceptID: string,
      name: string,
      isDefault: boolean,
      expiryDate?: string | null,
      days?: Array< string | null > | null,
      timeSlots?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAreasQueryVariables = {
  filter?: ModelAreaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAreasQuery = {
  syncAreas?:  {
    __typename: "ModelAreaConnection",
    items:  Array< {
      __typename: "Area",
      id: string,
      conceptID: string,
      name: string,
      isDefault: boolean,
      expiryDate?: string | null,
      days?: Array< string | null > | null,
      timeSlots?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTableQueryVariables = {
  id: string,
};

export type GetTableQuery = {
  getTable?:  {
    __typename: "Table",
    id: string,
    conceptID: string,
    label: string,
    name: string,
    width?: number | null,
    height?: number | null,
    capacity?: number | null,
    image?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTablesQueryVariables = {
  filter?: ModelTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTablesQuery = {
  listTables?:  {
    __typename: "ModelTableConnection",
    items:  Array< {
      __typename: "Table",
      id: string,
      conceptID: string,
      label: string,
      name: string,
      width?: number | null,
      height?: number | null,
      capacity?: number | null,
      image?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTablesQueryVariables = {
  filter?: ModelTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTablesQuery = {
  syncTables?:  {
    __typename: "ModelTableConnection",
    items:  Array< {
      __typename: "Table",
      id: string,
      conceptID: string,
      label: string,
      name: string,
      width?: number | null,
      height?: number | null,
      capacity?: number | null,
      image?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFriendQueryVariables = {
  id: string,
};

export type GetFriendQuery = {
  getFriend?:  {
    __typename: "Friend",
    id: string,
    customerId?: string | null,
    friendId?: string | null,
    bookingID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFriendsQueryVariables = {
  filter?: ModelFriendFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFriendsQuery = {
  listFriends?:  {
    __typename: "ModelFriendConnection",
    items:  Array< {
      __typename: "Friend",
      id: string,
      customerId?: string | null,
      friendId?: string | null,
      bookingID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFriendsQueryVariables = {
  filter?: ModelFriendFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFriendsQuery = {
  syncFriends?:  {
    __typename: "ModelFriendConnection",
    items:  Array< {
      __typename: "Friend",
      id: string,
      customerId?: string | null,
      friendId?: string | null,
      bookingID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCommentQueryVariables = {
  id: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    id: string,
    message: string,
    customerId?: string | null,
    bookingId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCommentsQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsQuery = {
  listComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      message: string,
      customerId?: string | null,
      bookingId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCommentsQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCommentsQuery = {
  syncComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      message: string,
      customerId?: string | null,
      bookingId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetServingAreaQueryVariables = {
  id: string,
};

export type GetServingAreaQuery = {
  getServingArea?:  {
    __typename: "ServingArea",
    id: string,
    name: string,
    fees: number,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListServingAreasQueryVariables = {
  filter?: ModelServingAreaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListServingAreasQuery = {
  listServingAreas?:  {
    __typename: "ModelServingAreaConnection",
    items:  Array< {
      __typename: "ServingArea",
      id: string,
      name: string,
      fees: number,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncServingAreasQueryVariables = {
  filter?: ModelServingAreaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncServingAreasQuery = {
  syncServingAreas?:  {
    __typename: "ModelServingAreaConnection",
    items:  Array< {
      __typename: "ServingArea",
      id: string,
      name: string,
      fees: number,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    accountID: string,
    conceptID: string,
    name?: string | null,
    description?: string | null,
    channel?: string | null,
    message?: string | null,
    from?: string | null,
    enabled?: boolean | null,
    autoSend?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      accountID: string,
      conceptID: string,
      name?: string | null,
      description?: string | null,
      channel?: string | null,
      message?: string | null,
      from?: string | null,
      enabled?: boolean | null,
      autoSend?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncNotificationsQuery = {
  syncNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      accountID: string,
      conceptID: string,
      name?: string | null,
      description?: string | null,
      channel?: string | null,
      message?: string | null,
      from?: string | null,
      enabled?: boolean | null,
      autoSend?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMobileNotificationQueryVariables = {
  id: string,
};

export type GetMobileNotificationQuery = {
  getMobileNotification?:  {
    __typename: "MobileNotification",
    id: string,
    title?: string | null,
    description?: string | null,
    body?: string | null,
    default?: boolean | null,
    event?: boolean | null,
    image?: string | null,
    concepts?: Array< string | null > | null,
    condition?: Array< string | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    group?: string | null,
    read?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMobileNotificationsQueryVariables = {
  filter?: ModelMobileNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMobileNotificationsQuery = {
  listMobileNotifications?:  {
    __typename: "ModelMobileNotificationConnection",
    items:  Array< {
      __typename: "MobileNotification",
      id: string,
      title?: string | null,
      description?: string | null,
      body?: string | null,
      default?: boolean | null,
      event?: boolean | null,
      image?: string | null,
      concepts?: Array< string | null > | null,
      condition?: Array< string | null > | null,
      startDate?: string | null,
      endDate?: string | null,
      group?: string | null,
      read?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMobileNotificationsQueryVariables = {
  filter?: ModelMobileNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMobileNotificationsQuery = {
  syncMobileNotifications?:  {
    __typename: "ModelMobileNotificationConnection",
    items:  Array< {
      __typename: "MobileNotification",
      id: string,
      title?: string | null,
      description?: string | null,
      body?: string | null,
      default?: boolean | null,
      event?: boolean | null,
      image?: string | null,
      concepts?: Array< string | null > | null,
      condition?: Array< string | null > | null,
      startDate?: string | null,
      endDate?: string | null,
      group?: string | null,
      read?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserPushTokenQueryVariables = {
  id: string,
};

export type GetUserPushTokenQuery = {
  getUserPushToken?:  {
    __typename: "UserPushToken",
    id: string,
    userID?: string | null,
    token?: string | null,
    reciveNotification?: boolean | null,
    type?: string | null,
    os?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserPushTokensQueryVariables = {
  filter?: ModelUserPushTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserPushTokensQuery = {
  listUserPushTokens?:  {
    __typename: "ModelUserPushTokenConnection",
    items:  Array< {
      __typename: "UserPushToken",
      id: string,
      userID?: string | null,
      token?: string | null,
      reciveNotification?: boolean | null,
      type?: string | null,
      os?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserPushTokensQueryVariables = {
  filter?: ModelUserPushTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserPushTokensQuery = {
  syncUserPushTokens?:  {
    __typename: "ModelUserPushTokenConnection",
    items:  Array< {
      __typename: "UserPushToken",
      id: string,
      userID?: string | null,
      token?: string | null,
      reciveNotification?: boolean | null,
      type?: string | null,
      os?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetNotificationReceiverQueryVariables = {
  id: string,
};

export type GetNotificationReceiverQuery = {
  getNotificationReceiver?:  {
    __typename: "NotificationReceiver",
    id: string,
    userID?: string | null,
    notificationID?: string | null,
    seen?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListNotificationReceiversQueryVariables = {
  filter?: ModelNotificationReceiverFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationReceiversQuery = {
  listNotificationReceivers?:  {
    __typename: "ModelNotificationReceiverConnection",
    items:  Array< {
      __typename: "NotificationReceiver",
      id: string,
      userID?: string | null,
      notificationID?: string | null,
      seen?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncNotificationReceiversQueryVariables = {
  filter?: ModelNotificationReceiverFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncNotificationReceiversQuery = {
  syncNotificationReceivers?:  {
    __typename: "ModelNotificationReceiverConnection",
    items:  Array< {
      __typename: "NotificationReceiver",
      id: string,
      userID?: string | null,
      notificationID?: string | null,
      seen?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPreparationAreaQueryVariables = {
  id: string,
};

export type GetPreparationAreaQuery = {
  getPreparationArea?:  {
    __typename: "PreparationArea",
    id: string,
    accountID: string,
    name: string,
    isAutoAccept?: boolean | null,
    hasKDS?: boolean | null,
    hasPrinter?: boolean | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    precedence: string,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPreparationAreasQueryVariables = {
  filter?: ModelPreparationAreaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPreparationAreasQuery = {
  listPreparationAreas?:  {
    __typename: "ModelPreparationAreaConnection",
    items:  Array< {
      __typename: "PreparationArea",
      id: string,
      accountID: string,
      name: string,
      isAutoAccept?: boolean | null,
      hasKDS?: boolean | null,
      hasPrinter?: boolean | null,
      users?: Array< string | null > | null,
      deleted?: string | null,
      precedence: string,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPreparationAreasQueryVariables = {
  filter?: ModelPreparationAreaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPreparationAreasQuery = {
  syncPreparationAreas?:  {
    __typename: "ModelPreparationAreaConnection",
    items:  Array< {
      __typename: "PreparationArea",
      id: string,
      accountID: string,
      name: string,
      isAutoAccept?: boolean | null,
      hasKDS?: boolean | null,
      hasPrinter?: boolean | null,
      users?: Array< string | null > | null,
      deleted?: string | null,
      precedence: string,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetQRcodeQueryVariables = {
  id: string,
};

export type GetQRcodeQuery = {
  getQRcode?:  {
    __typename: "QRcode",
    id: string,
    tableID: string,
    conceptID: string,
    tableName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListQRcodesQueryVariables = {
  filter?: ModelQRcodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQRcodesQuery = {
  listQRcodes?:  {
    __typename: "ModelQRcodeConnection",
    items:  Array< {
      __typename: "QRcode",
      id: string,
      tableID: string,
      conceptID: string,
      tableName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncQRcodesQueryVariables = {
  filter?: ModelQRcodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncQRcodesQuery = {
  syncQRcodes?:  {
    __typename: "ModelQRcodeConnection",
    items:  Array< {
      __typename: "QRcode",
      id: string,
      tableID: string,
      conceptID: string,
      tableName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCallWaiterQueryVariables = {
  id: string,
};

export type GetCallWaiterQuery = {
  getCallWaiter?:  {
    __typename: "CallWaiter",
    id: string,
    conceptID?: string | null,
    tableID?: string | null,
    tableName?: string | null,
    waiterID?: string | null,
    waiterName?: string | null,
    userID?: string | null,
    count?: number | null,
    status?: string | null,
    deleted?: string | null,
    createdAt: string,
    acceptedAt?: string | null,
    arrivedAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCallWaitersQueryVariables = {
  filter?: ModelCallWaiterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCallWaitersQuery = {
  listCallWaiters?:  {
    __typename: "ModelCallWaiterConnection",
    items:  Array< {
      __typename: "CallWaiter",
      id: string,
      conceptID?: string | null,
      tableID?: string | null,
      tableName?: string | null,
      waiterID?: string | null,
      waiterName?: string | null,
      userID?: string | null,
      count?: number | null,
      status?: string | null,
      deleted?: string | null,
      createdAt: string,
      acceptedAt?: string | null,
      arrivedAt?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCallWaitersQueryVariables = {
  filter?: ModelCallWaiterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCallWaitersQuery = {
  syncCallWaiters?:  {
    __typename: "ModelCallWaiterConnection",
    items:  Array< {
      __typename: "CallWaiter",
      id: string,
      conceptID?: string | null,
      tableID?: string | null,
      tableName?: string | null,
      waiterID?: string | null,
      waiterName?: string | null,
      userID?: string | null,
      count?: number | null,
      status?: string | null,
      deleted?: string | null,
      createdAt: string,
      acceptedAt?: string | null,
      arrivedAt?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    accountID: string,
    username?: string | null,
    preferred_username?: string | null,
    email?: string | null,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    pictureCrop?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    facebook?: string | null,
    instagram?: string | null,
    group?: string | null,
    phones?: Array< string | null > | null,
    flags?: Array< string | null > | null,
    flagsName?: Array< string | null > | null,
    interests?: Array< string | null > | null,
    interestsName?: Array< string | null > | null,
    status?: string | null,
    stats?: Array< string | null > | null,
    spending?: number | null,
    prevOrderDate?: string | null,
    lastOrderDate?: string | null,
    promocodes?:  Array< {
      __typename: "PromocodeItem",
      name: string,
      promocodeId: string,
      canBeUsed: number,
      discountVal: number,
      discountLimit?: number | null,
      isDisplayed?: boolean | null,
    } | null > | null,
    mostOrderedItems?: string | null,
    points?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    cognitoUsername?: string | null,
    cognitoSub?: string | null,
    updated?: string | null,
    sub?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
    scannedCount?: number | null,
    count?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
    scannedCount?: number | null,
    count?: number | null,
  } | null,
};

export type ByPhoneNumberQueryVariables = {
  phone_number: string,
  deletedNameCreatedAt?: ModelUserUsersByPhoneNumberCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByPhoneNumberQuery = {
  ByPhoneNumber?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
    scannedCount?: number | null,
    count?: number | null,
  } | null,
};

export type GetReviewQueryVariables = {
  id: string,
};

export type GetReviewQuery = {
  getReview?:  {
    __typename: "Review",
    id: string,
    accountID?: string | null,
    bookingID?: string | null,
    guestName?: string | null,
    guestId?: string | null,
    reason?: Array< string | null > | null,
    value?: string | null,
    userID?: string | null,
    orderID?: string | null,
    rate?: number | null,
    satisfaction?: string | null,
    thoughts?: string | null,
    itemReviews?:  Array< {
      __typename: "ItemReview",
      itemID: string,
      rate?: number | null,
      satisfaction?: string | null,
      multiLanguages?: string | null,
    } | null > | null,
    photos?: Array< string | null > | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListReviewsQueryVariables = {
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewsQuery = {
  listReviews?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      accountID?: string | null,
      bookingID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncReviewsQueryVariables = {
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncReviewsQuery = {
  syncReviews?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      accountID?: string | null,
      bookingID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAccountQueryVariables = {
  id: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    logo?: string | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    callCenterEmployeesIds?: Array< string | null > | null,
    kpiReportResetFrequency?: string | null,
    agentBarResetFrequency?: string | null,
    dynamicStatus?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAccountsQueryVariables = {
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountsQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      logo?: string | null,
      domain: string,
      siteTitle: string,
      guestsCount?: number | null,
      tagline: string,
      description: string,
      siteAddress: string,
      defaultLanguage: string,
      languages: Array< string | null >,
      features: Array< string | null >,
      status: string,
      socialLinks?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      callCenterEmployeesIds?: Array< string | null > | null,
      kpiReportResetFrequency?: string | null,
      agentBarResetFrequency?: string | null,
      dynamicStatus?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAccountsQueryVariables = {
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAccountsQuery = {
  syncAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      logo?: string | null,
      domain: string,
      siteTitle: string,
      guestsCount?: number | null,
      tagline: string,
      description: string,
      siteAddress: string,
      defaultLanguage: string,
      languages: Array< string | null >,
      features: Array< string | null >,
      status: string,
      socialLinks?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      callCenterEmployeesIds?: Array< string | null > | null,
      kpiReportResetFrequency?: string | null,
      agentBarResetFrequency?: string | null,
      dynamicStatus?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFeatureQueryVariables = {
  id: string,
};

export type GetFeatureQuery = {
  getFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFeaturesQueryVariables = {
  filter?: ModelFeatureFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFeaturesQuery = {
  listFeatures?:  {
    __typename: "ModelFeatureConnection",
    items:  Array< {
      __typename: "Feature",
      id: string,
      name: string,
      icon?: string | null,
      slug: string,
      precedence: string,
      parent?: string | null,
      private?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFeaturesQueryVariables = {
  filter?: ModelFeatureFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFeaturesQuery = {
  syncFeatures?:  {
    __typename: "ModelFeatureConnection",
    items:  Array< {
      __typename: "Feature",
      id: string,
      name: string,
      icon?: string | null,
      slug: string,
      precedence: string,
      parent?: string | null,
      private?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMediaQueryVariables = {
  id: string,
};

export type GetMediaQuery = {
  getMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMediaQueryVariables = {
  filter?: ModelMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMediaQuery = {
  listMedia?:  {
    __typename: "ModelMediaConnection",
    items:  Array< {
      __typename: "Media",
      id: string,
      accountID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      attachments?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMediaQueryVariables = {
  filter?: ModelMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMediaQuery = {
  syncMedia?:  {
    __typename: "ModelMediaConnection",
    items:  Array< {
      __typename: "Media",
      id: string,
      accountID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      attachments?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetConceptQueryVariables = {
  id: string,
};

export type GetConceptQuery = {
  getConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    kiosks?: Array< string | null > | null,
    merchantID?: string | null,
    merchantUsername?: string | null,
    merchantPassword?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    simphonyServerBaseURL?: string | null,
    simphonyShortOrgName?: string | null,
    simphonyLocRef?: string | null,
    simphonyToken?: string | null,
    revenueCenterID?: number | null,
    employeeID?: number | null,
    tenderID?: number | null,
    tenders?:  {
      __typename: "ModelTenderConnection",
      items:  Array< {
        __typename: "Tender",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptTendersId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderType?:  {
      __typename: "ModelOrderTypeConnection",
      items:  Array< {
        __typename: "OrderType",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptOrderTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    discountID?: number | null,
    useServiceCharge?: boolean | null,
    serviceChargeId?: number | null,
    specialRequestID?: number | null,
    addressID?: number | null,
    simphonyAuthClientId?: string | null,
    simphonyAuthUsername?: string | null,
    simphonyAuthPassword?: string | null,
    simphonyAuthBaseURL?: string | null,
    orderTypeDineIn?: number | null,
    orderTypeTakeAway?: number | null,
    orderTypeDelivery?: number | null,
    simphonyTakeAwayRvc?: number | null,
    simphonyDeliveryRvc?: number | null,
    exportInterval?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    foodicsToken?: string | null,
    enableTableFoodics?: string | null,
    image?: string | null,
    logo?: string | null,
    album?: Array< string | null > | null,
    type?: string | null,
    rating?: number | null,
    location?: string | null,
    description?: string | null,
    areas?: Array< string | null > | null,
    seatTypes?: Array< string | null > | null,
    needAssistance?: boolean | null,
    spaBooking?: boolean | null,
    showerBooking?: boolean | null,
    cigarMenu?: boolean | null,
    precedence?: number | null,
    isPinRequired?: boolean | null,
    viewOnly?: boolean | null,
    openTime?: string | null,
    closeTime?: string | null,
    varifyForAmount?: number | null,
    varifyFirstOrder?: boolean | null,
    varifyNewAddress?: boolean | null,
    sliderImages?: Array< string | null > | null,
    receiveWaiters?: Array< string | null > | null,
    WaiterNotificationOption?: string | null,
    applyLoyalty?: boolean | null,
    rewardPoints?: number | null,
    moneySpent?: number | null,
    afterVat?: boolean | null,
    afterDeliveryFee?: boolean | null,
    beforePromoCode?: boolean | null,
    pointsCalcWithPromoCode?: boolean | null,
    isBusy?: boolean | null,
    allowOnlineOrdering?: boolean | null,
    minOrderVal?: number | null,
    vatPercentage?: number | null,
    serviceChargePercentage?: number | null,
    addDeliveryToVat?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListConceptsQueryVariables = {
  filter?: ModelConceptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConceptsQuery = {
  listConcepts?:  {
    __typename: "ModelConceptConnection",
    items:  Array< {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncConceptsQueryVariables = {
  filter?: ModelConceptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncConceptsQuery = {
  syncConcepts?:  {
    __typename: "ModelConceptConnection",
    items:  Array< {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTenderQueryVariables = {
  id: string,
};

export type GetTenderQuery = {
  getTender?:  {
    __typename: "Tender",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptTendersId?: string | null,
  } | null,
};

export type ListTendersQueryVariables = {
  id?: string | null,
  filter?: ModelTenderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTendersQuery = {
  listTenders?:  {
    __typename: "ModelTenderConnection",
    items:  Array< {
      __typename: "Tender",
      id: string,
      name: string,
      simphonyID?: string | null,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      conceptTendersId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTendersQueryVariables = {
  filter?: ModelTenderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTendersQuery = {
  syncTenders?:  {
    __typename: "ModelTenderConnection",
    items:  Array< {
      __typename: "Tender",
      id: string,
      name: string,
      simphonyID?: string | null,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      conceptTendersId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOrderTypeQueryVariables = {
  id: string,
};

export type GetOrderTypeQuery = {
  getOrderType?:  {
    __typename: "OrderType",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptOrderTypeId?: string | null,
  } | null,
};

export type ListOrderTypesQueryVariables = {
  id?: string | null,
  filter?: ModelOrderTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrderTypesQuery = {
  listOrderTypes?:  {
    __typename: "ModelOrderTypeConnection",
    items:  Array< {
      __typename: "OrderType",
      id: string,
      name: string,
      simphonyID?: string | null,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      conceptOrderTypeId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOrderTypesQueryVariables = {
  filter?: ModelOrderTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOrderTypesQuery = {
  syncOrderTypes?:  {
    __typename: "ModelOrderTypeConnection",
    items:  Array< {
      __typename: "OrderType",
      id: string,
      name: string,
      simphonyID?: string | null,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      conceptOrderTypeId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetParentConceptQueryVariables = {
  id: string,
};

export type GetParentConceptQuery = {
  getParentConcept?:  {
    __typename: "ParentConcept",
    id: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    image?: string | null,
    name?: string | null,
    logo?: string | null,
    type?: string | null,
    venues?: Array< string | null > | null,
    index?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListParentConceptsQueryVariables = {
  filter?: ModelParentConceptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListParentConceptsQuery = {
  listParentConcepts?:  {
    __typename: "ModelParentConceptConnection",
    items:  Array< {
      __typename: "ParentConcept",
      id: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      image?: string | null,
      name?: string | null,
      logo?: string | null,
      type?: string | null,
      venues?: Array< string | null > | null,
      index?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncParentConceptsQueryVariables = {
  filter?: ModelParentConceptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncParentConceptsQuery = {
  syncParentConcepts?:  {
    __typename: "ModelParentConceptConnection",
    items:  Array< {
      __typename: "ParentConcept",
      id: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      image?: string | null,
      name?: string | null,
      logo?: string | null,
      type?: string | null,
      venues?: Array< string | null > | null,
      index?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPlanItemQueryVariables = {
  id: string,
};

export type GetPlanItemQuery = {
  getPlanItem?:  {
    __typename: "PlanItem",
    id: string,
    areaID: string,
    conceptID?: string | null,
    xPosition: number,
    yPosition: number,
    name?: string | null,
    isReserved?: boolean | null,
    bookings?: Array< string | null > | null,
    table?: string | null,
    object?: string | null,
    lockDays?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPlanItemsQueryVariables = {
  filter?: ModelPlanItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPlanItemsQuery = {
  listPlanItems?:  {
    __typename: "ModelPlanItemConnection",
    items:  Array< {
      __typename: "PlanItem",
      id: string,
      areaID: string,
      conceptID?: string | null,
      xPosition: number,
      yPosition: number,
      name?: string | null,
      isReserved?: boolean | null,
      bookings?: Array< string | null > | null,
      table?: string | null,
      object?: string | null,
      lockDays?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPlanItemsQueryVariables = {
  filter?: ModelPlanItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPlanItemsQuery = {
  syncPlanItems?:  {
    __typename: "ModelPlanItemConnection",
    items:  Array< {
      __typename: "PlanItem",
      id: string,
      areaID: string,
      conceptID?: string | null,
      xPosition: number,
      yPosition: number,
      name?: string | null,
      isReserved?: boolean | null,
      bookings?: Array< string | null > | null,
      table?: string | null,
      object?: string | null,
      lockDays?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PlanItemByConceptIDQueryVariables = {
  conceptID: string,
  areaIDName?: ModelPlanItemByConceptIDCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlanItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlanItemByConceptIDQuery = {
  planItemByConceptID?:  {
    __typename: "ModelPlanItemConnection",
    items:  Array< {
      __typename: "PlanItem",
      id: string,
      areaID: string,
      conceptID?: string | null,
      xPosition: number,
      yPosition: number,
      name?: string | null,
      isReserved?: boolean | null,
      bookings?: Array< string | null > | null,
      table?: string | null,
      object?: string | null,
      lockDays?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetObjectQueryVariables = {
  id: string,
};

export type GetObjectQuery = {
  getObject?:  {
    __typename: "Object",
    id: string,
    conceptID: string,
    label: string,
    name: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    width?: number | null,
    height?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    objectImageId?: string | null,
  } | null,
};

export type ListObjectsQueryVariables = {
  filter?: ModelObjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListObjectsQuery = {
  listObjects?:  {
    __typename: "ModelObjectConnection",
    items:  Array< {
      __typename: "Object",
      id: string,
      conceptID: string,
      label: string,
      name: string,
      image?:  {
        __typename: "Attachment",
        id: string,
        mediaID: string,
        fileUrl: string,
        filename: string,
        filetype?: string | null,
        fileSize?: number | null,
        alternativeText?: string | null,
        caption?: string | null,
        description?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      width?: number | null,
      height?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      objectImageId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncObjectsQueryVariables = {
  filter?: ModelObjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncObjectsQuery = {
  syncObjects?:  {
    __typename: "ModelObjectConnection",
    items:  Array< {
      __typename: "Object",
      id: string,
      conceptID: string,
      label: string,
      name: string,
      image?:  {
        __typename: "Attachment",
        id: string,
        mediaID: string,
        fileUrl: string,
        filename: string,
        filetype?: string | null,
        fileSize?: number | null,
        alternativeText?: string | null,
        caption?: string | null,
        description?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      width?: number | null,
      height?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      objectImageId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBookingQueryVariables = {
  id: string,
};

export type GetBookingQuery = {
  getBooking?:  {
    __typename: "Booking",
    id: string,
    depositValue?: string | null,
    depositStatus?: boolean | null,
    eventStatus?: boolean | null,
    eventName?: string | null,
    paymentStatus?: boolean | null,
    paymentValue?: string | null,
    disablePartialPayment?: boolean | null,
    disablePayment?: boolean | null,
    eventDate?: string | null,
    eventGuests?: Array< string | null > | null,
    timeSlotID?: string | null,
    timeSlots?: Array< string | null > | null,
    conceptID?: string | null,
    mainGuest: string,
    customerName?: string | null,
    customerPhone?: string | null,
    customerGroup?: string | null,
    accompaniedCount?: number | null,
    channel?: string | null,
    statusID?: string | null,
    rating?: number | null,
    review?: Array< string | null > | null,
    confirmationHash?: string | null,
    isConfirmedByUser?: boolean | null,
    date?: string | null,
    tables?: Array< string | null > | null,
    tableName?: string | null,
    comments?: Array< string | null > | null,
    lastComment?: string | null,
    commentCreatedBy?: string | null,
    commentCreatedDate?: string | null,
    timeLeft?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBookingsQueryVariables = {
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBookingsQuery = {
  listBookings?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      depositValue?: string | null,
      depositStatus?: boolean | null,
      eventStatus?: boolean | null,
      eventName?: string | null,
      paymentStatus?: boolean | null,
      paymentValue?: string | null,
      disablePartialPayment?: boolean | null,
      disablePayment?: boolean | null,
      eventDate?: string | null,
      eventGuests?: Array< string | null > | null,
      timeSlotID?: string | null,
      timeSlots?: Array< string | null > | null,
      conceptID?: string | null,
      mainGuest: string,
      customerName?: string | null,
      customerPhone?: string | null,
      customerGroup?: string | null,
      accompaniedCount?: number | null,
      channel?: string | null,
      statusID?: string | null,
      rating?: number | null,
      review?: Array< string | null > | null,
      confirmationHash?: string | null,
      isConfirmedByUser?: boolean | null,
      date?: string | null,
      tables?: Array< string | null > | null,
      tableName?: string | null,
      comments?: Array< string | null > | null,
      lastComment?: string | null,
      commentCreatedBy?: string | null,
      commentCreatedDate?: string | null,
      timeLeft?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBookingsQueryVariables = {
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBookingsQuery = {
  syncBookings?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      depositValue?: string | null,
      depositStatus?: boolean | null,
      eventStatus?: boolean | null,
      eventName?: string | null,
      paymentStatus?: boolean | null,
      paymentValue?: string | null,
      disablePartialPayment?: boolean | null,
      disablePayment?: boolean | null,
      eventDate?: string | null,
      eventGuests?: Array< string | null > | null,
      timeSlotID?: string | null,
      timeSlots?: Array< string | null > | null,
      conceptID?: string | null,
      mainGuest: string,
      customerName?: string | null,
      customerPhone?: string | null,
      customerGroup?: string | null,
      accompaniedCount?: number | null,
      channel?: string | null,
      statusID?: string | null,
      rating?: number | null,
      review?: Array< string | null > | null,
      confirmationHash?: string | null,
      isConfirmedByUser?: boolean | null,
      date?: string | null,
      tables?: Array< string | null > | null,
      tableName?: string | null,
      comments?: Array< string | null > | null,
      lastComment?: string | null,
      commentCreatedBy?: string | null,
      commentCreatedDate?: string | null,
      timeLeft?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTimelineQueryVariables = {
  id: string,
};

export type GetTimelineQuery = {
  getTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    transactionId?: string | null,
    customerId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTimelinesQueryVariables = {
  filter?: ModelTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTimelinesQuery = {
  listTimelines?:  {
    __typename: "ModelTimelineConnection",
    items:  Array< {
      __typename: "Timeline",
      id: string,
      actionName: string,
      oldStatus: string,
      newStatus: string,
      bookingId?: string | null,
      transactionId?: string | null,
      customerId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTimelinesQueryVariables = {
  filter?: ModelTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTimelinesQuery = {
  syncTimelines?:  {
    __typename: "ModelTimelineConnection",
    items:  Array< {
      __typename: "Timeline",
      id: string,
      actionName: string,
      oldStatus: string,
      newStatus: string,
      bookingId?: string | null,
      transactionId?: string | null,
      customerId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TimelineByTransactionIDQueryVariables = {
  transactionId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TimelineByTransactionIDQuery = {
  timelineByTransactionID?:  {
    __typename: "ModelTimelineConnection",
    items:  Array< {
      __typename: "Timeline",
      id: string,
      actionName: string,
      oldStatus: string,
      newStatus: string,
      bookingId?: string | null,
      transactionId?: string | null,
      customerId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TimelineByCustomerIDQueryVariables = {
  customerId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TimelineByCustomerIDQuery = {
  timelineByCustomerID?:  {
    __typename: "ModelTimelineConnection",
    items:  Array< {
      __typename: "Timeline",
      id: string,
      actionName: string,
      oldStatus: string,
      newStatus: string,
      bookingId?: string | null,
      transactionId?: string | null,
      customerId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCallQueryVariables = {
  id: string,
};

export type GetCallQuery = {
  getCall?:  {
    __typename: "Call",
    id: string,
    phone_number?: string | null,
    customer?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    endedAt?: string | null,
    duration?: string | null,
    date: string,
    employeeId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    callCustomerId?: string | null,
  } | null,
};

export type ListCallsQueryVariables = {
  filter?: ModelCallFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCallsQuery = {
  listCalls?:  {
    __typename: "ModelCallConnection",
    items:  Array< {
      __typename: "Call",
      id: string,
      phone_number?: string | null,
      customer?:  {
        __typename: "User",
        id: string,
        accountID: string,
        username?: string | null,
        preferred_username?: string | null,
        email?: string | null,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        pictureCrop?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        facebook?: string | null,
        instagram?: string | null,
        group?: string | null,
        phones?: Array< string | null > | null,
        flags?: Array< string | null > | null,
        flagsName?: Array< string | null > | null,
        interests?: Array< string | null > | null,
        interestsName?: Array< string | null > | null,
        status?: string | null,
        stats?: Array< string | null > | null,
        spending?: number | null,
        prevOrderDate?: string | null,
        lastOrderDate?: string | null,
        promocodes?:  Array< {
          __typename: "PromocodeItem",
          name: string,
          promocodeId: string,
          canBeUsed: number,
          discountVal: number,
          discountLimit?: number | null,
          isDisplayed?: boolean | null,
        } | null > | null,
        mostOrderedItems?: string | null,
        points?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        cognitoUsername?: string | null,
        cognitoSub?: string | null,
        updated?: string | null,
        sub?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      endedAt?: string | null,
      duration?: string | null,
      date: string,
      employeeId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      callCustomerId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCallsQueryVariables = {
  filter?: ModelCallFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCallsQuery = {
  syncCalls?:  {
    __typename: "ModelCallConnection",
    items:  Array< {
      __typename: "Call",
      id: string,
      phone_number?: string | null,
      customer?:  {
        __typename: "User",
        id: string,
        accountID: string,
        username?: string | null,
        preferred_username?: string | null,
        email?: string | null,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        pictureCrop?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        facebook?: string | null,
        instagram?: string | null,
        group?: string | null,
        phones?: Array< string | null > | null,
        flags?: Array< string | null > | null,
        flagsName?: Array< string | null > | null,
        interests?: Array< string | null > | null,
        interestsName?: Array< string | null > | null,
        status?: string | null,
        stats?: Array< string | null > | null,
        spending?: number | null,
        prevOrderDate?: string | null,
        lastOrderDate?: string | null,
        promocodes?:  Array< {
          __typename: "PromocodeItem",
          name: string,
          promocodeId: string,
          canBeUsed: number,
          discountVal: number,
          discountLimit?: number | null,
          isDisplayed?: boolean | null,
        } | null > | null,
        mostOrderedItems?: string | null,
        points?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        cognitoUsername?: string | null,
        cognitoSub?: string | null,
        updated?: string | null,
        sub?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      endedAt?: string | null,
      duration?: string | null,
      date: string,
      employeeId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      callCustomerId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAdminRoleQueryVariables = {
  id: string,
};

export type GetAdminRoleQuery = {
  getAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAdminRolesQueryVariables = {
  filter?: ModelAdminRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdminRolesQuery = {
  listAdminRoles?:  {
    __typename: "ModelAdminRoleConnection",
    items:  Array< {
      __typename: "AdminRole",
      id: string,
      name?: string | null,
      description?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAdminRolesQueryVariables = {
  filter?: ModelAdminRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAdminRolesQuery = {
  syncAdminRoles?:  {
    __typename: "ModelAdminRoleConnection",
    items:  Array< {
      __typename: "AdminRole",
      id: string,
      name?: string | null,
      description?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAdminGroupQueryVariables = {
  id: string,
};

export type GetAdminGroupQuery = {
  getAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAdminGroupsQueryVariables = {
  filter?: ModelAdminGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdminGroupsQuery = {
  listAdminGroups?:  {
    __typename: "ModelAdminGroupConnection",
    items:  Array< {
      __typename: "AdminGroup",
      id: string,
      name?: string | null,
      description?: string | null,
      roles?: Array< string | null > | null,
      users?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAdminGroupsQueryVariables = {
  filter?: ModelAdminGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAdminGroupsQuery = {
  syncAdminGroups?:  {
    __typename: "ModelAdminGroupConnection",
    items:  Array< {
      __typename: "AdminGroup",
      id: string,
      name?: string | null,
      description?: string | null,
      roles?: Array< string | null > | null,
      users?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserConceptsQueryVariables = {
  id: string,
};

export type GetUserConceptsQuery = {
  getUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserConceptsQueryVariables = {
  filter?: ModelUserConceptsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserConceptsQuery = {
  listUserConcepts?:  {
    __typename: "ModelUserConceptsConnection",
    items:  Array< {
      __typename: "UserConcepts",
      id: string,
      defaultConcept?: string | null,
      concepts?: Array< string | null > | null,
      conceptsRoles?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserConceptsQueryVariables = {
  filter?: ModelUserConceptsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserConceptsQuery = {
  syncUserConcepts?:  {
    __typename: "ModelUserConceptsConnection",
    items:  Array< {
      __typename: "UserConcepts",
      id: string,
      defaultConcept?: string | null,
      concepts?: Array< string | null > | null,
      conceptsRoles?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTransactionQueryVariables = {
  id: string,
};

export type GetTransactionQuery = {
  getTransaction?:  {
    __typename: "Transaction",
    id: string,
    transactionID: string,
    date: string,
    guestName: string,
    guestPhone: string,
    guestID: string,
    guestGroup: string,
    guestsNames?: Array< string | null > | null,
    bookingID?: string | null,
    bookingDate?: string | null,
    amount_cents: string,
    status: boolean,
    failureReason?: string | null,
    type: string,
    timeSlots?: Array< string | null > | null,
    tables?: Array< string | null > | null,
    conceptID: string,
    currency: string,
    refund: boolean,
    reference_orderID?: string | null,
    ownerID: string,
    refunded_amount_cents: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTransactionsQueryVariables = {
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTransactionsQuery = {
  listTransactions?:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      transactionID: string,
      date: string,
      guestName: string,
      guestPhone: string,
      guestID: string,
      guestGroup: string,
      guestsNames?: Array< string | null > | null,
      bookingID?: string | null,
      bookingDate?: string | null,
      amount_cents: string,
      status: boolean,
      failureReason?: string | null,
      type: string,
      timeSlots?: Array< string | null > | null,
      tables?: Array< string | null > | null,
      conceptID: string,
      currency: string,
      refund: boolean,
      reference_orderID?: string | null,
      ownerID: string,
      refunded_amount_cents: string,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTransactionsQueryVariables = {
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTransactionsQuery = {
  syncTransactions?:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      transactionID: string,
      date: string,
      guestName: string,
      guestPhone: string,
      guestID: string,
      guestGroup: string,
      guestsNames?: Array< string | null > | null,
      bookingID?: string | null,
      bookingDate?: string | null,
      amount_cents: string,
      status: boolean,
      failureReason?: string | null,
      type: string,
      timeSlots?: Array< string | null > | null,
      tables?: Array< string | null > | null,
      conceptID: string,
      currency: string,
      refund: boolean,
      reference_orderID?: string | null,
      ownerID: string,
      refunded_amount_cents: string,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TransactionsByDeletedQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TransactionsByDeletedQuery = {
  transactionsByDeleted?:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      transactionID: string,
      date: string,
      guestName: string,
      guestPhone: string,
      guestID: string,
      guestGroup: string,
      guestsNames?: Array< string | null > | null,
      bookingID?: string | null,
      bookingDate?: string | null,
      amount_cents: string,
      status: boolean,
      failureReason?: string | null,
      type: string,
      timeSlots?: Array< string | null > | null,
      tables?: Array< string | null > | null,
      conceptID: string,
      currency: string,
      refund: boolean,
      reference_orderID?: string | null,
      ownerID: string,
      refunded_amount_cents: string,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPaymentStatQueryVariables = {
  id: string,
};

export type GetPaymentStatQuery = {
  getPaymentStat?:  {
    __typename: "PaymentStat",
    id: string,
    accountID?: string | null,
    conceptID: string,
    year: number,
    month: number,
    monthName: string,
    earning: number,
    refund: number,
    earningTrxCount: number,
    refundTrxCount: number,
    deleted?: string | null,
    createdAt: string,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPaymentStatsQueryVariables = {
  filter?: ModelPaymentStatFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaymentStatsQuery = {
  listPaymentStats?:  {
    __typename: "ModelPaymentStatConnection",
    items:  Array< {
      __typename: "PaymentStat",
      id: string,
      accountID?: string | null,
      conceptID: string,
      year: number,
      month: number,
      monthName: string,
      earning: number,
      refund: number,
      earningTrxCount: number,
      refundTrxCount: number,
      deleted?: string | null,
      createdAt: string,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPaymentStatsQueryVariables = {
  filter?: ModelPaymentStatFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPaymentStatsQuery = {
  syncPaymentStats?:  {
    __typename: "ModelPaymentStatConnection",
    items:  Array< {
      __typename: "PaymentStat",
      id: string,
      accountID?: string | null,
      conceptID: string,
      year: number,
      month: number,
      monthName: string,
      earning: number,
      refund: number,
      earningTrxCount: number,
      refundTrxCount: number,
      deleted?: string | null,
      createdAt: string,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBookingGuestQueryVariables = {
  id: string,
};

export type GetBookingGuestQuery = {
  getBookingGuest?:  {
    __typename: "BookingGuest",
    id: string,
    bookingID: string,
    guestName: string,
    paidAmount: number,
    paymentAmount: number,
    paymentStatus: boolean,
    transactionID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBookingGuestsQueryVariables = {
  filter?: ModelBookingGuestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBookingGuestsQuery = {
  listBookingGuests?:  {
    __typename: "ModelBookingGuestConnection",
    items:  Array< {
      __typename: "BookingGuest",
      id: string,
      bookingID: string,
      guestName: string,
      paidAmount: number,
      paymentAmount: number,
      paymentStatus: boolean,
      transactionID: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBookingGuestsQueryVariables = {
  filter?: ModelBookingGuestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBookingGuestsQuery = {
  syncBookingGuests?:  {
    __typename: "ModelBookingGuestConnection",
    items:  Array< {
      __typename: "BookingGuest",
      id: string,
      bookingID: string,
      guestName: string,
      paidAmount: number,
      paymentAmount: number,
      paymentStatus: boolean,
      transactionID: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    conceptID: string,
    preparationAreaID?: string | null,
    subCategory?: string | null,
    name: string,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    darkImage?: string | null,
    menuItems?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        conceptID: string,
        kioskID?: string | null,
        name: string,
        description?: string | null,
        image?: string | null,
        precedence?: string | null,
        categoryID: string,
        categoryName?: string | null,
        outOfStock?: boolean | null,
        requiredPoints?: number | null,
        symphonyID?: string | null,
        comboItemID?: string | null,
        ratings?: Array< string | null > | null,
        prices?: Array< string | null > | null,
        choiceGroups?: Array< string | null > | null,
        choiceGroupsRules?:  Array< {
          __typename: "ChoiceGroupRules",
          id: string,
          minNumberOfChoices?: number | null,
          maxNumberOfChoices?: number | null,
        } | null > | null,
        ExtraChoiceGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        hasLimitedQuantity?: boolean | null,
        quantity?: number | null,
        autoRestock?: boolean | null,
        restockQuantity?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        quantityOrdered?: number | null,
        performancePerDay?: Array< string | null > | null,
        multiLanguages?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    precedence: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    symphonyID?: string | null,
    isHidden?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCategoriesQueryVariables = {
  id?: string | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCategoriesQuery = {
  listCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      conceptID: string,
      preparationAreaID?: string | null,
      subCategory?: string | null,
      name: string,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      darkImage?: string | null,
      menuItems?:  {
        __typename: "ModelMenuItemConnection",
        items:  Array< {
          __typename: "MenuItem",
          id: string,
          conceptID: string,
          kioskID?: string | null,
          name: string,
          description?: string | null,
          image?: string | null,
          precedence?: string | null,
          categoryID: string,
          categoryName?: string | null,
          outOfStock?: boolean | null,
          requiredPoints?: number | null,
          symphonyID?: string | null,
          comboItemID?: string | null,
          ratings?: Array< string | null > | null,
          prices?: Array< string | null > | null,
          choiceGroups?: Array< string | null > | null,
          ExtraChoiceGroups?: Array< string | null > | null,
          enabled?: boolean | null,
          hasLimitedQuantity?: boolean | null,
          quantity?: number | null,
          autoRestock?: boolean | null,
          restockQuantity?: number | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          quantityOrdered?: number | null,
          performancePerDay?: Array< string | null > | null,
          multiLanguages?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      precedence: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      symphonyID?: string | null,
      isHidden?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCategoriesQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCategoriesQuery = {
  syncCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      conceptID: string,
      preparationAreaID?: string | null,
      subCategory?: string | null,
      name: string,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      darkImage?: string | null,
      menuItems?:  {
        __typename: "ModelMenuItemConnection",
        items:  Array< {
          __typename: "MenuItem",
          id: string,
          conceptID: string,
          kioskID?: string | null,
          name: string,
          description?: string | null,
          image?: string | null,
          precedence?: string | null,
          categoryID: string,
          categoryName?: string | null,
          outOfStock?: boolean | null,
          requiredPoints?: number | null,
          symphonyID?: string | null,
          comboItemID?: string | null,
          ratings?: Array< string | null > | null,
          prices?: Array< string | null > | null,
          choiceGroups?: Array< string | null > | null,
          ExtraChoiceGroups?: Array< string | null > | null,
          enabled?: boolean | null,
          hasLimitedQuantity?: boolean | null,
          quantity?: number | null,
          autoRestock?: boolean | null,
          restockQuantity?: number | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          quantityOrdered?: number | null,
          performancePerDay?: Array< string | null > | null,
          multiLanguages?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      precedence: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      symphonyID?: string | null,
      isHidden?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CategoryByConceptIDQueryVariables = {
  conceptID: string,
  precedence?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoryByConceptIDQuery = {
  categoryByConceptID?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      conceptID: string,
      preparationAreaID?: string | null,
      subCategory?: string | null,
      name: string,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      darkImage?: string | null,
      menuItems?:  {
        __typename: "ModelMenuItemConnection",
        items:  Array< {
          __typename: "MenuItem",
          id: string,
          conceptID: string,
          kioskID?: string | null,
          name: string,
          description?: string | null,
          image?: string | null,
          precedence?: string | null,
          categoryID: string,
          categoryName?: string | null,
          outOfStock?: boolean | null,
          requiredPoints?: number | null,
          symphonyID?: string | null,
          comboItemID?: string | null,
          ratings?: Array< string | null > | null,
          prices?: Array< string | null > | null,
          choiceGroups?: Array< string | null > | null,
          ExtraChoiceGroups?: Array< string | null > | null,
          enabled?: boolean | null,
          hasLimitedQuantity?: boolean | null,
          quantity?: number | null,
          autoRestock?: boolean | null,
          restockQuantity?: number | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          quantityOrdered?: number | null,
          performancePerDay?: Array< string | null > | null,
          multiLanguages?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      precedence: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      symphonyID?: string | null,
      isHidden?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMenuItemQueryVariables = {
  id: string,
};

export type GetMenuItemQuery = {
  getMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    conceptID: string,
    kioskID?: string | null,
    name: string,
    description?: string | null,
    image?: string | null,
    precedence?: string | null,
    categoryID: string,
    categoryName?: string | null,
    outOfStock?: boolean | null,
    requiredPoints?: number | null,
    symphonyID?: string | null,
    comboItemID?: string | null,
    ratings?: Array< string | null > | null,
    prices?: Array< string | null > | null,
    choiceGroups?: Array< string | null > | null,
    choiceGroupsRules?:  Array< {
      __typename: "ChoiceGroupRules",
      id: string,
      minNumberOfChoices?: number | null,
      maxNumberOfChoices?: number | null,
    } | null > | null,
    ExtraChoiceGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    hasLimitedQuantity?: boolean | null,
    quantity?: number | null,
    autoRestock?: boolean | null,
    restockQuantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    quantityOrdered?: number | null,
    performancePerDay?: Array< string | null > | null,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMenuItemsQueryVariables = {
  id?: string | null,
  filter?: ModelMenuItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMenuItemsQuery = {
  listMenuItems?:  {
    __typename: "ModelMenuItemConnection",
    items:  Array< {
      __typename: "MenuItem",
      id: string,
      conceptID: string,
      kioskID?: string | null,
      name: string,
      description?: string | null,
      image?: string | null,
      precedence?: string | null,
      categoryID: string,
      categoryName?: string | null,
      outOfStock?: boolean | null,
      requiredPoints?: number | null,
      symphonyID?: string | null,
      comboItemID?: string | null,
      ratings?: Array< string | null > | null,
      prices?: Array< string | null > | null,
      choiceGroups?: Array< string | null > | null,
      choiceGroupsRules?:  Array< {
        __typename: "ChoiceGroupRules",
        id: string,
        minNumberOfChoices?: number | null,
        maxNumberOfChoices?: number | null,
      } | null > | null,
      ExtraChoiceGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      hasLimitedQuantity?: boolean | null,
      quantity?: number | null,
      autoRestock?: boolean | null,
      restockQuantity?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      quantityOrdered?: number | null,
      performancePerDay?: Array< string | null > | null,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMenuItemsQueryVariables = {
  filter?: ModelMenuItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMenuItemsQuery = {
  syncMenuItems?:  {
    __typename: "ModelMenuItemConnection",
    items:  Array< {
      __typename: "MenuItem",
      id: string,
      conceptID: string,
      kioskID?: string | null,
      name: string,
      description?: string | null,
      image?: string | null,
      precedence?: string | null,
      categoryID: string,
      categoryName?: string | null,
      outOfStock?: boolean | null,
      requiredPoints?: number | null,
      symphonyID?: string | null,
      comboItemID?: string | null,
      ratings?: Array< string | null > | null,
      prices?: Array< string | null > | null,
      choiceGroups?: Array< string | null > | null,
      choiceGroupsRules?:  Array< {
        __typename: "ChoiceGroupRules",
        id: string,
        minNumberOfChoices?: number | null,
        maxNumberOfChoices?: number | null,
      } | null > | null,
      ExtraChoiceGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      hasLimitedQuantity?: boolean | null,
      quantity?: number | null,
      autoRestock?: boolean | null,
      restockQuantity?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      quantityOrdered?: number | null,
      performancePerDay?: Array< string | null > | null,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MenuItemByConceptIDQueryVariables = {
  conceptID: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMenuItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MenuItemByConceptIDQuery = {
  menuItemByConceptID?:  {
    __typename: "ModelMenuItemConnection",
    items:  Array< {
      __typename: "MenuItem",
      id: string,
      conceptID: string,
      kioskID?: string | null,
      name: string,
      description?: string | null,
      image?: string | null,
      precedence?: string | null,
      categoryID: string,
      categoryName?: string | null,
      outOfStock?: boolean | null,
      requiredPoints?: number | null,
      symphonyID?: string | null,
      comboItemID?: string | null,
      ratings?: Array< string | null > | null,
      prices?: Array< string | null > | null,
      choiceGroups?: Array< string | null > | null,
      choiceGroupsRules?:  Array< {
        __typename: "ChoiceGroupRules",
        id: string,
        minNumberOfChoices?: number | null,
        maxNumberOfChoices?: number | null,
      } | null > | null,
      ExtraChoiceGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      hasLimitedQuantity?: boolean | null,
      quantity?: number | null,
      autoRestock?: boolean | null,
      restockQuantity?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      quantityOrdered?: number | null,
      performancePerDay?: Array< string | null > | null,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MenuItemsByCategoryIDAndPrecedenceQueryVariables = {
  categoryID: string,
  precedence?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMenuItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MenuItemsByCategoryIDAndPrecedenceQuery = {
  menuItemsByCategoryIDAndPrecedence?:  {
    __typename: "ModelMenuItemConnection",
    items:  Array< {
      __typename: "MenuItem",
      id: string,
      conceptID: string,
      kioskID?: string | null,
      name: string,
      description?: string | null,
      image?: string | null,
      precedence?: string | null,
      categoryID: string,
      categoryName?: string | null,
      outOfStock?: boolean | null,
      requiredPoints?: number | null,
      symphonyID?: string | null,
      comboItemID?: string | null,
      ratings?: Array< string | null > | null,
      prices?: Array< string | null > | null,
      choiceGroups?: Array< string | null > | null,
      choiceGroupsRules?:  Array< {
        __typename: "ChoiceGroupRules",
        id: string,
        minNumberOfChoices?: number | null,
        maxNumberOfChoices?: number | null,
      } | null > | null,
      ExtraChoiceGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      hasLimitedQuantity?: boolean | null,
      quantity?: number | null,
      autoRestock?: boolean | null,
      restockQuantity?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      quantityOrdered?: number | null,
      performancePerDay?: Array< string | null > | null,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPriceQueryVariables = {
  id: string,
};

export type GetPriceQuery = {
  getPrice?:  {
    __typename: "Price",
    id: string,
    conceptID: string,
    name: string,
    multiLanguages?: string | null,
    price: number,
    symphonyID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPricesQueryVariables = {
  filter?: ModelPriceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPricesQuery = {
  listPrices?:  {
    __typename: "ModelPriceConnection",
    items:  Array< {
      __typename: "Price",
      id: string,
      conceptID: string,
      name: string,
      multiLanguages?: string | null,
      price: number,
      symphonyID: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPricesQueryVariables = {
  filter?: ModelPriceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPricesQuery = {
  syncPrices?:  {
    __typename: "ModelPriceConnection",
    items:  Array< {
      __typename: "Price",
      id: string,
      conceptID: string,
      name: string,
      multiLanguages?: string | null,
      price: number,
      symphonyID: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PriceByConceptIDQueryVariables = {
  conceptID: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPriceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PriceByConceptIDQuery = {
  priceByConceptID?:  {
    __typename: "ModelPriceConnection",
    items:  Array< {
      __typename: "Price",
      id: string,
      conceptID: string,
      name: string,
      multiLanguages?: string | null,
      price: number,
      symphonyID: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetChoiceGroupsQueryVariables = {
  id: string,
};

export type GetChoiceGroupsQuery = {
  getChoiceGroups?:  {
    __typename: "ChoiceGroups",
    id: string,
    conceptID: string,
    name: string,
    required?: boolean | null,
    maxNumberOfChoices?: number | null,
    minNumberOfChoices?: number | null,
    precedence?: string | null,
    type?: string | null,
    choices?: Array< string | null > | null,
    choiceItems?:  {
      __typename: "ModelChoiceConnection",
      items:  Array< {
        __typename: "Choice",
        id: string,
        conceptID: string,
        name: string,
        price: number,
        precedence?: string | null,
        choiceGroupID: string,
        choiceGroupName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        symphonyID?: string | null,
        multiLanguages?: string | null,
        definitionNumber?: number | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    simphonyID?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListChoiceGroupsQueryVariables = {
  filter?: ModelChoiceGroupsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChoiceGroupsQuery = {
  listChoiceGroups?:  {
    __typename: "ModelChoiceGroupsConnection",
    items:  Array< {
      __typename: "ChoiceGroups",
      id: string,
      conceptID: string,
      name: string,
      required?: boolean | null,
      maxNumberOfChoices?: number | null,
      minNumberOfChoices?: number | null,
      precedence?: string | null,
      type?: string | null,
      choices?: Array< string | null > | null,
      choiceItems?:  {
        __typename: "ModelChoiceConnection",
        items:  Array< {
          __typename: "Choice",
          id: string,
          conceptID: string,
          name: string,
          price: number,
          precedence?: string | null,
          choiceGroupID: string,
          choiceGroupName?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          symphonyID?: string | null,
          multiLanguages?: string | null,
          definitionNumber?: number | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      simphonyID?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncChoiceGroupsQueryVariables = {
  filter?: ModelChoiceGroupsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncChoiceGroupsQuery = {
  syncChoiceGroups?:  {
    __typename: "ModelChoiceGroupsConnection",
    items:  Array< {
      __typename: "ChoiceGroups",
      id: string,
      conceptID: string,
      name: string,
      required?: boolean | null,
      maxNumberOfChoices?: number | null,
      minNumberOfChoices?: number | null,
      precedence?: string | null,
      type?: string | null,
      choices?: Array< string | null > | null,
      choiceItems?:  {
        __typename: "ModelChoiceConnection",
        items:  Array< {
          __typename: "Choice",
          id: string,
          conceptID: string,
          name: string,
          price: number,
          precedence?: string | null,
          choiceGroupID: string,
          choiceGroupName?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          symphonyID?: string | null,
          multiLanguages?: string | null,
          definitionNumber?: number | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      simphonyID?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ChoiceGroupsByConceptIDQueryVariables = {
  conceptID: string,
  precedence?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChoiceGroupsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChoiceGroupsByConceptIDQuery = {
  choiceGroupsByConceptID?:  {
    __typename: "ModelChoiceGroupsConnection",
    items:  Array< {
      __typename: "ChoiceGroups",
      id: string,
      conceptID: string,
      name: string,
      required?: boolean | null,
      maxNumberOfChoices?: number | null,
      minNumberOfChoices?: number | null,
      precedence?: string | null,
      type?: string | null,
      choices?: Array< string | null > | null,
      choiceItems?:  {
        __typename: "ModelChoiceConnection",
        items:  Array< {
          __typename: "Choice",
          id: string,
          conceptID: string,
          name: string,
          price: number,
          precedence?: string | null,
          choiceGroupID: string,
          choiceGroupName?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          symphonyID?: string | null,
          multiLanguages?: string | null,
          definitionNumber?: number | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      simphonyID?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetChoiceQueryVariables = {
  id: string,
};

export type GetChoiceQuery = {
  getChoice?:  {
    __typename: "Choice",
    id: string,
    conceptID: string,
    name: string,
    price: number,
    precedence?: string | null,
    choiceGroupID: string,
    choiceGroupName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    symphonyID?: string | null,
    multiLanguages?: string | null,
    definitionNumber?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListChoicesQueryVariables = {
  filter?: ModelChoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChoicesQuery = {
  listChoices?:  {
    __typename: "ModelChoiceConnection",
    items:  Array< {
      __typename: "Choice",
      id: string,
      conceptID: string,
      name: string,
      price: number,
      precedence?: string | null,
      choiceGroupID: string,
      choiceGroupName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      symphonyID?: string | null,
      multiLanguages?: string | null,
      definitionNumber?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncChoicesQueryVariables = {
  filter?: ModelChoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncChoicesQuery = {
  syncChoices?:  {
    __typename: "ModelChoiceConnection",
    items:  Array< {
      __typename: "Choice",
      id: string,
      conceptID: string,
      name: string,
      price: number,
      precedence?: string | null,
      choiceGroupID: string,
      choiceGroupName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      symphonyID?: string | null,
      multiLanguages?: string | null,
      definitionNumber?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ChoiceByConceptIDQueryVariables = {
  conceptID: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChoiceByConceptIDQuery = {
  choiceByConceptID?:  {
    __typename: "ModelChoiceConnection",
    items:  Array< {
      __typename: "Choice",
      id: string,
      conceptID: string,
      name: string,
      price: number,
      precedence?: string | null,
      choiceGroupID: string,
      choiceGroupName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      symphonyID?: string | null,
      multiLanguages?: string | null,
      definitionNumber?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ChoicesByChoiceGroupIDAndPrecedenceQueryVariables = {
  choiceGroupID: string,
  precedence?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChoicesByChoiceGroupIDAndPrecedenceQuery = {
  choicesByChoiceGroupIDAndPrecedence?:  {
    __typename: "ModelChoiceConnection",
    items:  Array< {
      __typename: "Choice",
      id: string,
      conceptID: string,
      name: string,
      price: number,
      precedence?: string | null,
      choiceGroupID: string,
      choiceGroupName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      symphonyID?: string | null,
      multiLanguages?: string | null,
      definitionNumber?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCartQueryVariables = {
  id: string,
};

export type GetCartQuery = {
  getCart?:  {
    __typename: "Cart",
    id: string,
    conceptID: string,
    userID: string,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      symphonyID?: string | null,
      preparationAreaID?: string | null,
      name?: string | null,
      quantity: number,
      choiceIDs?: Array< string | null > | null,
      choiceNames?: Array< string | null > | null,
      choiceSymphonyID?: Array< string | null > | null,
      extraChoiceIDs?: Array< string | null > | null,
      extraChoiceSymphonyID?: Array< string | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      comboItemID?: string | null,
    } | null > | null,
    specialRequest?: string | null,
    deliveryFee?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    totalPrice?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCartsQueryVariables = {
  filter?: ModelCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCartsQuery = {
  listCarts?:  {
    __typename: "ModelCartConnection",
    items:  Array< {
      __typename: "Cart",
      id: string,
      conceptID: string,
      userID: string,
      orderedItems?:  Array< {
        __typename: "CartItem",
        menuItemID: string,
        symphonyID?: string | null,
        preparationAreaID?: string | null,
        name?: string | null,
        quantity: number,
        choiceIDs?: Array< string | null > | null,
        choiceNames?: Array< string | null > | null,
        choiceSymphonyID?: Array< string | null > | null,
        extraChoiceIDs?: Array< string | null > | null,
        extraChoiceSymphonyID?: Array< string | null > | null,
        price: number,
        priceName?: string | null,
        priceID?: string | null,
        priceMultiLanguages?: string | null,
        multiLanguages?: string | null,
        notes?: string | null,
        image?: string | null,
        comboItemID?: string | null,
      } | null > | null,
      specialRequest?: string | null,
      deliveryFee?: number | null,
      subTotal?: number | null,
      subTaxTotal?: number | null,
      totalPrice?: number | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCartsQueryVariables = {
  filter?: ModelCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCartsQuery = {
  syncCarts?:  {
    __typename: "ModelCartConnection",
    items:  Array< {
      __typename: "Cart",
      id: string,
      conceptID: string,
      userID: string,
      orderedItems?:  Array< {
        __typename: "CartItem",
        menuItemID: string,
        symphonyID?: string | null,
        preparationAreaID?: string | null,
        name?: string | null,
        quantity: number,
        choiceIDs?: Array< string | null > | null,
        choiceNames?: Array< string | null > | null,
        choiceSymphonyID?: Array< string | null > | null,
        extraChoiceIDs?: Array< string | null > | null,
        extraChoiceSymphonyID?: Array< string | null > | null,
        price: number,
        priceName?: string | null,
        priceID?: string | null,
        priceMultiLanguages?: string | null,
        multiLanguages?: string | null,
        notes?: string | null,
        image?: string | null,
        comboItemID?: string | null,
      } | null > | null,
      specialRequest?: string | null,
      deliveryFee?: number | null,
      subTotal?: number | null,
      subTaxTotal?: number | null,
      totalPrice?: number | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CartByUserIDQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CartByUserIDQuery = {
  cartByUserID?:  {
    __typename: "ModelCartConnection",
    items:  Array< {
      __typename: "Cart",
      id: string,
      conceptID: string,
      userID: string,
      orderedItems?:  Array< {
        __typename: "CartItem",
        menuItemID: string,
        symphonyID?: string | null,
        preparationAreaID?: string | null,
        name?: string | null,
        quantity: number,
        choiceIDs?: Array< string | null > | null,
        choiceNames?: Array< string | null > | null,
        choiceSymphonyID?: Array< string | null > | null,
        extraChoiceIDs?: Array< string | null > | null,
        extraChoiceSymphonyID?: Array< string | null > | null,
        price: number,
        priceName?: string | null,
        priceID?: string | null,
        priceMultiLanguages?: string | null,
        multiLanguages?: string | null,
        notes?: string | null,
        image?: string | null,
        comboItemID?: string | null,
      } | null > | null,
      specialRequest?: string | null,
      deliveryFee?: number | null,
      subTotal?: number | null,
      subTaxTotal?: number | null,
      totalPrice?: number | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAdminStatusQueryVariables = {
  id: string,
};

export type GetAdminStatusQuery = {
  getAdminStatus?:  {
    __typename: "AdminStatus",
    id: string,
    sub: string,
    accountID: string,
    name?: string | null,
    averageCallTime?: number | null,
    numberOfCalls?: number | null,
    idelDuration?: number | null,
    activeDuration?: number | null,
    awayDuration?: number | null,
    lastCallTime?: number | null,
    agentBarAverageCallTime?: number | null,
    agentBarNumberOfCalls?: number | null,
    type?: string | null,
    waiterStatus?: string | null,
    totalAverageRatio?: number | null,
    performancePerDay?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAdminStatusesQueryVariables = {
  filter?: ModelAdminStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdminStatusesQuery = {
  listAdminStatuses?:  {
    __typename: "ModelAdminStatusConnection",
    items:  Array< {
      __typename: "AdminStatus",
      id: string,
      sub: string,
      accountID: string,
      name?: string | null,
      averageCallTime?: number | null,
      numberOfCalls?: number | null,
      idelDuration?: number | null,
      activeDuration?: number | null,
      awayDuration?: number | null,
      lastCallTime?: number | null,
      agentBarAverageCallTime?: number | null,
      agentBarNumberOfCalls?: number | null,
      type?: string | null,
      waiterStatus?: string | null,
      totalAverageRatio?: number | null,
      performancePerDay?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAdminStatusesQueryVariables = {
  filter?: ModelAdminStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAdminStatusesQuery = {
  syncAdminStatuses?:  {
    __typename: "ModelAdminStatusConnection",
    items:  Array< {
      __typename: "AdminStatus",
      id: string,
      sub: string,
      accountID: string,
      name?: string | null,
      averageCallTime?: number | null,
      numberOfCalls?: number | null,
      idelDuration?: number | null,
      activeDuration?: number | null,
      awayDuration?: number | null,
      lastCallTime?: number | null,
      agentBarAverageCallTime?: number | null,
      agentBarNumberOfCalls?: number | null,
      type?: string | null,
      waiterStatus?: string | null,
      totalAverageRatio?: number | null,
      performancePerDay?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ByTotalAverageRatioQueryVariables = {
  name: string,
  totalAverageRatio?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAdminStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTotalAverageRatioQuery = {
  ByTotalAverageRatio?:  {
    __typename: "ModelAdminStatusConnection",
    items:  Array< {
      __typename: "AdminStatus",
      id: string,
      sub: string,
      accountID: string,
      name?: string | null,
      averageCallTime?: number | null,
      numberOfCalls?: number | null,
      idelDuration?: number | null,
      activeDuration?: number | null,
      awayDuration?: number | null,
      lastCallTime?: number | null,
      agentBarAverageCallTime?: number | null,
      agentBarNumberOfCalls?: number | null,
      type?: string | null,
      waiterStatus?: string | null,
      totalAverageRatio?: number | null,
      performancePerDay?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOnlineOrderQueryVariables = {
  id: string,
};

export type GetOnlineOrderQuery = {
  getOnlineOrder?:  {
    __typename: "OnlineOrder",
    id: string,
    conceptID: string,
    statusID?: string | null,
    status?: string | null,
    reason?: string | null,
    orderType?: string | null,
    channel?: string | null,
    userID: string,
    userName?: string | null,
    userPhoneNumber?: string | null,
    dispatcherID?: string | null,
    dispatcherName?: string | null,
    cartID?: string | null,
    tenderId?: number | null,
    employeeId?: number | null,
    rvcId?: number | null,
    checkNumber?: string | null,
    totalAmount?: number | null,
    discountedValue?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    tax?: number | null,
    deliveryFee?: number | null,
    orderTime?: string | null,
    executionArn?: string | null,
    specialRequest?: string | null,
    orderAddress?: string | null,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      symphonyID?: string | null,
      preparationAreaID?: string | null,
      name?: string | null,
      quantity: number,
      choiceIDs?: Array< string | null > | null,
      choiceNames?: Array< string | null > | null,
      choiceSymphonyID?: Array< string | null > | null,
      extraChoiceIDs?: Array< string | null > | null,
      extraChoiceSymphonyID?: Array< string | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      comboItemID?: string | null,
    } | null > | null,
    checkRef?: string | null,
    discountID?: number | null,
    amount_cents?: string | null,
    transactionID?: string | null,
    paymentStatus?: boolean | null,
    paymentType?: string | null,
    referenceId?: string | null,
    cardHolderNumber?: string | null,
    cardHolderName?: string | null,
    cardSchemeName?: string | null,
    deleted?: string | null,
    acceptedByWaiterID?: string | null,
    acceptedByWaiterName?: string | null,
    deliveredByWaiterID?: string | null,
    tableID?: string | null,
    tableName?: string | null,
    shift?: string | null,
    parentOrder?: string | null,
    preparationAreaID?: string | null,
    childrenStatus?: Array< string | null > | null,
    createdAt: string,
    acceptedByWaiterAt?: string | null,
    sentToKitchenAt?: string | null,
    acceptedByKitchenAt?: string | null,
    readyAt?: string | null,
    outForDeliveryAt?: string | null,
    deliveredAt?: string | null,
    promoCodeID?: string | null,
    promoCodeName?: string | null,
    promoCodeAppliedTo?: string | null,
    promoCode?:  {
      __typename: "Promocode",
      id: string,
      name: string,
      redemptions: number,
      status?: string | null,
      startDate: string,
      endDate: string,
      appliesTo: string,
      minOrderReq: string,
      minOrderVal?: number | null,
      minOrderQty?: number | null,
      tenderReq: Array< string | null >,
      discountVal: number,
      discountLimit?: number | null,
      quota?: number | null,
      quotaUsed?: number | null,
      totalRevenue?: number | null,
      appliedDiscountedVal?: number | null,
      usePerCustomer?: number | null,
      channelReq: Array< string | null >,
      customerEligibility: string,
      customerReq?: Array< string | null > | null,
      spendingExceeds?: number | null,
      spendingBelow?: number | null,
      orderSinceDate?: string | null,
      orderFromDate?: string | null,
      orderToDate?: string | null,
      isDisplayed?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    reviewID?: string | null,
    review?:  {
      __typename: "Review",
      id: string,
      accountID?: string | null,
      bookingID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    simphEmpID?: string | null,
    createdByID: string,
    createdByName: string,
    tmpField?: string | null,
    updatedByID?: string | null,
    updatedByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListOnlineOrdersQueryVariables = {
  filter?: ModelOnlineOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOnlineOrdersQuery = {
  listOnlineOrders?:  {
    __typename: "ModelOnlineOrderConnection",
    items:  Array< {
      __typename: "OnlineOrder",
      id: string,
      conceptID: string,
      statusID?: string | null,
      status?: string | null,
      reason?: string | null,
      orderType?: string | null,
      channel?: string | null,
      userID: string,
      userName?: string | null,
      userPhoneNumber?: string | null,
      dispatcherID?: string | null,
      dispatcherName?: string | null,
      cartID?: string | null,
      tenderId?: number | null,
      employeeId?: number | null,
      rvcId?: number | null,
      checkNumber?: string | null,
      totalAmount?: number | null,
      discountedValue?: number | null,
      subTotal?: number | null,
      subTaxTotal?: number | null,
      tax?: number | null,
      deliveryFee?: number | null,
      orderTime?: string | null,
      executionArn?: string | null,
      specialRequest?: string | null,
      orderAddress?: string | null,
      orderedItems?:  Array< {
        __typename: "CartItem",
        menuItemID: string,
        symphonyID?: string | null,
        preparationAreaID?: string | null,
        name?: string | null,
        quantity: number,
        choiceIDs?: Array< string | null > | null,
        choiceNames?: Array< string | null > | null,
        choiceSymphonyID?: Array< string | null > | null,
        extraChoiceIDs?: Array< string | null > | null,
        extraChoiceSymphonyID?: Array< string | null > | null,
        price: number,
        priceName?: string | null,
        priceID?: string | null,
        priceMultiLanguages?: string | null,
        multiLanguages?: string | null,
        notes?: string | null,
        image?: string | null,
        comboItemID?: string | null,
      } | null > | null,
      checkRef?: string | null,
      discountID?: number | null,
      amount_cents?: string | null,
      transactionID?: string | null,
      paymentStatus?: boolean | null,
      paymentType?: string | null,
      referenceId?: string | null,
      cardHolderNumber?: string | null,
      cardHolderName?: string | null,
      cardSchemeName?: string | null,
      deleted?: string | null,
      acceptedByWaiterID?: string | null,
      acceptedByWaiterName?: string | null,
      deliveredByWaiterID?: string | null,
      tableID?: string | null,
      tableName?: string | null,
      shift?: string | null,
      parentOrder?: string | null,
      preparationAreaID?: string | null,
      childrenStatus?: Array< string | null > | null,
      createdAt: string,
      acceptedByWaiterAt?: string | null,
      sentToKitchenAt?: string | null,
      acceptedByKitchenAt?: string | null,
      readyAt?: string | null,
      outForDeliveryAt?: string | null,
      deliveredAt?: string | null,
      promoCodeID?: string | null,
      promoCodeName?: string | null,
      promoCodeAppliedTo?: string | null,
      promoCode?:  {
        __typename: "Promocode",
        id: string,
        name: string,
        redemptions: number,
        status?: string | null,
        startDate: string,
        endDate: string,
        appliesTo: string,
        minOrderReq: string,
        minOrderVal?: number | null,
        minOrderQty?: number | null,
        tenderReq: Array< string | null >,
        discountVal: number,
        discountLimit?: number | null,
        quota?: number | null,
        quotaUsed?: number | null,
        totalRevenue?: number | null,
        appliedDiscountedVal?: number | null,
        usePerCustomer?: number | null,
        channelReq: Array< string | null >,
        customerEligibility: string,
        customerReq?: Array< string | null > | null,
        spendingExceeds?: number | null,
        spendingBelow?: number | null,
        orderSinceDate?: string | null,
        orderFromDate?: string | null,
        orderToDate?: string | null,
        isDisplayed?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      reviewID?: string | null,
      review?:  {
        __typename: "Review",
        id: string,
        accountID?: string | null,
        bookingID?: string | null,
        guestName?: string | null,
        guestId?: string | null,
        reason?: Array< string | null > | null,
        value?: string | null,
        userID?: string | null,
        orderID?: string | null,
        rate?: number | null,
        satisfaction?: string | null,
        thoughts?: string | null,
        itemReviews?:  Array< {
          __typename: "ItemReview",
          itemID: string,
          rate?: number | null,
          satisfaction?: string | null,
          multiLanguages?: string | null,
        } | null > | null,
        photos?: Array< string | null > | null,
        multiLanguages?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      simphEmpID?: string | null,
      createdByID: string,
      createdByName: string,
      tmpField?: string | null,
      updatedByID?: string | null,
      updatedByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOnlineOrdersQueryVariables = {
  filter?: ModelOnlineOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOnlineOrdersQuery = {
  syncOnlineOrders?:  {
    __typename: "ModelOnlineOrderConnection",
    items:  Array< {
      __typename: "OnlineOrder",
      id: string,
      conceptID: string,
      statusID?: string | null,
      status?: string | null,
      reason?: string | null,
      orderType?: string | null,
      channel?: string | null,
      userID: string,
      userName?: string | null,
      userPhoneNumber?: string | null,
      dispatcherID?: string | null,
      dispatcherName?: string | null,
      cartID?: string | null,
      tenderId?: number | null,
      employeeId?: number | null,
      rvcId?: number | null,
      checkNumber?: string | null,
      totalAmount?: number | null,
      discountedValue?: number | null,
      subTotal?: number | null,
      subTaxTotal?: number | null,
      tax?: number | null,
      deliveryFee?: number | null,
      orderTime?: string | null,
      executionArn?: string | null,
      specialRequest?: string | null,
      orderAddress?: string | null,
      orderedItems?:  Array< {
        __typename: "CartItem",
        menuItemID: string,
        symphonyID?: string | null,
        preparationAreaID?: string | null,
        name?: string | null,
        quantity: number,
        choiceIDs?: Array< string | null > | null,
        choiceNames?: Array< string | null > | null,
        choiceSymphonyID?: Array< string | null > | null,
        extraChoiceIDs?: Array< string | null > | null,
        extraChoiceSymphonyID?: Array< string | null > | null,
        price: number,
        priceName?: string | null,
        priceID?: string | null,
        priceMultiLanguages?: string | null,
        multiLanguages?: string | null,
        notes?: string | null,
        image?: string | null,
        comboItemID?: string | null,
      } | null > | null,
      checkRef?: string | null,
      discountID?: number | null,
      amount_cents?: string | null,
      transactionID?: string | null,
      paymentStatus?: boolean | null,
      paymentType?: string | null,
      referenceId?: string | null,
      cardHolderNumber?: string | null,
      cardHolderName?: string | null,
      cardSchemeName?: string | null,
      deleted?: string | null,
      acceptedByWaiterID?: string | null,
      acceptedByWaiterName?: string | null,
      deliveredByWaiterID?: string | null,
      tableID?: string | null,
      tableName?: string | null,
      shift?: string | null,
      parentOrder?: string | null,
      preparationAreaID?: string | null,
      childrenStatus?: Array< string | null > | null,
      createdAt: string,
      acceptedByWaiterAt?: string | null,
      sentToKitchenAt?: string | null,
      acceptedByKitchenAt?: string | null,
      readyAt?: string | null,
      outForDeliveryAt?: string | null,
      deliveredAt?: string | null,
      promoCodeID?: string | null,
      promoCodeName?: string | null,
      promoCodeAppliedTo?: string | null,
      promoCode?:  {
        __typename: "Promocode",
        id: string,
        name: string,
        redemptions: number,
        status?: string | null,
        startDate: string,
        endDate: string,
        appliesTo: string,
        minOrderReq: string,
        minOrderVal?: number | null,
        minOrderQty?: number | null,
        tenderReq: Array< string | null >,
        discountVal: number,
        discountLimit?: number | null,
        quota?: number | null,
        quotaUsed?: number | null,
        totalRevenue?: number | null,
        appliedDiscountedVal?: number | null,
        usePerCustomer?: number | null,
        channelReq: Array< string | null >,
        customerEligibility: string,
        customerReq?: Array< string | null > | null,
        spendingExceeds?: number | null,
        spendingBelow?: number | null,
        orderSinceDate?: string | null,
        orderFromDate?: string | null,
        orderToDate?: string | null,
        isDisplayed?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      reviewID?: string | null,
      review?:  {
        __typename: "Review",
        id: string,
        accountID?: string | null,
        bookingID?: string | null,
        guestName?: string | null,
        guestId?: string | null,
        reason?: Array< string | null > | null,
        value?: string | null,
        userID?: string | null,
        orderID?: string | null,
        rate?: number | null,
        satisfaction?: string | null,
        thoughts?: string | null,
        itemReviews?:  Array< {
          __typename: "ItemReview",
          itemID: string,
          rate?: number | null,
          satisfaction?: string | null,
          multiLanguages?: string | null,
        } | null > | null,
        photos?: Array< string | null > | null,
        multiLanguages?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      simphEmpID?: string | null,
      createdByID: string,
      createdByName: string,
      tmpField?: string | null,
      updatedByID?: string | null,
      updatedByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OrderByConceptIDQueryVariables = {
  conceptID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOnlineOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderByConceptIDQuery = {
  orderByConceptID?:  {
    __typename: "ModelOnlineOrderConnection",
    items:  Array< {
      __typename: "OnlineOrder",
      id: string,
      conceptID: string,
      statusID?: string | null,
      status?: string | null,
      reason?: string | null,
      orderType?: string | null,
      channel?: string | null,
      userID: string,
      userName?: string | null,
      userPhoneNumber?: string | null,
      dispatcherID?: string | null,
      dispatcherName?: string | null,
      cartID?: string | null,
      tenderId?: number | null,
      employeeId?: number | null,
      rvcId?: number | null,
      checkNumber?: string | null,
      totalAmount?: number | null,
      discountedValue?: number | null,
      subTotal?: number | null,
      subTaxTotal?: number | null,
      tax?: number | null,
      deliveryFee?: number | null,
      orderTime?: string | null,
      executionArn?: string | null,
      specialRequest?: string | null,
      orderAddress?: string | null,
      orderedItems?:  Array< {
        __typename: "CartItem",
        menuItemID: string,
        symphonyID?: string | null,
        preparationAreaID?: string | null,
        name?: string | null,
        quantity: number,
        choiceIDs?: Array< string | null > | null,
        choiceNames?: Array< string | null > | null,
        choiceSymphonyID?: Array< string | null > | null,
        extraChoiceIDs?: Array< string | null > | null,
        extraChoiceSymphonyID?: Array< string | null > | null,
        price: number,
        priceName?: string | null,
        priceID?: string | null,
        priceMultiLanguages?: string | null,
        multiLanguages?: string | null,
        notes?: string | null,
        image?: string | null,
        comboItemID?: string | null,
      } | null > | null,
      checkRef?: string | null,
      discountID?: number | null,
      amount_cents?: string | null,
      transactionID?: string | null,
      paymentStatus?: boolean | null,
      paymentType?: string | null,
      referenceId?: string | null,
      cardHolderNumber?: string | null,
      cardHolderName?: string | null,
      cardSchemeName?: string | null,
      deleted?: string | null,
      acceptedByWaiterID?: string | null,
      acceptedByWaiterName?: string | null,
      deliveredByWaiterID?: string | null,
      tableID?: string | null,
      tableName?: string | null,
      shift?: string | null,
      parentOrder?: string | null,
      preparationAreaID?: string | null,
      childrenStatus?: Array< string | null > | null,
      createdAt: string,
      acceptedByWaiterAt?: string | null,
      sentToKitchenAt?: string | null,
      acceptedByKitchenAt?: string | null,
      readyAt?: string | null,
      outForDeliveryAt?: string | null,
      deliveredAt?: string | null,
      promoCodeID?: string | null,
      promoCodeName?: string | null,
      promoCodeAppliedTo?: string | null,
      promoCode?:  {
        __typename: "Promocode",
        id: string,
        name: string,
        redemptions: number,
        status?: string | null,
        startDate: string,
        endDate: string,
        appliesTo: string,
        minOrderReq: string,
        minOrderVal?: number | null,
        minOrderQty?: number | null,
        tenderReq: Array< string | null >,
        discountVal: number,
        discountLimit?: number | null,
        quota?: number | null,
        quotaUsed?: number | null,
        totalRevenue?: number | null,
        appliedDiscountedVal?: number | null,
        usePerCustomer?: number | null,
        channelReq: Array< string | null >,
        customerEligibility: string,
        customerReq?: Array< string | null > | null,
        spendingExceeds?: number | null,
        spendingBelow?: number | null,
        orderSinceDate?: string | null,
        orderFromDate?: string | null,
        orderToDate?: string | null,
        isDisplayed?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      reviewID?: string | null,
      review?:  {
        __typename: "Review",
        id: string,
        accountID?: string | null,
        bookingID?: string | null,
        guestName?: string | null,
        guestId?: string | null,
        reason?: Array< string | null > | null,
        value?: string | null,
        userID?: string | null,
        orderID?: string | null,
        rate?: number | null,
        satisfaction?: string | null,
        thoughts?: string | null,
        itemReviews?:  Array< {
          __typename: "ItemReview",
          itemID: string,
          rate?: number | null,
          satisfaction?: string | null,
          multiLanguages?: string | null,
        } | null > | null,
        photos?: Array< string | null > | null,
        multiLanguages?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      simphEmpID?: string | null,
      createdByID: string,
      createdByName: string,
      tmpField?: string | null,
      updatedByID?: string | null,
      updatedByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OrderByStatusQueryVariables = {
  status: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOnlineOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderByStatusQuery = {
  orderByStatus?:  {
    __typename: "ModelOnlineOrderConnection",
    items:  Array< {
      __typename: "OnlineOrder",
      id: string,
      conceptID: string,
      statusID?: string | null,
      status?: string | null,
      reason?: string | null,
      orderType?: string | null,
      channel?: string | null,
      userID: string,
      userName?: string | null,
      userPhoneNumber?: string | null,
      dispatcherID?: string | null,
      dispatcherName?: string | null,
      cartID?: string | null,
      tenderId?: number | null,
      employeeId?: number | null,
      rvcId?: number | null,
      checkNumber?: string | null,
      totalAmount?: number | null,
      discountedValue?: number | null,
      subTotal?: number | null,
      subTaxTotal?: number | null,
      tax?: number | null,
      deliveryFee?: number | null,
      orderTime?: string | null,
      executionArn?: string | null,
      specialRequest?: string | null,
      orderAddress?: string | null,
      orderedItems?:  Array< {
        __typename: "CartItem",
        menuItemID: string,
        symphonyID?: string | null,
        preparationAreaID?: string | null,
        name?: string | null,
        quantity: number,
        choiceIDs?: Array< string | null > | null,
        choiceNames?: Array< string | null > | null,
        choiceSymphonyID?: Array< string | null > | null,
        extraChoiceIDs?: Array< string | null > | null,
        extraChoiceSymphonyID?: Array< string | null > | null,
        price: number,
        priceName?: string | null,
        priceID?: string | null,
        priceMultiLanguages?: string | null,
        multiLanguages?: string | null,
        notes?: string | null,
        image?: string | null,
        comboItemID?: string | null,
      } | null > | null,
      checkRef?: string | null,
      discountID?: number | null,
      amount_cents?: string | null,
      transactionID?: string | null,
      paymentStatus?: boolean | null,
      paymentType?: string | null,
      referenceId?: string | null,
      cardHolderNumber?: string | null,
      cardHolderName?: string | null,
      cardSchemeName?: string | null,
      deleted?: string | null,
      acceptedByWaiterID?: string | null,
      acceptedByWaiterName?: string | null,
      deliveredByWaiterID?: string | null,
      tableID?: string | null,
      tableName?: string | null,
      shift?: string | null,
      parentOrder?: string | null,
      preparationAreaID?: string | null,
      childrenStatus?: Array< string | null > | null,
      createdAt: string,
      acceptedByWaiterAt?: string | null,
      sentToKitchenAt?: string | null,
      acceptedByKitchenAt?: string | null,
      readyAt?: string | null,
      outForDeliveryAt?: string | null,
      deliveredAt?: string | null,
      promoCodeID?: string | null,
      promoCodeName?: string | null,
      promoCodeAppliedTo?: string | null,
      promoCode?:  {
        __typename: "Promocode",
        id: string,
        name: string,
        redemptions: number,
        status?: string | null,
        startDate: string,
        endDate: string,
        appliesTo: string,
        minOrderReq: string,
        minOrderVal?: number | null,
        minOrderQty?: number | null,
        tenderReq: Array< string | null >,
        discountVal: number,
        discountLimit?: number | null,
        quota?: number | null,
        quotaUsed?: number | null,
        totalRevenue?: number | null,
        appliedDiscountedVal?: number | null,
        usePerCustomer?: number | null,
        channelReq: Array< string | null >,
        customerEligibility: string,
        customerReq?: Array< string | null > | null,
        spendingExceeds?: number | null,
        spendingBelow?: number | null,
        orderSinceDate?: string | null,
        orderFromDate?: string | null,
        orderToDate?: string | null,
        isDisplayed?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      reviewID?: string | null,
      review?:  {
        __typename: "Review",
        id: string,
        accountID?: string | null,
        bookingID?: string | null,
        guestName?: string | null,
        guestId?: string | null,
        reason?: Array< string | null > | null,
        value?: string | null,
        userID?: string | null,
        orderID?: string | null,
        rate?: number | null,
        satisfaction?: string | null,
        thoughts?: string | null,
        itemReviews?:  Array< {
          __typename: "ItemReview",
          itemID: string,
          rate?: number | null,
          satisfaction?: string | null,
          multiLanguages?: string | null,
        } | null > | null,
        photos?: Array< string | null > | null,
        multiLanguages?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      simphEmpID?: string | null,
      createdByID: string,
      createdByName: string,
      tmpField?: string | null,
      updatedByID?: string | null,
      updatedByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OrdersByUserIDQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOnlineOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrdersByUserIDQuery = {
  ordersByUserID?:  {
    __typename: "ModelOnlineOrderConnection",
    items:  Array< {
      __typename: "OnlineOrder",
      id: string,
      conceptID: string,
      statusID?: string | null,
      status?: string | null,
      reason?: string | null,
      orderType?: string | null,
      channel?: string | null,
      userID: string,
      userName?: string | null,
      userPhoneNumber?: string | null,
      dispatcherID?: string | null,
      dispatcherName?: string | null,
      cartID?: string | null,
      tenderId?: number | null,
      employeeId?: number | null,
      rvcId?: number | null,
      checkNumber?: string | null,
      totalAmount?: number | null,
      discountedValue?: number | null,
      subTotal?: number | null,
      subTaxTotal?: number | null,
      tax?: number | null,
      deliveryFee?: number | null,
      orderTime?: string | null,
      executionArn?: string | null,
      specialRequest?: string | null,
      orderAddress?: string | null,
      orderedItems?:  Array< {
        __typename: "CartItem",
        menuItemID: string,
        symphonyID?: string | null,
        preparationAreaID?: string | null,
        name?: string | null,
        quantity: number,
        choiceIDs?: Array< string | null > | null,
        choiceNames?: Array< string | null > | null,
        choiceSymphonyID?: Array< string | null > | null,
        extraChoiceIDs?: Array< string | null > | null,
        extraChoiceSymphonyID?: Array< string | null > | null,
        price: number,
        priceName?: string | null,
        priceID?: string | null,
        priceMultiLanguages?: string | null,
        multiLanguages?: string | null,
        notes?: string | null,
        image?: string | null,
        comboItemID?: string | null,
      } | null > | null,
      checkRef?: string | null,
      discountID?: number | null,
      amount_cents?: string | null,
      transactionID?: string | null,
      paymentStatus?: boolean | null,
      paymentType?: string | null,
      referenceId?: string | null,
      cardHolderNumber?: string | null,
      cardHolderName?: string | null,
      cardSchemeName?: string | null,
      deleted?: string | null,
      acceptedByWaiterID?: string | null,
      acceptedByWaiterName?: string | null,
      deliveredByWaiterID?: string | null,
      tableID?: string | null,
      tableName?: string | null,
      shift?: string | null,
      parentOrder?: string | null,
      preparationAreaID?: string | null,
      childrenStatus?: Array< string | null > | null,
      createdAt: string,
      acceptedByWaiterAt?: string | null,
      sentToKitchenAt?: string | null,
      acceptedByKitchenAt?: string | null,
      readyAt?: string | null,
      outForDeliveryAt?: string | null,
      deliveredAt?: string | null,
      promoCodeID?: string | null,
      promoCodeName?: string | null,
      promoCodeAppliedTo?: string | null,
      promoCode?:  {
        __typename: "Promocode",
        id: string,
        name: string,
        redemptions: number,
        status?: string | null,
        startDate: string,
        endDate: string,
        appliesTo: string,
        minOrderReq: string,
        minOrderVal?: number | null,
        minOrderQty?: number | null,
        tenderReq: Array< string | null >,
        discountVal: number,
        discountLimit?: number | null,
        quota?: number | null,
        quotaUsed?: number | null,
        totalRevenue?: number | null,
        appliedDiscountedVal?: number | null,
        usePerCustomer?: number | null,
        channelReq: Array< string | null >,
        customerEligibility: string,
        customerReq?: Array< string | null > | null,
        spendingExceeds?: number | null,
        spendingBelow?: number | null,
        orderSinceDate?: string | null,
        orderFromDate?: string | null,
        orderToDate?: string | null,
        isDisplayed?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      reviewID?: string | null,
      review?:  {
        __typename: "Review",
        id: string,
        accountID?: string | null,
        bookingID?: string | null,
        guestName?: string | null,
        guestId?: string | null,
        reason?: Array< string | null > | null,
        value?: string | null,
        userID?: string | null,
        orderID?: string | null,
        rate?: number | null,
        satisfaction?: string | null,
        thoughts?: string | null,
        itemReviews?:  Array< {
          __typename: "ItemReview",
          itemID: string,
          rate?: number | null,
          satisfaction?: string | null,
          multiLanguages?: string | null,
        } | null > | null,
        photos?: Array< string | null > | null,
        multiLanguages?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      simphEmpID?: string | null,
      createdByID: string,
      createdByName: string,
      tmpField?: string | null,
      updatedByID?: string | null,
      updatedByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OrderByDeletedQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOnlineOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderByDeletedQuery = {
  orderByDeleted?:  {
    __typename: "ModelOnlineOrderConnection",
    items:  Array< {
      __typename: "OnlineOrder",
      id: string,
      conceptID: string,
      statusID?: string | null,
      status?: string | null,
      reason?: string | null,
      orderType?: string | null,
      channel?: string | null,
      userID: string,
      userName?: string | null,
      userPhoneNumber?: string | null,
      dispatcherID?: string | null,
      dispatcherName?: string | null,
      cartID?: string | null,
      tenderId?: number | null,
      employeeId?: number | null,
      rvcId?: number | null,
      checkNumber?: string | null,
      totalAmount?: number | null,
      discountedValue?: number | null,
      subTotal?: number | null,
      subTaxTotal?: number | null,
      tax?: number | null,
      deliveryFee?: number | null,
      orderTime?: string | null,
      executionArn?: string | null,
      specialRequest?: string | null,
      orderAddress?: string | null,
      orderedItems?:  Array< {
        __typename: "CartItem",
        menuItemID: string,
        symphonyID?: string | null,
        preparationAreaID?: string | null,
        name?: string | null,
        quantity: number,
        choiceIDs?: Array< string | null > | null,
        choiceNames?: Array< string | null > | null,
        choiceSymphonyID?: Array< string | null > | null,
        extraChoiceIDs?: Array< string | null > | null,
        extraChoiceSymphonyID?: Array< string | null > | null,
        price: number,
        priceName?: string | null,
        priceID?: string | null,
        priceMultiLanguages?: string | null,
        multiLanguages?: string | null,
        notes?: string | null,
        image?: string | null,
        comboItemID?: string | null,
      } | null > | null,
      checkRef?: string | null,
      discountID?: number | null,
      amount_cents?: string | null,
      transactionID?: string | null,
      paymentStatus?: boolean | null,
      paymentType?: string | null,
      referenceId?: string | null,
      cardHolderNumber?: string | null,
      cardHolderName?: string | null,
      cardSchemeName?: string | null,
      deleted?: string | null,
      acceptedByWaiterID?: string | null,
      acceptedByWaiterName?: string | null,
      deliveredByWaiterID?: string | null,
      tableID?: string | null,
      tableName?: string | null,
      shift?: string | null,
      parentOrder?: string | null,
      preparationAreaID?: string | null,
      childrenStatus?: Array< string | null > | null,
      createdAt: string,
      acceptedByWaiterAt?: string | null,
      sentToKitchenAt?: string | null,
      acceptedByKitchenAt?: string | null,
      readyAt?: string | null,
      outForDeliveryAt?: string | null,
      deliveredAt?: string | null,
      promoCodeID?: string | null,
      promoCodeName?: string | null,
      promoCodeAppliedTo?: string | null,
      promoCode?:  {
        __typename: "Promocode",
        id: string,
        name: string,
        redemptions: number,
        status?: string | null,
        startDate: string,
        endDate: string,
        appliesTo: string,
        minOrderReq: string,
        minOrderVal?: number | null,
        minOrderQty?: number | null,
        tenderReq: Array< string | null >,
        discountVal: number,
        discountLimit?: number | null,
        quota?: number | null,
        quotaUsed?: number | null,
        totalRevenue?: number | null,
        appliedDiscountedVal?: number | null,
        usePerCustomer?: number | null,
        channelReq: Array< string | null >,
        customerEligibility: string,
        customerReq?: Array< string | null > | null,
        spendingExceeds?: number | null,
        spendingBelow?: number | null,
        orderSinceDate?: string | null,
        orderFromDate?: string | null,
        orderToDate?: string | null,
        isDisplayed?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      reviewID?: string | null,
      review?:  {
        __typename: "Review",
        id: string,
        accountID?: string | null,
        bookingID?: string | null,
        guestName?: string | null,
        guestId?: string | null,
        reason?: Array< string | null > | null,
        value?: string | null,
        userID?: string | null,
        orderID?: string | null,
        rate?: number | null,
        satisfaction?: string | null,
        thoughts?: string | null,
        itemReviews?:  Array< {
          __typename: "ItemReview",
          itemID: string,
          rate?: number | null,
          satisfaction?: string | null,
          multiLanguages?: string | null,
        } | null > | null,
        photos?: Array< string | null > | null,
        multiLanguages?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      simphEmpID?: string | null,
      createdByID: string,
      createdByName: string,
      tmpField?: string | null,
      updatedByID?: string | null,
      updatedByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOrderStatusQueryVariables = {
  id: string,
};

export type GetOrderStatusQuery = {
  getOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    conceptID: string,
    name: string,
    color?: string | null,
    precedence?: number | null,
    accessibleStatus?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListOrderStatusesQueryVariables = {
  filter?: ModelOrderStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderStatusesQuery = {
  listOrderStatuses?:  {
    __typename: "ModelOrderStatusConnection",
    items:  Array< {
      __typename: "OrderStatus",
      id: string,
      conceptID: string,
      name: string,
      color?: string | null,
      precedence?: number | null,
      accessibleStatus?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOrderStatusesQueryVariables = {
  filter?: ModelOrderStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOrderStatusesQuery = {
  syncOrderStatuses?:  {
    __typename: "ModelOrderStatusConnection",
    items:  Array< {
      __typename: "OrderStatus",
      id: string,
      conceptID: string,
      name: string,
      color?: string | null,
      precedence?: number | null,
      accessibleStatus?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDiscountCouponQueryVariables = {
  id: string,
};

export type GetDiscountCouponQuery = {
  getDiscountCoupon?:  {
    __typename: "DiscountCoupon",
    id: string,
    discountPercent: string,
    usedBy?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDiscountCouponsQueryVariables = {
  filter?: ModelDiscountCouponFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDiscountCouponsQuery = {
  listDiscountCoupons?:  {
    __typename: "ModelDiscountCouponConnection",
    items:  Array< {
      __typename: "DiscountCoupon",
      id: string,
      discountPercent: string,
      usedBy?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDiscountCouponsQueryVariables = {
  filter?: ModelDiscountCouponFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDiscountCouponsQuery = {
  syncDiscountCoupons?:  {
    __typename: "ModelDiscountCouponConnection",
    items:  Array< {
      __typename: "DiscountCoupon",
      id: string,
      discountPercent: string,
      usedBy?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetZoneQueryVariables = {
  id: string,
};

export type GetZoneQuery = {
  getZone?:  {
    __typename: "Zone",
    id: string,
    conceptID: string,
    location: string,
    deliveryFee: number,
    concepts?:  Array< {
      __typename: "ZoneConcept",
      id: string,
      deliveryFee: number,
    } | null > | null,
    polygon?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListZonesQueryVariables = {
  filter?: ModelZoneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListZonesQuery = {
  listZones?:  {
    __typename: "ModelZoneConnection",
    items:  Array< {
      __typename: "Zone",
      id: string,
      conceptID: string,
      location: string,
      deliveryFee: number,
      concepts?:  Array< {
        __typename: "ZoneConcept",
        id: string,
        deliveryFee: number,
      } | null > | null,
      polygon?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncZonesQueryVariables = {
  filter?: ModelZoneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncZonesQuery = {
  syncZones?:  {
    __typename: "ModelZoneConnection",
    items:  Array< {
      __typename: "Zone",
      id: string,
      conceptID: string,
      location: string,
      deliveryFee: number,
      concepts?:  Array< {
        __typename: "ZoneConcept",
        id: string,
        deliveryFee: number,
      } | null > | null,
      polygon?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ZoneByConceptIDQueryVariables = {
  conceptID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelZoneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ZoneByConceptIDQuery = {
  zoneByConceptID?:  {
    __typename: "ModelZoneConnection",
    items:  Array< {
      __typename: "Zone",
      id: string,
      conceptID: string,
      location: string,
      deliveryFee: number,
      concepts?:  Array< {
        __typename: "ZoneConcept",
        id: string,
        deliveryFee: number,
      } | null > | null,
      polygon?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserIPQueryVariables = {
  id: string,
};

export type GetUserIPQuery = {
  getUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserIPSQueryVariables = {
  filter?: ModelUserIPFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserIPSQuery = {
  listUserIPS?:  {
    __typename: "ModelUserIPConnection",
    items:  Array< {
      __typename: "UserIP",
      id: string,
      ip?: string | null,
      date?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserIPSQueryVariables = {
  filter?: ModelUserIPFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserIPSQuery = {
  syncUserIPS?:  {
    __typename: "ModelUserIPConnection",
    items:  Array< {
      __typename: "UserIP",
      id: string,
      ip?: string | null,
      date?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMainCategoryQueryVariables = {
  id: string,
};

export type GetMainCategoryQuery = {
  getMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMainCategoriesQueryVariables = {
  filter?: ModelMainCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMainCategoriesQuery = {
  listMainCategories?:  {
    __typename: "ModelMainCategoryConnection",
    items:  Array< {
      __typename: "MainCategory",
      id: string,
      conceptID: string,
      name: string,
      precedence: string,
      openTime?: string | null,
      closeTime?: string | null,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      menuPDF?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMainCategoriesQueryVariables = {
  filter?: ModelMainCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMainCategoriesQuery = {
  syncMainCategories?:  {
    __typename: "ModelMainCategoryConnection",
    items:  Array< {
      __typename: "MainCategory",
      id: string,
      conceptID: string,
      name: string,
      precedence: string,
      openTime?: string | null,
      closeTime?: string | null,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      menuPDF?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MainCategoryByConceptIDQueryVariables = {
  conceptID: string,
  precedence?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMainCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MainCategoryByConceptIDQuery = {
  mainCategoryByConceptID?:  {
    __typename: "ModelMainCategoryConnection",
    items:  Array< {
      __typename: "MainCategory",
      id: string,
      conceptID: string,
      name: string,
      precedence: string,
      openTime?: string | null,
      closeTime?: string | null,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      menuPDF?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSubCategoryQueryVariables = {
  id: string,
};

export type GetSubCategoryQuery = {
  getSubCategory?:  {
    __typename: "SubCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    mainCategoryID?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSubCategoriesQueryVariables = {
  filter?: ModelSubCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSubCategoriesQuery = {
  listSubCategories?:  {
    __typename: "ModelSubCategoryConnection",
    items:  Array< {
      __typename: "SubCategory",
      id: string,
      conceptID: string,
      name: string,
      precedence: string,
      openTime?: string | null,
      closeTime?: string | null,
      mainCategoryID?: string | null,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      menuPDF?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSubCategoriesQueryVariables = {
  filter?: ModelSubCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSubCategoriesQuery = {
  syncSubCategories?:  {
    __typename: "ModelSubCategoryConnection",
    items:  Array< {
      __typename: "SubCategory",
      id: string,
      conceptID: string,
      name: string,
      precedence: string,
      openTime?: string | null,
      closeTime?: string | null,
      mainCategoryID?: string | null,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      menuPDF?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SubCategoryByConceptIDQueryVariables = {
  conceptID: string,
  precedence?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSubCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SubCategoryByConceptIDQuery = {
  subCategoryByConceptID?:  {
    __typename: "ModelSubCategoryConnection",
    items:  Array< {
      __typename: "SubCategory",
      id: string,
      conceptID: string,
      name: string,
      precedence: string,
      openTime?: string | null,
      closeTime?: string | null,
      mainCategoryID?: string | null,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      menuPDF?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetShiftQueryVariables = {
  id: string,
};

export type GetShiftQuery = {
  getShift?:  {
    __typename: "Shift",
    id: string,
    accountID: string,
    name: string,
    precedence: string,
    startTime: string,
    endTime: string,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListShiftsQueryVariables = {
  filter?: ModelShiftFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShiftsQuery = {
  listShifts?:  {
    __typename: "ModelShiftConnection",
    items:  Array< {
      __typename: "Shift",
      id: string,
      accountID: string,
      name: string,
      precedence: string,
      startTime: string,
      endTime: string,
      users?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncShiftsQueryVariables = {
  filter?: ModelShiftFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncShiftsQuery = {
  syncShifts?:  {
    __typename: "ModelShiftConnection",
    items:  Array< {
      __typename: "Shift",
      id: string,
      accountID: string,
      name: string,
      precedence: string,
      startTime: string,
      endTime: string,
      users?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetChannelQueryVariables = {
  id: string,
};

export type GetChannelQuery = {
  getChannel?:  {
    __typename: "Channel",
    id: string,
    name: string,
    description?: string | null,
    numberOfOrders?: number | null,
    isActive?: boolean | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListChannelsQueryVariables = {
  filter?: ModelChannelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChannelsQuery = {
  listChannels?:  {
    __typename: "ModelChannelConnection",
    items:  Array< {
      __typename: "Channel",
      id: string,
      name: string,
      description?: string | null,
      numberOfOrders?: number | null,
      isActive?: boolean | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncChannelsQueryVariables = {
  filter?: ModelChannelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncChannelsQuery = {
  syncChannels?:  {
    __typename: "ModelChannelConnection",
    items:  Array< {
      __typename: "Channel",
      id: string,
      name: string,
      description?: string | null,
      numberOfOrders?: number | null,
      isActive?: boolean | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPromocodeQueryVariables = {
  id: string,
};

export type GetPromocodeQuery = {
  getPromocode?:  {
    __typename: "Promocode",
    id: string,
    name: string,
    redemptions: number,
    status?: string | null,
    startDate: string,
    endDate: string,
    appliesTo: string,
    minOrderReq: string,
    minOrderVal?: number | null,
    minOrderQty?: number | null,
    tenderReq: Array< string | null >,
    discountVal: number,
    discountLimit?: number | null,
    quota?: number | null,
    quotaUsed?: number | null,
    totalRevenue?: number | null,
    appliedDiscountedVal?: number | null,
    usePerCustomer?: number | null,
    channelReq: Array< string | null >,
    customerEligibility: string,
    customerReq?: Array< string | null > | null,
    spendingExceeds?: number | null,
    spendingBelow?: number | null,
    orderSinceDate?: string | null,
    orderFromDate?: string | null,
    orderToDate?: string | null,
    isDisplayed?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPromocodesQueryVariables = {
  filter?: ModelPromocodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPromocodesQuery = {
  listPromocodes?:  {
    __typename: "ModelPromocodeConnection",
    items:  Array< {
      __typename: "Promocode",
      id: string,
      name: string,
      redemptions: number,
      status?: string | null,
      startDate: string,
      endDate: string,
      appliesTo: string,
      minOrderReq: string,
      minOrderVal?: number | null,
      minOrderQty?: number | null,
      tenderReq: Array< string | null >,
      discountVal: number,
      discountLimit?: number | null,
      quota?: number | null,
      quotaUsed?: number | null,
      totalRevenue?: number | null,
      appliedDiscountedVal?: number | null,
      usePerCustomer?: number | null,
      channelReq: Array< string | null >,
      customerEligibility: string,
      customerReq?: Array< string | null > | null,
      spendingExceeds?: number | null,
      spendingBelow?: number | null,
      orderSinceDate?: string | null,
      orderFromDate?: string | null,
      orderToDate?: string | null,
      isDisplayed?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPromocodesQueryVariables = {
  filter?: ModelPromocodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPromocodesQuery = {
  syncPromocodes?:  {
    __typename: "ModelPromocodeConnection",
    items:  Array< {
      __typename: "Promocode",
      id: string,
      name: string,
      redemptions: number,
      status?: string | null,
      startDate: string,
      endDate: string,
      appliesTo: string,
      minOrderReq: string,
      minOrderVal?: number | null,
      minOrderQty?: number | null,
      tenderReq: Array< string | null >,
      discountVal: number,
      discountLimit?: number | null,
      quota?: number | null,
      quotaUsed?: number | null,
      totalRevenue?: number | null,
      appliedDiscountedVal?: number | null,
      usePerCustomer?: number | null,
      channelReq: Array< string | null >,
      customerEligibility: string,
      customerReq?: Array< string | null > | null,
      spendingExceeds?: number | null,
      spendingBelow?: number | null,
      orderSinceDate?: string | null,
      orderFromDate?: string | null,
      orderToDate?: string | null,
      isDisplayed?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PromoCodeByNameQueryVariables = {
  name: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPromocodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PromoCodeByNameQuery = {
  promoCodeByName?:  {
    __typename: "ModelPromocodeConnection",
    items:  Array< {
      __typename: "Promocode",
      id: string,
      name: string,
      redemptions: number,
      status?: string | null,
      startDate: string,
      endDate: string,
      appliesTo: string,
      minOrderReq: string,
      minOrderVal?: number | null,
      minOrderQty?: number | null,
      tenderReq: Array< string | null >,
      discountVal: number,
      discountLimit?: number | null,
      quota?: number | null,
      quotaUsed?: number | null,
      totalRevenue?: number | null,
      appliedDiscountedVal?: number | null,
      usePerCustomer?: number | null,
      channelReq: Array< string | null >,
      customerEligibility: string,
      customerReq?: Array< string | null > | null,
      spendingExceeds?: number | null,
      spendingBelow?: number | null,
      orderSinceDate?: string | null,
      orderFromDate?: string | null,
      orderToDate?: string | null,
      isDisplayed?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PromoCodeByDeletedQueryVariables = {
  deleted: string,
  totalRevenue?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPromocodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PromoCodeByDeletedQuery = {
  promoCodeByDeleted?:  {
    __typename: "ModelPromocodeConnection",
    items:  Array< {
      __typename: "Promocode",
      id: string,
      name: string,
      redemptions: number,
      status?: string | null,
      startDate: string,
      endDate: string,
      appliesTo: string,
      minOrderReq: string,
      minOrderVal?: number | null,
      minOrderQty?: number | null,
      tenderReq: Array< string | null >,
      discountVal: number,
      discountLimit?: number | null,
      quota?: number | null,
      quotaUsed?: number | null,
      totalRevenue?: number | null,
      appliedDiscountedVal?: number | null,
      usePerCustomer?: number | null,
      channelReq: Array< string | null >,
      customerEligibility: string,
      customerReq?: Array< string | null > | null,
      spendingExceeds?: number | null,
      spendingBelow?: number | null,
      orderSinceDate?: string | null,
      orderFromDate?: string | null,
      orderToDate?: string | null,
      isDisplayed?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPromocodeDashboardQueryVariables = {
  id: string,
};

export type GetPromocodeDashboardQuery = {
  getPromocodeDashboard?:  {
    __typename: "PromocodeDashboard",
    id: string,
    conceptID: string,
    totalRevenue?: number | null,
    totalRedemptions?: number | null,
    totalDiscount?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPromocodeDashboardsQueryVariables = {
  filter?: ModelPromocodeDashboardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPromocodeDashboardsQuery = {
  listPromocodeDashboards?:  {
    __typename: "ModelPromocodeDashboardConnection",
    items:  Array< {
      __typename: "PromocodeDashboard",
      id: string,
      conceptID: string,
      totalRevenue?: number | null,
      totalRedemptions?: number | null,
      totalDiscount?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPromocodeDashboardsQueryVariables = {
  filter?: ModelPromocodeDashboardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPromocodeDashboardsQuery = {
  syncPromocodeDashboards?:  {
    __typename: "ModelPromocodeDashboardConnection",
    items:  Array< {
      __typename: "PromocodeDashboard",
      id: string,
      conceptID: string,
      totalRevenue?: number | null,
      totalRedemptions?: number | null,
      totalDiscount?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLoyaltyItemQueryVariables = {
  id: string,
};

export type GetLoyaltyItemQuery = {
  getLoyaltyItem?:  {
    __typename: "LoyaltyItem",
    id: string,
    name: string,
    description?: string | null,
    startDate: string,
    endDate?: string | null,
    points: number,
    redemptionPerUser?: number | null,
    image?: string | null,
    simphonyID: string,
    multiLanguages?: string | null,
    isActive?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLoyaltyItemsQueryVariables = {
  filter?: ModelLoyaltyItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLoyaltyItemsQuery = {
  listLoyaltyItems?:  {
    __typename: "ModelLoyaltyItemConnection",
    items:  Array< {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID: string,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLoyaltyItemsQueryVariables = {
  filter?: ModelLoyaltyItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLoyaltyItemsQuery = {
  syncLoyaltyItems?:  {
    __typename: "ModelLoyaltyItemConnection",
    items:  Array< {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID: string,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOTPQueryVariables = {
  id: string,
};

export type GetOTPQuery = {
  getOTP?:  {
    __typename: "OTP",
    id: string,
    OTP: string,
    redeemed?: boolean | null,
    deleted?: string | null,
    userID: string,
    conceptID?: string | null,
    loyaltyItemID?: string | null,
    loyaltyItem?:  {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID: string,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    concept?:  {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListOTPSQueryVariables = {
  filter?: ModelOTPFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOTPSQuery = {
  listOTPS?:  {
    __typename: "ModelOTPConnection",
    items:  Array< {
      __typename: "OTP",
      id: string,
      OTP: string,
      redeemed?: boolean | null,
      deleted?: string | null,
      userID: string,
      conceptID?: string | null,
      loyaltyItemID?: string | null,
      loyaltyItem?:  {
        __typename: "LoyaltyItem",
        id: string,
        name: string,
        description?: string | null,
        startDate: string,
        endDate?: string | null,
        points: number,
        redemptionPerUser?: number | null,
        image?: string | null,
        simphonyID: string,
        multiLanguages?: string | null,
        isActive?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        accountID: string,
        username?: string | null,
        preferred_username?: string | null,
        email?: string | null,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        pictureCrop?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        facebook?: string | null,
        instagram?: string | null,
        group?: string | null,
        phones?: Array< string | null > | null,
        flags?: Array< string | null > | null,
        flagsName?: Array< string | null > | null,
        interests?: Array< string | null > | null,
        interestsName?: Array< string | null > | null,
        status?: string | null,
        stats?: Array< string | null > | null,
        spending?: number | null,
        prevOrderDate?: string | null,
        lastOrderDate?: string | null,
        promocodes?:  Array< {
          __typename: "PromocodeItem",
          name: string,
          promocodeId: string,
          canBeUsed: number,
          discountVal: number,
          discountLimit?: number | null,
          isDisplayed?: boolean | null,
        } | null > | null,
        mostOrderedItems?: string | null,
        points?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        cognitoUsername?: string | null,
        cognitoSub?: string | null,
        updated?: string | null,
        sub?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      concept?:  {
        __typename: "Concept",
        id: string,
        accountID: string,
        name: string,
        kiosks?: Array< string | null > | null,
        merchantID?: string | null,
        merchantUsername?: string | null,
        merchantPassword?: string | null,
        merchantIntegrationNumber?: string | null,
        merchantAPIKey?: string | null,
        simphonyServerBaseURL?: string | null,
        simphonyShortOrgName?: string | null,
        simphonyLocRef?: string | null,
        simphonyToken?: string | null,
        revenueCenterID?: number | null,
        employeeID?: number | null,
        tenderID?: number | null,
        tenders?:  {
          __typename: "ModelTenderConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderType?:  {
          __typename: "ModelOrderTypeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        discountID?: number | null,
        useServiceCharge?: boolean | null,
        serviceChargeId?: number | null,
        specialRequestID?: number | null,
        addressID?: number | null,
        simphonyAuthClientId?: string | null,
        simphonyAuthUsername?: string | null,
        simphonyAuthPassword?: string | null,
        simphonyAuthBaseURL?: string | null,
        orderTypeDineIn?: number | null,
        orderTypeTakeAway?: number | null,
        orderTypeDelivery?: number | null,
        simphonyTakeAwayRvc?: number | null,
        simphonyDeliveryRvc?: number | null,
        exportInterval?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        foodicsToken?: string | null,
        enableTableFoodics?: string | null,
        image?: string | null,
        logo?: string | null,
        album?: Array< string | null > | null,
        type?: string | null,
        rating?: number | null,
        location?: string | null,
        description?: string | null,
        areas?: Array< string | null > | null,
        seatTypes?: Array< string | null > | null,
        needAssistance?: boolean | null,
        spaBooking?: boolean | null,
        showerBooking?: boolean | null,
        cigarMenu?: boolean | null,
        precedence?: number | null,
        isPinRequired?: boolean | null,
        viewOnly?: boolean | null,
        openTime?: string | null,
        closeTime?: string | null,
        varifyForAmount?: number | null,
        varifyFirstOrder?: boolean | null,
        varifyNewAddress?: boolean | null,
        sliderImages?: Array< string | null > | null,
        receiveWaiters?: Array< string | null > | null,
        WaiterNotificationOption?: string | null,
        applyLoyalty?: boolean | null,
        rewardPoints?: number | null,
        moneySpent?: number | null,
        afterVat?: boolean | null,
        afterDeliveryFee?: boolean | null,
        beforePromoCode?: boolean | null,
        pointsCalcWithPromoCode?: boolean | null,
        isBusy?: boolean | null,
        allowOnlineOrdering?: boolean | null,
        minOrderVal?: number | null,
        vatPercentage?: number | null,
        serviceChargePercentage?: number | null,
        addDeliveryToVat?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOTPSQueryVariables = {
  filter?: ModelOTPFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOTPSQuery = {
  syncOTPS?:  {
    __typename: "ModelOTPConnection",
    items:  Array< {
      __typename: "OTP",
      id: string,
      OTP: string,
      redeemed?: boolean | null,
      deleted?: string | null,
      userID: string,
      conceptID?: string | null,
      loyaltyItemID?: string | null,
      loyaltyItem?:  {
        __typename: "LoyaltyItem",
        id: string,
        name: string,
        description?: string | null,
        startDate: string,
        endDate?: string | null,
        points: number,
        redemptionPerUser?: number | null,
        image?: string | null,
        simphonyID: string,
        multiLanguages?: string | null,
        isActive?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        accountID: string,
        username?: string | null,
        preferred_username?: string | null,
        email?: string | null,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        pictureCrop?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        facebook?: string | null,
        instagram?: string | null,
        group?: string | null,
        phones?: Array< string | null > | null,
        flags?: Array< string | null > | null,
        flagsName?: Array< string | null > | null,
        interests?: Array< string | null > | null,
        interestsName?: Array< string | null > | null,
        status?: string | null,
        stats?: Array< string | null > | null,
        spending?: number | null,
        prevOrderDate?: string | null,
        lastOrderDate?: string | null,
        promocodes?:  Array< {
          __typename: "PromocodeItem",
          name: string,
          promocodeId: string,
          canBeUsed: number,
          discountVal: number,
          discountLimit?: number | null,
          isDisplayed?: boolean | null,
        } | null > | null,
        mostOrderedItems?: string | null,
        points?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        cognitoUsername?: string | null,
        cognitoSub?: string | null,
        updated?: string | null,
        sub?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      concept?:  {
        __typename: "Concept",
        id: string,
        accountID: string,
        name: string,
        kiosks?: Array< string | null > | null,
        merchantID?: string | null,
        merchantUsername?: string | null,
        merchantPassword?: string | null,
        merchantIntegrationNumber?: string | null,
        merchantAPIKey?: string | null,
        simphonyServerBaseURL?: string | null,
        simphonyShortOrgName?: string | null,
        simphonyLocRef?: string | null,
        simphonyToken?: string | null,
        revenueCenterID?: number | null,
        employeeID?: number | null,
        tenderID?: number | null,
        tenders?:  {
          __typename: "ModelTenderConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderType?:  {
          __typename: "ModelOrderTypeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        discountID?: number | null,
        useServiceCharge?: boolean | null,
        serviceChargeId?: number | null,
        specialRequestID?: number | null,
        addressID?: number | null,
        simphonyAuthClientId?: string | null,
        simphonyAuthUsername?: string | null,
        simphonyAuthPassword?: string | null,
        simphonyAuthBaseURL?: string | null,
        orderTypeDineIn?: number | null,
        orderTypeTakeAway?: number | null,
        orderTypeDelivery?: number | null,
        simphonyTakeAwayRvc?: number | null,
        simphonyDeliveryRvc?: number | null,
        exportInterval?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        foodicsToken?: string | null,
        enableTableFoodics?: string | null,
        image?: string | null,
        logo?: string | null,
        album?: Array< string | null > | null,
        type?: string | null,
        rating?: number | null,
        location?: string | null,
        description?: string | null,
        areas?: Array< string | null > | null,
        seatTypes?: Array< string | null > | null,
        needAssistance?: boolean | null,
        spaBooking?: boolean | null,
        showerBooking?: boolean | null,
        cigarMenu?: boolean | null,
        precedence?: number | null,
        isPinRequired?: boolean | null,
        viewOnly?: boolean | null,
        openTime?: string | null,
        closeTime?: string | null,
        varifyForAmount?: number | null,
        varifyFirstOrder?: boolean | null,
        varifyNewAddress?: boolean | null,
        sliderImages?: Array< string | null > | null,
        receiveWaiters?: Array< string | null > | null,
        WaiterNotificationOption?: string | null,
        applyLoyalty?: boolean | null,
        rewardPoints?: number | null,
        moneySpent?: number | null,
        afterVat?: boolean | null,
        afterDeliveryFee?: boolean | null,
        beforePromoCode?: boolean | null,
        pointsCalcWithPromoCode?: boolean | null,
        isBusy?: boolean | null,
        allowOnlineOrdering?: boolean | null,
        minOrderVal?: number | null,
        vatPercentage?: number | null,
        serviceChargePercentage?: number | null,
        addDeliveryToVat?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ByOTPQueryVariables = {
  OTP: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOTPFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByOTPQuery = {
  ByOTP?:  {
    __typename: "ModelOTPConnection",
    items:  Array< {
      __typename: "OTP",
      id: string,
      OTP: string,
      redeemed?: boolean | null,
      deleted?: string | null,
      userID: string,
      conceptID?: string | null,
      loyaltyItemID?: string | null,
      loyaltyItem?:  {
        __typename: "LoyaltyItem",
        id: string,
        name: string,
        description?: string | null,
        startDate: string,
        endDate?: string | null,
        points: number,
        redemptionPerUser?: number | null,
        image?: string | null,
        simphonyID: string,
        multiLanguages?: string | null,
        isActive?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        accountID: string,
        username?: string | null,
        preferred_username?: string | null,
        email?: string | null,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        pictureCrop?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        facebook?: string | null,
        instagram?: string | null,
        group?: string | null,
        phones?: Array< string | null > | null,
        flags?: Array< string | null > | null,
        flagsName?: Array< string | null > | null,
        interests?: Array< string | null > | null,
        interestsName?: Array< string | null > | null,
        status?: string | null,
        stats?: Array< string | null > | null,
        spending?: number | null,
        prevOrderDate?: string | null,
        lastOrderDate?: string | null,
        promocodes?:  Array< {
          __typename: "PromocodeItem",
          name: string,
          promocodeId: string,
          canBeUsed: number,
          discountVal: number,
          discountLimit?: number | null,
          isDisplayed?: boolean | null,
        } | null > | null,
        mostOrderedItems?: string | null,
        points?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        cognitoUsername?: string | null,
        cognitoSub?: string | null,
        updated?: string | null,
        sub?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      concept?:  {
        __typename: "Concept",
        id: string,
        accountID: string,
        name: string,
        kiosks?: Array< string | null > | null,
        merchantID?: string | null,
        merchantUsername?: string | null,
        merchantPassword?: string | null,
        merchantIntegrationNumber?: string | null,
        merchantAPIKey?: string | null,
        simphonyServerBaseURL?: string | null,
        simphonyShortOrgName?: string | null,
        simphonyLocRef?: string | null,
        simphonyToken?: string | null,
        revenueCenterID?: number | null,
        employeeID?: number | null,
        tenderID?: number | null,
        tenders?:  {
          __typename: "ModelTenderConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderType?:  {
          __typename: "ModelOrderTypeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        discountID?: number | null,
        useServiceCharge?: boolean | null,
        serviceChargeId?: number | null,
        specialRequestID?: number | null,
        addressID?: number | null,
        simphonyAuthClientId?: string | null,
        simphonyAuthUsername?: string | null,
        simphonyAuthPassword?: string | null,
        simphonyAuthBaseURL?: string | null,
        orderTypeDineIn?: number | null,
        orderTypeTakeAway?: number | null,
        orderTypeDelivery?: number | null,
        simphonyTakeAwayRvc?: number | null,
        simphonyDeliveryRvc?: number | null,
        exportInterval?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        foodicsToken?: string | null,
        enableTableFoodics?: string | null,
        image?: string | null,
        logo?: string | null,
        album?: Array< string | null > | null,
        type?: string | null,
        rating?: number | null,
        location?: string | null,
        description?: string | null,
        areas?: Array< string | null > | null,
        seatTypes?: Array< string | null > | null,
        needAssistance?: boolean | null,
        spaBooking?: boolean | null,
        showerBooking?: boolean | null,
        cigarMenu?: boolean | null,
        precedence?: number | null,
        isPinRequired?: boolean | null,
        viewOnly?: boolean | null,
        openTime?: string | null,
        closeTime?: string | null,
        varifyForAmount?: number | null,
        varifyFirstOrder?: boolean | null,
        varifyNewAddress?: boolean | null,
        sliderImages?: Array< string | null > | null,
        receiveWaiters?: Array< string | null > | null,
        WaiterNotificationOption?: string | null,
        applyLoyalty?: boolean | null,
        rewardPoints?: number | null,
        moneySpent?: number | null,
        afterVat?: boolean | null,
        afterDeliveryFee?: boolean | null,
        beforePromoCode?: boolean | null,
        pointsCalcWithPromoCode?: boolean | null,
        isBusy?: boolean | null,
        allowOnlineOrdering?: boolean | null,
        minOrderVal?: number | null,
        vatPercentage?: number | null,
        serviceChargePercentage?: number | null,
        addDeliveryToVat?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OtpByUserIDQueryVariables = {
  userID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOTPFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OtpByUserIDQuery = {
  otpByUserID?:  {
    __typename: "ModelOTPConnection",
    items:  Array< {
      __typename: "OTP",
      id: string,
      OTP: string,
      redeemed?: boolean | null,
      deleted?: string | null,
      userID: string,
      conceptID?: string | null,
      loyaltyItemID?: string | null,
      loyaltyItem?:  {
        __typename: "LoyaltyItem",
        id: string,
        name: string,
        description?: string | null,
        startDate: string,
        endDate?: string | null,
        points: number,
        redemptionPerUser?: number | null,
        image?: string | null,
        simphonyID: string,
        multiLanguages?: string | null,
        isActive?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        accountID: string,
        username?: string | null,
        preferred_username?: string | null,
        email?: string | null,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        pictureCrop?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        facebook?: string | null,
        instagram?: string | null,
        group?: string | null,
        phones?: Array< string | null > | null,
        flags?: Array< string | null > | null,
        flagsName?: Array< string | null > | null,
        interests?: Array< string | null > | null,
        interestsName?: Array< string | null > | null,
        status?: string | null,
        stats?: Array< string | null > | null,
        spending?: number | null,
        prevOrderDate?: string | null,
        lastOrderDate?: string | null,
        promocodes?:  Array< {
          __typename: "PromocodeItem",
          name: string,
          promocodeId: string,
          canBeUsed: number,
          discountVal: number,
          discountLimit?: number | null,
          isDisplayed?: boolean | null,
        } | null > | null,
        mostOrderedItems?: string | null,
        points?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        cognitoUsername?: string | null,
        cognitoSub?: string | null,
        updated?: string | null,
        sub?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      concept?:  {
        __typename: "Concept",
        id: string,
        accountID: string,
        name: string,
        kiosks?: Array< string | null > | null,
        merchantID?: string | null,
        merchantUsername?: string | null,
        merchantPassword?: string | null,
        merchantIntegrationNumber?: string | null,
        merchantAPIKey?: string | null,
        simphonyServerBaseURL?: string | null,
        simphonyShortOrgName?: string | null,
        simphonyLocRef?: string | null,
        simphonyToken?: string | null,
        revenueCenterID?: number | null,
        employeeID?: number | null,
        tenderID?: number | null,
        tenders?:  {
          __typename: "ModelTenderConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderType?:  {
          __typename: "ModelOrderTypeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        discountID?: number | null,
        useServiceCharge?: boolean | null,
        serviceChargeId?: number | null,
        specialRequestID?: number | null,
        addressID?: number | null,
        simphonyAuthClientId?: string | null,
        simphonyAuthUsername?: string | null,
        simphonyAuthPassword?: string | null,
        simphonyAuthBaseURL?: string | null,
        orderTypeDineIn?: number | null,
        orderTypeTakeAway?: number | null,
        orderTypeDelivery?: number | null,
        simphonyTakeAwayRvc?: number | null,
        simphonyDeliveryRvc?: number | null,
        exportInterval?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        foodicsToken?: string | null,
        enableTableFoodics?: string | null,
        image?: string | null,
        logo?: string | null,
        album?: Array< string | null > | null,
        type?: string | null,
        rating?: number | null,
        location?: string | null,
        description?: string | null,
        areas?: Array< string | null > | null,
        seatTypes?: Array< string | null > | null,
        needAssistance?: boolean | null,
        spaBooking?: boolean | null,
        showerBooking?: boolean | null,
        cigarMenu?: boolean | null,
        precedence?: number | null,
        isPinRequired?: boolean | null,
        viewOnly?: boolean | null,
        openTime?: string | null,
        closeTime?: string | null,
        varifyForAmount?: number | null,
        varifyFirstOrder?: boolean | null,
        varifyNewAddress?: boolean | null,
        sliderImages?: Array< string | null > | null,
        receiveWaiters?: Array< string | null > | null,
        WaiterNotificationOption?: string | null,
        applyLoyalty?: boolean | null,
        rewardPoints?: number | null,
        moneySpent?: number | null,
        afterVat?: boolean | null,
        afterDeliveryFee?: boolean | null,
        beforePromoCode?: boolean | null,
        pointsCalcWithPromoCode?: boolean | null,
        isBusy?: boolean | null,
        allowOnlineOrdering?: boolean | null,
        minOrderVal?: number | null,
        vatPercentage?: number | null,
        serviceChargePercentage?: number | null,
        addDeliveryToVat?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateEmailVerificationSubscriptionVariables = {
  filter?: ModelSubscriptionEmailVerificationFilterInput | null,
};

export type OnCreateEmailVerificationSubscription = {
  onCreateEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    accountID: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateEmailVerificationSubscriptionVariables = {
  filter?: ModelSubscriptionEmailVerificationFilterInput | null,
};

export type OnUpdateEmailVerificationSubscription = {
  onUpdateEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    accountID: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteEmailVerificationSubscriptionVariables = {
  filter?: ModelSubscriptionEmailVerificationFilterInput | null,
};

export type OnDeleteEmailVerificationSubscription = {
  onDeleteEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    accountID: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSocialLinkSubscriptionVariables = {
  filter?: ModelSubscriptionSocialLinkFilterInput | null,
};

export type OnCreateSocialLinkSubscription = {
  onCreateSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSocialLinkSubscriptionVariables = {
  filter?: ModelSubscriptionSocialLinkFilterInput | null,
};

export type OnUpdateSocialLinkSubscription = {
  onUpdateSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSocialLinkSubscriptionVariables = {
  filter?: ModelSubscriptionSocialLinkFilterInput | null,
};

export type OnDeleteSocialLinkSubscription = {
  onDeleteSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateLanguageSubscriptionVariables = {
  filter?: ModelSubscriptionLanguageFilterInput | null,
};

export type OnCreateLanguageSubscription = {
  onCreateLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateLanguageSubscriptionVariables = {
  filter?: ModelSubscriptionLanguageFilterInput | null,
};

export type OnUpdateLanguageSubscription = {
  onUpdateLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteLanguageSubscriptionVariables = {
  filter?: ModelSubscriptionLanguageFilterInput | null,
};

export type OnDeleteLanguageSubscription = {
  onDeleteLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
};

export type OnCreateAttachmentSubscription = {
  onCreateAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
};

export type OnUpdateAttachmentSubscription = {
  onUpdateAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
};

export type OnDeleteAttachmentSubscription = {
  onDeleteAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateGroupSubscriptionVariables = {
  filter?: ModelSubscriptionGroupFilterInput | null,
};

export type OnCreateGroupSubscription = {
  onCreateGroup?:  {
    __typename: "Group",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    color?: string | null,
    requireApproval?: boolean | null,
    status?: string | null,
    agents?: Array< string | null > | null,
    groupSettings?: Array< string | null > | null,
    numToUpgrade?: number | null,
    numToDowngrade?: number | null,
    upgradeGroup?: string | null,
    downgradeGroup?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateGroupSubscriptionVariables = {
  filter?: ModelSubscriptionGroupFilterInput | null,
};

export type OnUpdateGroupSubscription = {
  onUpdateGroup?:  {
    __typename: "Group",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    color?: string | null,
    requireApproval?: boolean | null,
    status?: string | null,
    agents?: Array< string | null > | null,
    groupSettings?: Array< string | null > | null,
    numToUpgrade?: number | null,
    numToDowngrade?: number | null,
    upgradeGroup?: string | null,
    downgradeGroup?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteGroupSubscriptionVariables = {
  filter?: ModelSubscriptionGroupFilterInput | null,
};

export type OnDeleteGroupSubscription = {
  onDeleteGroup?:  {
    __typename: "Group",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    color?: string | null,
    requireApproval?: boolean | null,
    status?: string | null,
    agents?: Array< string | null > | null,
    groupSettings?: Array< string | null > | null,
    numToUpgrade?: number | null,
    numToDowngrade?: number | null,
    upgradeGroup?: string | null,
    downgradeGroup?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateGroupSettingSubscriptionVariables = {
  filter?: ModelSubscriptionGroupSettingFilterInput | null,
};

export type OnCreateGroupSettingSubscription = {
  onCreateGroupSetting?:  {
    __typename: "GroupSetting",
    id: string,
    groupID: string,
    statusID: string,
    needsVerification: boolean,
    needsDeposit: boolean,
    isDefault: boolean,
    amount?: number | null,
    days?: Array< string | null > | null,
    timeSlots?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateGroupSettingSubscriptionVariables = {
  filter?: ModelSubscriptionGroupSettingFilterInput | null,
};

export type OnUpdateGroupSettingSubscription = {
  onUpdateGroupSetting?:  {
    __typename: "GroupSetting",
    id: string,
    groupID: string,
    statusID: string,
    needsVerification: boolean,
    needsDeposit: boolean,
    isDefault: boolean,
    amount?: number | null,
    days?: Array< string | null > | null,
    timeSlots?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteGroupSettingSubscriptionVariables = {
  filter?: ModelSubscriptionGroupSettingFilterInput | null,
};

export type OnDeleteGroupSettingSubscription = {
  onDeleteGroupSetting?:  {
    __typename: "GroupSetting",
    id: string,
    groupID: string,
    statusID: string,
    needsVerification: boolean,
    needsDeposit: boolean,
    isDefault: boolean,
    amount?: number | null,
    days?: Array< string | null > | null,
    timeSlots?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCustomerStatsSubscriptionVariables = {
  filter?: ModelSubscriptionCustomerStatsFilterInput | null,
};

export type OnCreateCustomerStatsSubscription = {
  onCreateCustomerStats?:  {
    __typename: "CustomerStats",
    id: string,
    conceptID: string,
    totalReservation?: number | null,
    confirmed?: number | null,
    notConfirmed?: number | null,
    canceled?: number | null,
    checkIn?: number | null,
    noShow?: number | null,
    userID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCustomerStatsSubscriptionVariables = {
  filter?: ModelSubscriptionCustomerStatsFilterInput | null,
};

export type OnUpdateCustomerStatsSubscription = {
  onUpdateCustomerStats?:  {
    __typename: "CustomerStats",
    id: string,
    conceptID: string,
    totalReservation?: number | null,
    confirmed?: number | null,
    notConfirmed?: number | null,
    canceled?: number | null,
    checkIn?: number | null,
    noShow?: number | null,
    userID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCustomerStatsSubscriptionVariables = {
  filter?: ModelSubscriptionCustomerStatsFilterInput | null,
};

export type OnDeleteCustomerStatsSubscription = {
  onDeleteCustomerStats?:  {
    __typename: "CustomerStats",
    id: string,
    conceptID: string,
    totalReservation?: number | null,
    confirmed?: number | null,
    notConfirmed?: number | null,
    canceled?: number | null,
    checkIn?: number | null,
    noShow?: number | null,
    userID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFlagSubscriptionVariables = {
  filter?: ModelSubscriptionFlagFilterInput | null,
};

export type OnCreateFlagSubscription = {
  onCreateFlag?:  {
    __typename: "Flag",
    id: string,
    accountID: string,
    name: string,
    icon: string,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFlagSubscriptionVariables = {
  filter?: ModelSubscriptionFlagFilterInput | null,
};

export type OnUpdateFlagSubscription = {
  onUpdateFlag?:  {
    __typename: "Flag",
    id: string,
    accountID: string,
    name: string,
    icon: string,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFlagSubscriptionVariables = {
  filter?: ModelSubscriptionFlagFilterInput | null,
};

export type OnDeleteFlagSubscription = {
  onDeleteFlag?:  {
    __typename: "Flag",
    id: string,
    accountID: string,
    name: string,
    icon: string,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateInterestSubscriptionVariables = {
  filter?: ModelSubscriptionInterestFilterInput | null,
};

export type OnCreateInterestSubscription = {
  onCreateInterest?:  {
    __typename: "Interest",
    id: string,
    accountID: string,
    name: string,
    icon?: string | null,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateInterestSubscriptionVariables = {
  filter?: ModelSubscriptionInterestFilterInput | null,
};

export type OnUpdateInterestSubscription = {
  onUpdateInterest?:  {
    __typename: "Interest",
    id: string,
    accountID: string,
    name: string,
    icon?: string | null,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteInterestSubscriptionVariables = {
  filter?: ModelSubscriptionInterestFilterInput | null,
};

export type OnDeleteInterestSubscription = {
  onDeleteInterest?:  {
    __typename: "Interest",
    id: string,
    accountID: string,
    name: string,
    icon?: string | null,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateReservationStatusSubscriptionVariables = {
  filter?: ModelSubscriptionReservationStatusFilterInput | null,
};

export type OnCreateReservationStatusSubscription = {
  onCreateReservationStatus?:  {
    __typename: "ReservationStatus",
    id: string,
    accountID: string,
    name: string,
    category: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    reservationStatusImageId?: string | null,
  } | null,
};

export type OnUpdateReservationStatusSubscriptionVariables = {
  filter?: ModelSubscriptionReservationStatusFilterInput | null,
};

export type OnUpdateReservationStatusSubscription = {
  onUpdateReservationStatus?:  {
    __typename: "ReservationStatus",
    id: string,
    accountID: string,
    name: string,
    category: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    reservationStatusImageId?: string | null,
  } | null,
};

export type OnDeleteReservationStatusSubscriptionVariables = {
  filter?: ModelSubscriptionReservationStatusFilterInput | null,
};

export type OnDeleteReservationStatusSubscription = {
  onDeleteReservationStatus?:  {
    __typename: "ReservationStatus",
    id: string,
    accountID: string,
    name: string,
    category: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    reservationStatusImageId?: string | null,
  } | null,
};

export type OnCreateTimeSlotSubscriptionVariables = {
  filter?: ModelSubscriptionTimeSlotFilterInput | null,
};

export type OnCreateTimeSlotSubscription = {
  onCreateTimeSlot?:  {
    __typename: "TimeSlot",
    id: string,
    conceptID: string,
    name: string,
    friendlyName: string,
    bookings?: Array< string | null > | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTimeSlotSubscriptionVariables = {
  filter?: ModelSubscriptionTimeSlotFilterInput | null,
};

export type OnUpdateTimeSlotSubscription = {
  onUpdateTimeSlot?:  {
    __typename: "TimeSlot",
    id: string,
    conceptID: string,
    name: string,
    friendlyName: string,
    bookings?: Array< string | null > | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTimeSlotSubscriptionVariables = {
  filter?: ModelSubscriptionTimeSlotFilterInput | null,
};

export type OnDeleteTimeSlotSubscription = {
  onDeleteTimeSlot?:  {
    __typename: "TimeSlot",
    id: string,
    conceptID: string,
    name: string,
    friendlyName: string,
    bookings?: Array< string | null > | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAreaSubscriptionVariables = {
  filter?: ModelSubscriptionAreaFilterInput | null,
};

export type OnCreateAreaSubscription = {
  onCreateArea?:  {
    __typename: "Area",
    id: string,
    conceptID: string,
    name: string,
    isDefault: boolean,
    expiryDate?: string | null,
    days?: Array< string | null > | null,
    timeSlots?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAreaSubscriptionVariables = {
  filter?: ModelSubscriptionAreaFilterInput | null,
};

export type OnUpdateAreaSubscription = {
  onUpdateArea?:  {
    __typename: "Area",
    id: string,
    conceptID: string,
    name: string,
    isDefault: boolean,
    expiryDate?: string | null,
    days?: Array< string | null > | null,
    timeSlots?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAreaSubscriptionVariables = {
  filter?: ModelSubscriptionAreaFilterInput | null,
};

export type OnDeleteAreaSubscription = {
  onDeleteArea?:  {
    __typename: "Area",
    id: string,
    conceptID: string,
    name: string,
    isDefault: boolean,
    expiryDate?: string | null,
    days?: Array< string | null > | null,
    timeSlots?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTableSubscriptionVariables = {
  filter?: ModelSubscriptionTableFilterInput | null,
};

export type OnCreateTableSubscription = {
  onCreateTable?:  {
    __typename: "Table",
    id: string,
    conceptID: string,
    label: string,
    name: string,
    width?: number | null,
    height?: number | null,
    capacity?: number | null,
    image?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTableSubscriptionVariables = {
  filter?: ModelSubscriptionTableFilterInput | null,
};

export type OnUpdateTableSubscription = {
  onUpdateTable?:  {
    __typename: "Table",
    id: string,
    conceptID: string,
    label: string,
    name: string,
    width?: number | null,
    height?: number | null,
    capacity?: number | null,
    image?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTableSubscriptionVariables = {
  filter?: ModelSubscriptionTableFilterInput | null,
};

export type OnDeleteTableSubscription = {
  onDeleteTable?:  {
    __typename: "Table",
    id: string,
    conceptID: string,
    label: string,
    name: string,
    width?: number | null,
    height?: number | null,
    capacity?: number | null,
    image?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFriendSubscriptionVariables = {
  filter?: ModelSubscriptionFriendFilterInput | null,
};

export type OnCreateFriendSubscription = {
  onCreateFriend?:  {
    __typename: "Friend",
    id: string,
    customerId?: string | null,
    friendId?: string | null,
    bookingID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFriendSubscriptionVariables = {
  filter?: ModelSubscriptionFriendFilterInput | null,
};

export type OnUpdateFriendSubscription = {
  onUpdateFriend?:  {
    __typename: "Friend",
    id: string,
    customerId?: string | null,
    friendId?: string | null,
    bookingID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFriendSubscriptionVariables = {
  filter?: ModelSubscriptionFriendFilterInput | null,
};

export type OnDeleteFriendSubscription = {
  onDeleteFriend?:  {
    __typename: "Friend",
    id: string,
    customerId?: string | null,
    friendId?: string | null,
    bookingID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
};

export type OnCreateCommentSubscription = {
  onCreateComment?:  {
    __typename: "Comment",
    id: string,
    message: string,
    customerId?: string | null,
    bookingId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
};

export type OnUpdateCommentSubscription = {
  onUpdateComment?:  {
    __typename: "Comment",
    id: string,
    message: string,
    customerId?: string | null,
    bookingId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
};

export type OnDeleteCommentSubscription = {
  onDeleteComment?:  {
    __typename: "Comment",
    id: string,
    message: string,
    customerId?: string | null,
    bookingId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateServingAreaSubscriptionVariables = {
  filter?: ModelSubscriptionServingAreaFilterInput | null,
};

export type OnCreateServingAreaSubscription = {
  onCreateServingArea?:  {
    __typename: "ServingArea",
    id: string,
    name: string,
    fees: number,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateServingAreaSubscriptionVariables = {
  filter?: ModelSubscriptionServingAreaFilterInput | null,
};

export type OnUpdateServingAreaSubscription = {
  onUpdateServingArea?:  {
    __typename: "ServingArea",
    id: string,
    name: string,
    fees: number,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteServingAreaSubscriptionVariables = {
  filter?: ModelSubscriptionServingAreaFilterInput | null,
};

export type OnDeleteServingAreaSubscription = {
  onDeleteServingArea?:  {
    __typename: "ServingArea",
    id: string,
    name: string,
    fees: number,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    id: string,
    accountID: string,
    conceptID: string,
    name?: string | null,
    description?: string | null,
    channel?: string | null,
    message?: string | null,
    from?: string | null,
    enabled?: boolean | null,
    autoSend?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    id: string,
    accountID: string,
    conceptID: string,
    name?: string | null,
    description?: string | null,
    channel?: string | null,
    message?: string | null,
    from?: string | null,
    enabled?: boolean | null,
    autoSend?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    id: string,
    accountID: string,
    conceptID: string,
    name?: string | null,
    description?: string | null,
    channel?: string | null,
    message?: string | null,
    from?: string | null,
    enabled?: boolean | null,
    autoSend?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMobileNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionMobileNotificationFilterInput | null,
};

export type OnCreateMobileNotificationSubscription = {
  onCreateMobileNotification?:  {
    __typename: "MobileNotification",
    id: string,
    title?: string | null,
    description?: string | null,
    body?: string | null,
    default?: boolean | null,
    event?: boolean | null,
    image?: string | null,
    concepts?: Array< string | null > | null,
    condition?: Array< string | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    group?: string | null,
    read?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMobileNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionMobileNotificationFilterInput | null,
};

export type OnUpdateMobileNotificationSubscription = {
  onUpdateMobileNotification?:  {
    __typename: "MobileNotification",
    id: string,
    title?: string | null,
    description?: string | null,
    body?: string | null,
    default?: boolean | null,
    event?: boolean | null,
    image?: string | null,
    concepts?: Array< string | null > | null,
    condition?: Array< string | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    group?: string | null,
    read?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMobileNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionMobileNotificationFilterInput | null,
};

export type OnDeleteMobileNotificationSubscription = {
  onDeleteMobileNotification?:  {
    __typename: "MobileNotification",
    id: string,
    title?: string | null,
    description?: string | null,
    body?: string | null,
    default?: boolean | null,
    event?: boolean | null,
    image?: string | null,
    concepts?: Array< string | null > | null,
    condition?: Array< string | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    group?: string | null,
    read?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserPushTokenSubscriptionVariables = {
  filter?: ModelSubscriptionUserPushTokenFilterInput | null,
};

export type OnCreateUserPushTokenSubscription = {
  onCreateUserPushToken?:  {
    __typename: "UserPushToken",
    id: string,
    userID?: string | null,
    token?: string | null,
    reciveNotification?: boolean | null,
    type?: string | null,
    os?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserPushTokenSubscriptionVariables = {
  filter?: ModelSubscriptionUserPushTokenFilterInput | null,
};

export type OnUpdateUserPushTokenSubscription = {
  onUpdateUserPushToken?:  {
    __typename: "UserPushToken",
    id: string,
    userID?: string | null,
    token?: string | null,
    reciveNotification?: boolean | null,
    type?: string | null,
    os?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserPushTokenSubscriptionVariables = {
  filter?: ModelSubscriptionUserPushTokenFilterInput | null,
};

export type OnDeleteUserPushTokenSubscription = {
  onDeleteUserPushToken?:  {
    __typename: "UserPushToken",
    id: string,
    userID?: string | null,
    token?: string | null,
    reciveNotification?: boolean | null,
    type?: string | null,
    os?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateNotificationReceiverSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationReceiverFilterInput | null,
};

export type OnCreateNotificationReceiverSubscription = {
  onCreateNotificationReceiver?:  {
    __typename: "NotificationReceiver",
    id: string,
    userID?: string | null,
    notificationID?: string | null,
    seen?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateNotificationReceiverSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationReceiverFilterInput | null,
};

export type OnUpdateNotificationReceiverSubscription = {
  onUpdateNotificationReceiver?:  {
    __typename: "NotificationReceiver",
    id: string,
    userID?: string | null,
    notificationID?: string | null,
    seen?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteNotificationReceiverSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationReceiverFilterInput | null,
};

export type OnDeleteNotificationReceiverSubscription = {
  onDeleteNotificationReceiver?:  {
    __typename: "NotificationReceiver",
    id: string,
    userID?: string | null,
    notificationID?: string | null,
    seen?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePreparationAreaSubscriptionVariables = {
  filter?: ModelSubscriptionPreparationAreaFilterInput | null,
};

export type OnCreatePreparationAreaSubscription = {
  onCreatePreparationArea?:  {
    __typename: "PreparationArea",
    id: string,
    accountID: string,
    name: string,
    isAutoAccept?: boolean | null,
    hasKDS?: boolean | null,
    hasPrinter?: boolean | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    precedence: string,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePreparationAreaSubscriptionVariables = {
  filter?: ModelSubscriptionPreparationAreaFilterInput | null,
};

export type OnUpdatePreparationAreaSubscription = {
  onUpdatePreparationArea?:  {
    __typename: "PreparationArea",
    id: string,
    accountID: string,
    name: string,
    isAutoAccept?: boolean | null,
    hasKDS?: boolean | null,
    hasPrinter?: boolean | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    precedence: string,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePreparationAreaSubscriptionVariables = {
  filter?: ModelSubscriptionPreparationAreaFilterInput | null,
};

export type OnDeletePreparationAreaSubscription = {
  onDeletePreparationArea?:  {
    __typename: "PreparationArea",
    id: string,
    accountID: string,
    name: string,
    isAutoAccept?: boolean | null,
    hasKDS?: boolean | null,
    hasPrinter?: boolean | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    precedence: string,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateQRcodeSubscriptionVariables = {
  filter?: ModelSubscriptionQRcodeFilterInput | null,
};

export type OnCreateQRcodeSubscription = {
  onCreateQRcode?:  {
    __typename: "QRcode",
    id: string,
    tableID: string,
    conceptID: string,
    tableName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateQRcodeSubscriptionVariables = {
  filter?: ModelSubscriptionQRcodeFilterInput | null,
};

export type OnUpdateQRcodeSubscription = {
  onUpdateQRcode?:  {
    __typename: "QRcode",
    id: string,
    tableID: string,
    conceptID: string,
    tableName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteQRcodeSubscriptionVariables = {
  filter?: ModelSubscriptionQRcodeFilterInput | null,
};

export type OnDeleteQRcodeSubscription = {
  onDeleteQRcode?:  {
    __typename: "QRcode",
    id: string,
    tableID: string,
    conceptID: string,
    tableName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCallWaiterSubscriptionVariables = {
  filter?: ModelSubscriptionCallWaiterFilterInput | null,
};

export type OnCreateCallWaiterSubscription = {
  onCreateCallWaiter?:  {
    __typename: "CallWaiter",
    id: string,
    conceptID?: string | null,
    tableID?: string | null,
    tableName?: string | null,
    waiterID?: string | null,
    waiterName?: string | null,
    userID?: string | null,
    count?: number | null,
    status?: string | null,
    deleted?: string | null,
    createdAt: string,
    acceptedAt?: string | null,
    arrivedAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCallWaiterSubscriptionVariables = {
  filter?: ModelSubscriptionCallWaiterFilterInput | null,
};

export type OnUpdateCallWaiterSubscription = {
  onUpdateCallWaiter?:  {
    __typename: "CallWaiter",
    id: string,
    conceptID?: string | null,
    tableID?: string | null,
    tableName?: string | null,
    waiterID?: string | null,
    waiterName?: string | null,
    userID?: string | null,
    count?: number | null,
    status?: string | null,
    deleted?: string | null,
    createdAt: string,
    acceptedAt?: string | null,
    arrivedAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCallWaiterSubscriptionVariables = {
  filter?: ModelSubscriptionCallWaiterFilterInput | null,
};

export type OnDeleteCallWaiterSubscription = {
  onDeleteCallWaiter?:  {
    __typename: "CallWaiter",
    id: string,
    conceptID?: string | null,
    tableID?: string | null,
    tableName?: string | null,
    waiterID?: string | null,
    waiterName?: string | null,
    userID?: string | null,
    count?: number | null,
    status?: string | null,
    deleted?: string | null,
    createdAt: string,
    acceptedAt?: string | null,
    arrivedAt?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    accountID: string,
    username?: string | null,
    preferred_username?: string | null,
    email?: string | null,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    pictureCrop?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    facebook?: string | null,
    instagram?: string | null,
    group?: string | null,
    phones?: Array< string | null > | null,
    flags?: Array< string | null > | null,
    flagsName?: Array< string | null > | null,
    interests?: Array< string | null > | null,
    interestsName?: Array< string | null > | null,
    status?: string | null,
    stats?: Array< string | null > | null,
    spending?: number | null,
    prevOrderDate?: string | null,
    lastOrderDate?: string | null,
    promocodes?:  Array< {
      __typename: "PromocodeItem",
      name: string,
      promocodeId: string,
      canBeUsed: number,
      discountVal: number,
      discountLimit?: number | null,
      isDisplayed?: boolean | null,
    } | null > | null,
    mostOrderedItems?: string | null,
    points?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    cognitoUsername?: string | null,
    cognitoSub?: string | null,
    updated?: string | null,
    sub?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    accountID: string,
    username?: string | null,
    preferred_username?: string | null,
    email?: string | null,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    pictureCrop?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    facebook?: string | null,
    instagram?: string | null,
    group?: string | null,
    phones?: Array< string | null > | null,
    flags?: Array< string | null > | null,
    flagsName?: Array< string | null > | null,
    interests?: Array< string | null > | null,
    interestsName?: Array< string | null > | null,
    status?: string | null,
    stats?: Array< string | null > | null,
    spending?: number | null,
    prevOrderDate?: string | null,
    lastOrderDate?: string | null,
    promocodes?:  Array< {
      __typename: "PromocodeItem",
      name: string,
      promocodeId: string,
      canBeUsed: number,
      discountVal: number,
      discountLimit?: number | null,
      isDisplayed?: boolean | null,
    } | null > | null,
    mostOrderedItems?: string | null,
    points?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    cognitoUsername?: string | null,
    cognitoSub?: string | null,
    updated?: string | null,
    sub?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    accountID: string,
    username?: string | null,
    preferred_username?: string | null,
    email?: string | null,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    pictureCrop?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    facebook?: string | null,
    instagram?: string | null,
    group?: string | null,
    phones?: Array< string | null > | null,
    flags?: Array< string | null > | null,
    flagsName?: Array< string | null > | null,
    interests?: Array< string | null > | null,
    interestsName?: Array< string | null > | null,
    status?: string | null,
    stats?: Array< string | null > | null,
    spending?: number | null,
    prevOrderDate?: string | null,
    lastOrderDate?: string | null,
    promocodes?:  Array< {
      __typename: "PromocodeItem",
      name: string,
      promocodeId: string,
      canBeUsed: number,
      discountVal: number,
      discountLimit?: number | null,
      isDisplayed?: boolean | null,
    } | null > | null,
    mostOrderedItems?: string | null,
    points?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    cognitoUsername?: string | null,
    cognitoSub?: string | null,
    updated?: string | null,
    sub?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateReviewSubscriptionVariables = {
  filter?: ModelSubscriptionReviewFilterInput | null,
};

export type OnCreateReviewSubscription = {
  onCreateReview?:  {
    __typename: "Review",
    id: string,
    accountID?: string | null,
    bookingID?: string | null,
    guestName?: string | null,
    guestId?: string | null,
    reason?: Array< string | null > | null,
    value?: string | null,
    userID?: string | null,
    orderID?: string | null,
    rate?: number | null,
    satisfaction?: string | null,
    thoughts?: string | null,
    itemReviews?:  Array< {
      __typename: "ItemReview",
      itemID: string,
      rate?: number | null,
      satisfaction?: string | null,
      multiLanguages?: string | null,
    } | null > | null,
    photos?: Array< string | null > | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateReviewSubscriptionVariables = {
  filter?: ModelSubscriptionReviewFilterInput | null,
};

export type OnUpdateReviewSubscription = {
  onUpdateReview?:  {
    __typename: "Review",
    id: string,
    accountID?: string | null,
    bookingID?: string | null,
    guestName?: string | null,
    guestId?: string | null,
    reason?: Array< string | null > | null,
    value?: string | null,
    userID?: string | null,
    orderID?: string | null,
    rate?: number | null,
    satisfaction?: string | null,
    thoughts?: string | null,
    itemReviews?:  Array< {
      __typename: "ItemReview",
      itemID: string,
      rate?: number | null,
      satisfaction?: string | null,
      multiLanguages?: string | null,
    } | null > | null,
    photos?: Array< string | null > | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteReviewSubscriptionVariables = {
  filter?: ModelSubscriptionReviewFilterInput | null,
};

export type OnDeleteReviewSubscription = {
  onDeleteReview?:  {
    __typename: "Review",
    id: string,
    accountID?: string | null,
    bookingID?: string | null,
    guestName?: string | null,
    guestId?: string | null,
    reason?: Array< string | null > | null,
    value?: string | null,
    userID?: string | null,
    orderID?: string | null,
    rate?: number | null,
    satisfaction?: string | null,
    thoughts?: string | null,
    itemReviews?:  Array< {
      __typename: "ItemReview",
      itemID: string,
      rate?: number | null,
      satisfaction?: string | null,
      multiLanguages?: string | null,
    } | null > | null,
    photos?: Array< string | null > | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnCreateAccountSubscription = {
  onCreateAccount?:  {
    __typename: "Account",
    id: string,
    logo?: string | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    callCenterEmployeesIds?: Array< string | null > | null,
    kpiReportResetFrequency?: string | null,
    agentBarResetFrequency?: string | null,
    dynamicStatus?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnUpdateAccountSubscription = {
  onUpdateAccount?:  {
    __typename: "Account",
    id: string,
    logo?: string | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    callCenterEmployeesIds?: Array< string | null > | null,
    kpiReportResetFrequency?: string | null,
    agentBarResetFrequency?: string | null,
    dynamicStatus?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnDeleteAccountSubscription = {
  onDeleteAccount?:  {
    __typename: "Account",
    id: string,
    logo?: string | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    callCenterEmployeesIds?: Array< string | null > | null,
    kpiReportResetFrequency?: string | null,
    agentBarResetFrequency?: string | null,
    dynamicStatus?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFeatureSubscriptionVariables = {
  filter?: ModelSubscriptionFeatureFilterInput | null,
};

export type OnCreateFeatureSubscription = {
  onCreateFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFeatureSubscriptionVariables = {
  filter?: ModelSubscriptionFeatureFilterInput | null,
};

export type OnUpdateFeatureSubscription = {
  onUpdateFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFeatureSubscriptionVariables = {
  filter?: ModelSubscriptionFeatureFilterInput | null,
};

export type OnDeleteFeatureSubscription = {
  onDeleteFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMediaSubscriptionVariables = {
  filter?: ModelSubscriptionMediaFilterInput | null,
};

export type OnCreateMediaSubscription = {
  onCreateMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMediaSubscriptionVariables = {
  filter?: ModelSubscriptionMediaFilterInput | null,
};

export type OnUpdateMediaSubscription = {
  onUpdateMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMediaSubscriptionVariables = {
  filter?: ModelSubscriptionMediaFilterInput | null,
};

export type OnDeleteMediaSubscription = {
  onDeleteMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateConceptSubscriptionVariables = {
  filter?: ModelSubscriptionConceptFilterInput | null,
};

export type OnCreateConceptSubscription = {
  onCreateConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    kiosks?: Array< string | null > | null,
    merchantID?: string | null,
    merchantUsername?: string | null,
    merchantPassword?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    simphonyServerBaseURL?: string | null,
    simphonyShortOrgName?: string | null,
    simphonyLocRef?: string | null,
    simphonyToken?: string | null,
    revenueCenterID?: number | null,
    employeeID?: number | null,
    tenderID?: number | null,
    tenders?:  {
      __typename: "ModelTenderConnection",
      items:  Array< {
        __typename: "Tender",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptTendersId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderType?:  {
      __typename: "ModelOrderTypeConnection",
      items:  Array< {
        __typename: "OrderType",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptOrderTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    discountID?: number | null,
    useServiceCharge?: boolean | null,
    serviceChargeId?: number | null,
    specialRequestID?: number | null,
    addressID?: number | null,
    simphonyAuthClientId?: string | null,
    simphonyAuthUsername?: string | null,
    simphonyAuthPassword?: string | null,
    simphonyAuthBaseURL?: string | null,
    orderTypeDineIn?: number | null,
    orderTypeTakeAway?: number | null,
    orderTypeDelivery?: number | null,
    simphonyTakeAwayRvc?: number | null,
    simphonyDeliveryRvc?: number | null,
    exportInterval?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    foodicsToken?: string | null,
    enableTableFoodics?: string | null,
    image?: string | null,
    logo?: string | null,
    album?: Array< string | null > | null,
    type?: string | null,
    rating?: number | null,
    location?: string | null,
    description?: string | null,
    areas?: Array< string | null > | null,
    seatTypes?: Array< string | null > | null,
    needAssistance?: boolean | null,
    spaBooking?: boolean | null,
    showerBooking?: boolean | null,
    cigarMenu?: boolean | null,
    precedence?: number | null,
    isPinRequired?: boolean | null,
    viewOnly?: boolean | null,
    openTime?: string | null,
    closeTime?: string | null,
    varifyForAmount?: number | null,
    varifyFirstOrder?: boolean | null,
    varifyNewAddress?: boolean | null,
    sliderImages?: Array< string | null > | null,
    receiveWaiters?: Array< string | null > | null,
    WaiterNotificationOption?: string | null,
    applyLoyalty?: boolean | null,
    rewardPoints?: number | null,
    moneySpent?: number | null,
    afterVat?: boolean | null,
    afterDeliveryFee?: boolean | null,
    beforePromoCode?: boolean | null,
    pointsCalcWithPromoCode?: boolean | null,
    isBusy?: boolean | null,
    allowOnlineOrdering?: boolean | null,
    minOrderVal?: number | null,
    vatPercentage?: number | null,
    serviceChargePercentage?: number | null,
    addDeliveryToVat?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateConceptSubscriptionVariables = {
  filter?: ModelSubscriptionConceptFilterInput | null,
};

export type OnUpdateConceptSubscription = {
  onUpdateConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    kiosks?: Array< string | null > | null,
    merchantID?: string | null,
    merchantUsername?: string | null,
    merchantPassword?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    simphonyServerBaseURL?: string | null,
    simphonyShortOrgName?: string | null,
    simphonyLocRef?: string | null,
    simphonyToken?: string | null,
    revenueCenterID?: number | null,
    employeeID?: number | null,
    tenderID?: number | null,
    tenders?:  {
      __typename: "ModelTenderConnection",
      items:  Array< {
        __typename: "Tender",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptTendersId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderType?:  {
      __typename: "ModelOrderTypeConnection",
      items:  Array< {
        __typename: "OrderType",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptOrderTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    discountID?: number | null,
    useServiceCharge?: boolean | null,
    serviceChargeId?: number | null,
    specialRequestID?: number | null,
    addressID?: number | null,
    simphonyAuthClientId?: string | null,
    simphonyAuthUsername?: string | null,
    simphonyAuthPassword?: string | null,
    simphonyAuthBaseURL?: string | null,
    orderTypeDineIn?: number | null,
    orderTypeTakeAway?: number | null,
    orderTypeDelivery?: number | null,
    simphonyTakeAwayRvc?: number | null,
    simphonyDeliveryRvc?: number | null,
    exportInterval?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    foodicsToken?: string | null,
    enableTableFoodics?: string | null,
    image?: string | null,
    logo?: string | null,
    album?: Array< string | null > | null,
    type?: string | null,
    rating?: number | null,
    location?: string | null,
    description?: string | null,
    areas?: Array< string | null > | null,
    seatTypes?: Array< string | null > | null,
    needAssistance?: boolean | null,
    spaBooking?: boolean | null,
    showerBooking?: boolean | null,
    cigarMenu?: boolean | null,
    precedence?: number | null,
    isPinRequired?: boolean | null,
    viewOnly?: boolean | null,
    openTime?: string | null,
    closeTime?: string | null,
    varifyForAmount?: number | null,
    varifyFirstOrder?: boolean | null,
    varifyNewAddress?: boolean | null,
    sliderImages?: Array< string | null > | null,
    receiveWaiters?: Array< string | null > | null,
    WaiterNotificationOption?: string | null,
    applyLoyalty?: boolean | null,
    rewardPoints?: number | null,
    moneySpent?: number | null,
    afterVat?: boolean | null,
    afterDeliveryFee?: boolean | null,
    beforePromoCode?: boolean | null,
    pointsCalcWithPromoCode?: boolean | null,
    isBusy?: boolean | null,
    allowOnlineOrdering?: boolean | null,
    minOrderVal?: number | null,
    vatPercentage?: number | null,
    serviceChargePercentage?: number | null,
    addDeliveryToVat?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteConceptSubscriptionVariables = {
  filter?: ModelSubscriptionConceptFilterInput | null,
};

export type OnDeleteConceptSubscription = {
  onDeleteConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    kiosks?: Array< string | null > | null,
    merchantID?: string | null,
    merchantUsername?: string | null,
    merchantPassword?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    simphonyServerBaseURL?: string | null,
    simphonyShortOrgName?: string | null,
    simphonyLocRef?: string | null,
    simphonyToken?: string | null,
    revenueCenterID?: number | null,
    employeeID?: number | null,
    tenderID?: number | null,
    tenders?:  {
      __typename: "ModelTenderConnection",
      items:  Array< {
        __typename: "Tender",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptTendersId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderType?:  {
      __typename: "ModelOrderTypeConnection",
      items:  Array< {
        __typename: "OrderType",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptOrderTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    discountID?: number | null,
    useServiceCharge?: boolean | null,
    serviceChargeId?: number | null,
    specialRequestID?: number | null,
    addressID?: number | null,
    simphonyAuthClientId?: string | null,
    simphonyAuthUsername?: string | null,
    simphonyAuthPassword?: string | null,
    simphonyAuthBaseURL?: string | null,
    orderTypeDineIn?: number | null,
    orderTypeTakeAway?: number | null,
    orderTypeDelivery?: number | null,
    simphonyTakeAwayRvc?: number | null,
    simphonyDeliveryRvc?: number | null,
    exportInterval?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    foodicsToken?: string | null,
    enableTableFoodics?: string | null,
    image?: string | null,
    logo?: string | null,
    album?: Array< string | null > | null,
    type?: string | null,
    rating?: number | null,
    location?: string | null,
    description?: string | null,
    areas?: Array< string | null > | null,
    seatTypes?: Array< string | null > | null,
    needAssistance?: boolean | null,
    spaBooking?: boolean | null,
    showerBooking?: boolean | null,
    cigarMenu?: boolean | null,
    precedence?: number | null,
    isPinRequired?: boolean | null,
    viewOnly?: boolean | null,
    openTime?: string | null,
    closeTime?: string | null,
    varifyForAmount?: number | null,
    varifyFirstOrder?: boolean | null,
    varifyNewAddress?: boolean | null,
    sliderImages?: Array< string | null > | null,
    receiveWaiters?: Array< string | null > | null,
    WaiterNotificationOption?: string | null,
    applyLoyalty?: boolean | null,
    rewardPoints?: number | null,
    moneySpent?: number | null,
    afterVat?: boolean | null,
    afterDeliveryFee?: boolean | null,
    beforePromoCode?: boolean | null,
    pointsCalcWithPromoCode?: boolean | null,
    isBusy?: boolean | null,
    allowOnlineOrdering?: boolean | null,
    minOrderVal?: number | null,
    vatPercentage?: number | null,
    serviceChargePercentage?: number | null,
    addDeliveryToVat?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTenderSubscriptionVariables = {
  filter?: ModelSubscriptionTenderFilterInput | null,
};

export type OnCreateTenderSubscription = {
  onCreateTender?:  {
    __typename: "Tender",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptTendersId?: string | null,
  } | null,
};

export type OnUpdateTenderSubscriptionVariables = {
  filter?: ModelSubscriptionTenderFilterInput | null,
};

export type OnUpdateTenderSubscription = {
  onUpdateTender?:  {
    __typename: "Tender",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptTendersId?: string | null,
  } | null,
};

export type OnDeleteTenderSubscriptionVariables = {
  filter?: ModelSubscriptionTenderFilterInput | null,
};

export type OnDeleteTenderSubscription = {
  onDeleteTender?:  {
    __typename: "Tender",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptTendersId?: string | null,
  } | null,
};

export type OnCreateOrderTypeSubscriptionVariables = {
  filter?: ModelSubscriptionOrderTypeFilterInput | null,
};

export type OnCreateOrderTypeSubscription = {
  onCreateOrderType?:  {
    __typename: "OrderType",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptOrderTypeId?: string | null,
  } | null,
};

export type OnUpdateOrderTypeSubscriptionVariables = {
  filter?: ModelSubscriptionOrderTypeFilterInput | null,
};

export type OnUpdateOrderTypeSubscription = {
  onUpdateOrderType?:  {
    __typename: "OrderType",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptOrderTypeId?: string | null,
  } | null,
};

export type OnDeleteOrderTypeSubscriptionVariables = {
  filter?: ModelSubscriptionOrderTypeFilterInput | null,
};

export type OnDeleteOrderTypeSubscription = {
  onDeleteOrderType?:  {
    __typename: "OrderType",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptOrderTypeId?: string | null,
  } | null,
};

export type OnCreateParentConceptSubscriptionVariables = {
  filter?: ModelSubscriptionParentConceptFilterInput | null,
};

export type OnCreateParentConceptSubscription = {
  onCreateParentConcept?:  {
    __typename: "ParentConcept",
    id: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    image?: string | null,
    name?: string | null,
    logo?: string | null,
    type?: string | null,
    venues?: Array< string | null > | null,
    index?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateParentConceptSubscriptionVariables = {
  filter?: ModelSubscriptionParentConceptFilterInput | null,
};

export type OnUpdateParentConceptSubscription = {
  onUpdateParentConcept?:  {
    __typename: "ParentConcept",
    id: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    image?: string | null,
    name?: string | null,
    logo?: string | null,
    type?: string | null,
    venues?: Array< string | null > | null,
    index?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteParentConceptSubscriptionVariables = {
  filter?: ModelSubscriptionParentConceptFilterInput | null,
};

export type OnDeleteParentConceptSubscription = {
  onDeleteParentConcept?:  {
    __typename: "ParentConcept",
    id: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    image?: string | null,
    name?: string | null,
    logo?: string | null,
    type?: string | null,
    venues?: Array< string | null > | null,
    index?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePlanItemSubscriptionVariables = {
  filter?: ModelSubscriptionPlanItemFilterInput | null,
};

export type OnCreatePlanItemSubscription = {
  onCreatePlanItem?:  {
    __typename: "PlanItem",
    id: string,
    areaID: string,
    conceptID?: string | null,
    xPosition: number,
    yPosition: number,
    name?: string | null,
    isReserved?: boolean | null,
    bookings?: Array< string | null > | null,
    table?: string | null,
    object?: string | null,
    lockDays?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePlanItemSubscriptionVariables = {
  filter?: ModelSubscriptionPlanItemFilterInput | null,
};

export type OnUpdatePlanItemSubscription = {
  onUpdatePlanItem?:  {
    __typename: "PlanItem",
    id: string,
    areaID: string,
    conceptID?: string | null,
    xPosition: number,
    yPosition: number,
    name?: string | null,
    isReserved?: boolean | null,
    bookings?: Array< string | null > | null,
    table?: string | null,
    object?: string | null,
    lockDays?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePlanItemSubscriptionVariables = {
  filter?: ModelSubscriptionPlanItemFilterInput | null,
};

export type OnDeletePlanItemSubscription = {
  onDeletePlanItem?:  {
    __typename: "PlanItem",
    id: string,
    areaID: string,
    conceptID?: string | null,
    xPosition: number,
    yPosition: number,
    name?: string | null,
    isReserved?: boolean | null,
    bookings?: Array< string | null > | null,
    table?: string | null,
    object?: string | null,
    lockDays?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateObjectSubscriptionVariables = {
  filter?: ModelSubscriptionObjectFilterInput | null,
};

export type OnCreateObjectSubscription = {
  onCreateObject?:  {
    __typename: "Object",
    id: string,
    conceptID: string,
    label: string,
    name: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    width?: number | null,
    height?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    objectImageId?: string | null,
  } | null,
};

export type OnUpdateObjectSubscriptionVariables = {
  filter?: ModelSubscriptionObjectFilterInput | null,
};

export type OnUpdateObjectSubscription = {
  onUpdateObject?:  {
    __typename: "Object",
    id: string,
    conceptID: string,
    label: string,
    name: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    width?: number | null,
    height?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    objectImageId?: string | null,
  } | null,
};

export type OnDeleteObjectSubscriptionVariables = {
  filter?: ModelSubscriptionObjectFilterInput | null,
};

export type OnDeleteObjectSubscription = {
  onDeleteObject?:  {
    __typename: "Object",
    id: string,
    conceptID: string,
    label: string,
    name: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    width?: number | null,
    height?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    objectImageId?: string | null,
  } | null,
};

export type OnCreateBookingSubscriptionVariables = {
  filter?: ModelSubscriptionBookingFilterInput | null,
};

export type OnCreateBookingSubscription = {
  onCreateBooking?:  {
    __typename: "Booking",
    id: string,
    depositValue?: string | null,
    depositStatus?: boolean | null,
    eventStatus?: boolean | null,
    eventName?: string | null,
    paymentStatus?: boolean | null,
    paymentValue?: string | null,
    disablePartialPayment?: boolean | null,
    disablePayment?: boolean | null,
    eventDate?: string | null,
    eventGuests?: Array< string | null > | null,
    timeSlotID?: string | null,
    timeSlots?: Array< string | null > | null,
    conceptID?: string | null,
    mainGuest: string,
    customerName?: string | null,
    customerPhone?: string | null,
    customerGroup?: string | null,
    accompaniedCount?: number | null,
    channel?: string | null,
    statusID?: string | null,
    rating?: number | null,
    review?: Array< string | null > | null,
    confirmationHash?: string | null,
    isConfirmedByUser?: boolean | null,
    date?: string | null,
    tables?: Array< string | null > | null,
    tableName?: string | null,
    comments?: Array< string | null > | null,
    lastComment?: string | null,
    commentCreatedBy?: string | null,
    commentCreatedDate?: string | null,
    timeLeft?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBookingSubscriptionVariables = {
  filter?: ModelSubscriptionBookingFilterInput | null,
};

export type OnUpdateBookingSubscription = {
  onUpdateBooking?:  {
    __typename: "Booking",
    id: string,
    depositValue?: string | null,
    depositStatus?: boolean | null,
    eventStatus?: boolean | null,
    eventName?: string | null,
    paymentStatus?: boolean | null,
    paymentValue?: string | null,
    disablePartialPayment?: boolean | null,
    disablePayment?: boolean | null,
    eventDate?: string | null,
    eventGuests?: Array< string | null > | null,
    timeSlotID?: string | null,
    timeSlots?: Array< string | null > | null,
    conceptID?: string | null,
    mainGuest: string,
    customerName?: string | null,
    customerPhone?: string | null,
    customerGroup?: string | null,
    accompaniedCount?: number | null,
    channel?: string | null,
    statusID?: string | null,
    rating?: number | null,
    review?: Array< string | null > | null,
    confirmationHash?: string | null,
    isConfirmedByUser?: boolean | null,
    date?: string | null,
    tables?: Array< string | null > | null,
    tableName?: string | null,
    comments?: Array< string | null > | null,
    lastComment?: string | null,
    commentCreatedBy?: string | null,
    commentCreatedDate?: string | null,
    timeLeft?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBookingSubscriptionVariables = {
  filter?: ModelSubscriptionBookingFilterInput | null,
};

export type OnDeleteBookingSubscription = {
  onDeleteBooking?:  {
    __typename: "Booking",
    id: string,
    depositValue?: string | null,
    depositStatus?: boolean | null,
    eventStatus?: boolean | null,
    eventName?: string | null,
    paymentStatus?: boolean | null,
    paymentValue?: string | null,
    disablePartialPayment?: boolean | null,
    disablePayment?: boolean | null,
    eventDate?: string | null,
    eventGuests?: Array< string | null > | null,
    timeSlotID?: string | null,
    timeSlots?: Array< string | null > | null,
    conceptID?: string | null,
    mainGuest: string,
    customerName?: string | null,
    customerPhone?: string | null,
    customerGroup?: string | null,
    accompaniedCount?: number | null,
    channel?: string | null,
    statusID?: string | null,
    rating?: number | null,
    review?: Array< string | null > | null,
    confirmationHash?: string | null,
    isConfirmedByUser?: boolean | null,
    date?: string | null,
    tables?: Array< string | null > | null,
    tableName?: string | null,
    comments?: Array< string | null > | null,
    lastComment?: string | null,
    commentCreatedBy?: string | null,
    commentCreatedDate?: string | null,
    timeLeft?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTimelineSubscriptionVariables = {
  filter?: ModelSubscriptionTimelineFilterInput | null,
};

export type OnCreateTimelineSubscription = {
  onCreateTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    transactionId?: string | null,
    customerId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTimelineSubscriptionVariables = {
  filter?: ModelSubscriptionTimelineFilterInput | null,
};

export type OnUpdateTimelineSubscription = {
  onUpdateTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    transactionId?: string | null,
    customerId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTimelineSubscriptionVariables = {
  filter?: ModelSubscriptionTimelineFilterInput | null,
};

export type OnDeleteTimelineSubscription = {
  onDeleteTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    transactionId?: string | null,
    customerId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCallSubscriptionVariables = {
  filter?: ModelSubscriptionCallFilterInput | null,
};

export type OnCreateCallSubscription = {
  onCreateCall?:  {
    __typename: "Call",
    id: string,
    phone_number?: string | null,
    customer?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    endedAt?: string | null,
    duration?: string | null,
    date: string,
    employeeId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    callCustomerId?: string | null,
  } | null,
};

export type OnUpdateCallSubscriptionVariables = {
  filter?: ModelSubscriptionCallFilterInput | null,
};

export type OnUpdateCallSubscription = {
  onUpdateCall?:  {
    __typename: "Call",
    id: string,
    phone_number?: string | null,
    customer?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    endedAt?: string | null,
    duration?: string | null,
    date: string,
    employeeId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    callCustomerId?: string | null,
  } | null,
};

export type OnDeleteCallSubscriptionVariables = {
  filter?: ModelSubscriptionCallFilterInput | null,
};

export type OnDeleteCallSubscription = {
  onDeleteCall?:  {
    __typename: "Call",
    id: string,
    phone_number?: string | null,
    customer?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    endedAt?: string | null,
    duration?: string | null,
    date: string,
    employeeId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    callCustomerId?: string | null,
  } | null,
};

export type OnCreateAdminRoleSubscriptionVariables = {
  filter?: ModelSubscriptionAdminRoleFilterInput | null,
};

export type OnCreateAdminRoleSubscription = {
  onCreateAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAdminRoleSubscriptionVariables = {
  filter?: ModelSubscriptionAdminRoleFilterInput | null,
};

export type OnUpdateAdminRoleSubscription = {
  onUpdateAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAdminRoleSubscriptionVariables = {
  filter?: ModelSubscriptionAdminRoleFilterInput | null,
};

export type OnDeleteAdminRoleSubscription = {
  onDeleteAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAdminGroupSubscriptionVariables = {
  filter?: ModelSubscriptionAdminGroupFilterInput | null,
};

export type OnCreateAdminGroupSubscription = {
  onCreateAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAdminGroupSubscriptionVariables = {
  filter?: ModelSubscriptionAdminGroupFilterInput | null,
};

export type OnUpdateAdminGroupSubscription = {
  onUpdateAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAdminGroupSubscriptionVariables = {
  filter?: ModelSubscriptionAdminGroupFilterInput | null,
};

export type OnDeleteAdminGroupSubscription = {
  onDeleteAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserConceptsSubscriptionVariables = {
  filter?: ModelSubscriptionUserConceptsFilterInput | null,
};

export type OnCreateUserConceptsSubscription = {
  onCreateUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserConceptsSubscriptionVariables = {
  filter?: ModelSubscriptionUserConceptsFilterInput | null,
};

export type OnUpdateUserConceptsSubscription = {
  onUpdateUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserConceptsSubscriptionVariables = {
  filter?: ModelSubscriptionUserConceptsFilterInput | null,
};

export type OnDeleteUserConceptsSubscription = {
  onDeleteUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionFilterInput | null,
};

export type OnCreateTransactionSubscription = {
  onCreateTransaction?:  {
    __typename: "Transaction",
    id: string,
    transactionID: string,
    date: string,
    guestName: string,
    guestPhone: string,
    guestID: string,
    guestGroup: string,
    guestsNames?: Array< string | null > | null,
    bookingID?: string | null,
    bookingDate?: string | null,
    amount_cents: string,
    status: boolean,
    failureReason?: string | null,
    type: string,
    timeSlots?: Array< string | null > | null,
    tables?: Array< string | null > | null,
    conceptID: string,
    currency: string,
    refund: boolean,
    reference_orderID?: string | null,
    ownerID: string,
    refunded_amount_cents: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionFilterInput | null,
};

export type OnUpdateTransactionSubscription = {
  onUpdateTransaction?:  {
    __typename: "Transaction",
    id: string,
    transactionID: string,
    date: string,
    guestName: string,
    guestPhone: string,
    guestID: string,
    guestGroup: string,
    guestsNames?: Array< string | null > | null,
    bookingID?: string | null,
    bookingDate?: string | null,
    amount_cents: string,
    status: boolean,
    failureReason?: string | null,
    type: string,
    timeSlots?: Array< string | null > | null,
    tables?: Array< string | null > | null,
    conceptID: string,
    currency: string,
    refund: boolean,
    reference_orderID?: string | null,
    ownerID: string,
    refunded_amount_cents: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionFilterInput | null,
};

export type OnDeleteTransactionSubscription = {
  onDeleteTransaction?:  {
    __typename: "Transaction",
    id: string,
    transactionID: string,
    date: string,
    guestName: string,
    guestPhone: string,
    guestID: string,
    guestGroup: string,
    guestsNames?: Array< string | null > | null,
    bookingID?: string | null,
    bookingDate?: string | null,
    amount_cents: string,
    status: boolean,
    failureReason?: string | null,
    type: string,
    timeSlots?: Array< string | null > | null,
    tables?: Array< string | null > | null,
    conceptID: string,
    currency: string,
    refund: boolean,
    reference_orderID?: string | null,
    ownerID: string,
    refunded_amount_cents: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePaymentStatSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentStatFilterInput | null,
};

export type OnCreatePaymentStatSubscription = {
  onCreatePaymentStat?:  {
    __typename: "PaymentStat",
    id: string,
    accountID?: string | null,
    conceptID: string,
    year: number,
    month: number,
    monthName: string,
    earning: number,
    refund: number,
    earningTrxCount: number,
    refundTrxCount: number,
    deleted?: string | null,
    createdAt: string,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePaymentStatSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentStatFilterInput | null,
};

export type OnUpdatePaymentStatSubscription = {
  onUpdatePaymentStat?:  {
    __typename: "PaymentStat",
    id: string,
    accountID?: string | null,
    conceptID: string,
    year: number,
    month: number,
    monthName: string,
    earning: number,
    refund: number,
    earningTrxCount: number,
    refundTrxCount: number,
    deleted?: string | null,
    createdAt: string,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePaymentStatSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentStatFilterInput | null,
};

export type OnDeletePaymentStatSubscription = {
  onDeletePaymentStat?:  {
    __typename: "PaymentStat",
    id: string,
    accountID?: string | null,
    conceptID: string,
    year: number,
    month: number,
    monthName: string,
    earning: number,
    refund: number,
    earningTrxCount: number,
    refundTrxCount: number,
    deleted?: string | null,
    createdAt: string,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateBookingGuestSubscriptionVariables = {
  filter?: ModelSubscriptionBookingGuestFilterInput | null,
};

export type OnCreateBookingGuestSubscription = {
  onCreateBookingGuest?:  {
    __typename: "BookingGuest",
    id: string,
    bookingID: string,
    guestName: string,
    paidAmount: number,
    paymentAmount: number,
    paymentStatus: boolean,
    transactionID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBookingGuestSubscriptionVariables = {
  filter?: ModelSubscriptionBookingGuestFilterInput | null,
};

export type OnUpdateBookingGuestSubscription = {
  onUpdateBookingGuest?:  {
    __typename: "BookingGuest",
    id: string,
    bookingID: string,
    guestName: string,
    paidAmount: number,
    paymentAmount: number,
    paymentStatus: boolean,
    transactionID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBookingGuestSubscriptionVariables = {
  filter?: ModelSubscriptionBookingGuestFilterInput | null,
};

export type OnDeleteBookingGuestSubscription = {
  onDeleteBookingGuest?:  {
    __typename: "BookingGuest",
    id: string,
    bookingID: string,
    guestName: string,
    paidAmount: number,
    paymentAmount: number,
    paymentStatus: boolean,
    transactionID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    conceptID: string,
    preparationAreaID?: string | null,
    subCategory?: string | null,
    name: string,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    darkImage?: string | null,
    menuItems?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        conceptID: string,
        kioskID?: string | null,
        name: string,
        description?: string | null,
        image?: string | null,
        precedence?: string | null,
        categoryID: string,
        categoryName?: string | null,
        outOfStock?: boolean | null,
        requiredPoints?: number | null,
        symphonyID?: string | null,
        comboItemID?: string | null,
        ratings?: Array< string | null > | null,
        prices?: Array< string | null > | null,
        choiceGroups?: Array< string | null > | null,
        choiceGroupsRules?:  Array< {
          __typename: "ChoiceGroupRules",
          id: string,
          minNumberOfChoices?: number | null,
          maxNumberOfChoices?: number | null,
        } | null > | null,
        ExtraChoiceGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        hasLimitedQuantity?: boolean | null,
        quantity?: number | null,
        autoRestock?: boolean | null,
        restockQuantity?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        quantityOrdered?: number | null,
        performancePerDay?: Array< string | null > | null,
        multiLanguages?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    precedence: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    symphonyID?: string | null,
    isHidden?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    conceptID: string,
    preparationAreaID?: string | null,
    subCategory?: string | null,
    name: string,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    darkImage?: string | null,
    menuItems?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        conceptID: string,
        kioskID?: string | null,
        name: string,
        description?: string | null,
        image?: string | null,
        precedence?: string | null,
        categoryID: string,
        categoryName?: string | null,
        outOfStock?: boolean | null,
        requiredPoints?: number | null,
        symphonyID?: string | null,
        comboItemID?: string | null,
        ratings?: Array< string | null > | null,
        prices?: Array< string | null > | null,
        choiceGroups?: Array< string | null > | null,
        choiceGroupsRules?:  Array< {
          __typename: "ChoiceGroupRules",
          id: string,
          minNumberOfChoices?: number | null,
          maxNumberOfChoices?: number | null,
        } | null > | null,
        ExtraChoiceGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        hasLimitedQuantity?: boolean | null,
        quantity?: number | null,
        autoRestock?: boolean | null,
        restockQuantity?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        quantityOrdered?: number | null,
        performancePerDay?: Array< string | null > | null,
        multiLanguages?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    precedence: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    symphonyID?: string | null,
    isHidden?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    conceptID: string,
    preparationAreaID?: string | null,
    subCategory?: string | null,
    name: string,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    darkImage?: string | null,
    menuItems?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        conceptID: string,
        kioskID?: string | null,
        name: string,
        description?: string | null,
        image?: string | null,
        precedence?: string | null,
        categoryID: string,
        categoryName?: string | null,
        outOfStock?: boolean | null,
        requiredPoints?: number | null,
        symphonyID?: string | null,
        comboItemID?: string | null,
        ratings?: Array< string | null > | null,
        prices?: Array< string | null > | null,
        choiceGroups?: Array< string | null > | null,
        choiceGroupsRules?:  Array< {
          __typename: "ChoiceGroupRules",
          id: string,
          minNumberOfChoices?: number | null,
          maxNumberOfChoices?: number | null,
        } | null > | null,
        ExtraChoiceGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        hasLimitedQuantity?: boolean | null,
        quantity?: number | null,
        autoRestock?: boolean | null,
        restockQuantity?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        quantityOrdered?: number | null,
        performancePerDay?: Array< string | null > | null,
        multiLanguages?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    precedence: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    symphonyID?: string | null,
    isHidden?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMenuItemSubscriptionVariables = {
  filter?: ModelSubscriptionMenuItemFilterInput | null,
};

export type OnCreateMenuItemSubscription = {
  onCreateMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    conceptID: string,
    kioskID?: string | null,
    name: string,
    description?: string | null,
    image?: string | null,
    precedence?: string | null,
    categoryID: string,
    categoryName?: string | null,
    outOfStock?: boolean | null,
    requiredPoints?: number | null,
    symphonyID?: string | null,
    comboItemID?: string | null,
    ratings?: Array< string | null > | null,
    prices?: Array< string | null > | null,
    choiceGroups?: Array< string | null > | null,
    choiceGroupsRules?:  Array< {
      __typename: "ChoiceGroupRules",
      id: string,
      minNumberOfChoices?: number | null,
      maxNumberOfChoices?: number | null,
    } | null > | null,
    ExtraChoiceGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    hasLimitedQuantity?: boolean | null,
    quantity?: number | null,
    autoRestock?: boolean | null,
    restockQuantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    quantityOrdered?: number | null,
    performancePerDay?: Array< string | null > | null,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMenuItemSubscriptionVariables = {
  filter?: ModelSubscriptionMenuItemFilterInput | null,
};

export type OnUpdateMenuItemSubscription = {
  onUpdateMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    conceptID: string,
    kioskID?: string | null,
    name: string,
    description?: string | null,
    image?: string | null,
    precedence?: string | null,
    categoryID: string,
    categoryName?: string | null,
    outOfStock?: boolean | null,
    requiredPoints?: number | null,
    symphonyID?: string | null,
    comboItemID?: string | null,
    ratings?: Array< string | null > | null,
    prices?: Array< string | null > | null,
    choiceGroups?: Array< string | null > | null,
    choiceGroupsRules?:  Array< {
      __typename: "ChoiceGroupRules",
      id: string,
      minNumberOfChoices?: number | null,
      maxNumberOfChoices?: number | null,
    } | null > | null,
    ExtraChoiceGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    hasLimitedQuantity?: boolean | null,
    quantity?: number | null,
    autoRestock?: boolean | null,
    restockQuantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    quantityOrdered?: number | null,
    performancePerDay?: Array< string | null > | null,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMenuItemSubscriptionVariables = {
  filter?: ModelSubscriptionMenuItemFilterInput | null,
};

export type OnDeleteMenuItemSubscription = {
  onDeleteMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    conceptID: string,
    kioskID?: string | null,
    name: string,
    description?: string | null,
    image?: string | null,
    precedence?: string | null,
    categoryID: string,
    categoryName?: string | null,
    outOfStock?: boolean | null,
    requiredPoints?: number | null,
    symphonyID?: string | null,
    comboItemID?: string | null,
    ratings?: Array< string | null > | null,
    prices?: Array< string | null > | null,
    choiceGroups?: Array< string | null > | null,
    choiceGroupsRules?:  Array< {
      __typename: "ChoiceGroupRules",
      id: string,
      minNumberOfChoices?: number | null,
      maxNumberOfChoices?: number | null,
    } | null > | null,
    ExtraChoiceGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    hasLimitedQuantity?: boolean | null,
    quantity?: number | null,
    autoRestock?: boolean | null,
    restockQuantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    quantityOrdered?: number | null,
    performancePerDay?: Array< string | null > | null,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePriceSubscriptionVariables = {
  filter?: ModelSubscriptionPriceFilterInput | null,
};

export type OnCreatePriceSubscription = {
  onCreatePrice?:  {
    __typename: "Price",
    id: string,
    conceptID: string,
    name: string,
    multiLanguages?: string | null,
    price: number,
    symphonyID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePriceSubscriptionVariables = {
  filter?: ModelSubscriptionPriceFilterInput | null,
};

export type OnUpdatePriceSubscription = {
  onUpdatePrice?:  {
    __typename: "Price",
    id: string,
    conceptID: string,
    name: string,
    multiLanguages?: string | null,
    price: number,
    symphonyID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePriceSubscriptionVariables = {
  filter?: ModelSubscriptionPriceFilterInput | null,
};

export type OnDeletePriceSubscription = {
  onDeletePrice?:  {
    __typename: "Price",
    id: string,
    conceptID: string,
    name: string,
    multiLanguages?: string | null,
    price: number,
    symphonyID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateChoiceGroupsSubscriptionVariables = {
  filter?: ModelSubscriptionChoiceGroupsFilterInput | null,
};

export type OnCreateChoiceGroupsSubscription = {
  onCreateChoiceGroups?:  {
    __typename: "ChoiceGroups",
    id: string,
    conceptID: string,
    name: string,
    required?: boolean | null,
    maxNumberOfChoices?: number | null,
    minNumberOfChoices?: number | null,
    precedence?: string | null,
    type?: string | null,
    choices?: Array< string | null > | null,
    choiceItems?:  {
      __typename: "ModelChoiceConnection",
      items:  Array< {
        __typename: "Choice",
        id: string,
        conceptID: string,
        name: string,
        price: number,
        precedence?: string | null,
        choiceGroupID: string,
        choiceGroupName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        symphonyID?: string | null,
        multiLanguages?: string | null,
        definitionNumber?: number | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    simphonyID?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateChoiceGroupsSubscriptionVariables = {
  filter?: ModelSubscriptionChoiceGroupsFilterInput | null,
};

export type OnUpdateChoiceGroupsSubscription = {
  onUpdateChoiceGroups?:  {
    __typename: "ChoiceGroups",
    id: string,
    conceptID: string,
    name: string,
    required?: boolean | null,
    maxNumberOfChoices?: number | null,
    minNumberOfChoices?: number | null,
    precedence?: string | null,
    type?: string | null,
    choices?: Array< string | null > | null,
    choiceItems?:  {
      __typename: "ModelChoiceConnection",
      items:  Array< {
        __typename: "Choice",
        id: string,
        conceptID: string,
        name: string,
        price: number,
        precedence?: string | null,
        choiceGroupID: string,
        choiceGroupName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        symphonyID?: string | null,
        multiLanguages?: string | null,
        definitionNumber?: number | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    simphonyID?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteChoiceGroupsSubscriptionVariables = {
  filter?: ModelSubscriptionChoiceGroupsFilterInput | null,
};

export type OnDeleteChoiceGroupsSubscription = {
  onDeleteChoiceGroups?:  {
    __typename: "ChoiceGroups",
    id: string,
    conceptID: string,
    name: string,
    required?: boolean | null,
    maxNumberOfChoices?: number | null,
    minNumberOfChoices?: number | null,
    precedence?: string | null,
    type?: string | null,
    choices?: Array< string | null > | null,
    choiceItems?:  {
      __typename: "ModelChoiceConnection",
      items:  Array< {
        __typename: "Choice",
        id: string,
        conceptID: string,
        name: string,
        price: number,
        precedence?: string | null,
        choiceGroupID: string,
        choiceGroupName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        symphonyID?: string | null,
        multiLanguages?: string | null,
        definitionNumber?: number | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    simphonyID?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateChoiceSubscriptionVariables = {
  filter?: ModelSubscriptionChoiceFilterInput | null,
};

export type OnCreateChoiceSubscription = {
  onCreateChoice?:  {
    __typename: "Choice",
    id: string,
    conceptID: string,
    name: string,
    price: number,
    precedence?: string | null,
    choiceGroupID: string,
    choiceGroupName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    symphonyID?: string | null,
    multiLanguages?: string | null,
    definitionNumber?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateChoiceSubscriptionVariables = {
  filter?: ModelSubscriptionChoiceFilterInput | null,
};

export type OnUpdateChoiceSubscription = {
  onUpdateChoice?:  {
    __typename: "Choice",
    id: string,
    conceptID: string,
    name: string,
    price: number,
    precedence?: string | null,
    choiceGroupID: string,
    choiceGroupName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    symphonyID?: string | null,
    multiLanguages?: string | null,
    definitionNumber?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteChoiceSubscriptionVariables = {
  filter?: ModelSubscriptionChoiceFilterInput | null,
};

export type OnDeleteChoiceSubscription = {
  onDeleteChoice?:  {
    __typename: "Choice",
    id: string,
    conceptID: string,
    name: string,
    price: number,
    precedence?: string | null,
    choiceGroupID: string,
    choiceGroupName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    symphonyID?: string | null,
    multiLanguages?: string | null,
    definitionNumber?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCartSubscriptionVariables = {
  filter?: ModelSubscriptionCartFilterInput | null,
};

export type OnCreateCartSubscription = {
  onCreateCart?:  {
    __typename: "Cart",
    id: string,
    conceptID: string,
    userID: string,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      symphonyID?: string | null,
      preparationAreaID?: string | null,
      name?: string | null,
      quantity: number,
      choiceIDs?: Array< string | null > | null,
      choiceNames?: Array< string | null > | null,
      choiceSymphonyID?: Array< string | null > | null,
      extraChoiceIDs?: Array< string | null > | null,
      extraChoiceSymphonyID?: Array< string | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      comboItemID?: string | null,
    } | null > | null,
    specialRequest?: string | null,
    deliveryFee?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    totalPrice?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCartSubscriptionVariables = {
  filter?: ModelSubscriptionCartFilterInput | null,
};

export type OnUpdateCartSubscription = {
  onUpdateCart?:  {
    __typename: "Cart",
    id: string,
    conceptID: string,
    userID: string,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      symphonyID?: string | null,
      preparationAreaID?: string | null,
      name?: string | null,
      quantity: number,
      choiceIDs?: Array< string | null > | null,
      choiceNames?: Array< string | null > | null,
      choiceSymphonyID?: Array< string | null > | null,
      extraChoiceIDs?: Array< string | null > | null,
      extraChoiceSymphonyID?: Array< string | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      comboItemID?: string | null,
    } | null > | null,
    specialRequest?: string | null,
    deliveryFee?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    totalPrice?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCartSubscriptionVariables = {
  filter?: ModelSubscriptionCartFilterInput | null,
};

export type OnDeleteCartSubscription = {
  onDeleteCart?:  {
    __typename: "Cart",
    id: string,
    conceptID: string,
    userID: string,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      symphonyID?: string | null,
      preparationAreaID?: string | null,
      name?: string | null,
      quantity: number,
      choiceIDs?: Array< string | null > | null,
      choiceNames?: Array< string | null > | null,
      choiceSymphonyID?: Array< string | null > | null,
      extraChoiceIDs?: Array< string | null > | null,
      extraChoiceSymphonyID?: Array< string | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      comboItemID?: string | null,
    } | null > | null,
    specialRequest?: string | null,
    deliveryFee?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    totalPrice?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAdminStatusSubscriptionVariables = {
  filter?: ModelSubscriptionAdminStatusFilterInput | null,
};

export type OnCreateAdminStatusSubscription = {
  onCreateAdminStatus?:  {
    __typename: "AdminStatus",
    id: string,
    sub: string,
    accountID: string,
    name?: string | null,
    averageCallTime?: number | null,
    numberOfCalls?: number | null,
    idelDuration?: number | null,
    activeDuration?: number | null,
    awayDuration?: number | null,
    lastCallTime?: number | null,
    agentBarAverageCallTime?: number | null,
    agentBarNumberOfCalls?: number | null,
    type?: string | null,
    waiterStatus?: string | null,
    totalAverageRatio?: number | null,
    performancePerDay?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAdminStatusSubscriptionVariables = {
  filter?: ModelSubscriptionAdminStatusFilterInput | null,
};

export type OnUpdateAdminStatusSubscription = {
  onUpdateAdminStatus?:  {
    __typename: "AdminStatus",
    id: string,
    sub: string,
    accountID: string,
    name?: string | null,
    averageCallTime?: number | null,
    numberOfCalls?: number | null,
    idelDuration?: number | null,
    activeDuration?: number | null,
    awayDuration?: number | null,
    lastCallTime?: number | null,
    agentBarAverageCallTime?: number | null,
    agentBarNumberOfCalls?: number | null,
    type?: string | null,
    waiterStatus?: string | null,
    totalAverageRatio?: number | null,
    performancePerDay?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAdminStatusSubscriptionVariables = {
  filter?: ModelSubscriptionAdminStatusFilterInput | null,
};

export type OnDeleteAdminStatusSubscription = {
  onDeleteAdminStatus?:  {
    __typename: "AdminStatus",
    id: string,
    sub: string,
    accountID: string,
    name?: string | null,
    averageCallTime?: number | null,
    numberOfCalls?: number | null,
    idelDuration?: number | null,
    activeDuration?: number | null,
    awayDuration?: number | null,
    lastCallTime?: number | null,
    agentBarAverageCallTime?: number | null,
    agentBarNumberOfCalls?: number | null,
    type?: string | null,
    waiterStatus?: string | null,
    totalAverageRatio?: number | null,
    performancePerDay?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateOnlineOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOnlineOrderFilterInput | null,
};

export type OnCreateOnlineOrderSubscription = {
  onCreateOnlineOrder?:  {
    __typename: "OnlineOrder",
    id: string,
    conceptID: string,
    statusID?: string | null,
    status?: string | null,
    reason?: string | null,
    orderType?: string | null,
    channel?: string | null,
    userID: string,
    userName?: string | null,
    userPhoneNumber?: string | null,
    dispatcherID?: string | null,
    dispatcherName?: string | null,
    cartID?: string | null,
    tenderId?: number | null,
    employeeId?: number | null,
    rvcId?: number | null,
    checkNumber?: string | null,
    totalAmount?: number | null,
    discountedValue?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    tax?: number | null,
    deliveryFee?: number | null,
    orderTime?: string | null,
    executionArn?: string | null,
    specialRequest?: string | null,
    orderAddress?: string | null,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      symphonyID?: string | null,
      preparationAreaID?: string | null,
      name?: string | null,
      quantity: number,
      choiceIDs?: Array< string | null > | null,
      choiceNames?: Array< string | null > | null,
      choiceSymphonyID?: Array< string | null > | null,
      extraChoiceIDs?: Array< string | null > | null,
      extraChoiceSymphonyID?: Array< string | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      comboItemID?: string | null,
    } | null > | null,
    checkRef?: string | null,
    discountID?: number | null,
    amount_cents?: string | null,
    transactionID?: string | null,
    paymentStatus?: boolean | null,
    paymentType?: string | null,
    referenceId?: string | null,
    cardHolderNumber?: string | null,
    cardHolderName?: string | null,
    cardSchemeName?: string | null,
    deleted?: string | null,
    acceptedByWaiterID?: string | null,
    acceptedByWaiterName?: string | null,
    deliveredByWaiterID?: string | null,
    tableID?: string | null,
    tableName?: string | null,
    shift?: string | null,
    parentOrder?: string | null,
    preparationAreaID?: string | null,
    childrenStatus?: Array< string | null > | null,
    createdAt: string,
    acceptedByWaiterAt?: string | null,
    sentToKitchenAt?: string | null,
    acceptedByKitchenAt?: string | null,
    readyAt?: string | null,
    outForDeliveryAt?: string | null,
    deliveredAt?: string | null,
    promoCodeID?: string | null,
    promoCodeName?: string | null,
    promoCodeAppliedTo?: string | null,
    promoCode?:  {
      __typename: "Promocode",
      id: string,
      name: string,
      redemptions: number,
      status?: string | null,
      startDate: string,
      endDate: string,
      appliesTo: string,
      minOrderReq: string,
      minOrderVal?: number | null,
      minOrderQty?: number | null,
      tenderReq: Array< string | null >,
      discountVal: number,
      discountLimit?: number | null,
      quota?: number | null,
      quotaUsed?: number | null,
      totalRevenue?: number | null,
      appliedDiscountedVal?: number | null,
      usePerCustomer?: number | null,
      channelReq: Array< string | null >,
      customerEligibility: string,
      customerReq?: Array< string | null > | null,
      spendingExceeds?: number | null,
      spendingBelow?: number | null,
      orderSinceDate?: string | null,
      orderFromDate?: string | null,
      orderToDate?: string | null,
      isDisplayed?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    reviewID?: string | null,
    review?:  {
      __typename: "Review",
      id: string,
      accountID?: string | null,
      bookingID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    simphEmpID?: string | null,
    createdByID: string,
    createdByName: string,
    tmpField?: string | null,
    updatedByID?: string | null,
    updatedByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateOnlineOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOnlineOrderFilterInput | null,
};

export type OnUpdateOnlineOrderSubscription = {
  onUpdateOnlineOrder?:  {
    __typename: "OnlineOrder",
    id: string,
    conceptID: string,
    statusID?: string | null,
    status?: string | null,
    reason?: string | null,
    orderType?: string | null,
    channel?: string | null,
    userID: string,
    userName?: string | null,
    userPhoneNumber?: string | null,
    dispatcherID?: string | null,
    dispatcherName?: string | null,
    cartID?: string | null,
    tenderId?: number | null,
    employeeId?: number | null,
    rvcId?: number | null,
    checkNumber?: string | null,
    totalAmount?: number | null,
    discountedValue?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    tax?: number | null,
    deliveryFee?: number | null,
    orderTime?: string | null,
    executionArn?: string | null,
    specialRequest?: string | null,
    orderAddress?: string | null,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      symphonyID?: string | null,
      preparationAreaID?: string | null,
      name?: string | null,
      quantity: number,
      choiceIDs?: Array< string | null > | null,
      choiceNames?: Array< string | null > | null,
      choiceSymphonyID?: Array< string | null > | null,
      extraChoiceIDs?: Array< string | null > | null,
      extraChoiceSymphonyID?: Array< string | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      comboItemID?: string | null,
    } | null > | null,
    checkRef?: string | null,
    discountID?: number | null,
    amount_cents?: string | null,
    transactionID?: string | null,
    paymentStatus?: boolean | null,
    paymentType?: string | null,
    referenceId?: string | null,
    cardHolderNumber?: string | null,
    cardHolderName?: string | null,
    cardSchemeName?: string | null,
    deleted?: string | null,
    acceptedByWaiterID?: string | null,
    acceptedByWaiterName?: string | null,
    deliveredByWaiterID?: string | null,
    tableID?: string | null,
    tableName?: string | null,
    shift?: string | null,
    parentOrder?: string | null,
    preparationAreaID?: string | null,
    childrenStatus?: Array< string | null > | null,
    createdAt: string,
    acceptedByWaiterAt?: string | null,
    sentToKitchenAt?: string | null,
    acceptedByKitchenAt?: string | null,
    readyAt?: string | null,
    outForDeliveryAt?: string | null,
    deliveredAt?: string | null,
    promoCodeID?: string | null,
    promoCodeName?: string | null,
    promoCodeAppliedTo?: string | null,
    promoCode?:  {
      __typename: "Promocode",
      id: string,
      name: string,
      redemptions: number,
      status?: string | null,
      startDate: string,
      endDate: string,
      appliesTo: string,
      minOrderReq: string,
      minOrderVal?: number | null,
      minOrderQty?: number | null,
      tenderReq: Array< string | null >,
      discountVal: number,
      discountLimit?: number | null,
      quota?: number | null,
      quotaUsed?: number | null,
      totalRevenue?: number | null,
      appliedDiscountedVal?: number | null,
      usePerCustomer?: number | null,
      channelReq: Array< string | null >,
      customerEligibility: string,
      customerReq?: Array< string | null > | null,
      spendingExceeds?: number | null,
      spendingBelow?: number | null,
      orderSinceDate?: string | null,
      orderFromDate?: string | null,
      orderToDate?: string | null,
      isDisplayed?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    reviewID?: string | null,
    review?:  {
      __typename: "Review",
      id: string,
      accountID?: string | null,
      bookingID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    simphEmpID?: string | null,
    createdByID: string,
    createdByName: string,
    tmpField?: string | null,
    updatedByID?: string | null,
    updatedByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteOnlineOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOnlineOrderFilterInput | null,
};

export type OnDeleteOnlineOrderSubscription = {
  onDeleteOnlineOrder?:  {
    __typename: "OnlineOrder",
    id: string,
    conceptID: string,
    statusID?: string | null,
    status?: string | null,
    reason?: string | null,
    orderType?: string | null,
    channel?: string | null,
    userID: string,
    userName?: string | null,
    userPhoneNumber?: string | null,
    dispatcherID?: string | null,
    dispatcherName?: string | null,
    cartID?: string | null,
    tenderId?: number | null,
    employeeId?: number | null,
    rvcId?: number | null,
    checkNumber?: string | null,
    totalAmount?: number | null,
    discountedValue?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    tax?: number | null,
    deliveryFee?: number | null,
    orderTime?: string | null,
    executionArn?: string | null,
    specialRequest?: string | null,
    orderAddress?: string | null,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      symphonyID?: string | null,
      preparationAreaID?: string | null,
      name?: string | null,
      quantity: number,
      choiceIDs?: Array< string | null > | null,
      choiceNames?: Array< string | null > | null,
      choiceSymphonyID?: Array< string | null > | null,
      extraChoiceIDs?: Array< string | null > | null,
      extraChoiceSymphonyID?: Array< string | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      comboItemID?: string | null,
    } | null > | null,
    checkRef?: string | null,
    discountID?: number | null,
    amount_cents?: string | null,
    transactionID?: string | null,
    paymentStatus?: boolean | null,
    paymentType?: string | null,
    referenceId?: string | null,
    cardHolderNumber?: string | null,
    cardHolderName?: string | null,
    cardSchemeName?: string | null,
    deleted?: string | null,
    acceptedByWaiterID?: string | null,
    acceptedByWaiterName?: string | null,
    deliveredByWaiterID?: string | null,
    tableID?: string | null,
    tableName?: string | null,
    shift?: string | null,
    parentOrder?: string | null,
    preparationAreaID?: string | null,
    childrenStatus?: Array< string | null > | null,
    createdAt: string,
    acceptedByWaiterAt?: string | null,
    sentToKitchenAt?: string | null,
    acceptedByKitchenAt?: string | null,
    readyAt?: string | null,
    outForDeliveryAt?: string | null,
    deliveredAt?: string | null,
    promoCodeID?: string | null,
    promoCodeName?: string | null,
    promoCodeAppliedTo?: string | null,
    promoCode?:  {
      __typename: "Promocode",
      id: string,
      name: string,
      redemptions: number,
      status?: string | null,
      startDate: string,
      endDate: string,
      appliesTo: string,
      minOrderReq: string,
      minOrderVal?: number | null,
      minOrderQty?: number | null,
      tenderReq: Array< string | null >,
      discountVal: number,
      discountLimit?: number | null,
      quota?: number | null,
      quotaUsed?: number | null,
      totalRevenue?: number | null,
      appliedDiscountedVal?: number | null,
      usePerCustomer?: number | null,
      channelReq: Array< string | null >,
      customerEligibility: string,
      customerReq?: Array< string | null > | null,
      spendingExceeds?: number | null,
      spendingBelow?: number | null,
      orderSinceDate?: string | null,
      orderFromDate?: string | null,
      orderToDate?: string | null,
      isDisplayed?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    reviewID?: string | null,
    review?:  {
      __typename: "Review",
      id: string,
      accountID?: string | null,
      bookingID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    simphEmpID?: string | null,
    createdByID: string,
    createdByName: string,
    tmpField?: string | null,
    updatedByID?: string | null,
    updatedByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateOrderStatusSubscriptionVariables = {
  filter?: ModelSubscriptionOrderStatusFilterInput | null,
};

export type OnCreateOrderStatusSubscription = {
  onCreateOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    conceptID: string,
    name: string,
    color?: string | null,
    precedence?: number | null,
    accessibleStatus?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateOrderStatusSubscriptionVariables = {
  filter?: ModelSubscriptionOrderStatusFilterInput | null,
};

export type OnUpdateOrderStatusSubscription = {
  onUpdateOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    conceptID: string,
    name: string,
    color?: string | null,
    precedence?: number | null,
    accessibleStatus?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteOrderStatusSubscriptionVariables = {
  filter?: ModelSubscriptionOrderStatusFilterInput | null,
};

export type OnDeleteOrderStatusSubscription = {
  onDeleteOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    conceptID: string,
    name: string,
    color?: string | null,
    precedence?: number | null,
    accessibleStatus?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDiscountCouponSubscriptionVariables = {
  filter?: ModelSubscriptionDiscountCouponFilterInput | null,
};

export type OnCreateDiscountCouponSubscription = {
  onCreateDiscountCoupon?:  {
    __typename: "DiscountCoupon",
    id: string,
    discountPercent: string,
    usedBy?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDiscountCouponSubscriptionVariables = {
  filter?: ModelSubscriptionDiscountCouponFilterInput | null,
};

export type OnUpdateDiscountCouponSubscription = {
  onUpdateDiscountCoupon?:  {
    __typename: "DiscountCoupon",
    id: string,
    discountPercent: string,
    usedBy?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDiscountCouponSubscriptionVariables = {
  filter?: ModelSubscriptionDiscountCouponFilterInput | null,
};

export type OnDeleteDiscountCouponSubscription = {
  onDeleteDiscountCoupon?:  {
    __typename: "DiscountCoupon",
    id: string,
    discountPercent: string,
    usedBy?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateZoneSubscriptionVariables = {
  filter?: ModelSubscriptionZoneFilterInput | null,
};

export type OnCreateZoneSubscription = {
  onCreateZone?:  {
    __typename: "Zone",
    id: string,
    conceptID: string,
    location: string,
    deliveryFee: number,
    concepts?:  Array< {
      __typename: "ZoneConcept",
      id: string,
      deliveryFee: number,
    } | null > | null,
    polygon?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateZoneSubscriptionVariables = {
  filter?: ModelSubscriptionZoneFilterInput | null,
};

export type OnUpdateZoneSubscription = {
  onUpdateZone?:  {
    __typename: "Zone",
    id: string,
    conceptID: string,
    location: string,
    deliveryFee: number,
    concepts?:  Array< {
      __typename: "ZoneConcept",
      id: string,
      deliveryFee: number,
    } | null > | null,
    polygon?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteZoneSubscriptionVariables = {
  filter?: ModelSubscriptionZoneFilterInput | null,
};

export type OnDeleteZoneSubscription = {
  onDeleteZone?:  {
    __typename: "Zone",
    id: string,
    conceptID: string,
    location: string,
    deliveryFee: number,
    concepts?:  Array< {
      __typename: "ZoneConcept",
      id: string,
      deliveryFee: number,
    } | null > | null,
    polygon?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserIPSubscriptionVariables = {
  filter?: ModelSubscriptionUserIPFilterInput | null,
};

export type OnCreateUserIPSubscription = {
  onCreateUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserIPSubscriptionVariables = {
  filter?: ModelSubscriptionUserIPFilterInput | null,
};

export type OnUpdateUserIPSubscription = {
  onUpdateUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserIPSubscriptionVariables = {
  filter?: ModelSubscriptionUserIPFilterInput | null,
};

export type OnDeleteUserIPSubscription = {
  onDeleteUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMainCategorySubscriptionVariables = {
  filter?: ModelSubscriptionMainCategoryFilterInput | null,
};

export type OnCreateMainCategorySubscription = {
  onCreateMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMainCategorySubscriptionVariables = {
  filter?: ModelSubscriptionMainCategoryFilterInput | null,
};

export type OnUpdateMainCategorySubscription = {
  onUpdateMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMainCategorySubscriptionVariables = {
  filter?: ModelSubscriptionMainCategoryFilterInput | null,
};

export type OnDeleteMainCategorySubscription = {
  onDeleteMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSubCategorySubscriptionVariables = {
  filter?: ModelSubscriptionSubCategoryFilterInput | null,
};

export type OnCreateSubCategorySubscription = {
  onCreateSubCategory?:  {
    __typename: "SubCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    mainCategoryID?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSubCategorySubscriptionVariables = {
  filter?: ModelSubscriptionSubCategoryFilterInput | null,
};

export type OnUpdateSubCategorySubscription = {
  onUpdateSubCategory?:  {
    __typename: "SubCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    mainCategoryID?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSubCategorySubscriptionVariables = {
  filter?: ModelSubscriptionSubCategoryFilterInput | null,
};

export type OnDeleteSubCategorySubscription = {
  onDeleteSubCategory?:  {
    __typename: "SubCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    mainCategoryID?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateShiftSubscriptionVariables = {
  filter?: ModelSubscriptionShiftFilterInput | null,
};

export type OnCreateShiftSubscription = {
  onCreateShift?:  {
    __typename: "Shift",
    id: string,
    accountID: string,
    name: string,
    precedence: string,
    startTime: string,
    endTime: string,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateShiftSubscriptionVariables = {
  filter?: ModelSubscriptionShiftFilterInput | null,
};

export type OnUpdateShiftSubscription = {
  onUpdateShift?:  {
    __typename: "Shift",
    id: string,
    accountID: string,
    name: string,
    precedence: string,
    startTime: string,
    endTime: string,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteShiftSubscriptionVariables = {
  filter?: ModelSubscriptionShiftFilterInput | null,
};

export type OnDeleteShiftSubscription = {
  onDeleteShift?:  {
    __typename: "Shift",
    id: string,
    accountID: string,
    name: string,
    precedence: string,
    startTime: string,
    endTime: string,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateChannelSubscriptionVariables = {
  filter?: ModelSubscriptionChannelFilterInput | null,
};

export type OnCreateChannelSubscription = {
  onCreateChannel?:  {
    __typename: "Channel",
    id: string,
    name: string,
    description?: string | null,
    numberOfOrders?: number | null,
    isActive?: boolean | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateChannelSubscriptionVariables = {
  filter?: ModelSubscriptionChannelFilterInput | null,
};

export type OnUpdateChannelSubscription = {
  onUpdateChannel?:  {
    __typename: "Channel",
    id: string,
    name: string,
    description?: string | null,
    numberOfOrders?: number | null,
    isActive?: boolean | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteChannelSubscriptionVariables = {
  filter?: ModelSubscriptionChannelFilterInput | null,
};

export type OnDeleteChannelSubscription = {
  onDeleteChannel?:  {
    __typename: "Channel",
    id: string,
    name: string,
    description?: string | null,
    numberOfOrders?: number | null,
    isActive?: boolean | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePromocodeSubscriptionVariables = {
  filter?: ModelSubscriptionPromocodeFilterInput | null,
};

export type OnCreatePromocodeSubscription = {
  onCreatePromocode?:  {
    __typename: "Promocode",
    id: string,
    name: string,
    redemptions: number,
    status?: string | null,
    startDate: string,
    endDate: string,
    appliesTo: string,
    minOrderReq: string,
    minOrderVal?: number | null,
    minOrderQty?: number | null,
    tenderReq: Array< string | null >,
    discountVal: number,
    discountLimit?: number | null,
    quota?: number | null,
    quotaUsed?: number | null,
    totalRevenue?: number | null,
    appliedDiscountedVal?: number | null,
    usePerCustomer?: number | null,
    channelReq: Array< string | null >,
    customerEligibility: string,
    customerReq?: Array< string | null > | null,
    spendingExceeds?: number | null,
    spendingBelow?: number | null,
    orderSinceDate?: string | null,
    orderFromDate?: string | null,
    orderToDate?: string | null,
    isDisplayed?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePromocodeSubscriptionVariables = {
  filter?: ModelSubscriptionPromocodeFilterInput | null,
};

export type OnUpdatePromocodeSubscription = {
  onUpdatePromocode?:  {
    __typename: "Promocode",
    id: string,
    name: string,
    redemptions: number,
    status?: string | null,
    startDate: string,
    endDate: string,
    appliesTo: string,
    minOrderReq: string,
    minOrderVal?: number | null,
    minOrderQty?: number | null,
    tenderReq: Array< string | null >,
    discountVal: number,
    discountLimit?: number | null,
    quota?: number | null,
    quotaUsed?: number | null,
    totalRevenue?: number | null,
    appliedDiscountedVal?: number | null,
    usePerCustomer?: number | null,
    channelReq: Array< string | null >,
    customerEligibility: string,
    customerReq?: Array< string | null > | null,
    spendingExceeds?: number | null,
    spendingBelow?: number | null,
    orderSinceDate?: string | null,
    orderFromDate?: string | null,
    orderToDate?: string | null,
    isDisplayed?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePromocodeSubscriptionVariables = {
  filter?: ModelSubscriptionPromocodeFilterInput | null,
};

export type OnDeletePromocodeSubscription = {
  onDeletePromocode?:  {
    __typename: "Promocode",
    id: string,
    name: string,
    redemptions: number,
    status?: string | null,
    startDate: string,
    endDate: string,
    appliesTo: string,
    minOrderReq: string,
    minOrderVal?: number | null,
    minOrderQty?: number | null,
    tenderReq: Array< string | null >,
    discountVal: number,
    discountLimit?: number | null,
    quota?: number | null,
    quotaUsed?: number | null,
    totalRevenue?: number | null,
    appliedDiscountedVal?: number | null,
    usePerCustomer?: number | null,
    channelReq: Array< string | null >,
    customerEligibility: string,
    customerReq?: Array< string | null > | null,
    spendingExceeds?: number | null,
    spendingBelow?: number | null,
    orderSinceDate?: string | null,
    orderFromDate?: string | null,
    orderToDate?: string | null,
    isDisplayed?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePromocodeDashboardSubscriptionVariables = {
  filter?: ModelSubscriptionPromocodeDashboardFilterInput | null,
};

export type OnCreatePromocodeDashboardSubscription = {
  onCreatePromocodeDashboard?:  {
    __typename: "PromocodeDashboard",
    id: string,
    conceptID: string,
    totalRevenue?: number | null,
    totalRedemptions?: number | null,
    totalDiscount?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePromocodeDashboardSubscriptionVariables = {
  filter?: ModelSubscriptionPromocodeDashboardFilterInput | null,
};

export type OnUpdatePromocodeDashboardSubscription = {
  onUpdatePromocodeDashboard?:  {
    __typename: "PromocodeDashboard",
    id: string,
    conceptID: string,
    totalRevenue?: number | null,
    totalRedemptions?: number | null,
    totalDiscount?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePromocodeDashboardSubscriptionVariables = {
  filter?: ModelSubscriptionPromocodeDashboardFilterInput | null,
};

export type OnDeletePromocodeDashboardSubscription = {
  onDeletePromocodeDashboard?:  {
    __typename: "PromocodeDashboard",
    id: string,
    conceptID: string,
    totalRevenue?: number | null,
    totalRedemptions?: number | null,
    totalDiscount?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateLoyaltyItemSubscriptionVariables = {
  filter?: ModelSubscriptionLoyaltyItemFilterInput | null,
};

export type OnCreateLoyaltyItemSubscription = {
  onCreateLoyaltyItem?:  {
    __typename: "LoyaltyItem",
    id: string,
    name: string,
    description?: string | null,
    startDate: string,
    endDate?: string | null,
    points: number,
    redemptionPerUser?: number | null,
    image?: string | null,
    simphonyID: string,
    multiLanguages?: string | null,
    isActive?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateLoyaltyItemSubscriptionVariables = {
  filter?: ModelSubscriptionLoyaltyItemFilterInput | null,
};

export type OnUpdateLoyaltyItemSubscription = {
  onUpdateLoyaltyItem?:  {
    __typename: "LoyaltyItem",
    id: string,
    name: string,
    description?: string | null,
    startDate: string,
    endDate?: string | null,
    points: number,
    redemptionPerUser?: number | null,
    image?: string | null,
    simphonyID: string,
    multiLanguages?: string | null,
    isActive?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteLoyaltyItemSubscriptionVariables = {
  filter?: ModelSubscriptionLoyaltyItemFilterInput | null,
};

export type OnDeleteLoyaltyItemSubscription = {
  onDeleteLoyaltyItem?:  {
    __typename: "LoyaltyItem",
    id: string,
    name: string,
    description?: string | null,
    startDate: string,
    endDate?: string | null,
    points: number,
    redemptionPerUser?: number | null,
    image?: string | null,
    simphonyID: string,
    multiLanguages?: string | null,
    isActive?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateOTPSubscriptionVariables = {
  filter?: ModelSubscriptionOTPFilterInput | null,
};

export type OnCreateOTPSubscription = {
  onCreateOTP?:  {
    __typename: "OTP",
    id: string,
    OTP: string,
    redeemed?: boolean | null,
    deleted?: string | null,
    userID: string,
    conceptID?: string | null,
    loyaltyItemID?: string | null,
    loyaltyItem?:  {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID: string,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    concept?:  {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateOTPSubscriptionVariables = {
  filter?: ModelSubscriptionOTPFilterInput | null,
};

export type OnUpdateOTPSubscription = {
  onUpdateOTP?:  {
    __typename: "OTP",
    id: string,
    OTP: string,
    redeemed?: boolean | null,
    deleted?: string | null,
    userID: string,
    conceptID?: string | null,
    loyaltyItemID?: string | null,
    loyaltyItem?:  {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID: string,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    concept?:  {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteOTPSubscriptionVariables = {
  filter?: ModelSubscriptionOTPFilterInput | null,
};

export type OnDeleteOTPSubscription = {
  onDeleteOTP?:  {
    __typename: "OTP",
    id: string,
    OTP: string,
    redeemed?: boolean | null,
    deleted?: string | null,
    userID: string,
    conceptID?: string | null,
    loyaltyItemID?: string | null,
    loyaltyItem?:  {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID: string,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    concept?:  {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
