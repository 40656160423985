import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useApp from "../../../hooks/useApp";
import { useEffect, useState } from "react";
import ButtonLoader from "../ButtonLoader";
import useUpdating from "../../../hooks/useUpdating";
import useChoice from "../../../hooks/useChoice";
import useChoiceGroup from "../../../hooks/useChoiceGroup";
import useLoading from "../../../hooks/useLoading";
import ContentLoader from "../ContentLoader";
import { Choice } from "../../../models";
import { useSelector } from "react-redux";

interface Props {
  choiceGroupId: string;
  choiceId: string;
}
interface CreatePriceInput {
  name: string;
  price: number;
  precedence: string;
}
interface UpdatePriceInput {
  name?: string;
  price?: number;
  precedence: string;
}

const ChoiceModal: React.FC<Props> = ({ choiceGroupId, choiceId }) => {
  const session = useSelector((state: any) => state.app.session);
  const { loading, changeLoading } = useLoading();
  const [name, setName] = useState("");
  const [precedence, setPrecedence] = useState("");
  const [price, setPrice] = useState(0);
  const { hideSecondaryPopup, showError } = useApp();
  const { updating, changeUpdating } = useUpdating();
  const { choicesCreate, choicesGet, choicesUpdate } = useChoice(
    "choices",
    "choice"
  );
  const { choiceGroupsUpdate } = useChoiceGroup("choiceGroups", "choiceGroup");
  // console.log("choice modal")

  useEffect(() => {
    const fetch = async () => {
      const choice: Choice = await choicesGet({ id: choiceId });
      // console.log({choice})
      setName(choice.name);
      setPrice(choice.price);
      setPrecedence(choice.precedence || "0");
      changeLoading(false);
    };
    choiceId.length > 0 ? fetch() : changeLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setName(event.target.value);
  };
  const handlePriceChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPrice(parseInt(event.target.value));
  };
  const handlePrecedenceChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPrecedence(event.target.value);
  };

  const create = async () => {
    changeUpdating(true);

    if (name === "") {
      changeUpdating(false);
      return showError("Name is required");
    }

    if (!price) {
      setPrice(0);
    }

    const input: CreatePriceInput = {
      name,
      price,
      precedence
    };

    const choiceEntry = await choicesCreate({
      userID: session.sub,
      userName: session.name,
      input,
    });
    await choiceGroupsUpdate({
      id: choiceGroupId,
      data: {
        choices: [choiceEntry.id],
      },
    });

    changeUpdating(false);
    hideSecondaryPopup();
  };

  const update = async () => {
    changeUpdating(true);

    const updateInput: UpdatePriceInput = {
      name,
      price,
      precedence
    };
    await choicesUpdate({ id: choiceId, data: updateInput });
    await choiceGroupsUpdate({
      id: choiceGroupId,
      data: {
        choices: [choiceId],
      },
    });
    changeUpdating(false);

    hideSecondaryPopup();
  };

  const handleSave = () => {
    choiceId ? update() : create();
  };

  if (loading) return <ContentLoader />;

  return (
    <Box
      sx={{
        width: "70%",
        maxWidth: 792,
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
      }}
    >
      <Box
        sx={{
          backgroundColor: "grey.300",
          padding: "8px 16px",
          color: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">
          {choiceId.length === 0 ? "Add New Choice" : "Edit Choice"}
        </Typography>
        <CloseIcon
          aria-label="close modal"
          onClick={hideSecondaryPopup}
          sx={{ cursor: "pointer" }}
        />
      </Box>
      <Paper
        square
        elevation={0}
        sx={{ border: "1px solid", borderColor: "divider" }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ mb: 0.5 }}>
            <Typography component={"span"}>Name</Typography>
          </Box>
          <TextField
            placeholder={"Ketchup"}
            fullWidth
            size="small"
            value={name}
            onChange={(event) => handleNameChange(event)}
          />
        </Box>
        <Box sx={{ p: 2 }}>
          <Box sx={{ mb: 0.5 }}>
            <Typography component={"span"}>Precedence</Typography>
          </Box>
          <TextField
            placeholder={"precedence"}
            fullWidth
            size="small"
            value={precedence}
            onChange={(event) => handlePrecedenceChange(event)}
          />
        </Box>
        <Box sx={{ px: 2, pt: 0, pb: 2 }}>
          <Box sx={{ mb: 0.5 }}>
            <Typography component={"span"}>Price</Typography>
          </Box>
          <Box sx={{ position: "relative" }}>
            <Typography
              variant="body2"
              sx={{
                position: "absolute",
                top: "50%",
                left: 14,
                transform: "translateY(-50%)",
                color: "grey.600",
              }}
            >
              EGP
            </Typography>
            <TextField
              placeholder={"00"}
              fullWidth
              type={"number"}
              size="small"
              sx={{ "& .MuiOutlinedInput-input": { pl: 7 } }}
              value={price}
              onChange={(event) => handlePriceChange(event)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "flex-end",
            borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Button
            disableElevation
            variant="text"
            size="small"
            onClick={hideSecondaryPopup}
            sx={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            variant="contained"
            size="small"
            onClick={handleSave}
            sx={{ ml: 2, textTransform: "none" }}
            disabled={updating}
            endIcon={updating && <ButtonLoader />}
          >
            {choiceId.length === 0 ? "Add New Choice" : "Update Choice"}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default ChoiceModal;
